import {useToastContext} from "@/context/toast-context";
import {CHAT_DELETE_MESSAGE_PAIR} from "@/graphql/mutations/ai-mutations";
import {ChatDeleteMessagePairResponse, ChatDeleteMessagePairVariables} from "@/models/ai-model";
import {ChatState, ChatAction, ChatActionTypes} from "@/reducer/chat-reducer";
import {useMutation} from "@apollo/client";
import {Dispatch, useCallback} from "react";

interface DeleteMessageOptions {
  onSuccess?: () => void;
  onError?: () => void;
}

export type DeleteMessage = (messageId: string, options?: DeleteMessageOptions) => Promise<void>;

export const useDeleteMessage = (dispatch: Dispatch<ChatAction>): DeleteMessage => {
  const {updateToast} = useToastContext();

  const [deleteMessagePair] = useMutation<ChatDeleteMessagePairResponse, ChatDeleteMessagePairVariables>(CHAT_DELETE_MESSAGE_PAIR);

  return useCallback(async (messageId: string, options: DeleteMessageOptions = {}) => {
    await deleteMessagePair({
      variables: {messageId},
      onCompleted: (data) => {
        dispatch({type: ChatActionTypes.DELETE_MESSAGE_PAIR, payload: data});
        updateToast({
          description: "Message deleted",
          type: "success",
        })
        options.onSuccess?.();
      },
      onError: () => {
        updateToast({
          type: "failure",
          description: "Failed to delete message",
        });
        options.onError?.();
      }
    });
  }, [dispatch, updateToast]);
}

