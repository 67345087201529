import React, {ReactElement, useEffect, useState} from "react";

import {BaseModalProps, Body, Button, Input, Modal, TextArea} from "../../../shared/v2";
import {useValidation} from "../../../hooks/useValidation";
import {useWorkflowStateContext} from "@/context/workflow-contexts/workflow-state-context";

import styles from "./edit-workflow-modal.module.scss";


export const EditWorkflowModal = ({
	isOpen,
	onClose,
}: BaseModalProps):ReactElement => {
	const {workflow, updateSettings: updateWorkflowSettings} = useWorkflowStateContext();
	const [changes, setChanges] = useState({name: "", objective: ""});

	useEffect(() => {
		setChanges({
			name: workflow?.name || "",
			objective: workflow?.description || "",
		});
	}, [workflow]);

	const {results: validation, validate, clearErrors} = useValidation(changes, {
		name: {
			ruleset: {
				required: true,
				max: 150,
			},
		},
		objective: {
			ruleset: {
				required: true,
			},
		}
	});

	const handleChangeField = (value: string, field: string): void => {
		setChanges({
			...changes,
			[field]: value,
		});
	}

	const handleSave = ():void => {
		if (!validate()) {
			return;
		}

		updateWorkflowSettings({
			name: changes.name,
			description: changes.objective,
		}, {save: true});

		handleClose();
	}

	const handleClose = (): void => {
		setChanges({
			name: "",
			objective: "",
		});
		clearErrors();
		onClose();
	}

	return <>
		<Modal
			size="medium"
			isOpen={isOpen}
			onClose={handleClose}
			title="Edit Workflow"
			className={styles.modal}
		>
			<div className={styles.form}>
				<Input
					placeholder="My workflow"
					label="Workflow Name"
					value={changes.name}
					onChange={(value: string) => handleChangeField(value, "name")}
					validation={validation.name}
				/>

				<div className={styles.objectiveWrapper}>
					<div className={styles.textContainer}>
						<Body color="text-secondary" size="s" type="medium">Objective</Body>
						<Body color="text-tertiary"  size="s" type="regular">
              Define the overall objective for this workflow. This doesn&apos;t need to be highly specific, as you&apos;ll be able to assign detailed tasks to each agent later on.
						</Body>
					</div>
					<TextArea
						placeholder="e.g., Enhance marketing insights or Analyze customer feedback"
						value={changes.objective}
						onChange={(value: string) => handleChangeField(value, "objective")}
						validation={validation.objective}
					/>
				</div>

				<div className={styles.footer}>
					<Button
						className={styles.button}
						variant="outlined"
						onClick={handleClose}
					>
            Cancel
					</Button>

					<Button
						className={styles.button}
						onClick={handleSave}
					>
            Save
					</Button>
				</div>
			</div>
		</Modal>
	</>
}
