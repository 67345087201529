export const getImageBase64 = (image: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const scaleFactor = 1024 / img.width;
      canvas.width = 1024;
      canvas.height = img.height * scaleFactor;

      if (ctx) {
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        const base64 = canvas.toDataURL('image/jpeg').split(',')[1];
        resolve(base64);
      } else {
        reject(new Error('Failed to get 2D context'));
      }
    };
    img.src = URL.createObjectURL(image);
  });
}
