import React, {ReactElement, useMemo} from "react";

import {ButtonIcon, Subheader} from "@/shared/v2";
import {ChevronLeftIcon} from "@/icons";
import {MediaTable} from "../../media-table";
import {OverflowTooltip} from "@/shared/v2/tooltip/overflow-tooltip";
import {SelectSourcesPage, useNavigationContext} from "../../../contexts/navigation-context";
import {useSearchContext} from "../../../contexts/search-context";

import styles from "./select-dataset-media-tab.module.scss";

export const SelectDatasetMediaTab = (): ReactElement => {
  const {dataset, setCurrentPage} = useNavigationContext();
  const {search} = useSearchContext();

  const media = useMemo(() => {
    if (!dataset) {
      return [];
    }

    return [...dataset.files, ...dataset.videos].filter(({originalFilename}) => originalFilename.toLowerCase().includes(search.toLowerCase()));
  }, [dataset]);

  return (
    <div className={styles.selectDatasetMediaTab}>
      <div className={styles.controls}>
        <ButtonIcon
          filledIcon
          icon={<ChevronLeftIcon />}
          color="text-secondary"
          onClick={() => setCurrentPage(SelectSourcesPage.DATASETS)}
        />

        <OverflowTooltip>
          <Subheader
            type="semibold"
            color="text-body"
            size="l"
            className={styles.trainingSetName}
          >
            {dataset ? dataset.alias : "Dataset"}
          </Subheader>
        </OverflowTooltip>
      </div>

      <MediaTable media={media} />
    </div>
  );
}
