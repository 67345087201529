import classNames from "classnames/bind";
import React, {ReactElement, useMemo} from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from "remark-supersub";

import {useThemeMode} from "@/context/theme-mode-context";
import {Body, Tooltip} from "../../../../shared/v2";
import {GroundingEntry} from "../grounding-section/grounding-entry";
import {CodeBlockRenderer, LinkRenderer} from "./components";
import {useCitationContext} from "./contexts/citation-context";
import {addCitationsToContent, removeValidUrlText} from "./utils";
import {ChatFakeResponseMessage, ChatResponseMessage} from "@/reducer/chat-reducer";
import styles from "./content.module.scss";
import {useImageHistoryContext} from "@/canvas/image-studio/contexts";

const cx = classNames.bind(styles);

export interface ContentProps {
  message: ChatResponseMessage | ChatFakeResponseMessage;
}

export const Content = ({ message }: ContentProps): ReactElement => {
  const { isDarkMode } = useThemeMode();
  const { isCitationsVisible } = useCitationContext();
  const { setImageSrc } = useImageHistoryContext();

  const handleImageClick = (src: string) => {
    if (!src) return;
    
    try {
      const url = new URL(src);
      if (!url.protocol.startsWith('http')) {
        console.warn('Invalid image URL protocol:', src);
        return;
      }
      setImageSrc(src);
    } catch (error) {
      console.warn('Invalid image URL:', src, error);
    }
  };

  const isValidImageUrl = (url: string): boolean => {
    if (!url || typeof url !== 'string') return false;
    
    try {
      const parsedUrl = new URL(url);
      if (!parsedUrl.protocol.startsWith('http')) return false;
      
      // Check if URL ends with an image extension
      const imageExtRegex = /\.(png|jpe?g|gif|webp|bmp|svg)(\?.*)?$/i;
      return imageExtRegex.test(parsedUrl.pathname);
    } catch {
      return false;
    }
  };

  const extractImageUrls = (content: string): string[] => {
    if (!content) return [];
    
    // Match both markdown image syntax and direct URLs
    const markdownImageRegex = /!\[.*?\]\((https?:\/\/\S+?\.(png|jpe?g|gif|webp|bmp|svg)(\?[^)\s]*)?)\)/gi;
    const urlRegex = /(https?:\/\/\S+?\.(png|jpe?g|gif|webp|bmp|svg)(\?[^\s\)]*)?)/gi;
    
    const urls = new Set<string>();
    
    // Extract markdown image URLs
    let match;
    while ((match = markdownImageRegex.exec(content)) !== null) {
      if (isValidImageUrl(match[1])) {
        urls.add(match[1]);
      }
    }
    
    // Extract direct URLs
    while ((match = urlRegex.exec(content)) !== null) {
      if (isValidImageUrl(match[1])) {
        urls.add(match[1]);
      }
    }
    
    return Array.from(urls);
  };

  const renderImages = (urls: string[]) => {
    if (!urls.length) return null;
    
    return (
      <div className={styles.imageList}>
        {urls.map((url, index) => (
          <div key={index} className={styles.imageContainer}>
            <img
              src={url}
              className={styles.markdownImage}
              onClick={() => handleImageClick(url)}
              alt=""
            />
          </div>
        ))}
      </div>
    );
  };

  const citations = useMemo(() => {
    let citations = [] as any;

    message.groundingData?.forEach((grounding, index) => {
      grounding.citations.forEach((citation) => {
        citations.push({
          ...citation,
          groundingIndex: index + 1,
          grounding: grounding,
        });
      });
    });

    return citations;
  }, [message?.groundingData]);

  // Memoize image URLs extraction and content cleaning to avoid processing on every render
  const { imageUrls, contentWithoutImages } = useMemo(() => {
    // Extract image URLs from the original content
    const urls = extractImageUrls(message.content);
    
    // Create a cleaned content by removing image URLs
    const removeImageUrlsFromContent = (content: string, imgUrls: string[]): string => {
      if (!content || !imgUrls.length) return content;
      
      let cleanedContent = content;
      
      // Remove markdown image syntax
      const markdownImageRegex = /!\[.*?\]\((https?:\/\/\S+?\.(png|jpe?g|gif|webp|bmp|svg)(\?[^)\s]*)?)\)/gi;
      cleanedContent = cleanedContent.replace(markdownImageRegex, '');
      
      // Remove direct image URLs
      imgUrls.forEach(url => {
        cleanedContent = cleanedContent.replace(url, '');
      });
      
      // Clean up any double spaces or empty lines that might have been created
      cleanedContent = cleanedContent.replace(/\n\s*\n\s*\n/g, '\n\n');
      cleanedContent = cleanedContent.replace(/  +/g, ' ');
      
      return cleanedContent;
    };
    
    return {
      imageUrls: urls,
      contentWithoutImages: removeImageUrlsFromContent(message.content, urls)
    };
  }, [message.content]);
  
  // Further clean content and add citations
  const cleanContent = removeValidUrlText(contentWithoutImages);
  
  const contentWithCitations = addCitationsToContent(
    cleanContent,
    citations,
    isCitationsVisible
  );

  return (
    <Body
      className={cx(imageUrls.length ? styles.imageContent : styles.content)}
      color='text-secondary'>
      {renderImages(imageUrls)}
      <ReactMarkdown
        remarkPlugins={[remarkGfm, supersub]}
        components={{
          a: LinkRenderer,
          p: ({ children }) => (
            <span className={styles.paragraph}>{children}</span>
          ),
          code: CodeBlockRenderer as any,
          table: ({ children }) => (
            <table className={cx("markdownTable", { isDarkMode })}>
              {children}
            </table>
          ),
          thead: ({ children }) => <thead>{children}</thead>,
          tbody: ({ children }) => <tbody>{children}</tbody>,
          tr: ({ children }) => <tr>{children}</tr>,
          th: ({ children }) => <th>{children}</th>,
          td: ({ children }) => <td>{children}</td>,
          img: ({ src, alt }) => null, // Images are handled separately above
          sup: ({ children }) => {
            const citation =
              typeof children === "string" &&
              citations.find(
                (cit) => cit.groundingIndex === parseInt(children)
              );

            if (!citation?.grounding) {
              return (
                <sup className={cx("citation", { isDarkMode })}>
                  {children}
                </sup>
              );
            }
            return (
              <Tooltip
                placement='top'
                interactive
                containerClassname={styles.tooltipWrapper}
                className={styles.tooltip}
                content={
                  <GroundingEntry
                    position={citation.groundingIndex}
                    entry={citation.grounding}
                    tooltipInside={false}
                    citationMode={true}
                  />
                }>
                <sup className={cx("citation", { isDarkMode })}>
                  {children}&nbsp;
                </sup>
              </Tooltip>
            );
          },
        }}>
        {contentWithCitations}
      </ReactMarkdown>
    </Body>
  );
};
