import {RichTextarea, RichTextareaProps} from "rich-textarea";
import classNames from "classnames/bind";
import React, {useEffect, ReactElement, useRef, useMemo, HTMLAttributes} from "react";

import {Command} from "./command";
import {InsertMention} from "./insert-mention";
import {Mention} from "./mention";
import {Persona} from "../../../models";
import {TextToHTMLPatternReplacer} from "../../../shared/v2/text-to-html-pattern-replacer";
import {TextToHTMLPatternReplacerOption} from "../../../shared/v2/text-to-html-pattern-replacer/patterns";
import {useKeyboardEventMiddleware, useKeyboardEventMiddlewareContext} from "../../../context/keyboard-event-middleware-context";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./command-text-area.module.scss";

const bStyles = classNames.bind(styles);

export interface Commands {
	command: string;
	description: string;
	icon: string;
}

export interface CommandTextAreaProps extends Omit<RichTextareaProps, "onChange"> {
	value: string;
	onChange: (value: string) => void;
	commands?: Commands[];
	personas?: Persona[];
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	handleSubmit?: any;
	placeholder?: string;
	focusOnMount?: boolean;
	textAreaProps?: HTMLAttributes<HTMLTextAreaElement>
	className?: string;
	mention?: Persona | null;
}

const CommandTextArea = ({
	handleSubmit,
	commands,
	placeholder,
	focusOnMount = true,
	personas,
	value,
	textAreaProps,
	onChange,
	className,
	mention = null,
}: CommandTextAreaProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const textareaRef = useRef<HTMLTextAreaElement>(null);
	const {onKeyDown} = useKeyboardEventMiddlewareContext();
	const selectionStart = textareaRef.current?.selectionStart || 0;

	const resizeTextarea = (): void => {
		const textarea = textareaRef.current;

		if (!textarea) {
			return;
		}
		textarea.style.height = "0";
		textarea.style.height = `${textarea?.scrollHeight}px`;
	};

	useEffect(resizeTextarea, [textareaRef]);

	useEffect(() => {
		if (focusOnMount) {
			textareaRef.current?.focus();
		}
	}, [focusOnMount]);

	useEffect(() => {
		resizeTextarea();
	}, [value]);

	const updateSelection = (placement: number): void => {
		textareaRef.current?.focus();
		textareaRef.current?.setSelectionRange(placement, placement);
	}

	const handleInsertCommand = (command: string): void => {
		onChange(command);
		updateSelection(command.length);
	}

	const handleInsertMention = (agentName: string) => {
		let mention = `@${agentName}`;
		if (selectionStart === value.length || /[^\s]/.test(value[selectionStart])) {
			mention += " ";
		}

		for (let i = selectionStart - 1; i >= 0; i--) {
			if (value[i] === "@") {
				onChange(value.substring(0, i) + mention + value.substring(selectionStart));
				break;
			}
		}

		updateSelection(selectionStart + mention.length);
	}

	useKeyboardEventMiddleware((e) => {
		if (e.key === "Enter" && !e.shiftKey) {
			handleSubmit();
			e.preventDefault();
		}

		return e;
	}, {name: "onEnterWithoutShift", order: 0, deps: [handleSubmit]});

	const personaNames = personas?.map((persona) => persona.name) || [];

	const renderMention = (text: string) => {
		const persona = personas?.find((persona) => `@${persona.name}` === text);

		if (!persona) {
			return <>{text}</>;
		}

		return <Mention register persona={persona} />;
	}

	const patterns = useMemo(() => {
		const patterns = {}

		if (personas && !mention) {
			patterns[TextToHTMLPatternReplacerOption.InsertMention] = {
				render: (text) => (
					<InsertMention
						onInsert={handleInsertMention}
						text={text}
						personas={personas}
					/>
				),
				mentions: personaNames,
				selectionStart,
			}
		}

		if (personas) {
			patterns[TextToHTMLPatternReplacerOption.Mention] = {
				render: renderMention,
				mentions: personaNames,
			}
		}

		if (commands) {
			patterns[TextToHTMLPatternReplacerOption.Command] = {
				render: (text) => <Command text={text} onInsert={handleInsertCommand} commands={commands} />,
				commands: commands.map((cmd) => cmd.command.substring(1)),
				selectionStart,
			};
		}

		return patterns;
	}, [commands, personas, selectionStart])

	return (
		<RichTextarea
			id="command-text-area"
			suppressContentEditableWarning={true}
			ref={textareaRef}
			value={value}
			style={{width: "100%"}}
			placeholder={placeholder}
			contentEditable
			onChange={(e) => onChange(e.target.value)}
			onKeyDown={onKeyDown}
			className={bStyles("textarea", "textareaContent", className, {isDarkMode})}
			{...textAreaProps}
		>
			{(text) => (
				<TextToHTMLPatternReplacer
					text={text}
					className={bStyles("textareaContent", {isDarkMode})}
					patterns={patterns}
				/>
			)}
		</RichTextarea>
	);
};

export default CommandTextArea;
