import React, {ReactElement} from "react";

import {useChatConversationContext} from "../../../context/chat-contexts";
import {CloseIconWrapper} from "../close-icon-wrapper";

import styles from "./uploaded-image-section.module.scss";

export const UploadedImageSection = (): ReactElement => {
	const {
		conversation: {
			nextQuestion: {image}
		},
		update: updateConversation
	} = useChatConversationContext();

	const clearImage = () => {
		updateConversation({nextQuestion: {image: null}});
	}

	if (!image) {
		return <></>;
	}

	return (
		<CloseIconWrapper onClose={clearImage} className={styles.thumbnailWrapper}>
			<img
				src={URL.createObjectURL(image)}
				alt="Uploaded file thumbnail"
				className={styles.thumbnail}
			/>
		</CloseIconWrapper>
	);
}
