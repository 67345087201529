import React, { ReactElement } from "react";
import styles from "./Recruit.module.scss";
import { RecruitProvider } from "@/campaign/contexts/RecruitContext";
import { TopBar, Steps, StepsContent } from "@/campaign/components/recruit";
import { RecruitQuestionsProvider } from "@/campaign/contexts/RecruitQuestionsContext";
import { ThemeSwitcher } from "@/campaign/components/ThemeSwitcher";
export const RecruitPage = (): ReactElement => {
  return (
    <RecruitProvider>
      <RecruitQuestionsProvider>
        <div className={styles.wrapper}>
          <TopBar />
          <div className={styles.contentWrapper}>
            <ThemeSwitcher className={styles.themeSwitcher} />
            <Steps />
            <StepsContent />
          </div>
        </div>
      </RecruitQuestionsProvider>
    </RecruitProvider>
  );
};
