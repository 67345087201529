import React, { ReactElement, useMemo } from "react";
import classNames from "classnames/bind";

import { SelectInput, SelectInputProps } from "@/shared/v2/inputs/select-input";
import { InputChip } from "@/shared/components/input-chip";
import { SelectValue } from "@/campaign/contexts/RecruitContext";
import { useThemeMode } from "@/context/theme-mode-context";

import styles from "./SelectWithAll.module.scss";

const cx = classNames.bind(styles);

export type SelectWithAllProps = Omit<SelectInputProps, "onChange"> & {
  selectedItems: SelectValue[];
  allItemsLabel?: string;
  onChange: (items: SelectValue[]) => void;
  selectionLimit?: number;
};

export const SelectWithAll = ({
  id,
  options,
  onChange,
  placeholder,
  selectedItems,
  selectionLimit,
  allItemsLabel = "All",
}: SelectWithAllProps): ReactElement => {
  const { isDarkMode } = useThemeMode();

  const handleRemove = (id: string) => () => {
    const updatedItems = selectedItems.filter((item) => item.id !== id);
    onChange(updatedItems);
  };

  const handleSelect = (selectedValue: string) => {
    if (selectedValue === "all") {
      onChange([]);
      return;
    }
    if (selectionLimit && selectedItems.length >= selectionLimit) {
      return;
    }

    const selectedItem = options.find(
      (industry) => industry.id === selectedValue
    );

    if (
      selectedItem &&
      selectedItems.find((industry) => industry.id === selectedItem.id)
    ) {
      return;
    }
    selectedItem && onChange([...selectedItems, selectedItem]);
  };

  const allItemsLabelName = useMemo(() => {
    return options.find((option) => option.id === "all")?.name || allItemsLabel;
  }, [options, allItemsLabel]);

  return (
    <div className={cx("selectWrapper", { isDarkMode })}>
      <SelectInput
        id={id}
        options={options}
        onChange={handleSelect}
        placeholder={placeholder}
      />

      <div className={styles.chipsContainer}>
        {!selectedItems.length && (
          <InputChip
            label={allItemsLabelName}
            variant='squared'
            showCloseIcon={false}
          />
        )}
        {selectedItems.map((item, index) => (
          <InputChip
            key={index}
            label={item.name}
            variant='squared'
            onRemove={handleRemove(item.id)}
          />
        ))}
      </div>
    </div>
  );
};
