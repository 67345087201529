import React, {ReactElement, ReactNode, createContext, useEffect, useMemo, useState} from "react";

import {Campaign} from "@/models/ai-model";
import {useSelectedQuestionsContext} from "./selected-questions-context";
import {usePropsContext} from "./props-context";

export interface SelectedCampaignsContextValue {
  selected: Campaign[];
  isDirty: boolean;
  add: (source: Campaign[]) => void;
  remove: (source: Campaign[]) => void;
  set: (sources: Campaign[]) => void;
}

export const SelectedCampaignsContext =
  createContext<SelectedCampaignsContextValue | undefined>(undefined);

export const SelectedCampaignsContextProvider = (
  {children}: {children: ReactNode},
): ReactElement => {
  const {
    init: {
      campaigns: initCampaigns,
      questions: initQuestions,
    }
  } = usePropsContext();
  const {
    selected: selectedQuestion,
    add: addQuestions,
    remove: removeQuestions,
  } = useSelectedQuestionsContext();
  const [selected, setSelected] = useState<Campaign[]>([...initCampaigns]);

  useEffect(() => {
    setSelected([...initCampaigns]);
  }, [initCampaigns.map(({id}) => id).sort().join(",")]);

  const isDirty = useMemo(() => {
    return isChanged(initCampaigns, selected);
  }, [initCampaigns, selected]);

  const addQuestionsFromCampaign = (campaign: Campaign): void => {
    const questionsToAdd = initQuestions.filter(({surveyId}) => campaign.id === surveyId);

    addQuestions(questionsToAdd);
  }

  const removeQuestionsFromCampaign = (campaign: Campaign): void => {
    const questionsToRemove = selectedQuestion.filter(({surveyId}) => campaign.id === surveyId);

    removeQuestions(questionsToRemove);
  }

  const addCampaigns = (campaigns: Campaign[]): void => {
    campaigns.forEach(removeQuestionsFromCampaign);
    setSelected([...selected, ...campaigns]);
  }

  const removeCampaigns = (campaigns: Campaign[]): void => {
    campaigns.forEach(addQuestionsFromCampaign);
    setSelected(selected.filter((selectedCampaign) => !campaigns.map(({id}) => id).includes(selectedCampaign.id)));
  }

  const setCampaigns = (campaigns: Campaign[]): void => {
    const addedCampaigns = campaigns.filter((campaign) => !selected.map(({id}) => id).includes(campaign.id));
    const removedCampaigns = selected.filter((campaign) => !campaigns.map(({id}) => id).includes(campaign.id));

    addedCampaigns.forEach(addQuestionsFromCampaign);
    removedCampaigns.forEach(removeQuestionsFromCampaign);

    setSelected(campaigns);
  }

  return (
    <SelectedCampaignsContext.Provider value={{
      selected,
      isDirty,
      add: addCampaigns,
      remove: removeCampaigns,
      set: setCampaigns,
    }}>
      {children}
    </SelectedCampaignsContext.Provider>
  );
};

export const useSelectedCampaignsContext = (): SelectedCampaignsContextValue => {
  const context = React.useContext(SelectedCampaignsContext);

  if (context === undefined) {
    throw new Error(
      "useSelectedCampaignsContext must be used within a SelectedCampaignsContextProvider",
    );
  }

  return context;
};

function isChanged(original: Campaign[], current: Campaign[]): boolean {
  const originalIds = original.map((campaign) => campaign.id);

  return (
    original.length !== current.length ||
    current.some(({id}) => !originalIds.includes(id))
  );
}
