import {ChatActionTypes, ChatConversationMessageSubtype, ChatFakeResponseMessage, ChatQueryMessage, ChatResponseMessage, ChatState, HandleConversationEventPayload, initialState, ReducedChatConversationMessage} from "@/reducer/chat-reducer";
import {ConversationEventTypes} from "@/models/subscriptions";
import {extractChatChanges} from "./extract-chat-changes";
import {formatReducedChatConversationMessages} from ".";
import {handleRevertAction} from "./handle-revert-action";
import {isChatFakeQueryMessage, isChatFakeResponseMessage} from "../type-guards";
import {formatReducedChatConversation} from "./format-reduced-chat-conversation";

const assertNever = (state: never): never => {
  throw new Error("Unexpected action: " + JSON.stringify(state));
}

export const handleConversationEvent = (state: ChatState, payload: HandleConversationEventPayload): ChatState => {
  const {type: event, data} = payload;

  switch (event) {
    case ConversationEventTypes.NAME_CHANGED: {
      if (state.original?.id !== data.conversationId) {
        return state;
      }

      const newOriginal = {
        ...state.original,
        name: data.name,
      }
      const newCurrent = {
        ...state.current,
        name: data.name,
      }
      const newChanges = extractChatChanges(newOriginal, newCurrent);

      return {
        ...state,
        original: newOriginal,
        current: newCurrent,
        changes: newChanges,
      }
    }
    case ConversationEventTypes.SOURCES_CHANGED: {
      // TODO: Refactor this event

      return state;
    }
    case ConversationEventTypes.PERSONA_CHANGED: {
      // TODO: Refactor this event

      return state;
    }
    case ConversationEventTypes.RESPONSE_ERROR_OCCURED: {
      const newState = handleRevertAction(state, {
        action: ChatActionTypes.SEND_QUESTION,
      });

      return newState;
    }
    case ConversationEventTypes.RESPONSE_STATE_CHANGED: {
      const {
        current,
        actions: {
          isSendingQuestion,
        }
      } = state;

      if (
        current?.id !== data.conversationId ||
        !isSendingQuestion
      ) {
        return state;
      }

      const newMessages = current.messages.map(message => {
        if (isChatFakeResponseMessage(message)) {
          const newStateHistory = [...message.stateHistory, data.content];

          return {
            ...message,
            stateHistory: newStateHistory,
          };
        }

        return message;
      })
      const newCurrent = {
        ...current,
        messages: newMessages,
      }

      return {
        ...state,
        current: newCurrent,
      }
    }
    case ConversationEventTypes.STARTED: {
      /**
       * We will need to handle this event
       * while implementing shared chats. For now,
       * there's no need to.
       */

      return state;
    }
    case ConversationEventTypes.RESPONSE_CREATED: {
      const {
        current: {
          id: conversationId,
        },
        actions: {
          isSendingQuestion,
        }
      } = state;
      const {
        conversationId: eventConversationId,
      } = payload.data;

      if (conversationId !== eventConversationId || !isSendingQuestion) {
        return state;
      }

      const newMessages = state.current.messages.map((message) => {
        if (isChatFakeResponseMessage(message)) {
          const {stateHistory, ...props} = message;
          return {
            ...props,
            content: data.content,
            type: data.type,
            suggestedFollowUps: [],
            likedByUsers: [],
            dislikedByUsers: [],
            groundingData: data.groundingData,
            subtype: ChatConversationMessageSubtype.RESPONSE,
            id: data.conversationResponseId,
          } as ChatResponseMessage;
        }

        return message;
      })
      const newNextQuestion = {...initialState.current.nextQuestion}
      const newCurrent = formatReducedChatConversation({
        ...state.current,
        messages: newMessages,
        nextQuestion: newNextQuestion,
      });
      const newActions = {
        ...state.actions,
        isSendingQuestion: false,
      }

      return {
        ...state,
        actions: newActions,
        current: newCurrent,
      };
    }
    case ConversationEventTypes.USER_MESSAGE_CREATED: {
      if (state.original && state.original.id !== data.conversationId) {
        return state;
      }

      let newMessages: ReducedChatConversationMessage[] = [];

      for (let index = 0; index < state.current.messages.length; index++) {
        const currentMessage = state.current.messages[index];

        if (isChatFakeQueryMessage(currentMessage)) {
          const newMessage = {
            ...currentMessage,
            id: data.questionId,
            content: data.content,
            position: data.position,
            subtype: ChatConversationMessageSubtype.QUERY,
          } as ChatQueryMessage;

          newMessages.push(newMessage);
          continue;
        }

        // If query message was updated the position of fake response message is lower than the previous message
        // so we need to update the position of the fake response message.
        if (isChatFakeResponseMessage(currentMessage)) {
          const previousMessage = newMessages[index - 1];

          if (previousMessage.position > currentMessage.position) {
            newMessages.push({
              ...currentMessage,
              position: previousMessage.position + 1,
            })
            continue;
          }
        }

        newMessages.push(currentMessage);
      }
      const newCurrent = {
        ...state.current,
        messages: formatReducedChatConversationMessages(newMessages),
      }

      return {
        ...state,
        current: newCurrent,
      };
    }
    default: {
      return assertNever(event);
    }
  }
}
