import React, {Dispatch, ReactElement, ReactNode, SetStateAction, createContext, useContext, useState} from "react";

export interface ChatDrawerContextProps {
  children?: ReactNode;
}

export interface ChatDrawerContextValue {
  isDrawerOpen: boolean;
  setIsDrawerOpen: Dispatch<SetStateAction<boolean>>;
}

export const ChatDrawerContext =
  createContext<ChatDrawerContextValue | undefined>(undefined);

export const ChatDrawerContextProvider = (
  {children}: ChatDrawerContextProps,
): ReactElement => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const contextValue = {
    isDrawerOpen,
    setIsDrawerOpen,
  };

  return (
    <ChatDrawerContext.Provider value={contextValue}>
      {children}
    </ChatDrawerContext.Provider>
  );
};

export const useChatDrawerContext = (): ChatDrawerContextValue => {
  const context = useContext(ChatDrawerContext);

  if (context === undefined) {
    throw new Error(
      "useChatDrawerContext must be used within a ChatDrawerContextProvider",
    );
  }

  return context;
};
