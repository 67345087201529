import React, { createContext, useContext, useState, ReactNode } from "react";
import { v4 as uuidv4 } from "uuid";
type AnswerType = "qualify" | "disqualify" | "maySelect" | "mustSelect";

export interface Answer {
  id: string;
  text: string;
  type: AnswerType;
  order: number;
}

export interface QualifyingQuestion {
  question: string;
  answers: Answer[];
  allowCustomAnswer: boolean;
  order: number;
  type: "single" | "multiple";
}

interface RecruitQuestionsContextType {
  questions: QualifyingQuestion[];
  addQuestion: (question: QualifyingQuestion) => void;
  updateQuestion: (index: number, question: QualifyingQuestion) => void;
  removeQuestion: (index: number) => void;
  reorderQuestions: (startIndex: number, endIndex: number) => void;
  addAnswer: (questionIndex: number, answer: Answer) => void;
  updateAnswer: (
    questionIndex: number,
    answerIndex: number,
    answer: Answer
  ) => void;
  removeAnswer: (questionIndex: number, answerIndex: number) => void;
  reorderAnswers: (
    questionIndex: number,
    startIndex: number,
    endIndex: number
  ) => void;
  bulkAddAnswers: (questionIndex: number, answers: Answer[]) => void;
}

const RecruitQuestionsContext = createContext<
  RecruitQuestionsContextType | undefined
>(undefined);

export const useRecruitQuestionsContext = () => {
  const context = useContext(RecruitQuestionsContext);
  if (!context) {
    throw new Error(
      "useRecruitQuestions must be used within a RecruitQuestionsProvider"
    );
  }
  return context;
};

interface RecruitQuestionsProviderProps {
  children: ReactNode;
}

export const RecruitQuestionsProvider: React.FC<
  RecruitQuestionsProviderProps
> = ({ children }) => {
  const initialQuestions: QualifyingQuestion[] = [
    {
      question: "What is your name?",
      allowCustomAnswer: false,
      order: 0,
      type: "single",
      answers: [
        {
          id: uuidv4(),
          text: "Yes",
          type: "qualify",
          order: 1,
        },
        {
          id: uuidv4(),
          text: "No",
          type: "disqualify",
          order: 0,
        },
        {
          id: uuidv4(),
          text: "trzo",
          type: "disqualify",
          order: 2,
        },
      ],
    },
    {
      question: "What is your email?",
      allowCustomAnswer: false,
      order: 1,
      type: "single",
      answers: [
        {
          id: uuidv4(),
          text: "",
          type: "qualify",
          order: 1,
        },
        {
          id: uuidv4(),
          text: "",
          type: "disqualify",
          order: 0,
        },
      ],
    },
  ];

  const [questions, setQuestions] =
    useState<QualifyingQuestion[]>(initialQuestions);

  const addQuestion = (question: QualifyingQuestion) => {
    setQuestions((prev) => [...prev, { ...question, order: prev.length }]);
  };

  const updateQuestion = (index: number, question: QualifyingQuestion) => {
    setQuestions((prev) => {
      const newQuestions = [...prev];
      newQuestions[index] = question;
      return newQuestions;
    });
  };

  const removeQuestion = (index: number) => {
    setQuestions((prev) => {
      const newQuestions = prev.filter((_, i) => i !== index);
      // Update order for remaining questions
      return newQuestions.map((q, i) => ({ ...q, order: i }));
    });
  };

  const reorderQuestions = (startIndex: number, endIndex: number) => {
    setQuestions((prev) => {
      const newQuestions = [...prev];
      const [removed] = newQuestions.splice(startIndex, 1);
      newQuestions.splice(endIndex, 0, removed);
      // Update order for all questions
      return newQuestions.map((q, i) => ({ ...q, order: i }));
    });
  };

  const addAnswer = (questionIndex: number, answer: Answer) => {
    setQuestions((prev) => {
      const newQuestions = [...prev];
      const question = { ...newQuestions[questionIndex] };
      question.answers = [
        ...question.answers,
        { ...answer, order: question.answers.length },
      ];
      newQuestions[questionIndex] = question;
      return newQuestions;
    });
  };

  const updateAnswer = (
    questionIndex: number,
    answerIndex: number,
    answer: Answer
  ) => {
    setQuestions((prev) => {
      const newQuestions = [...prev];
      const question = { ...newQuestions[questionIndex] };
      question.answers = [...question.answers];
      question.answers[answerIndex] = answer;
      newQuestions[questionIndex] = question;
      return newQuestions;
    });
  };

  const removeAnswer = (questionIndex: number, answerIndex: number) => {
    setQuestions((prev) => {
      const newQuestions = [...prev];
      const question = { ...newQuestions[questionIndex] };
      question.answers = question.answers
        .filter((_, index) => index !== answerIndex)
        .map((answer, index) => ({ ...answer, order: index }));
      newQuestions[questionIndex] = question;
      return newQuestions;
    });
  };

  const reorderAnswers = (
    questionIndex: number,
    startIndex: number,
    endIndex: number
  ) => {
    setQuestions((prev) => {
      const newQuestions = [...prev];
      const question = { ...newQuestions[questionIndex] };
      const answers = [...question.answers];
      const [removed] = answers.splice(startIndex, 1);
      answers.splice(endIndex, 0, removed);

      // Update order for all answers
      question.answers = answers.map((answer, index) => ({
        ...answer,
        order: index,
      }));

      newQuestions[questionIndex] = question;
      return newQuestions;
    });
  };

  const bulkAddAnswers = (questionIndex: number, newAnswers: Answer[]) => {
    setQuestions((prev) => {
      const newQuestions = [...prev];
      const question = { ...newQuestions[questionIndex] };
      const currentLength = question.answers.length;
      question.answers = [
        ...question.answers,
        ...newAnswers.map((answer, index) => ({
          ...answer,
          order: currentLength + index,
        })),
      ];
      newQuestions[questionIndex] = question;
      return newQuestions;
    });
  };

  const value = {
    questions,
    addQuestion,
    updateQuestion,
    removeQuestion,
    reorderQuestions,
    addAnswer,
    updateAnswer,
    removeAnswer,
    reorderAnswers,
    bulkAddAnswers,
  };

  return (
    <RecruitQuestionsContext.Provider value={value}>
      {children}
    </RecruitQuestionsContext.Provider>
  );
};
