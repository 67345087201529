import classNames from "classnames/bind";
import React, { ReactElement } from "react";

import { Caption } from "../../../../shared/v2/typography";
import { Tooltip } from "../../../../shared/v2/tooltip";
import { FileEmbeddingsGenerationStatus } from "@/models/ai-model";
import { PersonaTransformedStatus } from "../../../../models/persona";
import { SurveyStatus } from "../../../../models/survey";
import { useThemeMode } from "../../../../context/theme-mode-context";

import styles from "./badge.module.scss";

const bStyles = classNames.bind(styles);

export interface BadgeProps {
  text:
    | "foundation"
    | "transcript"
    | "question"
    | "campaign"
    | "creator"
    | "answer-video"
    | "language"
    | "speech"
    | "multimodal"
    | "video"
    | "document"
    | "image"
    | "generative"
    | "active"
    | "inactive"
    | SurveyStatus
    | PersonaTransformedStatus
    | FileEmbeddingsGenerationStatus
    | "In progress"
    | "beta"
    | "Cooming Soon";
  tooltip?: string;
}

export const Badge = ({ text, tooltip }: BadgeProps): ReactElement => {
  const { isDarkMode } = useThemeMode();

  const badge = (
    <div
      className={bStyles("badge", text?.toLowerCase().replaceAll(" ", ""), {
        isDarkMode,
      })}>
      <Caption
        type='medium'
        className={bStyles("text", text?.toLowerCase().replaceAll(" ", ""))}>
        {text?.toLowerCase()}
      </Caption>
    </div>
  );

  if (tooltip) {
    return <Tooltip content={tooltip}>{badge}</Tooltip>;
  }

  return badge;
};
