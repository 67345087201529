import React, {ReactElement, ReactNode} from "react";
import {
	ChatConversationContextProvider,
	ChatDrawerContextProvider,
	ChatScrollContextProvider,
} from "../../context/chat-contexts";
import {ImageHistoryContextProvider} from "../image-studio/contexts";

export interface ChatContextsAndHandlersProps {
  children?: ReactNode;
}

export const ChatContextsAndHandlers = ({children}: ChatContextsAndHandlersProps): ReactElement => {
	return (
		<ChatConversationContextProvider>
			<ImageHistoryContextProvider>
				<ChatScrollContextProvider>
					<ChatDrawerContextProvider>
						{children}
					</ChatDrawerContextProvider>
				</ChatScrollContextProvider>
			</ImageHistoryContextProvider>
		</ChatConversationContextProvider>
	);
}
