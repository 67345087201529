import React, {createRef, ReactElement, ReactNode, useCallback, useMemo, useState} from "react";
import classNames from "classnames/bind";

import {Body} from "../../typography";
import {Tooltip, TooltipProps as BaseTooltipProps} from "..";
import {useObserver} from "../../../../hooks/useObserver";

import styles from "./overflow-tooltip.module.scss";

const cx = classNames.bind(styles);

type TooltipProps = Omit<BaseTooltipProps, "content" | "children">;

// Checks if the text is overflowing the element or its children
const isTextOverflowing = (element: HTMLElement): boolean => {
	const children = Array.from(element.childNodes);

	if (!children.length) {
		return false;
	}

	const textNodes = children.filter((child) => child.nodeType === Node.TEXT_NODE);

	if (textNodes.length) {
		return (
			(element.scrollWidth > element.clientWidth) ||
      (element.scrollHeight > element.clientHeight)
		);
	}

	return children.some((child) => isTextOverflowing(child as HTMLElement));
}

export type OverflowTooltipProps = TooltipProps & {
  content: string;
} | {
  children: ReactNode
};

export const OverflowTooltip = (props: OverflowTooltipProps): ReactElement => {
	const ref = createRef<HTMLDivElement>();

	const {content, ...rest} = useMemo(() => {
		if ("content" in props) {
			const {content, ...rest} = props;
			return {content, ...(rest as TooltipProps)};
		}

		const {children, ...rest} = props;
		return {content: children, ...(rest as TooltipProps)};
	}, [props]);

	const [isOverflowing, setIsOverflowing] = useState(false);

	const handleResize = useCallback((entries: ResizeObserverEntry[]) => {
		const {target} = entries[0];
		setIsOverflowing(isTextOverflowing(target as HTMLElement));
	}, [setIsOverflowing]);

	useObserver(ref, ResizeObserver, handleResize);

	const renderContent = () => {
		if (isOverflowing) {
			return (
				<Tooltip
					{...rest}
					className={cx(styles.window, rest.className)}
					content={
						<Body size="s">
							{content}
						</Body>
					}
					containerClassname={cx(styles.tooltip, rest.containerClassname)}
				>
					<>
						{content}
					</>
				</Tooltip>
			);
		}

		return content;
	}

	return (
		<div ref={ref} className={styles.overflowToolitpWrapper}>
			{renderContent()}
		</div>
	);
}
