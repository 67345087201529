import React, { ReactNode } from "react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";

import styles from "./Accordion.module.scss";
import { Body, Separator } from "@/shared/v2";
import { Icon } from "@/shared";
import { ChevronDownIcon } from "@/icons";
import { useThemeMode } from "@/context/theme-mode-context";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

export interface AccordionProps {
  title: string | ReactNode;
  isOptional?: boolean;
  children: ReactNode;
  triggerPosition?: "left" | "right";
}

export const Accordion = ({
  title,
  children,
  isOptional,
  triggerPosition = "left",
}: AccordionProps) => {
  const { isDarkMode } = useThemeMode();

  return (
    <AccordionPrimitive.Root
      type='single'
      collapsible
      className={cx("accordion", { isDarkMode })}>
      <AccordionPrimitive.Item value='item' className={styles.item}>
        <AccordionPrimitive.Header className={styles.header}>
          <AccordionPrimitive.Trigger className={styles.trigger}>
            <ChevronDownIcon className={cx("chevronIcon", triggerPosition)} />
            {typeof title === "string" ? (
              <div className={styles.title}>
                <Body size='l' type='semibold'>
                  {title}
                </Body>
                {isOptional && (
                  <Body size='l' color='text-secondary'>
                    (optional)
                  </Body>
                )}
              </div>
            ) : (
              title
            )}
          </AccordionPrimitive.Trigger>
        </AccordionPrimitive.Header>
        <AccordionPrimitive.Content className={styles.content}>
          <Separator color='recruit' className={styles.separator} />
          {children}
        </AccordionPrimitive.Content>
      </AccordionPrimitive.Item>
    </AccordionPrimitive.Root>
  );
};
