import {ChatCommerceTool, ChatGatedTool, ChatImageTool, ChatSocialTool, ChatState, ChatToolValue, ChatWebTool, SendQuestionPayload} from "@/reducer/chat-reducer";
import {extractChatChanges} from "./extract-chat-changes";
import {handleCreateConversation} from "./handle-create-conversation";
import {generateChatFakeQueryMessage, generateChatFakeResponseMessage} from "./generate-chat-fake-message";
import {formatReducedChatConversationMessages} from ".";

const COMMANDS: Record<ChatToolValue | "agent", string | null> = {
  ["agent"]: null,
  [ChatSocialTool.LINKEDIN]: "/linkedin",
  [ChatSocialTool.REDDIT]: "/reddit",
  [ChatSocialTool.TIKTOK]: "/tiktok",
  [ChatSocialTool.YOUTUBE]: "/youtube",
  [ChatSocialTool.X_TWITTER]: "/twitter",
  [ChatSocialTool.INSTAGRAM]: "/instagram",
  [ChatImageTool.ALL]: "/image",
  [ChatImageTool.DALLE]: "/imagede",
  [ChatImageTool.IMAGEN]: "/imagen",
  [ChatImageTool.STABLE_DIFFUSION]: "/imagesd",
  [ChatWebTool.SCRAPE]: "/scrape",
  [ChatWebTool.SCRAPE_ADVANCED]: "/scrape_advanced",
  [ChatWebTool.WEB]: "/web",
  [ChatCommerceTool.AMAZON]: null,
  [ChatGatedTool.WORKSPACE]: null,
}

export const handleSendQuestion = (state: ChatState, payload: SendQuestionPayload): ChatState => {
  // Log the action to help with debugging
  console.log('[Chat] Send question action initiated:', {
    query: payload.query,
    isCreatingNew: payload.createNewConversation,
    currentConversationId: state.current.id,
    timestamp: new Date().toISOString()
  });

  const {
    query,
    createNewConversation,
    conversationInput = {},
    nextQuestionInput = {},
  } = payload;

  let newNextQuestion = {
    ...state.current.nextQuestion,
    ...nextQuestionInput,
    query,
  }
  let newCurrent = {
    ...state.current,
    ...(createNewConversation ? conversationInput : {}),
    nextQuestion: newNextQuestion,
  }

  const command = newCurrent.tool ? COMMANDS[newCurrent.tool] : null;
  const newQuery = command ? `${command} ${newNextQuestion.query}` : newNextQuestion.query;

  newNextQuestion = {
    ...newNextQuestion,
    query: newQuery,
  }
  newCurrent = {
    ...newCurrent,
    nextQuestion: newNextQuestion,
  }

  const currentAgent =
    newNextQuestion.persona ||
    newNextQuestion.mention ||
    newCurrent.aiPersona ||
    undefined;

  const lastMessagePosition = newCurrent.messages.length > 0 ? newCurrent.messages[newCurrent.messages.length - 1].position : -1;
  const nextMessagePosition = lastMessagePosition + 1;

  // Create a more permanent-like fake query message that won't disappear as easily
  const userMessage = generateChatFakeQueryMessage({
    conversationId: newCurrent.id ?? undefined,
    content: newNextQuestion.query,
    position: nextMessagePosition,
    // Add a timestamp to help with debugging
    sentAt: new Date().toISOString(),
  });
  
  const loadingMessage = generateChatFakeResponseMessage({
    persona: currentAgent ?? undefined,
    conversationId: newCurrent.id ?? undefined,
    position: nextMessagePosition + 1,
  });

  console.log('[Chat] Created fake messages:', {
    userMessageId: userMessage.id,
    loadingMessageId: loadingMessage.id,
    userContent: userMessage.content,
    timestamp: new Date().toISOString()
  });

  const newMessages = formatReducedChatConversationMessages([...newCurrent.messages, userMessage, loadingMessage]);
  newCurrent = {
    ...newCurrent,
    messages: newMessages,
  }
  const newChanges = extractChatChanges(state.original, newCurrent);
  const newActions = {
    ...state.actions,
    isSendingQuestion: true,
  }

  let newState: ChatState = {
    ...state,
    actions: newActions,
    changes: newChanges,
    current: newCurrent,
  }

  const newIsCreating = !Boolean(state.original) || Boolean(createNewConversation);

  if (newIsCreating) {
    newState = handleCreateConversation(newState, conversationInput);
  }

  return newState;
}
