import React, {ReactElement, useState} from "react";
import {AnimatePresence, motion, Variants} from "framer-motion";

import {Chat} from "../chat";
import {ChatBubble} from "../chat-bubble";
import {ChatLayoutMode} from "@/reducer/chat-reducer";
import {ChatNamePill} from "../chat/chat-name-pill";
import {Header, Subheader} from "../../shared/v2";
import {useChatConversationContext} from "@/context/chat-contexts";
import {useElementSize} from "@/hooks/useElementSize";
import {useUserContext} from "../../context/user-context";
import {variants, transition} from "./animation-helpers";

import styles from "./chat-view.module.scss";

export const ChatView = (): ReactElement => {
	const {user} = useUserContext();
	const {conversation: {layout}} = useChatConversationContext();
	const [homeContentElement, setHomeContentElement] = useState<HTMLDivElement | null>(null);
	const {height: homeContentHeight} = useElementSize(homeContentElement);

	const containerVariants = variants({
		[ChatLayoutMode.HOME]: {
			paddingTop: "160px",
			height: `${107 + homeContentHeight + 32}px`,
		},
		[ChatLayoutMode.CHAT]: {
			paddingTop: "0px",
			height: "calc(100% - 32px)",
		}
	});

	const chatContentWrapperVariants: Variants = variants({
		[ChatLayoutMode.HOME]: {
			opacity: 0,
			y: "-100%",
		},
		[ChatLayoutMode.CHAT]: {
			opacity: 1,
			y: "0%",
		}
	}, 1);

	return (
		<div className={styles.background}>
			<motion.div
				animate={layout}
				className={styles.container}
				initial={false}
				variants={containerVariants}
			>
				<div className={styles.content}>
					<AnimatePresence>
						{layout === ChatLayoutMode.HOME && (
							<motion.div
								ref={setHomeContentElement}
								className={styles.homeContentWrapper}
								exit={{
									opacity: 0,
									y: "-100%",
									transition: transition(1)
								}}
							>
								<Header className={styles.header} type="medium">
                  Hi {user.firstName}!
								</Header>

								<Subheader size="xl" className={styles.subheader} color="text-secondary">
                  What might we create today?
								</Subheader>

								{/* {renderPrompts()} */}
							</motion.div>
						)}
					</AnimatePresence>
					<motion.div
						className={styles.chatContentWrapper}
						variants={chatContentWrapperVariants}
					>
						<div className={styles.pillWrapper}>
							<ChatNamePill className={styles.chatNamePill} />
						</div>
						<Chat wrapperClassName={styles.chat} />
					</motion.div>
				</div>
				<ChatBubble className={styles.chatBubble} />
			</motion.div>
		</div>
	);
};
