import React from "react";

import {ReactElement, ReactNode} from "react";
import {AiActionsContextProvider} from "./ai-actions";
import {CanvasContextProvider} from "./canvas-area-selection";
import {EditorSettingsContextProvider} from "./editor-settings";
import {ImageElementContextProvider} from "./image-element";
import {CanvasMouseEventMiddlewareContextProvider} from "./canvas-mouse-event-middleware";
import {ImageStudioProps} from "..";

export * from "./ai-actions";
export * from "./canvas-area-selection";
export * from "./canvas-mouse-event-middleware";
export * from "./editor-settings";
export * from "./image-element";
export * from "./image-history";

export interface ImageStudioContextsProviderProps extends Omit<ImageStudioProps, "submitButton"> {
  children: ReactNode;
}

export const ImageStudioContextsProvider = (
  {children, onSubmit}: ImageStudioContextsProviderProps
): ReactElement => {
  return (
    <CanvasMouseEventMiddlewareContextProvider>
      <EditorSettingsContextProvider>
        <ImageElementContextProvider>
          <CanvasContextProvider>
            <AiActionsContextProvider onSubmit={onSubmit}>
              {children}
            </AiActionsContextProvider>
          </CanvasContextProvider>
        </ImageElementContextProvider>
      </EditorSettingsContextProvider>
    </CanvasMouseEventMiddlewareContextProvider>
  )
};
