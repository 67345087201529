import React, {ReactElement} from "react";

import {Button} from "@/shared/v2";
import {useActionsContext} from "../../contexts/actions-context";
import {useSelectedCampaignsContext} from "../../contexts/selected-campaigns-context";
import {useSelectedQuestionsContext} from "../../contexts/selected-questions-context";
import {useSelectedSourcesContext} from "../../contexts/selected-sources-context";

import styles from "./buttons.module.scss";

export const SubmitButton = (): ReactElement => {
  const {isDirty: hasSourcesChanges} = useSelectedSourcesContext();
  const {isDirty: hasCampaignsChanges} = useSelectedCampaignsContext();
  const {isDirty: hasQuestionsChanges} = useSelectedQuestionsContext();
  const {submit} = useActionsContext();

  const isDisabled = (
    !hasSourcesChanges &&
    !hasCampaignsChanges &&
    !hasQuestionsChanges
  );

  return (
    <Button
      className={styles.commitButton}
      disabled={isDisabled}
      onClick={submit}
      type="button"
    >
      Add sources
    </Button>
  );
}
