import React, {ReactElement, ReactNode, createContext} from "react";
import {useSearchContext} from "./search-context";
import {usePropsContext} from "./props-context";
import {Campaign, TrainingSet} from "@/models/ai-model";
import {isCampaign, isTrainingSet} from "@/shared";

export enum SelectSourcesPage {
  CAMPAIGNS = "campaigns",
  CAMPAIGN_QUESTIONS = "campaignQuestions",
  DATASETS = "datasets",
  DATASET_MEDIA= "datasetMedia",
  MEDIA = "media",
}

export interface NavigationContextValue {
  currentPage: SelectSourcesPage;
  setCurrentPage: {
    (page: SelectSourcesPage.CAMPAIGNS | SelectSourcesPage.DATASETS | SelectSourcesPage.MEDIA): void;
    (page: SelectSourcesPage.CAMPAIGN_QUESTIONS, parent: Campaign): void;
    (page: SelectSourcesPage.DATASET_MEDIA, parent: TrainingSet): void;
  }
  dataset: TrainingSet | null;
  campaign: Campaign | null;
  close: () => void;
}

export const NavigationContext =
  createContext<NavigationContextValue | undefined>(undefined);

export const NavigationContextProvider = (
  {children}: {children: ReactNode},
): ReactElement => {
  const {onClose: close} = usePropsContext();
  const {setSearch} = useSearchContext();
  const [currentPage, setCurrentPage] = React.useState<SelectSourcesPage>(SelectSourcesPage.CAMPAIGNS);
  const [currentDataset, setCurrentDataset] = React.useState<TrainingSet | null>(null);
  const [currentCampaign, setCurrentCampaign] = React.useState<Campaign | null>(null);

  function handleSetCurrentPage(page: SelectSourcesPage.CAMPAIGNS | SelectSourcesPage.DATASETS | SelectSourcesPage.MEDIA): void;
  function handleSetCurrentPage(page: SelectSourcesPage.CAMPAIGN_QUESTIONS, parent: Campaign): void;
  function handleSetCurrentPage(page: SelectSourcesPage.DATASET_MEDIA, parent: TrainingSet): void;
  function handleSetCurrentPage(page: SelectSourcesPage, parent?: TrainingSet | Campaign): void {
    if (page === SelectSourcesPage.DATASET_MEDIA && isTrainingSet(parent)) {
      setCurrentDataset(parent);
    } else {
      setCurrentDataset(null);
    }

    if (page === SelectSourcesPage.CAMPAIGN_QUESTIONS && isCampaign(parent)) {
      setCurrentCampaign(parent);
    } else {
      setCurrentCampaign(null);
    }

    setSearch("");
    setCurrentPage(page);
  }

  return (
    <NavigationContext.Provider value={{
      currentPage,
      setCurrentPage: handleSetCurrentPage,
      dataset: currentDataset,
      campaign: currentCampaign,
      close: () => {
        close();
      }
    }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigationContext = (): NavigationContextValue => {
  const context = React.useContext(NavigationContext);

  if (context === undefined) {
    throw new Error(
      "useNavigationContext must be used within a NavigationContextProvider",
    );
  }

  return context;
};
