import React, {ReactElement, useEffect, useMemo, useState} from "react";

import {CampaignsPageData} from "@/models/ai-model";
import {GET_CAMPAIGNS} from "@/graphql/queries/ai-models-queries";
import {SelectCampaignButton} from "../../buttons";
import {Spinner} from "@/shared/v2";
import {SurveysQueryArgs, SurveyStatus} from "@/models/survey";
import {TablePagination} from "@/shared/components/table/table-pagination";
import {useDebounceValue, useLoadingQuery} from "@/hooks";
import {useSearchContext} from "../../../contexts/search-context";
import {useSelectedCampaignsContext} from "../../../contexts/selected-campaigns-context";
import {useSelectedQuestionsContext} from "../../../contexts/selected-questions-context";
import {useWorkspaceContext} from "@/context/workspace-context";

import styles from "./select-campaign-tab.module.scss";

const PAGE_SIZE = 10;

export const SelectCampaignTab = (): ReactElement => {
  const {workspace: {id: workspaceId}} = useWorkspaceContext();
  const {selected: selectedCampaigns} = useSelectedCampaignsContext();
  const {selected: selectedQuestions} = useSelectedQuestionsContext();
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
	const [currentPage, setCurrentPage] = useState(0);
  const {search} = useSearchContext();

  const [debouncedSearch] = useDebounceValue(search, 500);

  const {
    data: {
      campaigns: {
        items: allCampaigns = [],
        remaining = 0,
      } = {},
    } = {},
    loading: isLoading,
    handleFetchMore,
    isFetchingMore,
  } = useLoadingQuery<CampaignsPageData, SurveysQueryArgs>(GET_CAMPAIGNS, {
    variables: {
      workspaceId,
      limit: pageSize,
      filter: {
        name: debouncedSearch,
        status: [SurveyStatus.OPEN, SurveyStatus.CLOSED],
        withResponsesOnly: true,
      }
    },
    fetchPolicy: "cache-first",
  });

  const currentCampaigns = useMemo(() => {
    return allCampaigns.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
  }, [allCampaigns, pageSize, currentPage]);

  useEffect(() => {
    setCurrentPage(0);
  }, [isLoading]);
  const totalCount = useMemo(() => {
		return allCampaigns.length + remaining;
	}, [allCampaigns, remaining]);

	const startingRow = currentPage === 0 ? currentPage + 1 : (currentPage * pageSize) + 1;
	const endRow = ((currentPage + 1) * pageSize) > totalCount
		? totalCount : (currentPage + 1) * pageSize;

	const pageCount = useMemo(() => {
		return Math.ceil(totalCount / pageSize);
	}, [totalCount, pageSize]);


	const handlePageSizeChange = (newPageSize: number): void => {
    const oldPageSize = pageSize;
    const toFetch = newPageSize - (currentPage * oldPageSize);
    toFetch > 0 && handleFetchMore(toFetch);
		setPageSize(newPageSize);
		setCurrentPage(0);
	}

	const handleNext = (): void => {
		if ((currentPage + 2) * pageSize > allCampaigns.length) handleFetchMore(pageSize);
		setCurrentPage(prev => prev + 1);
	};
	const handlePrev = (): void => {
		setCurrentPage(prev => prev - 1);
	};

	const gotoPage = (newPage: number): void => {
		if (currentPage < newPage && (newPage + 1) * pageSize > allCampaigns.length) {
			handleFetchMore((newPage - currentPage) * pageSize);
		}
		setCurrentPage(newPage);
	};

  const campaignRecords = useMemo(() => {
    return currentCampaigns?.map(campaign => {
      const isSelected = selectedCampaigns.some(({id}) => id === campaign.id);
      const totalSelectedQuestions = selectedQuestions.filter(({surveyId}) => surveyId === campaign.id).length;

      return {
        campaign,
        isSelected,
        totalSelectedQuestions,
      };
    });
  }, [selectedCampaigns, currentCampaigns, selectedQuestions]);

  if (isLoading || isFetchingMore) {
    return <Spinner className={styles.spinner} />;
  }

  return (
    <div className={styles.selectCampaignTab}>
      <div className={styles.campaigns}>
        {
          campaignRecords.map((props) => (
            <SelectCampaignButton
              key={props.campaign.id}
              {...props}
            />
          ))
        }
      </div>
      <TablePagination
        currentPage={currentPage}
        pageCount={pageCount}
        handleNext={handleNext}
        handlePrev={handlePrev}
        gotoPage={gotoPage}
        startingRow={startingRow}
        endRow={endRow}
        totalCount={totalCount}
        pageSize={pageSize}
        onPageSizeChange={handlePageSizeChange}
      />
    </div>
  );
}
