import React, {ReactElement, ReactNode, createContext, useEffect, useReducer} from "react";

import {ChatConversation, Persona} from "../../models";
import {ChatConversationMessage, UpdateChatConversationChanges} from "../../models/ai-model";
import {chatReducer, ReducedChatConversation, SendQuestionPayload, UpdateConversationPayload} from "@/reducer/chat-reducer";
import {initialState} from "@/reducer/chat-reducer";
import {useCreateConversation, useUpdateConversation, useSendQuestion, useEventHandlers} from "./actions";
import {useSearchParams} from "../../route";
import {useDeleteMessage, DeleteMessage} from "./actions/use-delete-message";
import {ChatLayoutMode} from "@/reducer/chat-reducer";
import {usePopulateData} from "./actions/use-populate-data";

export interface ChatConversationContextValue {
	conversation: ReducedChatConversation;
	create: (input?: UpdateConversationPayload) => void;
	update: (input: UpdateConversationPayload) => void;
	send: (payload: SendQuestionPayload) => void;
	deleteMessage: DeleteMessage;
	isInitialLoading: boolean;
	isLoading: boolean;
	isUpdating: boolean;
	isCreating: boolean;
	isSendingQuestion: boolean;
	isUpdatingDisabled: boolean;
}

export const ChatConversationContext = createContext<ChatConversationContextValue | undefined>(undefined);

export interface ChatUpdateConversationResponse {
	chatUpdateConversation: ChatConversation;
}

export interface ChatUpdateConversationVariables {
  conversationId: string;
  changes: UpdateChatConversationChanges;
}

export interface ChatSendQuestionResponse {
	chatSendQuestion: ChatConversationMessage;
}

export interface ChatSendQuestionVariables {
  workspaceId: string;
  question: string;
  conversationId: string;
  surveyIds?: string[];
  instructions?: string;
  image?: string;
  documentId?: string;
  systemAgentId?: string;
  messageId?: string;
  smartPromptEnabled?: boolean;
  personaId?: string;
}

export interface CreateChatConversationInput {
	aiPersonaId?: string;
	trainingSetIds?: string[];
	fileIds?: string[];
	videoIds?: string[];
	surveyIds?: string[];
	questionIds?: string[];
	isPreview?: boolean;
	name?: string;
}

export interface ChatSendQestionInput {
	image?: string;
	persona?: Persona;
	instructions?: string;
	personaId?: string;
}

export const ChatConversationContextProvider = ({children}: {children: ReactNode}): ReactElement => {
	const {canvasId} = useSearchParams();

	const [state, dispatch] = useReducer(chatReducer, {
		...initialState,
		current: {
			...initialState.current,
			layout: canvasId ? ChatLayoutMode.CHAT : initialState.current.layout,
		},
	});

	const {
		current: chatConversation,
		actions: {
			isCreating,
			isUpdating,
			isSendingQuestion,
			isInitialLoading,
			isLoading,
		}
	} = state;

	usePopulateData(dispatch);
	useEventHandlers(dispatch);
	const create = useCreateConversation(state, dispatch);
	const update = useUpdateConversation(state, dispatch);
	const send = useSendQuestion(state, dispatch);

	// TODO: refactor during events refactor
	const deleteMessage = useDeleteMessage(dispatch);

	return (
		<ChatConversationContext.Provider
			value={{
				conversation: chatConversation,
				create,
				update,
				send,
				deleteMessage,
				isInitialLoading,
				isLoading,
				isUpdating,
				isCreating,
				isSendingQuestion,
				isUpdatingDisabled: isCreating || isUpdating || isLoading || isSendingQuestion,
			}}
		>
			{children}
		</ChatConversationContext.Provider>
	);
};

export const useChatConversationContext = (): ChatConversationContextValue => {
	const context = React.useContext(ChatConversationContext);

	if (context === undefined) {
		throw new Error("useChatConversationContext must be used within a ChatConversationContextProvider");
	}

	return context;
};
