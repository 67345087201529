import React, {ReactElement, ReactNode, useMemo} from "react";

import {Body, Tooltip} from "@/shared/v2";
import {determineQuestionType, Question} from "@/models/questions";
import {Icon} from "@/shared";
import {QUESTION_TYPES, TOOLTIP_DELAY_MEDIUM} from "@/shared/constants/constants";

import styles from "./question-tooltip.module.scss";

export interface QuestionTooltipProps {
  children: ReactNode;
  question: Question;
}

export const QuestionTooltip = ({children, question}: QuestionTooltipProps): ReactElement => {
  const questionType = useMemo(() => {
    return determineQuestionType(question);
  }, [question])

  return (
    <Tooltip
      startDelay={TOOLTIP_DELAY_MEDIUM}
      containerClassname={styles.questionTooltipContainer}
      placement="top-start"
      content={(
        <div className={styles.questionTooltip}>
          <div className={styles.section}>
            <Body type="semibold">
              Question:
            </Body>
            <Body size="s">
              {question.text}
            </Body>
          </div>
          <div className={styles.section}>
            <Body type="semibold">
              Type:
            </Body>
            <div className={styles.typeWrapper}>
              <Icon name={QUESTION_TYPES[questionType].icon} size="extrasmall"/>
              <Body size="s">
                {QUESTION_TYPES[questionType].text}
              </Body>
            </div>

          </div>
          {question.imageId &&
            <div className={styles.section}>

              <Body type="semibold">
                Image:
              </Body>
              <img src={question.image?.small} alt="question image" className={styles.image} />
            </div>
          }

          {question.videoResponse &&
            <div className={styles.section}>
              <Body type="semibold">
                Video Follow Up:
              </Body>

              <Body size="s">
                {question.followUp}
              </Body>
            </div>
          }
        </div>
      )}
    >
      {children}
    </Tooltip>
  );
}
