import {groupBy} from "lodash-es";
import React, {ReactElement, useMemo} from "react";

import {SelectTrainingSetButton} from "../../buttons";
import {Spinner} from "@/shared/v2";
import {useSearchContext} from "../../../contexts/search-context";
import {useSelectedSourcesContext} from "../../../contexts/selected-sources-context";

import styles from "./select-dataset-tab.module.scss";

export const SelectDatasetTab = (): ReactElement => {
  const {
    all: trainingSets,
    isLoading,
    selected: selectedSources,
  } = useSelectedSourcesContext();
  const {search} = useSearchContext();

  const trainingSetRecords = useMemo(() => {
    const {
      trainingSets: selectedTrainingSets,
      files: selectedFiles,
      videos: selectedVideos
    } = selectedSources;
    const selectedMediaByTrainingSetId = groupBy([...selectedFiles, ...selectedVideos], "trainingSetId");

    return trainingSets?.map(trainingSet => {
      const trainingSetSelectedMedia = selectedMediaByTrainingSetId[trainingSet.id] || [];
      const isSelected = selectedTrainingSets.some(({id}) => id === trainingSet.id);
      const totalSelectedMedia = isSelected ?
      // If the training set is selected, then the total selected media is the sum of the files and videos
        (trainingSet.files.length + trainingSet.videos.length) :
      // Otherwise, the total selected media is the length of the selected media array
        trainingSetSelectedMedia.length;

      return {
        trainingSet,
        isSelected,
        totalSelectedMedia,
      };
    });
  }, [selectedSources]);

  const filteredTrainingSetRecords = useMemo(
		() =>
			trainingSetRecords?.filter(({trainingSet: {alias}}) =>
				alias.toLowerCase().includes(search.toLowerCase()),
			),
		[search, trainingSetRecords],
	);

  if (isLoading) {
    return <Spinner className={styles.spinner} />;
  }

  return (
    <div className={styles.selectDatasetTab}>
      {filteredTrainingSetRecords?.map((props) => (
        <SelectTrainingSetButton
          key={props.trainingSet.id}
          {...props}
        />
      ))}
    </div>
  );
}
