import React, {ReactElement, useEffect, useRef} from "react";
import classNames from "classnames/bind";

import {AiPersonaTask} from "../../../models/ai-orchestration";
import {Avatar, Body, Input, Subheader, TextArea, Switch} from "../../../shared/v2";
import {CloseMediumIcon} from "../../../icons";
import {NoDraggableItem} from "../no-draggable-item";
import {useThemeMode} from "../../../context/theme-mode-context";
import {useWorkflowContext} from "../../../context/workflow-contexts/workflow-context";
import {useWorkflowStateContext} from "@/context/workflow-contexts/workflow-state-context";
import config from "../../../config";
import {AutoModeTooltip} from "@/canvas/chat-bubble/auto-mode-tooltip";

import styles from "./agent-task-card.module.scss";

const bStyles = classNames.bind(styles);

export interface AgentTaskCardProps {
	agentTask: AiPersonaTask;
	onRemove: (agent: AiPersonaTask) => void;
	onChangeTask: (value: string) => void;
	onChangeIndex: (agent: AiPersonaTask, newIndex: number) => void;
	disabled?: boolean;
	elementRef?: any;
	agentsLength?: number;
}

export const AgentTaskCard = ({
	agentTask,
	onRemove,
	onChangeTask,
	onChangeIndex,
	disabled,
	elementRef,
	agentsLength
}: AgentTaskCardProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {updateTask} = useWorkflowStateContext();
	const {isWorkflowRunning} = useWorkflowContext();
	const taskRef = useRef<HTMLTextAreaElement>(null);
	const orderRef = useRef<HTMLInputElement>(null);

	// prevent zooming when scrolling or typing
	useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      event.stopPropagation();
    };

		const handleWheel = (event: WheelEvent) => {
      event.stopPropagation();
    };

    const taskElement = taskRef.current;
    if (taskElement) {
      taskElement.addEventListener("mousedown", handleMouseDown);
			taskElement.addEventListener("wheel", handleWheel);

    }

		const orderElement = orderRef.current;
		if (orderElement) {
			orderElement.addEventListener("mousedown", handleMouseDown);
		}

    return () => {
      if (taskElement) {
        taskElement.removeEventListener("mousedown", handleMouseDown);
				taskElement.removeEventListener("wheel", handleWheel);

      }
			if (orderElement) {
				orderElement.removeEventListener("mousedown", handleMouseDown);
			}
    };
  }, []);

	const handleChangeTask = (value: string) => {
		onChangeTask(value);
	};

	const handleRemove = () => {
		if (isWorkflowRunning) {
			return;
		}
		onRemove(agentTask);
	};

	const handleSmartPromptChange = (checked: boolean) => {
		updateTask(agentTask.id, {smartPromptEnabled: checked});
	};

	const renderAgentName = (task: AiPersonaTask) => {
		if (task.persona?.name.includes("auto")) {
			return task.persona.name.replace(/-auto-.*$/, "").split(/(?=[A-Z])/).join(" ")
		};

		return task.persona?.name || "";
	}

	const cardStyles = bStyles("card", {
		processing: agentTask.processingState === "processing",
		completed: !!agentTask.output,
		isDarkMode,
	});

	return <NoDraggableItem className={styles.noDraggableWrapper}>
		<div className={cardStyles} ref={elementRef} id={agentTask.id}>
			<div className={styles.topSection}>
				<Avatar
					key={agentTask.persona?.id}
					size="xxl"
					firstName={agentTask.persona?.name}
					url={agentTask.persona?.picture?.url || agentTask.persona?.avatarUri}
					className={styles.avatar}
				/>
				<div className={styles.generalInfo}>
					<Subheader className={styles.name} type="semibold">
						{renderAgentName(agentTask)}
					</Subheader>
				</div>
				{!disabled &&
					<CloseMediumIcon onClick={handleRemove} className={bStyles("closeIcon", {isWorkflowRunning})} />
				}
			</div>

			<TextArea
				className={bStyles("task", {isDarkMode})}
				value={agentTask.task?.taskPrompt || ""}
				onChange={handleChangeTask}
				label="Task"
				disabled={isWorkflowRunning || disabled}
				refProp={taskRef}
				isExpandable
			/>

			<div className={styles.bottomSection}>
				{true && (
					<div className={styles.smartPromptControl}>
						<Switch
							checked={agentTask.smartPromptEnabled || false}
							onChange={handleSmartPromptChange}
							disabled={isWorkflowRunning || disabled}
						/>

						<Body size="s" type="semibold" className={bStyles("autoLabel", {disabled: !agentTask.smartPromptEnabled})}>
							Auto
						</Body>

						<AutoModeTooltip offDescription="Will only use sources added directly to the workflow" />
					</div>
				)}
				<Input
					type="number"
					label="Order"
					value={((agentTask.index ?? 0) + 1).toString()}
					onChange={value => {
						const newIndex = parseInt(value, 10) - 1;
						if (!isNaN(newIndex) && newIndex >= 0) {
							onChangeIndex(agentTask, newIndex);
						}
					}}
					min={1}
					max={agentsLength}
					disabled={isWorkflowRunning || disabled}
					className={styles.indexInput}
					refProp={orderRef}
				/>
			</div>
		</div>
	</NoDraggableItem>
};
