import {Layer, Line, Stage} from "react-konva";
import {LineConfig} from "konva/lib/shapes/Line";
import {Spinner} from "@/shared/v2";
import {throttle} from "lodash-es";
import classNames from "classnames/bind";
import Konva from "konva";
import React, {ReactElement, useCallback, useEffect, useMemo, useState} from "react";
import {motion} from "framer-motion";

import {CanvasImage, Contours, Cursor, SelectedArea} from "./components";
import {useAiActionsContext, useCanvasContext, useCanvasMouseEventMiddleware, useCanvasMouseEventMiddlewareContext, useEditorSettingsContext, useImageHistoryContext} from "../../contexts";
import {useElementSize} from "../../../../hooks/useElementSize";
import {useThemeMode} from "@/context/theme-mode-context";

import styles from "./image-canvas.module.scss";

const cx = classNames.bind(styles);

// Default minimum dimensions when the container size is not available
const DEFAULT_MIN_WIDTH = 600;
const DEFAULT_MIN_HEIGHT = 500;

// Loading messages to show while processing
const LOADING_MESSAGES = [
  "AI is painting your changes...",
  "Analyzing the selection...",
  "Working on your masterpiece...",
  "Applying creative magic...",
  "Almost there..."
];

export const ImageCanvas = (): ReactElement => {
  const [canvasWrapperEl, setCanvasWrapperEl] = useState<HTMLDivElement | null>(null);
  const [currentLine, setCurrentLine] = useState<LineConfig | null>(null);
  const {brushSize, brushMode} = useEditorSettingsContext();
  const {imageSrc} = useImageHistoryContext();
  const {isUpdatingImage} = useAiActionsContext();
  const {mouseEvents} = useCanvasMouseEventMiddlewareContext();
  const {stageRef, lines, setLines} = useCanvasContext();
  const {isDarkMode} = useThemeMode();
  const isPainting = Boolean(currentLine);
  const elementSize = useElementSize(canvasWrapperEl);
  const [loadingMessageIndex, setLoadingMessageIndex] = useState(0);
  
  // Ensure we have usable dimensions even if the container hasn't rendered properly yet
  const size = useMemo(() => {
    return {
      width: elementSize.width > 0 ? elementSize.width : DEFAULT_MIN_WIDTH,
      height: elementSize.height > 0 ? elementSize.height : DEFAULT_MIN_HEIGHT
    };
  }, [elementSize.width, elementSize.height]);
  
  // Cycle through loading messages for a more engaging experience
  useEffect(() => {
    if (!isUpdatingImage) return;
    
    const interval = setInterval(() => {
      setLoadingMessageIndex(prev => (prev + 1) % LOADING_MESSAGES.length);
    }, 2500);
    
    return () => clearInterval(interval);
  }, [isUpdatingImage]);
  
  // Log dimensions for debugging
  useEffect(() => {
    console.log("Canvas elementSize:", elementSize);
    console.log("Canvas size being used:", size);
  }, [elementSize, size]);

  // Start drawing line
  useCanvasMouseEventMiddleware((e) => {
    const stage = e.target.getStage();
    const pos = stage?.getPointerPosition();

    if (!pos || !stage || !stage.width() || !stage.height()) {
      console.warn("Cannot start drawing: Stage not ready or pointer position not available");
      return;
    }

    // Ensure stage ref is set
    if (stageRef && !stageRef.current) {
      stageRef.current = stage;
    }

    // Use red color for visual drawing, but the mask will be white in getMask
    setCurrentLine({
      points: [pos.x, pos.y, pos.x + 1, pos.y + 1],
      strokeWidth: brushSize,
      stroke: "#F00000", // Keep red color for visual feedback
      tension: 1,
      globalCompositeOperation: brushMode === "draw" ? "source-over" : "destination-out",
    });
  }, {
    name: "drawLine",
    deps: [currentLine, brushMode, brushSize, stageRef],
    event: "onMouseDown",
    order: 100,
  });

  const handleMouseMove = useCallback((e: Konva.KonvaEventObject<MouseEvent>) => {
    const stage = e.target.getStage();
    const point = stage?.getPointerPosition();

    if (!point || !currentLine || !currentLine.points || !stage || !stage.width() || !stage.height()) {
      return;
    }

    // Ensure stage ref is set
    if (stageRef && !stageRef.current) {
      stageRef.current = stage;
    }

    setCurrentLine(prev => ((prev && prev.points) ? {
      ...prev,
      points: Array.from(prev.points).concat([point.x, point.y]),
    } : null));

    stage?.batchDraw();
  }, [currentLine, stageRef]);

  const throttledHandleMouseMove = useCallback(
    throttle((e: Konva.KonvaEventObject<MouseEvent>) => {
      handleMouseMove(e);
    }, 16),
    [handleMouseMove]
  );

  useEffect(() => {
    return () => {
      throttledHandleMouseMove.cancel();
    };
  }, [throttledHandleMouseMove]);

  // Draw line
  useCanvasMouseEventMiddleware(throttledHandleMouseMove, {
    name: "drawLine",
    deps: [currentLine],
    event: "onMouseMove",
    order: 100,
  });

  // Finish drawing line
  const finishDrawingLine = () => {
    if (!currentLine) {
      return;
    }
    
    // Make sure we have at least a valid line (minimum 4 points for 2 coordinates)
    if (currentLine.points && currentLine.points.length >= 4) {
      setLines(prev => [...prev, currentLine]);
      
      // Force stage update to ensure the line is rendered
      if (stageRef.current) {
        stageRef.current.batchDraw();
      }
    }
    
    setCurrentLine(null);
  };

  useCanvasMouseEventMiddleware(finishDrawingLine, {
    name: "drawLine",
    event: "onMouseUp",
    deps: [currentLine],
    order: 100,
  });

  useCanvasMouseEventMiddleware(finishDrawingLine, {
    name: "drawLine",
    event: "onMouseLeave",
    deps: [currentLine],
    order: 100,
  });

  return (
    <div 
      ref={setCanvasWrapperEl} 
      className={cx("imageWrapper", {disabled: isUpdatingImage, isDarkMode})}
      style={{minWidth: DEFAULT_MIN_WIDTH, minHeight: DEFAULT_MIN_HEIGHT}}
    >
      <Stage
        className={styles.imageCanvas}
        ref={stageRef}
        width={size.width}
        height={size.height}
        {...mouseEvents}
      >
        <Layer>
          <CanvasImage 
            src={imageSrc} 
            width={size.width} 
            height={size.height} 
          />
          <SelectedArea />
          {currentLine && <Line
            opacity={0.5}
            lineCap="round"
            lineJoin="round"
            tension={1}
            {...currentLine}
          />}
          <Contours
            isPainting={isPainting}
            lines={lines}
            marchingSpeed={isUpdatingImage ? 0 : 100}
          />
          <Cursor />
        </Layer>
      </Stage>

      {isUpdatingImage && (
        <div className={styles.loadingOverlay}>
          <motion.div 
            className={styles.loadingContent}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Spinner className={styles.spinner} />
            <motion.div
              className={styles.loadingMessage}
              key={loadingMessageIndex}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -10 }}
              transition={{ duration: 0.5 }}
            >
              {LOADING_MESSAGES[loadingMessageIndex]}
            </motion.div>
          </motion.div>
        </div>
      )}
    </div>
  )
}
