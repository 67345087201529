import {Popover} from "@headlessui/react";
import React, {ReactElement, useMemo} from "react";

import {DropdownItem} from "..";
import {DropdownItemContent} from "../_helpers/content";
import {DropdownItemSpacing, useItemClassName} from "../_helpers";
import {Separator} from "@/shared/v2";

export interface DropdownActionItemProps {
	item: DropdownItem & {onClick: (event?: MouseEvent) => void};
}

export const DropdownActionItem = ({item}: DropdownActionItemProps): ReactElement => {
	const itemClassName = useItemClassName(item);

	const isAll = useMemo(() => {
		return item.id === "all"
	}, [item.id]);

	return (
		<>
			<DropdownItemSpacing active={item.active}>
				<Popover.Button className={itemClassName} onClick={e => {
					e.stopPropagation();
					item.onClick(e as any);
				}}>
					<DropdownItemContent item={item}/>
				</Popover.Button>
			</DropdownItemSpacing>
			{isAll && <Separator color="workflow" />}
		</>
	);
};
