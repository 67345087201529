import {useCreateElement} from "./useCreateElement";

export interface UseLocalFileUploadProps {
	onUpload: (files: File[]) => void;
	options?: {
		accept?: string;
	}
}

export const useLocalFileUpload = ({onUpload, options}: UseLocalFileUploadProps) => {
  const inputElement = useCreateElement<HTMLInputElement>("input", element => {
		element.setAttribute("type", "file");
		element.setAttribute("accept", options?.accept ?? "image/*");
		element.onchange = (event) => {
			const target = event.target as HTMLInputElement;
			if (target.files) {
				onUpload(Array.from(target.files));
				target.value = "";
			}
		}
	});

	return (): void => {
		if (inputElement) {
			inputElement.click();
		}
  };
};
