import {gql} from "@apollo/client";
import {QUESTION_FRAGMENT} from "./fragments";

export const GROUNDING_FRAGMENT = gql`
	fragment GroundingFields on Grounding {
    type
    score
    page
    questionSummary {
      questionId
      surveyId
      questionText
    }
    answer {
      surveyId
      surveyTitle
      questionId
      questionText
      answerVideoThumbnailUrl
      answerId
      creatorTag
    }
    trainingSetFile {
      trainingSetId
      trainingSetName
      fileUrl
      originalFilename
      trainingSetDescription
    }
    trainingSetVideo {
      trainingSetId
      trainingSetName
      fileUrl
      originalFilename
      trainingSetDescription
    }
    citations {
      claimText
      startPos
      endPos
    }
	}
`;

export const TRAINING_SET_FRAGMENT = gql`
  fragment TrainingSetFields on TrainingSet {
    name
    alias
    description
    id
    files {
      id
      originalFilename
      url
      originalMimetype
      embeddingsGenerationStatus
      tags {
        id
        key
        value
      }
      trainingSetId
    }
    videos {
      id
      originalFilename
      url(version: H264_AAC)
      originalMimetype
      transcodingStatus
      embeddingsGenerationStatus
      tags {
        id
        key
        value
      }
      trainingSetId
    }
  }
`;

export const CAMPAIGN_FRAGMENT = gql`
  fragment CampaignFields on Survey {
    id
    name
    questionCount
    completedResponseCount
    responseCount
    totalVideoDuration
  }
`;

export const CHAT_CONVERSATION_FRAGMENT = gql`
	fragment ChatConversationFields on ChatConversation {
    id
    name
    workspaceId
    isPreview
    createdAt
    surveys {
      ...CampaignFields
    }
    files {
      id
      originalFilename
      url
      originalMimetype
      embeddingsGenerationStatus
      tags {
        id
        key
        value
      }
      trainingSetId
    }
    videos {
      id
      originalFilename
      url(version: H264_AAC)
      originalMimetype
      transcodingStatus
      embeddingsGenerationStatus
      tags {
        id
        key
        value
      }
      trainingSetId
    }
    questions {
      ...QuestionFields
    }
    messages {
      id
      content
      conversationId
      persona {
        id
        name
        voiceId
        voiceName
        description
        isVurvey
        personaCategory {
          id
          name
        }
        personaStatus
        picture {
          id
          url(version: THUMBNAIL)
        }
        avatarUri
      }
      role
      type
      position
      likedByUsers {
        id
      }
      dislikedByUsers {
        id
      }
      groundingData {
        ...GroundingFields
      }
    }
    trainingSets {
      ...TrainingSetFields
    }
    aiPersona {
      id
      name
      voiceId
      voiceName
      description
      isVurvey
      personaCategory {
        name
      }
      picture {
        id
        url(version: THUMBNAIL)
      }
      avatarUri
    }
	}
  ${GROUNDING_FRAGMENT}
  ${QUESTION_FRAGMENT}
  ${TRAINING_SET_FRAGMENT}
  ${CAMPAIGN_FRAGMENT}
`;
