/* eslint-disable */
import React, {ReactElement, useContext, useMemo, useState} from "react";
import {NetworkStatus, useMutation} from "@apollo/client";
import classNames from "classnames/bind";

import {Icon, Options} from "../../../shared";
import {Link} from "../../../route";
import {DELETE_ATTRIBUTE} from "../../../graphql/mutations/attribute-mutations";
import {GET_ALL_ATTRIBUTES, GET_ATTR_CATEGORIES} from "../../../graphql/queries/attribute-queries";
import {AlignText} from "../../../shared/typography/align-text";
import {AttributeModal} from "../../../modals/attribute-modal";
import {DeleteConfirmModal} from "../../../modals/delete-reel";
import {EmptyScreen} from "../../components/empty-screen";
import {ToastContext} from "../../../context/toast-context";
import {useLoadingQuery} from "../../../hooks";
import {updateCacheDeletePageItem} from "../../../shared/utility/update-cache";
import {AttributeCategoriesData, AttributesSort, RuleAction} from "../../../models/attribute";
import {SearchableFilter} from "../../../shared/components/searchable-filter";
import {Body} from "../../../shared/v2/typography";
import {AttributeCategoryModal} from "../../../modals/attribute-category";
import {EditCategoryModal} from "../../modals/edit-category";
import {useThemeMode} from "../../../context/theme-mode-context";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {Button, SearchInput} from "../../../shared/v2";
import {HeaderGroup} from "react-table";
import {PaginatedTable} from "../../../shared/components/table/paginated-table";
import {UploadCSVModal} from "../../modals/upload-csv";
import {ADD_ATTRIBUTE_BY_CSV} from "../../../graphql/mutations/mutations";

import styles from "./properties.module.scss";

const bStyles = classNames.bind(styles);
const PAGE_SIZE = 50;

const PropertiesPage = (): ReactElement => {
	const [searchValue, setSearchValue] = useState("");
  const {
		workspace: {id: workspaceId},
	} = useWorkspaceContext();
	const {updateToast} = useContext(ToastContext);

	const {isDarkMode} = useThemeMode();

	const [showModal, setShowModal] = useState({isOpen: false, id: ""});
	const [showCSV, setShowCSV] = useState(false);
	const [catModal, setCatModal] = useState(false);
	const [selectedProperty, setSelectedProperty] = useState("");
	const [sortBy, setSortBy] = useState(AttributesSort.DEFAULT);
	const [currentPage, setCurrentPage] = useState(0);
	const [selectedCategories, setSelectedCategories] = useState<string[]>([]);
	const [editModal, setEditModal] = useState(false);
	const [pageSize, setPageSize] = useState(PAGE_SIZE);

	const {data, fragment, handleFetchMore, networkStatus, fetchMoreFragment} = useLoadingQuery(GET_ALL_ATTRIBUTES, {
		variables: {workspaceId, sort: sortBy, limit: pageSize, filter: {name: searchValue}},
		fetchPolicy: "network-only",
		nextFetchPolicy: "cache-first",
	});

	const {data: attrCatData} = useLoadingQuery<AttributeCategoriesData>(GET_ATTR_CATEGORIES, {
		variables: {workspaceId},
		what: "properties",
	});

	const handleCloseModal = (): void => setShowModal({isOpen: false, id: ""});
	const handleNewProp = (): void => setShowModal({isOpen: true, id: ""});
	const handleCSVModal = (): void => setShowCSV(true);

	const [deleteAttribute] = useMutation(DELETE_ATTRIBUTE, {
		onCompleted: () => updateToast({description: "Deleted property", type: "informational"}),
	});
	const [addAttributeCSV, {loading: csvLoading}] = useMutation(ADD_ATTRIBUTE_BY_CSV);

	const handleDeleteProperty = (): void => {
		deleteAttribute({
			variables: {id: selectedProperty},
			update(cache, {data: deleteData}) {
				if (!deleteData) return;
				updateCacheDeletePageItem(cache, "attributes", "attribute", deleteData.deleteAttribute.id);
			},
		});
		setSelectedProperty("");
	};

	/**
	 * Mix of using the currentPage and the filter we do on the frontend. Should have it on the backend probably
	 */
	const currentData = useMemo(() => {
		const current = data?.attributes.items.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
		if (!selectedCategories.length) return current;
		return current.filter((item) => selectedCategories.includes(item.category?.id));
	}, [data, currentPage, selectedCategories]);

	const columns = useMemo(
		(): any => [
			{
				Header: "Name",
				accessor: "name",
				Cell: ({
					value,
					row: {
						original: {id},
					},
				}) => (
					<Link className={styles.name} to={id} workspace state={value}>
						{value}
					</Link>
				),
			},
			{
				Header: "Used",
				accessor: "rules",
				Cell: ({value}) => <Body size="s">{value.length}</Body>,
				sortType: (rowA, rowB, colId) => rowA.values[colId].length - rowB.values[colId].length,
			},
			{
				Header: "Category",
				accessor: "category.name",
				Cell: ({value}) => <Body size="s">{value || ""}</Body>,
			},
			{
				Header: "Created by",
				accessor: "creator.name",
				Cell: ({value}) => <Body size="s">{value || ""}</Body>,
			},
			{
				Header: " ",
				accessor: " ",
				maxWidth: 50,
				disableSortBy: true,
				Cell: ({row: {original}}) => (
					<AlignText align="right">
						<Options
							type="menu-vertical"
							position="right"
							options={[
								{
									name: "Edit",
									actionOptions: {onClick: () => setShowModal({isOpen: true, id: original.id})},
									icon: "pen",
									iconFill: "var(--color-text-body)",
								},
								{
									name: "Delete",
									actionOptions: {onClick: () => setSelectedProperty(original.id)},
									icon: "trash",
									iconFill: "var(--color-text-body)",
								},
							]}
						/>
					</AlignText>
				),
			},
		],
		[data],
	);

	const categories = useMemo(
		() => Array.from(attrCatData?.attributeCategories?.items || []),
		[attrCatData?.attributeCategories?.items],
	);

	const handleCategoriesFilter = (selected: {id: string; name: string}): void => {
		if (selectedCategories.includes(selected.id)) {
			setSelectedCategories(selectedCategories.filter((id: string) => id !== selected.id));
		} else {
			setSelectedCategories([...selectedCategories, selected.id]);
		}
	};

	const handleUploadCSV = (file: File, ruleAction: RuleAction): void => {
		addAttributeCSV({
			variables: {file, ruleAction, workspaceId},
			onCompleted: () => {
				updateToast({description: "Successfully uploaded CSV", type: "informational"});
				setShowCSV(false);
			},
			onError: () => {
				updateToast({
					description: "Failed to upload. Make sure the attribute names exist and in the correct format",
					type: "failure",
				});
				setShowCSV(false);
			},
		});
	};

	const handleSort = (value: HeaderGroup<any>): void => {
		if (!value.canSort) return;

		switch (value.Header) {
			case "Name":
				if (value.isSorted === false && value.isSortedDesc === undefined) return setSortBy(AttributesSort.NAME_ASC);
				if (value.isSorted === true && value.isSortedDesc === false) return setSortBy(AttributesSort.NAME_DESC);
				setSortBy(AttributesSort.DEFAULT);
				break;
			case "Used":
				if (value.isSorted === false && value.isSortedDesc === undefined)
					return setSortBy(AttributesSort.RULES_CNT_ASC);
				if (value.isSorted === true && value.isSortedDesc === false) return setSortBy(AttributesSort.RULES_CNT_DESC);
				setSortBy(AttributesSort.DEFAULT);
				break;
			case "Category":
				if (value.isSorted === false && value.isSortedDesc === undefined) return setSortBy(AttributesSort.CAT_DESC);
				if (value.isSorted === true && value.isSortedDesc === false) return setSortBy(AttributesSort.CAT_DESC);
				setSortBy(AttributesSort.DEFAULT);
				break;
			case "Created by":
				if (value.isSorted === false && value.isSortedDesc === undefined)
					return setSortBy(AttributesSort.CREATOR_NAME_ASC);
				if (value.isSorted === true && value.isSortedDesc === false) return setSortBy(AttributesSort.CREATOR_NAME_DESC);
				setSortBy(AttributesSort.DEFAULT);
				break;
			default:
				setSortBy(AttributesSort.DEFAULT);
		}

	};

	const toggleAttrModal = (): void => setCatModal((prev) => !prev);
	const toggleEditModal = (): void => setEditModal((prev) => !prev);

	const handlePerPageChange = (newPageSize: number): void => {
		setPageSize(newPageSize);
		setCurrentPage(0);
	}

	return (
		<>
			<div>
				<div className={styles.actions}>
					<SearchInput value={searchValue} onChange={setSearchValue} />
					<div className={styles.filterWrapper}>
						<SearchableFilter
							options={categories}
							value={selectedCategories}
							link={
								<>
									{categories.length > 1 && ( // We always have Uncategorized
										<p className={bStyles("dropLink", {isDarkMode})} onClick={toggleEditModal}>
											<Icon name="pen" />
											Edit Categories
										</p>
									)}
									<p className={bStyles("dropLink", {isDarkMode})} onClick={toggleAttrModal}>
										+ Create new category
									</p>
								</>
							}
							selectedLabel={selectedCategories.length === 1 ? "1 category" : `${selectedCategories.length} categories`}
							onChange={handleCategoriesFilter}
							placeholder="All Categories"
						/>

						<Button onClick={handleNewProp}>New Property</Button>
						<Button variant="outlined" onClick={handleCSVModal}>
							Upload CSV
						</Button>
					</div>
				</div>
				{(!data && fragment) ||
					(data && currentData && data.attributes.items.length > 0 ? (
						<PaginatedTable
							columns={columns}
							data={currentData}
							dataLength={data.attributes.items.length}
							totalCount={data.attributes.remaining + data.attributes.items.length}
							handleFetchMore={handleFetchMore}
							pageSize={pageSize}
							pageState={[currentPage, setCurrentPage]}
							sortLoading={networkStatus === NetworkStatus.setVariables}
							onSort={handleSort}
							fetchMoreFragment={fetchMoreFragment}
							onPageSizeChange={handlePerPageChange}
						/>
					) : searchValue ? (
						<Body>No results found</Body>
					) : (
						<EmptyScreen whatIsEmpty="PROPERTY" action={handleNewProp} />
					))}
				<AttributeModal isOpen={showModal.isOpen} id={showModal.id} onClose={handleCloseModal} />
				<DeleteConfirmModal
					isOpen={Boolean(selectedProperty)}
					onClose={() => setSelectedProperty("")}
					handleConfirm={handleDeleteProperty}
					warningText={
						<>
							<Body className={styles.warning}>Are you sure you want to delete this property?</Body>
							<Body className={styles.extra}>
								This will also delete all creator values associated with this property
							</Body>
						</>
					}
				/>
				<AttributeCategoryModal isOpen={catModal} onClose={toggleAttrModal} />
				<EditCategoryModal isOpen={editModal} onClose={toggleEditModal} categories={categories} />
			</div>
			<UploadCSVModal
				isOpen={showCSV}
				onClose={() => setShowCSV(false)}
				uploadCallback={handleUploadCSV}
				loading={csvLoading}
			/>
		</>
	);
};

export {PropertiesPage};
