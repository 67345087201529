import {useMutation} from "@apollo/client";
import {Dispatch, useCallback, useEffect} from "react";
import {ChatSendQuestionResponse, ChatSendQuestionVariables} from "../chat-conversation-context";
import {CHAT_SEND_QUESTION} from "@/graphql/mutations/ai-mutations";
import {useWorkspaceContext} from "@/context/workspace-context";
import {getImageBase64} from "@/shared/utility";
import {ChatConversationMode, ChatState, SendQuestionPayload, ChatAction, ChatActionTypes} from "@/reducer/chat-reducer";

const FIVE_MINUTES = 5 * 60 * 1000;

export const useSendQuestion = (state: ChatState, dispatch: Dispatch<ChatAction>) => {
  const {workspace: {id: workspaceId}} = useWorkspaceContext();
  const {
    current,
    actions: {
      isSendingQuestion,
      isCreating,
      isUpdating,
    },
  } = state;

  const [chatSendQuestion] = useMutation<ChatSendQuestionResponse, ChatSendQuestionVariables>(CHAT_SEND_QUESTION, {
    context: {
      timeout: FIVE_MINUTES,
    }
  });

  const handleSendQuestion = useCallback(async () => {
    if (isCreating || !current.id || !isSendingQuestion || isUpdating) {
      return;
    }

    const {nextQuestion} = current;
    const image = nextQuestion.image ? await getImageBase64(nextQuestion.image) : undefined;

    chatSendQuestion({
      variables: {
        workspaceId,
        question: nextQuestion.query ?? "",
        conversationId: current.id,
        instructions: nextQuestion.instructions ?? undefined,
        image,
        documentId: nextQuestion.document?.id,
        smartPromptEnabled: current.mode === ChatConversationMode.REASONING,
        personaId: nextQuestion.persona?.id,
        systemAgentId: nextQuestion.systemAgent?.id,
        messageId: nextQuestion.message?.id,
      },
    })

  }, [isCreating, isUpdating, isSendingQuestion]);

  useEffect(() => {
    handleSendQuestion();
  }, [handleSendQuestion])

  return useCallback((payload: SendQuestionPayload) => {
    if (isSendingQuestion) {
      return;
    }

    dispatch({type: ChatActionTypes.SEND_QUESTION, payload});
  }, [isSendingQuestion]);
}
