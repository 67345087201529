import React, {ReactElement} from "react";
import {useQuery} from "@apollo/client";

import {AGENTS_PUBLISHED} from "../../../graphql/queries/ai-models-queries";
import {AiPersonaTask} from "../../../models/ai-orchestration";
import {EditWorkflowModal} from "../../modals/edit-workflow-modal";
import {Persona} from "../../../models/persona";
import {SelectAgentModal} from "../select-agent-modal";
import {useWorkflowModalsContext} from "../../../context/workflow-contexts";
import {useWorkflowStateContext} from "@/context/workflow-contexts/workflow-state-context";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {SelectWorkflowSourcesModal} from "@/workflow/modals/select-workflow-sources-modal";

export const WorkflowModals = (): ReactElement => {
	const {
    isEditWorkflowModalOpen,
    setIsEditWorkflowModalOpen,
    isSelectAgentModalOpen,
    setIsSelectAgentModalOpen,
		sourcesModal: {value: isSourcesModalOpen, close: closeSourcesModal},
  } = useWorkflowModalsContext();
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const {workflow, createTask} = useWorkflowStateContext();

	const agentTasks = workflow?.aiPersonaTasks || [];

	const {data: agentsData, loading: isLoadingAgents} = useQuery(
		AGENTS_PUBLISHED,
		{
			variables: {workspaceId, version: "THUMBNAIL", orchestrationId: workflow?.id},
			skip: !workspaceId || !workflow?.id,
			fetchPolicy: "cache-first",
		}
	);

	const handleAddAgentTask = (persona: Persona) => {
		const agentTask: AiPersonaTask = {
			persona: persona,
			index: Math.max(...agentTasks.map(task => task.index), -1) + 1,
			processingState: "idle",
			output: "",
			id: `NEW-${new Date().getTime()}`,
			task: {
				taskPrompt: "",
			}
		}

		createTask(agentTask);
		setIsSelectAgentModalOpen(false);
	}

	return <>
		{isEditWorkflowModalOpen && (
			<EditWorkflowModal
				isOpen={isEditWorkflowModalOpen}
				onClose={() => setIsEditWorkflowModalOpen(false)}
			/>
		)}
		<SelectAgentModal
			isOpen={isSelectAgentModalOpen}
			onClose={() => setIsSelectAgentModalOpen(false)}
			onSelect={handleAddAgentTask}
			agents={agentsData?.publishedAgents || []}
			isLoadingAgents={isLoadingAgents}
		/>
		<SelectWorkflowSourcesModal
			isOpen={isSourcesModalOpen}
			onClose={closeSourcesModal}
		/>
	</>
}
