import {motion} from "framer-motion";
import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {Body, ButtonIcon, Tooltip} from "../../../shared/v2";
import {SendArrowChatIcon} from "../../../icons";
import {TOOLTIP_DELAY_MEDIUM} from "@/shared/constants/constants";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./submit-button.module.scss";

const cx = classNames.bind(styles);

export interface SubmitButtonProps {
  onSubmit: () => void;
	isDisabled: boolean;
}

export const SubmitButton = ({onSubmit, isDisabled}: SubmitButtonProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	return (
		<Tooltip
			content={(
				<Body size="xs">
					Send message
				</Body>
			)}
			placement="top"
			startDelay={TOOLTIP_DELAY_MEDIUM}
		>
			<motion.div
				className={cx("submitButton", {isDarkMode})}
			>
				<ButtonIcon
					aria-label='send-button'
					className={styles.icon}
					icon={<SendArrowChatIcon />}
					onClick={onSubmit}
					disabled={isDisabled}
				/>
			</motion.div>
		</Tooltip>
	);
}
