import {ArgumentArray} from "classnames";
import classNames from "classnames/bind";
import React, {ReactElement, useMemo} from "react";

import {AiSources} from "@/modals/select-ai-sources-modal";
import {Campaign, TrainingSetVideo, TrainingSetFile} from "@/models/ai-model";
import {ChatBubbleQuestionIcon, DocumentsFolderFileIcon, FileVideoIcon, FileTextIcon, HornMegaphoneCampaignIcon} from "@/icons";
import {InputChip} from "@/shared/components/input-chip";
import {isQuestion, isTrainingSet, isTrainingSetVideo, isTrainingSetFile} from "@/shared";
import {Question} from "@/models/questions";
import {TrainingSet} from "@/models";

import styles from "./sources-section.module.scss";

const cx = classNames.bind(styles);

export interface SourcesSectionProps {
  sources: AiSources;
  onRemoveCampaign: (campaign: Campaign) => void;
  onRemoveQuestion: (question: Question) => void;
  onRemoveTrainingSet: (trainingSet: TrainingSet) => void;
  onRemoveVideo: (video: TrainingSetVideo) => void;
  onRemoveFile: (file: TrainingSetFile) => void;
}

export const SourcesSection = (props: SourcesSectionProps): ReactElement => {
  const {
    sources,
    onRemoveCampaign,
    onRemoveQuestion,
    onRemoveTrainingSet,
    onRemoveVideo,
    onRemoveFile,
  } = props;

	const sourcesArray = useMemo(() => {
		return [
			...sources.campaigns,
      ...sources.questions,
      ...sources.trainingSets,
      ...sources.files,
      ...sources.videos,
		];
	}, [sources]);

	const getInputChipProps = (classNames?: ArgumentArray) => ({
		variant: "squared",
		className: cx("source", ...(classNames || [])),
		size: "small",
	} as const);

	const renderSource = (source: (typeof sourcesArray)[0]) => {
		if (isQuestion(source)) {
			return (
				<InputChip
					icon={<ChatBubbleQuestionIcon />}
					key={source.id}
					onRemove={() => onRemoveQuestion(source)}
					label={source.text}
					{...getInputChipProps()}
				/>
			)
		}
		if (isTrainingSet(source)) {
			return (
				<InputChip
					icon={<DocumentsFolderFileIcon />}
					key={source.id}
					onRemove={() => onRemoveTrainingSet(source)}
					label={source.alias}
					{...getInputChipProps()}
				/>
			);
		}
		if (isTrainingSetVideo(source)) {
			return (
				<InputChip
					icon={<FileVideoIcon />}
					key={source.id}
					onRemove={() => onRemoveVideo(source)}
					label={source.originalFilename}
					{...getInputChipProps()}
				/>
			);
		}
		if (isTrainingSetFile(source)) {
			return (
				<InputChip
					icon={<FileTextIcon />}
					key={source.id}
					onRemove={() => onRemoveFile(source)}
					label={source.originalFilename}
					{...getInputChipProps()}
				/>
			);
		}

    return (
      <InputChip
        icon={<HornMegaphoneCampaignIcon />}
        key={source.id}
        onRemove={() => onRemoveCampaign(source)}
        label={source.name}
        {...getInputChipProps()}
      />
    );
	};

	if (!sourcesArray.length) {
		return <></>;
	}

	return (
		<div className={styles.sourcesSection}>
			{sourcesArray.map(renderSource)}
		</div>
	);
}
