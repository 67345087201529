import {MapInteractionCSS} from 'react-map-interaction';
import {motion} from "framer-motion";
import classNames from "classnames/bind";
import React, {useLayoutEffect, useRef, useMemo} from "react";

import {AgentTaskContainer} from "../../components/agent-task-container";
import {AiPersonaTask} from "../../../models/ai-orchestration";
import {Connector} from "../connector";
import {FlowOutputCard} from "../flow-output-card";
import {SourcesCard} from "../../components/sources-card";
import {Spinner} from "../../../shared/v2";
import {useThemeMode} from "../../../context/theme-mode-context";
import {useWorkflowContext, useCarouselScrollContext, useWorkflowModalsContext, useCarouselConstraintsContext} from "../../../context/workflow-contexts";
import {useWorkflowStateContext} from "@/context/workflow-contexts/workflow-state-context";
import {WorkflowCanvasButton} from "../workflow-canvas-button";

import styles from "./workflow-canvas.module.scss";

const bStyles = classNames.bind(styles);

export interface WorkflowCanvasProps {
	disabled?: boolean;
	agents?: AiPersonaTask[];
}

export const WorkflowCanvas = ({disabled, agents}: WorkflowCanvasProps) => {
	const {isDarkMode} = useThemeMode();
	const {
		workflow,
		updateTask,
		deleteTask,
		isLoading: isLoadingWorkflow,
	} = useWorkflowStateContext();
	const {
		currentHistory,
		isOnHistoryTab,
		reportUrl,
		reportWordUrl,
		isWorkflowRunning
	} = useWorkflowContext();

	const currentHistorySources = currentHistory?.populatedSources || [];

	const currentSources = useMemo(() => (workflow ? [
		...workflow.campaigns,
		...workflow.questions,
		...workflow.trainingSets,
		...workflow.files,
		...workflow.videos,
	] : []), [workflow]);

	const {setIsSelectAgentModalOpen, sourcesModal: {open: openSourcesModal}} = useWorkflowModalsContext();
	const {
		setDraggableEl,
		setConstraintEl,
		constraints: carouselConstraints,
	} = useCarouselConstraintsContext();
	const lastElementRef = useRef<HTMLDivElement>(null);

	const {scrollToElement} = useCarouselScrollContext();

	useLayoutEffect(() => {
		if (agents && lastElementRef.current) {
			scrollToElement(agents[agents.length - 1].id);
		}
	}, [carouselConstraints.left, carouselConstraints.right, currentHistory]);

	const handleTaskChange = (agent: AiPersonaTask, value: string) => {
		updateTask(agent.id, {taskPrompt: value});
	};

	const handleRemoveAgent = (agentTask) => {
		deleteTask(agentTask.id);
	};

	const handleAddAgent = () => {
		setIsSelectAgentModalOpen(true);
	};

	const handleIndexChange = (agent: AiPersonaTask, newIndex: number) => {
		updateTask(agent.id, {index: newIndex});
	};

	const renderWorkflow = () => {
		if (!workflow) {
			return null;
		}

		return (
			<motion.div
				className={bStyles("carouselContent")}
				dragElastic={0}
				dragMomentum={false}
				dragConstraints={carouselConstraints}
				id="workflow-carousel"

			>
				<div className={styles.workflowContent}>
					<div className={styles.carousel} ref={setDraggableEl}>
						{isOnHistoryTab &&
							<SourcesCard
								workflow={workflow}
								openSourcesModal={openSourcesModal}
								currentSources={currentHistorySources}
								disabled={disabled}
							/>
						}
						{!isOnHistoryTab &&
							<SourcesCard
								workflow={workflow}
								openSourcesModal={openSourcesModal}
								currentSources={currentSources}
								disabled={disabled}
							/>
						}

						{agents?.map((agent, index) => (
							<AgentTaskContainer
								key={agent.id}
								agentTask={agent}
								onRemove={handleRemoveAgent}
								onChangeTask={value => handleTaskChange(agent, value)}
								onChangeIndex={handleIndexChange}
								disabled={disabled}
								elementRef={index === agents.length - 1 ? lastElementRef : null}
								agentsLength={agents.length}
							/>
						))}

						{!disabled && !isWorkflowRunning &&
							<>
								<Connector />
								<WorkflowCanvasButton
									onClick={handleAddAgent}
									disabled={isWorkflowRunning}
								/>
							</>
						}

						<Connector disabled={disabled} />
						<FlowOutputCard
							reportUrl={isOnHistoryTab ? currentHistory?.reportUrl : reportUrl}
							reportWordUrl={isOnHistoryTab ? currentHistory?.reportWordUrl : reportWordUrl}
						/>
					</div>
				</div>
			</motion.div>
		);
	};

	return (
		<div className={bStyles("canvas", {isDarkMode})}>
			{isLoadingWorkflow ? <div className={styles.loadingContainer}>
					<Spinner className={styles.spinner} />
				</div> :
				<MapInteractionCSS
					showControls={true}
					btnClass={styles.zoomBtn}
				>
					<div
						ref={setConstraintEl}
					>
						{renderWorkflow()}
					</div>

				</MapInteractionCSS>
				}
		</div>
	);
};
