import { ChatState, UpdateConversationPayload } from "@/reducer/chat-reducer/types";
import {extractChatChanges, isChatDirty} from ".";

export const handleUpdateConversation = (state: ChatState, payload: UpdateConversationPayload): ChatState => {
  const {nextQuestion: nextQuestionChanges, ...currentChanges} = payload;

  const newNextQuestion = nextQuestionChanges ? {
    ...state.current.nextQuestion,
    ...nextQuestionChanges,
  } : state.current.nextQuestion;

  const newCurrent = {
    ...state.current,
    ...currentChanges,
    nextQuestion: newNextQuestion,
  }
  const newChanges = extractChatChanges(state.original, newCurrent);
  const newIsUpdating = state.original ? isChatDirty(newChanges) : false;
  const newIsLoading = !state.actions.isLoading ? newIsUpdating : false;
  const newActions = {
    ...state.actions,
    isUpdating: newIsUpdating,
    isLoading: newIsLoading,
  }

  return {
    ...state,
    current: newCurrent,
    changes: newChanges,
    actions: newActions,
  };
}
