import {ChatConversationMessageRole} from "@/models/ai-model";
import {ReducedChatConversationMessage, ChatConversationMessageSubtype} from "@/reducer/chat-reducer";
import {isReducedChatConversationMessage} from "../type-guards";
import {UnformatedChatConversationMessage} from "./format-reduced-chat-conversation";

const addSubtype = (messages: UnformatedChatConversationMessage[]): ReducedChatConversationMessage[] => {
  return messages.map(message => {
    if (isReducedChatConversationMessage(message)) {
      return message;
    }

    const newSubtype = message.role === ChatConversationMessageRole.USER ?
      ChatConversationMessageSubtype.QUERY :
      ChatConversationMessageSubtype.RESPONSE;

    return {
      ...message,
      subtype: newSubtype,
    } as ReducedChatConversationMessage;
  });
}

export const sortByPosition = (messages: UnformatedChatConversationMessage[]): UnformatedChatConversationMessage[] => {
  return [...messages].sort((a, b) => a.position - b.position);
}

export const formatReducedChatConversationMessages = (messages: UnformatedChatConversationMessage[]): ReducedChatConversationMessage[] => {
  return addSubtype(sortByPosition(messages));
}
