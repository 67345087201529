import { Body, Separator } from "@/shared/v2";
import React, { ReactElement } from "react";
import classNames from "classnames/bind";
import styles from "./Summary.module.scss";
import { Badge } from "@/workspace-settings/components/model-card/badge";
import { BottomBar } from "./BottomBar";
import { useThemeMode } from "@/context/theme-mode-context";
import { useRecruitQuestionsContext } from "@/campaign/contexts/RecruitQuestionsContext";
import { useRecruitContext } from "@/campaign/contexts/RecruitContext";
const cx = classNames.bind(styles);

export const Summary = (): ReactElement => {
  const { isDarkMode } = useThemeMode();
  const { questions } = useRecruitQuestionsContext();
  const { targetParticipants } = useRecruitContext();

  return (
    <>
      <div className={cx("wrapper", { isDarkMode })}>
        <Body size='xl' type='semibold'>
          Recruitment Summary
        </Body>

        <Separator color='recruit' />

        <div className={styles.content}>
          <Body size='l' type='medium'>
            Exploring Trends in Remote Work
          </Body>

          <div className={styles.rowItem}>
            <Body type='medium'>Participants</Body>
            <Body size='s' color='text-secondary'>
              {targetParticipants} B2B Participants
            </Body>
          </div>

          <div className={styles.rowItem}>
            <Body type='medium'>Questions</Body>
            <Body size='s' color='text-secondary'>
              {questions.length} Questions
            </Body>
          </div>

          <div className={styles.singleRow}>
            <Body type='medium'>Available Credits</Body>
            <Badge text='Cooming Soon' />
          </div>

          <Separator color='recruit' />

          <div className={styles.singleRow}>
            <Body type='medium'>Estimated Cost</Body>
            <Body type='medium'>23 Credits</Body>
          </div>
        </div>
      </div>

      <BottomBar />
    </>
  );
};
