import React, {ReactElement} from "react";

import {EmailSignInButton} from "../../buttons/EmailSignInButton"
import {GoogleSignInButton} from "../../buttons/GoogleSignInButton"
import {StepTemplate} from "../../step-template";
import {Body} from "../../../shared/v2";
import {SsoSignInButton} from "@/login/buttons/SsoSignInButton";

import styles from "./initial-step.module.scss";

export const InitialStep = (): ReactElement => {
	return <StepTemplate>
		<div className={styles.signInButtons}>
			<GoogleSignInButton id="google-sign-up"/>
			<EmailSignInButton id="email-sign-up"/>
			<SsoSignInButton id="sso-sign-up"/>
		</div>

		<div className={styles.bottomSection}>
			<Body size="xs" className={styles.terms}>
          By signing up you agree to Vurvey&apos;s&nbsp;
				<a
					className={styles.link}
					target="_blank"
					href="https://vurvey.ai/terms-conditions/"
					rel="noreferrer">
              Terms and Conditions.
				</a>
			</Body>
			<Body size="xs" className={styles.terms}>
          Read our&nbsp;
				<a
					className={styles.link}
					href="https://vurvey.ai/privacy-policy/"
					target="_blank"
					rel="noreferrer">
            Privacy Policy.
				</a>
			</Body>
		</div>
	</StepTemplate>
}
