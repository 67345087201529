import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {Campaign, TrainingSet, TrainingSetFile, TrainingSetVideo} from "@/models/ai-model";
import {ClearAllButton, CancelButton, SubmitButton} from "./components/buttons";
import {Content} from "./components/content";
import {Modal} from "@/shared/v2";
import {Question} from "@/models/questions";
import {SearchSourcesInput} from "./components/search-sources-input";
import {SelectAiSourcesContextProviders} from "./contexts";
import {SourcesNavigation} from "./components/sources-navigation";
import {useThemeMode} from "@/context/theme-mode-context";

import styles from "./select-ai-sources-modal.module.scss";

const cx = classNames.bind(styles);

export interface AiSources {
	campaigns: Campaign[];
	questions: Question[];
	trainingSets: TrainingSet[];
	files: TrainingSetFile[];
	videos: TrainingSetVideo[];
}

export interface SelectAiSourcesModalProps {
  isOpen: boolean;
  onClose: () => void;
	onSave: (sources: AiSources) => Promise<void>;
	init: AiSources;
	portal?: boolean;
}

export const SelectAiSourcesModal = ({isOpen, onClose, onSave, init, portal = false}: SelectAiSourcesModalProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	return (
		<Modal
			title="Choose Sources"
			isOpen={isOpen}
			onClose={(onClose)}
			className={styles.selectSourceModal}
			headerClassName={styles.header}
			portal={portal}
		>
			<SelectAiSourcesContextProviders onClose={onClose} onSave={onSave} init={init}>
				<div className={styles.navWrapper}>
					<SourcesNavigation />
					<SearchSourcesInput />
				</div>

				<div className={cx("content", {isDarkMode})}>
					<Content />
				</div>

				<div className={styles.bottomSection}>
					<ClearAllButton />

					<div className={styles.commitButtonsWrapper}>
						<CancelButton />
						<SubmitButton />
					</div>
				</div>
			</SelectAiSourcesContextProviders>
		</Modal>
	);
}
