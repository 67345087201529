import {useNavigate} from "react-router";
import {NetworkStatus, useMutation} from "@apollo/client";
import React, {ReactElement, useState, useMemo} from "react";

import {AiOrchestrationDeleteData} from "../../../models/ai-orchestration";
import {Button, DebounceSearch, LoadingContainer} from "../../../shared/v2";
import {PlusSmallIcon, SearchIcon} from "../../../icons";
import {DELETE_AI_ORCHESTRATION} from "../../../graphql/mutations/ai-mutations";
import {AI_ORCHESTRATIONS_PAGE} from "../../../graphql/queries/ai-orchestration-queries";
import {DeleteConfirmModal} from "../../../modals/delete-reel";
import {WorkflowCard} from "../../components/workflow-card";
import {WorkflowSort} from "../../components/workflow-sort";
import {useToastContext} from "../../../context/toast-context";
import {useUserContext} from "../../../context/user-context";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {CreateWorkflowModal} from "../../modals/create-workflow-modal";
import {CreateCard} from "../../../shared/v2/cards";
import {FlowsDisabledState} from "./flows-disabled-state";
import { WorkflowCreationEventsProvider } from '../../../context/workflow-contexts/workflow-creation-events-context';
import {useLoadingQuery} from "@/hooks";
import {TablePagination} from "@/shared/components/table/table-pagination";

import styles from "./flows-page.module.scss";

const PAGE_SIZE = 20;

const FlowsPage = (): ReactElement => {
	const {workspace} = useWorkspaceContext();
	const {user: {role}} = useUserContext();
	const {updateToast} = useToastContext();
	const navigate = useNavigate();

	const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
	const [idForDelete, setIdForDelete] = useState("");
	const [searchValue, setSearchValue] = useState<string>("");
	const [sortValue, setSortValue] = useState("UPDATED_AT_DESC");
	const [pageSize, setPageSize] = useState(PAGE_SIZE);
	const [currentPage, setCurrentPage] = useState(0);

	const {
		data,
		handleFetchMore,
		networkStatus,
		fragment
	} = useLoadingQuery(AI_ORCHESTRATIONS_PAGE, {
		skip: !workspace.id,
		fetchPolicy: "network-only",
		nextFetchPolicy: "cache-first",
		notifyOnNetworkStatusChange: true,
		errorPolicy: "all",
		variables: {
			workspaceId: workspace.id,
			limit: pageSize,
			filter: {
				search: searchValue
			},
			sort: sortValue
		},
	})

	const [deleteOrchestration] = useMutation<AiOrchestrationDeleteData>(DELETE_AI_ORCHESTRATION);

	const totalCount = useMemo(() => {
		if (!data) return 0;
		return data?.aiOrchestrationsPage.items?.length + data?.aiOrchestrationsPage.remaining;
	}, [data?.aiOrchestrationsPage?.items, data?.aiOrchestrationsPage?.remaining]);

	const startingRow = currentPage === 0 ? currentPage + 1 : (currentPage * pageSize) + 1;
	const endRow = ((currentPage + 1) * pageSize) > totalCount
		? totalCount : (currentPage + 1) * pageSize;

	const pageCount = useMemo(() => {
		return Math.ceil(totalCount / pageSize);
	}, [totalCount, pageSize]);

	const dataLength = useMemo(() => {
		if (!data) return 0;
		return data?.aiOrchestrationsPage.items?.length;
	}, [data?.aiOrchestrationsPage?.items])

	const currentData = useMemo(() => {
		const current = data?.aiOrchestrationsPage.items.slice(currentPage * pageSize, (currentPage + 1) * pageSize);

		return current
	}, [data?.aiOrchestrationsPage.items, currentPage]);

	const handleChangeSearch = (value: string): void => {
		setSearchValue(value);
		setCurrentPage(0);
	}

	const handleSortChange = (value: string): void => {
		setSortValue(value);
		setCurrentPage(0);
	}

	const handlePageSizeChange = (newPageSize: number): void => {
		setPageSize(newPageSize);
		setCurrentPage(0);
	}

	const handleNext = (): void => {
		if ((currentPage + 2) * pageSize > dataLength) handleFetchMore(pageSize);
		setCurrentPage(prev => prev + 1);
	};
	const handlePrev = (): void => {
		setCurrentPage(prev => prev - 1);
	};

	const gotoPage = (newPage: number): void => {
		if (currentPage < newPage && (newPage + 1) * pageSize > dataLength) {
			handleFetchMore((newPage - currentPage) * pageSize);
		}
		setCurrentPage(newPage);
	};

	const openWorkflowPage = (id: string): void => {
		navigate(`/workflow/flows/${id}`);
	}

	const handleDeleteWorkflow = (): void => {
		deleteOrchestration({
			variables: {id: idForDelete},
			onCompleted: (data) => {
				updateToast({description: "Deleted Workflow", type: "informational"});
				setIdForDelete("");
			},
			onError: () => {
				setIdForDelete("");
			},
			update: (cache) => {
				cache.modify({
					fields: {
						aiOrchestrationsPage(existingPage = {}, { readField }) {
							const newItems = existingPage.items.filter(
								item => readField('id', item) !== idForDelete
							);
							return {
								...existingPage,
								items: newItems,
							};
						},
					},
				})
			}
		});
	};

	const handleAddWorkflow = (id: string): void => {
		setIsCreateModalOpen(false);
		openWorkflowPage(id);
	};

	const renderContent = (): ReactElement => {
		if (networkStatus !== NetworkStatus.ready && !data?.aiOrchestrationsPage.items.length) {
			return <LoadingContainer />;
		}

		return <>
			{networkStatus === NetworkStatus.fetchMore || networkStatus === NetworkStatus.setVariables ?
				<LoadingContainer /> :
				<>
					<div className={styles.cardsContainer}>
						<div className={styles.cards}>
							<CreateCard wrapperClassname={styles.cardWrapper}>
								<Button
									leftIcon={<PlusSmallIcon className={styles.plusIcon} />}
									color="purple"
									onClick={() => setIsCreateModalOpen(true)}
								>
									Create new
								</Button>
							</CreateCard>

							{(!data && fragment) ||
								(data && currentData && currentData.map((workflow) => (
									<WorkflowCard
										key={workflow.id}
										workflow={workflow}
										onClick={openWorkflowPage}
										handleDelete={id => setIdForDelete(id)}
									/>
							)))}
						</div>
					</div>
			</>
		}

			<TablePagination
				currentPage={currentPage}
				startingRow={startingRow}
				endRow={endRow}
				totalCount={totalCount}
				onPageSizeChange={handlePageSizeChange}
				handleNext={handleNext}
				handlePrev={handlePrev}
				gotoPage={gotoPage}
				pageCount={pageCount}
				pageSize={pageSize}
				disablePagination={networkStatus === NetworkStatus.fetchMore}
			/>
		</>
	}

	if (!workspace.workflowEnabled) {
		return <FlowsDisabledState />
	}

	return(
		<WorkflowCreationEventsProvider>
			<div className={styles.container}>
				<div className={styles.header}>
					<DebounceSearch
						leftIcon={<SearchIcon />}
						size="small"
						placeholder="Search"
						value={searchValue}
						onChange={handleChangeSearch}
						className={styles.searchInput}
					/>

					<WorkflowSort
						value={sortValue}
						onChange={handleSortChange}
						className={styles.sort}
					/>
				</div>
				{renderContent()}

				<DeleteConfirmModal
					isOpen={Boolean(idForDelete)}
					onClose={() => setIdForDelete("")}
					handleConfirm={handleDeleteWorkflow}
					warningText="Are you sure you want to delete this flow?"
				/>

				<CreateWorkflowModal
					isOpen={isCreateModalOpen}
					onClose={() => setIsCreateModalOpen(false)}
					onAdd={handleAddWorkflow}
				/>
			</div>
		</WorkflowCreationEventsProvider>
	);
};

export {FlowsPage};
