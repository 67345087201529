import React, {ReactElement, useMemo, useState} from "react";
import classNames from "classnames/bind";

import {Body, ButtonIcon, Tooltip} from "../../shared/v2";
import {BinDeleteIcon, CopyDocumentsIcon} from "../../icons";
import {useToastContext} from "../../context/toast-context";
import {useChatConversationContext} from "../../context/chat-contexts";
import {ConfirmActionModal} from "@/shared/v2/modals/confirm-action-modal";
import {ChatFakeQueryMessage, ChatQueryMessage} from "@/reducer/chat-reducer";
import {isChatFakeQueryMessage} from "@/shared";

import styles from "./chat-actions.module.scss";

const bStyles = classNames.bind(styles);

interface ButtonTooltipProps {
	icon: ReactElement;
	text: string;
	onClick?: (event) => void;
	iconClassName?: string;
	disabled?: boolean;
}

const ButtonTooltip = ({icon, text, onClick, iconClassName, disabled}: ButtonTooltipProps) => {
	return (
		<Tooltip
			content={
				<Body size="xs" color="text-tertiary">
					{text}
				</Body>
			}
			placement={"top"}
		>
			<ButtonIcon
				size="small"
				color="gray-modern"
				className={iconClassName}
				icon={icon}
				onClick={onClick}
				disabled={disabled}
			/>
		</Tooltip>
	);
};

export interface UserMessageActionsProps {
	className?: string;
	message: ChatQueryMessage | ChatFakeQueryMessage;
}

export const UserMessageActions = ({
	className,
	message,
}: UserMessageActionsProps): ReactElement => {
	const {updateToast} = useToastContext();

	const {
		conversation: {messages},
		isSendingQuestion,
		deleteMessage
	} = useChatConversationContext();
	const isResponsePending = isSendingQuestion && messages.indexOf(message) === messages.length - 2;
	const [isDeleting, setIsDeleting] = useState(false);

	const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);

	const handleDeleteClick = (event) => {
		event.stopPropagation();
		setShowDeleteConfirm(true);
	};

	const handleConfirmDelete = async () => {
		setIsDeleting(true);
		await deleteMessage(message.id, {onSuccess: () => setShowDeleteConfirm(false)});
		setIsDeleting(false);
	};

	const handleCancelDelete = () => {
		setShowDeleteConfirm(false);
	};

	const handleCopy = async (event) => {
		event.stopPropagation();
		try {
			await navigator.clipboard.writeText(message.content);
			updateToast({
				description: "Message copied to clipboard",
				type: "success",
			});
		} catch (error) {
			updateToast({
				description: "Failed to copy message",
				type: "failure",
			});
		}
	};

	const deleteDisabled = useMemo(() => {
		return isDeleting || isChatFakeQueryMessage(message) || isResponsePending;
	}, [message, messages, isDeleting, isResponsePending]);

	return (
		<>
			<div className={bStyles("actions", className)}>
				<ButtonTooltip
					icon={<CopyDocumentsIcon />}
					text="Copy message"
					onClick={handleCopy}
				/>
				<ButtonTooltip
					icon={<BinDeleteIcon />}
					text="Delete message"
					onClick={handleDeleteClick}
					disabled={deleteDisabled}
				/>
			</div>


			<ConfirmActionModal
				portal
				title="Are You Sure?"
				description="This will delete both your message and the assistant's response. Are you sure you want to continue?"
				isOpen={showDeleteConfirm}
				onClose={handleCancelDelete}
				onConfirm={handleConfirmDelete}
			/>
		</>
	);
};
