import React, { ReactElement } from "react";

import { SelectInput, SelectInputProps } from "@/shared/v2/inputs/select-input";
import { InputChip } from "@/shared/components/input-chip";
import { SelectValue } from "@/campaign/contexts/RecruitContext";
import { useThemeMode } from "@/context/theme-mode-context";

import styles from "./SelectWithChips.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

export type SelectWithChips = Omit<SelectInputProps, "onChange"> & {
  selectedItems: SelectValue[];
  allItemsLabel?: string;
  onChange: (items: SelectValue[]) => void;
  selectionLimit?: number;
};

export const SelectWithChips = ({
  id,
  options,
  onChange,
  placeholder,
  selectedItems,
  selectionLimit,
}: SelectWithChips): ReactElement => {
  const { isDarkMode } = useThemeMode();

  const handleRemove = (id: string) => () => {
    const updatedItems = selectedItems.filter((item) => item.id !== id);
    onChange(updatedItems);
  };

  const handleSelect = (jobTitleId: string) => {
    if (selectionLimit && selectedItems.length >= selectionLimit) {
      return;
    }

    const selectedItem = options.find((jobTitle) => jobTitle.id === jobTitleId);

    if (
      selectedItem &&
      selectedItems.find((jobTitle) => jobTitle.id === selectedItem.id)
    ) {
      return;
    }
    selectedItem && onChange([...selectedItems, selectedItem]);
  };

  return (
    <div className={cx("selectWrapper", { isDarkMode })}>
      <SelectInput
        id={id}
        options={options}
        onChange={handleSelect}
        placeholder={placeholder}
      />

      {selectedItems.length > 0 && (
        <div className={styles.chipsContainer}>
          {selectedItems.map((item, index) => (
            <InputChip
              key={index}
              label={item.name}
              variant='squared'
              onRemove={handleRemove(item.id)}
            />
          ))}
        </div>
      )}
    </div>
  );
};
