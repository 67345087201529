import {TrainingSetFile, TrainingSetVideo} from "@/models/ai-model";
import {WorkflowSources} from "@/reducer/workflow-reducer";

export const normalizeWorkflowSources = (sources: WorkflowSources): WorkflowSources => {
  const trainingSetIds = new Set(sources.trainingSets.map(ts => ts.id));

  const filesToKeep: TrainingSetFile[] = [];
  const videosToKeep: TrainingSetVideo[] = [];

  const selectedSets = new Set<string>(trainingSetIds);

  for (const file of sources.files) {
    if (!selectedSets.has(file.trainingSetId)) {
      filesToKeep.push(file);
    }
  }

  for (const video of sources.videos) {
    if (!selectedSets.has(video.trainingSetId)) {
      videosToKeep.push(video);
    }
  }

  return {
    trainingSets: sources.trainingSets,
    files: filesToKeep,
    videos: videosToKeep,
  };
};
