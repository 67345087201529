import React, {ReactElement, useMemo} from "react";

import {SelectCampaignQuestionsTab} from "./select-campaign-questions-tab";
import {SelectCampaignTab} from "./select-campaign-tab";
import {SelectDatasetMediaTab} from "./select-dataset-media";
import {SelectDatasetTab} from "./select-dataset-tab";
import {SelectMediaTab} from "./select-media-tab";
import {SelectSourcesPage, useNavigationContext} from "../../contexts/navigation-context";

export const Content = (): ReactElement => {
  const {currentPage} = useNavigationContext();

  const component = useMemo(() => {
    switch (currentPage) {
      case SelectSourcesPage.CAMPAIGNS:
        return <SelectCampaignTab />;
      case SelectSourcesPage.CAMPAIGN_QUESTIONS:
        return <SelectCampaignQuestionsTab />;
      case SelectSourcesPage.DATASETS:
        return <SelectDatasetTab />;
      case SelectSourcesPage.MEDIA:
        return <SelectMediaTab />;
      case SelectSourcesPage.DATASET_MEDIA:
        return <SelectDatasetMediaTab />;
      default:
        throw new Error(`Invalid page: ${currentPage}`);
    }
  }, [currentPage]);

  return component;
}
