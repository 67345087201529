import React, { ReactElement, useState, useMemo } from "react";
import styles from "./QuestionBlock.module.scss";
import {
  Body,
  Button,
  ButtonIcon,
  Checkbox,
  Input,
  SelectInput,
} from "@/shared/v2";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  CloseMediumIcon,
  PlusIcon,
} from "@/icons";
import { Link } from "@/hoc/layout/navigation/link";
import { AddMultipleModal } from "./AddMultipleModal";
import { useRecruitQuestionsContext } from "@/campaign/contexts/RecruitQuestionsContext";
import { useThemeMode } from "@/context/theme-mode-context";
import classnames from "classnames/bind";
import { DragEndEvent } from "@dnd-kit/core";
import { arrayMove } from "@dnd-kit/sortable";
import { v4 as uuidv4 } from "uuid";
import { ConfirmActionModal } from "@/shared/v2/modals/confirm-action-modal";
import { SortableAnswers } from "./SortableAnswers";

export interface QualifyingQuestion {
  question: string;
  answers: QualifyingQuestionAnswer[];
  allowCustomAnswer: boolean;
  order: number;
  type: "single" | "multiple";
}

export type SingleQuestionOption = "qualify" | "disqualify";
export type MultipleQuestionOption = "maySelect" | "mustSelect" | "disqualify";

export interface QualifyingQuestionAnswer {
  id: string;
  text: string;
  type: SingleQuestionOption | MultipleQuestionOption;
  order: number;
}

export interface QuestionBlockProps {
  question: QualifyingQuestion;
}

const cx = classnames.bind(styles);

export const QuestionBlock = ({
  question,
}: QuestionBlockProps): ReactElement => {
  const { isDarkMode } = useThemeMode();
  const {
    removeQuestion,
    addAnswer,
    removeAnswer,
    bulkAddAnswers,
    updateQuestion,
    reorderQuestions,
    questions,
  } = useRecruitQuestionsContext();

  const [isAddMultipleModalOpen, setIsAddMultipleModalOpen] =
    useState<boolean>(false);
  const [isRemoveQuestionModalOpen, setIsRemoveQuestionModalOpen] =
    useState<boolean>(false);

  const handleAddAnswer = () => {
    addAnswer(question.order, {
      id: uuidv4(),
      text: "",
      type: "qualify",
      order: question.answers.length,
    });
  };

  const handleRemoveAnswer = (order: number) => {
    removeAnswer(question.order, order);
  };

  const toggleAllowCustomAnswer = () => {
    if (question.allowCustomAnswer) {
      updateQuestion(question.order, {
        ...question,
        allowCustomAnswer: !question.allowCustomAnswer,
      });
      const otherAnswerIndex = question.answers.findIndex(
        (answer) => answer.text === "Other"
      );
      if (otherAnswerIndex !== -1) {
        removeAnswer(question.order, otherAnswerIndex);
      }
    } else {
      updateQuestion(question.order, {
        ...question,
        allowCustomAnswer: !question.allowCustomAnswer,
      });
      addAnswer(question.order, {
        id: uuidv4(),
        text: "Other",
        type: "qualify",
        order: question.answers.length,
      });
    }
  };

  const handleBulkAddAnswers = (newAnswers: QualifyingQuestionAnswer[]) => {
    bulkAddAnswers(question.order, newAnswers);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over.id) {
      const oldIndex = question.answers.findIndex(
        (answer) => answer.order === Number(active.id)
      );
      const newIndex = question.answers.findIndex(
        (answer) => answer.order === Number(over.id)
      );

      const newAnswers = arrayMove(question.answers, oldIndex, newIndex).map(
        (answer, index) => ({
          ...answer,
          order: index,
        })
      );

      updateQuestion(question.order, {
        ...question,
        answers: newAnswers,
      });
    }
  };

  const handleMoveUp = () => {
    if (question.order > 0) {
      reorderQuestions(question.order, question.order - 1);
    }
  };

  const handleMoveDown = () => {
    if (question.order < questions.length - 1) {
      reorderQuestions(question.order, question.order + 1);
    }
  };

  const questionTypes = [
    {
      name: "Radio (single select)",
      id: "single",
    },
    {
      name: "Checkbox (multiple select)",
      id: "multiple",
    },
  ];

  const sortedAnswers = useMemo(() => {
    return question.answers.sort((a, b) => a.order - b.order);
  }, [question.answers]);

  const isFirstQuestion = useMemo(() => {
    return question.order === 0;
  }, [question.order]);

  const isLastQuestion = useMemo(() => {
    return question.order === questions.length - 1;
  }, [question.order, questions.length]);

  const isDeleteDisabled = useMemo(() => {
    return questions.length <= 2;
  }, [questions.length]);

  return (
    <>
      <div className={cx("wrapper", { isDarkMode })}>
        <ButtonIcon
          className={styles.removeIcon}
          icon={<CloseMediumIcon />}
          disabled={isDeleteDisabled}
          onClick={() => setIsRemoveQuestionModalOpen(true)}
        />
        <div className={styles.orderWrapper}>
          <ButtonIcon
            filledIcon
            icon={<ChevronUpIcon />}
            onClick={handleMoveUp}
            disabled={isFirstQuestion}
          />
          <ButtonIcon
            filledIcon
            icon={<ChevronDownIcon />}
            onClick={handleMoveDown}
            disabled={isLastQuestion}
          />
        </div>

        <div className={styles.questionWrapper}>
          <div className={styles.question}>
            <div className={styles.headerTypeContainer}>
              <Body size='l' type='semibold'>
                Question {question.order + 1}
              </Body>

              <SelectInput
                className={styles.questionTypeSelect}
                options={questionTypes}
                onChange={(value) =>
                  updateQuestion(question.order, {
                    ...question,
                    type: value as "single" | "multiple",
                  })
                }
                value={question.type}
                placeholder='Select question type'
              />
            </div>
            <Input
              size='small'
              value={question.question}
              onChange={(value) =>
                updateQuestion(question.order, {
                  ...question,
                  question: value,
                })
              }
              placeholder='e.g., Do you feel comfortable speaking on camera?'
            />
          </div>

          <div className={styles.answersContainer}>
            <div className={styles.subheaderContainer}>
              <Body size='s' type='medium'>
                Answer choices
              </Body>

              <Link
                weight='medium'
                type='underline'
                onClick={() => setIsAddMultipleModalOpen(true)}>
                Add multiple at once
              </Link>
            </div>
            <div className={styles.answers}>
              <SortableAnswers
                answers={sortedAnswers}
                questionType={question.type}
                onDragEnd={handleDragEnd}
                onRemoveAnswer={handleRemoveAnswer}
              />
            </div>

            <Button
              className={cx("addAnswerButton", { isDarkMode })}
              variant='outlined'
              onClick={handleAddAnswer}
              leftIcon={<PlusIcon />}>
              Add Answer
            </Button>

            <Checkbox
              size='s'
              checked={question.allowCustomAnswer}
              onChange={toggleAllowCustomAnswer}
              text={
                <Body className={cx("checkboxText", { isDarkMode })}>
                  Allow respondents to add their own answer
                </Body>
              }
            />
          </div>
        </div>
      </div>

      <AddMultipleModal
        title='Add multiple answers'
        description={
          "Enter each answer on a new line. Press 'Enter' to add another."
        }
        isOpen={isAddMultipleModalOpen}
        onClose={() => setIsAddMultipleModalOpen(false)}
        onAddAnswers={handleBulkAddAnswers}
      />

      <ConfirmActionModal
        title='Delete question'
        description={`Are you sure you want to delete the question "${question.question}"?`}
        isOpen={isRemoveQuestionModalOpen}
        onConfirm={() => removeQuestion(question.order)}
        onClose={() => setIsRemoveQuestionModalOpen(false)}
        confirmText='Delete'
        cancelText='Cancel'
      />
    </>
  );
};
