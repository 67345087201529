import React, { ReactElement, useState } from "react";
import styles from "./Answer.module.scss";
import { DragIcon, BinDeleteIcon } from "@/icons";
import { QualifyingQuestionAnswer } from "..";
import { ButtonIcon, Input, SelectInput } from "@/shared/v2";
import classnames from "classnames/bind";
import { useThemeMode } from "@/context/theme-mode-context";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const cx = classnames.bind(styles);

export const Answer = ({
  answer,
  onRemove,
  questionType,
}: {
  answer: QualifyingQuestionAnswer;
  onRemove: (order: number) => void;
  questionType: "single" | "multiple";
}): ReactElement => {
  const { isDarkMode } = useThemeMode();
  const [questionCondition, setQuestionCondition] = useState<string>(
    answer.type
  );
  const [answerText, setAnswerText] = useState<string>(answer.text);

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id: answer.order });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  const singleQuestionTypes = [
    {
      name: "Qualify",
      id: "qualify",
    },
    {
      name: "Disqualify",
      id: "disqualify",
    },
  ];

  const multipleQuestionTypes = [
    {
      name: "May Select",
      id: "qualify",
    },
    {
      name: "Must Select",
      id: "mustSelect",
    },
    {
      name: "Disqualify",
      id: "disqualify",
    },
  ];

  const removeAnswer = () => {
    onRemove(answer.order);
  };

  return (
    <div ref={setNodeRef} style={style} className={styles.answer}>
      <div className={styles.answerInputContainer}>
        <div {...attributes} {...listeners}>
          <DragIcon className={cx("dragIcon", { isDarkMode })} />
        </div>
        <Input
          className={styles.answerInput}
          size='small'
          value={answerText}
          onChange={setAnswerText}
          placeholder='Answer'
        />
      </div>

      {questionType === "single" && (
        <SelectInput
          options={singleQuestionTypes}
          onChange={setQuestionCondition}
          value={questionCondition}
          placeholder='Option'
        />
      )}

      {questionType === "multiple" && (
        <SelectInput
          options={multipleQuestionTypes}
          onChange={setQuestionCondition}
          value={questionCondition}
          placeholder='Option'
        />
      )}

      <ButtonIcon
        icon={<BinDeleteIcon className={cx("deleteIcon", { isDarkMode })} />}
        onClick={removeAnswer}
      />
    </div>
  );
};
