import {uniqBy} from "lodash-es";
import React, {ReactElement, ReactNode, createContext, useEffect, useMemo, useState} from "react";

import {TrainingSet} from "@/models";
import {TrainingSetFile, TrainingSetMedia, TrainingSetVideo} from "@/models/ai-model";
import {WorkflowSources} from "@/reducer/workflow-reducer";
import {isTrainingSet, normalizeWorkflowSources} from "@/shared";
import {usePropsContext} from "./props-context";
import {useGetTrainingSets} from "@/hooks";

export interface SelectedSourcesContextValue {
  all: TrainingSet[];
  isLoading: boolean;
  selected: WorkflowSources;
  isDirty: boolean;
  add: (source: TrainingSet[] | TrainingSetMedia[]) => void;
  remove: (source: TrainingSet[] | TrainingSetMedia[]) => void;
  set: (sources: WorkflowSources) => void;
}

export const SelectedSourcesContext =
  createContext<SelectedSourcesContextValue | undefined>(undefined);

export const SelectedSourcesContextProvider = (
  {children}: {children: ReactNode},
): ReactElement => {
  const {init} = usePropsContext();
  const [selected, _setSelected] = useState<WorkflowSources>({
    trainingSets: [],
    files: [],
    videos: [],
  });

  const {trainingSets, isLoading} = useGetTrainingSets();

  const setSelected = (sources: WorkflowSources): void => {
    _setSelected(normalizeWorkflowSources(sources));
  };

  useEffect(() => {
    setSelected({
      trainingSets: [...init.trainingSets],
      files: [...init.files],
      videos: [...init.videos],
    });
  }, [init]);

  const isDirty = useMemo(() => {
    if (!init) {
      return false;
    }

    return (
      isChanged(init.trainingSets, selected.trainingSets) ||
      isChanged(init.files, selected.files) ||
      isChanged(init.videos, selected.videos)
    );
  }, [init, selected]);

  const addTrainingSets = (trainingSets: TrainingSet[]): void => {
    const currentTrainingSets = selected.trainingSets;
    const newTrainingSets = uniqBy([...currentTrainingSets, ...trainingSets], "id");

    setSelected({
      ...selected,
      trainingSets: newTrainingSets,
    });
  };

  const addMedia = (media: TrainingSetMedia[]): void => {
    const newFiles = media.filter(({__typename}) => __typename === "File") as TrainingSetFile[];
    const newVideos = media.filter(({__typename}) => __typename === "TrainingSetVideo") as TrainingSetVideo[];

    setSelected({
      ...selected,
      files: uniqBy([...selected.files, ...newFiles], "id"),
      videos: uniqBy([...selected.videos, ...newVideos], "id"),
    });
  };

  const add = (sources: TrainingSet[] | TrainingSetMedia[]): void => {
    const source = sources[0];

    if (isTrainingSet(source)) {
      addTrainingSets(sources as TrainingSet[]);
    } else {
      addMedia(sources as TrainingSetMedia[]);
    }
  };

  const removeTrainingSets = (trainingSets: TrainingSet[]): void => {
    const currentTrainingSets = selected.trainingSets;
    const newTrainingSets = currentTrainingSets.filter(({id}) => !trainingSets.some(({id: tsId}) => tsId === id));

    setSelected({
      ...selected,
      trainingSets: newTrainingSets,
    });
  };

  const removeMedia = (mediaToRemove: TrainingSetMedia[]): void => {
    const newSelected = mediaToRemove.reduce((acc, media) => {
      const activeTrainingSet = acc.trainingSets.find(({id}) => id === media.trainingSetId);

      if (activeTrainingSet) {
        const newTrainingSets = acc.trainingSets.filter(({id}) => id !== media.trainingSetId);
        const filesFromTrainingSet = activeTrainingSet.files.filter(({id}) => id !== media.id);
        const videosFromTrainingSet = activeTrainingSet.videos.filter(({id}) => id !== media.id);

        return {
          trainingSets: newTrainingSets,
          files: [
            ...acc.files,
            ...filesFromTrainingSet,
          ],
          videos: [
            ...acc.videos,
            ...videosFromTrainingSet,
          ],
        }
      }

      if (media.__typename === "File") {
        return {
          ...acc,
          files: acc.files.filter(({id}) => id !== media.id),
          videos: acc.videos,
        };
      }

      if (media.__typename === "TrainingSetVideo") {
        return {
          ...acc,
          files: acc.files,
          videos: acc.videos.filter(({id}) => id !== media.id),
        };
      }

      return acc;
    }, {
      trainingSets: [...selected.trainingSets],
      files: [...selected.files],
      videos: [...selected.videos],
    } as WorkflowSources)

    setSelected(newSelected);
  };

  const remove = (sources: TrainingSet[] | TrainingSetMedia[]): void => {
    const source = sources[0];

    if (isTrainingSet(source)) {
      removeTrainingSets(sources as TrainingSet[]);
    } else {
      removeMedia(sources as TrainingSetMedia[]);
    }
  };

  return (
    <SelectedSourcesContext.Provider value={{
      all: trainingSets,
      isLoading,
      selected,
      isDirty,
      add,
      remove,
      set: setSelected,
    }}>
      {children}
    </SelectedSourcesContext.Provider>
  );
};

export const useSelectedSourcesContext = (): SelectedSourcesContextValue => {
  const context = React.useContext(SelectedSourcesContext);

  if (context === undefined) {
    throw new Error(
      "useSelectedSourcesContext must be used within a SelectedSourcesContextProvider",
    );
  }

  return context;
};

function isChanged(original: TrainingSet[], current: TrainingSet[]): boolean;
function isChanged(original: TrainingSetFile[], current: TrainingSetFile[]): boolean;
function isChanged(original: TrainingSetVideo[], current: TrainingSetVideo[]): boolean;
function isChanged(original: (TrainingSet[] | TrainingSetFile[] | TrainingSetVideo[]), current: (TrainingSet[] | TrainingSetFile[] | TrainingSetVideo[])): boolean {
  const originalIds = original.map(({id}) => id);

  return (
    original.length !== current.length ||
    current.some(({id}) => !originalIds.includes(id))
  );
}
