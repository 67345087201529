import classNames from "classnames/bind";
import React, {ReactElement, useState} from "react";

import {ActionsDropdown} from "../../../../../canvas/chat-bubble/actions-dropdown";
import {ButtonIcon} from "../../../../../shared/v2";
import {CHAT_COMMANDS} from "../../../../../shared/constants/constants";
import {KeyboardEventMiddlewareContextProvider} from "../../../../../context/keyboard-event-middleware-context";
import {Persona} from "../../../../../models";
import {PlusIcon, SendArrowChatIcon} from "../../../../../icons";
import {SourcesSection} from "../../../../../canvas/chat-bubble/sources-section";
import {UploadedImageSection} from "../../../../../canvas/chat-bubble/uploaded-image-section";
import {useChatConversationContext} from "../../../../../context/chat-contexts";
import {useNavigate} from "../../../../../route";
import {useTask} from "../../../../../hooks/useTask";
import {useThemeMode} from "../../../../../context/theme-mode-context";
import CommandTextArea from "../../../../../canvas/chat-bubble/command-text-area";

import styles from "./drawer-chat-bubble.module.scss";

const cx = classNames.bind(styles);

export interface DrawerChatBubbleProps {
	agent: Persona;
}

export const DrawerChatBubble = ({agent}: DrawerChatBubbleProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const navigate = useNavigate();
	const {conversation: state} = useChatConversationContext();
	const {nextQuestion: {mention}} = state;
	const [value, setValue] = useState<string>("");
	const isDisabled = value.trim() === "";

	const {run: handleResponse, loading: isRunningHandleResponse} = useTask(async () => {
		if (isDisabled) {
			return;
		}

		navigate(`/`, {
			state: {
				chatConversation: {
					chat: {
						...state,
						aiPersona: agent,
					},
					invokeSendQuestion: value,
				}
			}
		})
	})

	return (
		<div className={cx("chatBubble", {isDarkMode, disabled: isRunningHandleResponse})}>
			<div className={styles.bottomLine}>
				<ActionsDropdown>
					<ButtonIcon
						icon={<PlusIcon />}
						className={styles.icon}
						aria-label='plus-button'
					/>
				</ActionsDropdown>

				<div className={styles.chatContentWrapper}>
					<div className={styles.chatAndSourcesWrapper}>
						<UploadedImageSection />

						<KeyboardEventMiddlewareContextProvider>
							<CommandTextArea
								value={value}
								onChange={setValue}
								placeholder={'Ask anything...'}
								commands={CHAT_COMMANDS}
								handleSubmit={handleResponse}
								mention={mention}
							/>
						</KeyboardEventMiddlewareContextProvider>
					</div>

					<SourcesSection />
				</div>

				<div className={cx("submitButton", {isDarkMode})}>
					<div
						className={styles.chatSubmit}
					>
						<ButtonIcon
							className={styles.icon}
							icon={<SendArrowChatIcon />}
							onClick={handleResponse}
							disabled={isDisabled}
						/>
					</div>
				</div>
			</div>
		</div>
	);
}
