import classNames from "classnames/bind";
import React, {ReactElement, useEffect, useMemo, useRef, useState} from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";

import {AiPersonaTask, ProcessingStep} from "../../../models/ai-orchestration";
import {ArrowSplitIcon, ChevronDownIcon} from "../../../icons";
import {Body} from "../../../shared/v2";
import {ChatScrollContextProvider} from "../../../context/chat-contexts/chat-scroll-context";
import {LinkRenderer} from "@/canvas/chat/response-bubble/content/components";
import {MermaidRenderer} from "../../../shared/v2/mermaid-renderer";
import {NoDraggableItem} from "../no-draggable-item";
import {OutputSteps} from "../output-steps";
import {useThemeMode} from "../../../context/theme-mode-context";
import {useWorkflowContext} from "@/context/workflow-contexts";

import styles from "./output-card.module.scss";

const bStyles = classNames.bind(styles);

export interface OutputCardProps {
	agentTask: AiPersonaTask;
}

interface CodeBlockProps {
  inline?: boolean;
  className?: string;
  children?: React.ReactNode;
}

const CodeBlock: React.FC<CodeBlockProps> = ({inline, className, children}) => {
	const match = /language-(\w+)/.exec(className || "");
	const language = match ? match[1] : "";

	if (!inline && language === "mermaid") {
		return <MermaidRenderer content={String(children).replace(/\n$/, "")} />;
	}

	return (
		<code className={className}>
			{children}
		</code>
	);
};

export const OutputCard = ({agentTask}: OutputCardProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const [isExpanded, setIsExpanded] = useState(false);
	const outputRef = useRef<HTMLDivElement>(null);
  const {currentHistoryRun} = useWorkflowContext();

	// prevent zooming when scrolling
	useEffect(() => {
		const handleWheel = (event: WheelEvent) => {
      event.stopPropagation();
    };

    const outputElement = outputRef.current;
    if (outputElement) {
			outputElement.addEventListener("wheel", handleWheel);

    }

    return () => {
      if (outputElement) {
				outputElement.removeEventListener("wheel", handleWheel);
      }
    };
  }, []);

  const agentOutput = useMemo(() => {
    if (agentTask?.output) {
      return agentTask.output;
    }

    if (currentHistoryRun) {
      const historyTask = currentHistoryRun.outputHistoryTasks.find((task) => task.taskId === agentTask.id);

      if (historyTask) {
        return historyTask.output;
      }
    }

    return "";
  }, [agentTask?.output, currentHistoryRun?.outputHistoryTasks]);


	useEffect(() => {
		// Set expanded when there's output OR processing steps
		if (agentOutput?.length || (agentTask?.processingSteps && agentTask.processingSteps.length > 0)) {
			setIsExpanded(true);
		}
	}, [agentOutput?.length, agentTask?.processingSteps]);

	const cardStyles = bStyles("outputWrapper", {
		completed: agentTask?.processingState?.toLowerCase().includes("[completed]"),
		processing: agentTask?.processingState?.toLowerCase().includes("processing") ||
		           agentTask?.processingState?.toLowerCase().includes("[completing]"),
		error: agentTask?.processingState?.toLowerCase().includes("error"),
		isDarkMode,
	});

	const hasProcessingSteps = agentTask?.processingSteps && agentTask.processingSteps.length > 0;
	const steps: ProcessingStep[] = hasProcessingSteps && agentTask.processingSteps ? agentTask.processingSteps : [];

	return (
		<NoDraggableItem className={styles.noDraggableWrapper}>
			<div className={cardStyles}>
				<div className={styles.header} onClick={() => setIsExpanded(!isExpanded)}>
					<div className={styles.leftSide}>
						<ArrowSplitIcon className={styles.arrowIcon} />
						<Body type="medium">Output</Body>
					</div>

					<ChevronDownIcon className={styles.chevronIcon} />
				</div>

				{isExpanded && (
					<div className={styles.content} ref={outputRef} onWheel={(e) => e.stopPropagation()}>
						{hasProcessingSteps && <OutputSteps steps={steps} />}
						{agentOutput && (
							<div className={styles.outputSection}>
								<Body type="medium" className={styles.sectionTitle}>Task Output:</Body>
								<Body className={styles.message}>
									<ChatScrollContextProvider>
										<ReactMarkdown
											remarkPlugins={[remarkGfm]}
											components={{
												a: LinkRenderer,
												code: CodeBlock as any,
											}}
										>
											{agentOutput}
										</ReactMarkdown>
									</ChatScrollContextProvider>
								</Body>
							</div>
						)}
					</div>
				)}
			</div>
		</NoDraggableItem>
	);
};
