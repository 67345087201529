import React, { ReactElement, useState } from "react";
import styles from "./BottomBar.module.scss";
import { Button } from "@/shared/v2";
import { ChevronLeftIcon } from "@/icons";
import { useRecruitContext, STEPS } from "@/campaign/contexts/RecruitContext";
import { SubmitForApprovalModal } from "../SubmitForApprovalModal";

export const BottomBar = (): ReactElement => {
  const { setCurrentStep } = useRecruitContext();
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);

  const handleSubmitForApproval = () => {
    setIsSubmitModalOpen(true);
  };

  const goToPreviousStep = () => {
    setCurrentStep(STEPS.QUALIFYING_QUESTIONS);
  };

  return (
    <>
      <div className={styles.bottomBar}>
        <Button
          variant='text'
          size='small'
          leftIcon={<ChevronLeftIcon />}
          onClick={goToPreviousStep}>
          Back
        </Button>

        <Button onClick={handleSubmitForApproval}>Submit for approval</Button>
      </div>

      <SubmitForApprovalModal
        isOpen={isSubmitModalOpen}
        onClose={() => setIsSubmitModalOpen(false)}
        onConfirm={() => {}}
      />
    </>
  );
};
