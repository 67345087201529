import React, {ReactElement} from "react";
import {WorkflowCanvas} from "../../../components/workflow-canvas";
import {WorkflowModals} from "../../../components/workflow-modals";
import {useWorkflowStateContext} from "@/context/workflow-contexts/workflow-state-context";

export const FlowBuilder = (): ReactElement => {
  const {workflow} = useWorkflowStateContext();
  const activeAgentTasks = workflow?.aiPersonaTasks || [];

  return (
    <>
      <WorkflowCanvas agents={activeAgentTasks} />
      <WorkflowModals />
    </>
  )
}

