import React from "react";

import {MermaidRenderer} from "@/shared/v2/mermaid-renderer";

export interface CodeBlockRendererProps {
  inline?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export const CodeBlockRenderer: React.FC<CodeBlockRendererProps> = ({inline, className, children}) => {
	const match = /language-(\w+)/.exec(className || "");
	const language = match ? match[1] : "";

	if (!inline && language === "mermaid") {
		return <MermaidRenderer content={String(children).replace(/\n$/, "")} />;
	}

	return (
		<code className={className}>
			{children}
		</code>
	);
};
