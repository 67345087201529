import React, {ReactElement, ReactNode, createContext} from "react";
import {AiSources} from "..";

export interface PropsContextValue {
  init: AiSources;
  onSave: (sources: AiSources) => Promise<void>;
  onClose: () => void;
}

export const PropsContext =
  createContext<PropsContextValue | undefined>(undefined);

export const PropsContextProvider = (
  {children, ...props}: {children: ReactNode} & PropsContextValue,
): ReactElement => {

  return (
    <PropsContext.Provider value={props}>
      {children}
    </PropsContext.Provider>
  );
};

export const usePropsContext = (): PropsContextValue => {
  const context = React.useContext(PropsContext);

  if (context === undefined) {
    throw new Error(
      "usePropsContext must be used within a PropsContextProvider",
    );
  }

  return context;
};
