import React, {ReactElement} from "react";
import {Outlet} from "react-router";

import {ImageHistoryContextProvider} from "../../../canvas/image-studio/contexts";
import {ImageStudio} from "../../../canvas/image-studio";
import {RunWorkflowContextProvider} from "../../../context/workflow-contexts/run-workflow-context";
import {TopBar} from "../../components/topbar";
import {useMount} from "../../../hooks/useMount";
import {WorkflowProvider, WorkflowEventsProvider, CarouselScrollProvider, WorkflowModalsProvider, CarouselConstraintsContextProvider} from "../../../context/workflow-contexts";
import {WorkflowStateContextProvider} from "@/context/workflow-contexts/workflow-state-context";

import styles from "./flow-page.module.scss";

export const FlowPage = (): ReactElement => {
	useMount(() => {document.title = "Vurvey - Workflow"})

	return (
		<ImageHistoryContextProvider>
			<CarouselConstraintsContextProvider>
				<CarouselScrollProvider>
					<WorkflowStateContextProvider>
						<WorkflowProvider>
							<RunWorkflowContextProvider>
								<WorkflowEventsProvider>
									<WorkflowModalsProvider>
										<div className={styles.layout}>
											<TopBar />
											<Outlet  />
											<ImageStudio />
										</div>
									</WorkflowModalsProvider>
								</WorkflowEventsProvider>
							</RunWorkflowContextProvider>
						</WorkflowProvider>
					</WorkflowStateContextProvider>
				</CarouselScrollProvider>
			</CarouselConstraintsContextProvider>
		</ImageHistoryContextProvider>
	);
}
