import React, {ReactElement, useState} from "react";
import {BaseModalProps, Body, Button, Input, Modal, TextArea} from "../../../shared/v2";
import {useMutation} from "@apollo/client";

import {AiSources, SelectAiSourcesModal} from "@/modals/select-ai-sources-modal";
import {Campaign, TrainingSet, TrainingSetFile, TrainingSetVideo} from "../../../models/ai-model";
import {CREATE_AI_ORCHESTRATION} from "../../../graphql/mutations/ai-mutations";
import {CreateAiOrchestrationData, CreateAiOrchestrationVars} from "../../../models/ai-orchestration";
import {PlusSmallIcon} from "../../../icons";
import {Question} from "@/models/questions";
import {SourcesSection} from "./components";
import {useModalActions} from "@/hooks";
import {useThemeMode} from "../../../context/theme-mode-context";
import {useToastContext} from "../../../context/toast-context";
import {useValidation} from "../../../hooks/useValidation";
import {useWorkflowCreationEventsContext} from "@/context/workflow-contexts/workflow-creation-events-context";
import {useWorkspaceContext} from "../../../context/workspace-context";

import styles from "./create-workflow-modal.module.scss";

export interface CreateWorkflowModalProps extends BaseModalProps {
	onAdd: (id: string) => void;
}

export const CreateWorkflowModal = ({isOpen, onClose, onAdd}: CreateWorkflowModalProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {updateToast} = useToastContext();
	const {
		workflowCreationStarted,
		agentCreationProgress,
		workflowCreationCompleted,
		workflowCreationError,
		setActiveOrchestrationId
	} = useWorkflowCreationEventsContext();
	const {
		workspace: {id: workspaceId},
	} = useWorkspaceContext();
	const [changes, setChanges] = useState({
		name: "",
		objective: "",
	});

	const {
		value: isSelectCreateWorkflowSourcesModalOpen,
		open: openIsSelectCreateWorkflowSourcesModal,
		close: closeIsSelectCreateWorkflowSourcesModal,
	} = useModalActions();
	const [sources, setSources] = useState<AiSources>({
		campaigns: [],
		questions: [],
		trainingSets: [],
		files: [],
		videos: [],
	});
	const [creationProgress, setCreationProgress] = useState<{current: number; total: number; message: string} | null>(null);
	const [createWorkflow] = useMutation<CreateAiOrchestrationData, CreateAiOrchestrationVars>(
		CREATE_AI_ORCHESTRATION,
		{
			onError: (error) => {
				console.error('Error creating workflow:', error);
				setCreationProgress(null);
				setActiveOrchestrationId(undefined);
				updateToast({
					description: "Failed to create workflow, please try again later.",
					type: "failure",
				});
			},
		},
	);

	const {
		results: validation,
		validate,
		clearErrors,
	} = useValidation(changes, {
		name: {
			ruleset: {
				required: true,
				max: 150,
			},
		},
		objective: {
			ruleset: {
				required: true,
			},
		},
	});

	const [autoWorkflow, setAutoWorkflow] = useState(true);

	const handleChangeField = (value: string, field: string): void => {
		setChanges({
			...changes,
			[field]: value,
		});
	};

	const handleSave = async (): Promise<void> => {
		if (!validate()) {
			return;
		}

		try {
			console.log('Creating workflow...');

			setCreationProgress({
				current: 0,
				total: 1,
				message: "🚀 Starting workflow creation..."
			});

			// Generate a valid GUID for the orchestration
			const orchestrationId = crypto.randomUUID();
			console.log('Generated orchestration ID:', orchestrationId);

			// Set up subscription first
			setActiveOrchestrationId(orchestrationId);

			// Fire and forget the mutation - we'll rely on subscription events
			createWorkflow({
				variables: {
					workspaceId,
					name: changes.name,
					description: changes.objective,
					trainingSetIds: sources.trainingSets.map(s => s.id),
					surveyIds: sources.campaigns.map(s => s.id),
					questionIds: sources.questions.map(s => s.id),
					fileIds: sources.files.map(s => s.id),
					videoIds: sources.videos.map(s => s.id),
					autoWorkflow,
					orchestrationId,
				},
			}).catch(error => {
				// Only log the error, don't throw it
				console.error('Mutation error:', error);
			});

			console.log('Workflow creation initiated with ID:', orchestrationId);

		} catch (error) {
			console.error('Error in handleSave:', error);
			setCreationProgress(null);
			setActiveOrchestrationId(undefined);
		}
	};

	// Handle workflow creation events
	React.useEffect(() => {
		const currentId = workflowCreationStarted?.orchestrationId ||
			agentCreationProgress?.orchestrationId ||
			workflowCreationCompleted?.orchestrationId ||
			workflowCreationError?.orchestrationId;

		if (!currentId) return;

		// Initialize timeout reference
		let timeoutId: NodeJS.Timeout | undefined;

		if (workflowCreationStarted?.orchestrationId === currentId) {
			console.log('Workflow creation started:', workflowCreationStarted);
			setCreationProgress({
				current: 0,
				total: 100,
				message: "✨ Preparing your intelligent workflow"
			});

			// Set a timeout to reload the page if the workflow hasn't completed after 1 minute
			timeoutId = setTimeout(() => {
				// Check if we're still in progress (not completed or errored)
				if (creationProgress && creationProgress.current < creationProgress.total) {
					console.log('Workflow creation timed out, reloading page...');
					window.location.reload();
				}
			}, 60000); // 1 minute timeout
		}

		if (agentCreationProgress?.orchestrationId === currentId) {
			console.log('Agent creation progress:', agentCreationProgress);

			// Extract the clean message
			const message = agentCreationProgress.agentName
				.replace(/^[⠋⠙⠹⠸⠼⠴⠦⠧⠇⠏]\s*/, '') // Remove spinner characters
				.trim();

			setCreationProgress({
				current: agentCreationProgress.progress,
				total: 100,
				message,
			});
		}

		if (workflowCreationCompleted?.orchestrationId === currentId) {
			console.log('Workflow creation completed:', workflowCreationCompleted);
			clearTimeout(timeoutId); // Clear the timeout on successful completion
			setCreationProgress({
				current: 100,
				total: 100,
				message: "✅ Workflow created successfully"
			});
			setTimeout(() => {
				onAdd(workflowCreationCompleted.orchestrationId);
				handleClose();
			}, 1000); // Short delay to show completion
		}

		if (workflowCreationError?.orchestrationId === currentId) {
			console.log('Workflow creation error:', workflowCreationError);
			clearTimeout(timeoutId); // Clear the timeout on error
			setCreationProgress(null);
			setActiveOrchestrationId(undefined);
			updateToast({
				description: `Error creating workflow: ${workflowCreationError.error}`,
				type: "failure",
			});
		}

		// Cleanup timeout on unmount
		return () => {
			timeoutId && clearTimeout(timeoutId);
		};
	}, [workflowCreationStarted, agentCreationProgress, workflowCreationCompleted, workflowCreationError]);

	const handleClose = (): void => {
		console.log('Closing modal, cleaning up state...');
		setChanges({
			name: "",
			objective: "",
		});
		setCreationProgress(null);
		setActiveOrchestrationId(undefined);
		clearErrors();
		onClose();
	};

	const formContentRef = React.useRef<HTMLDivElement>(null);
	const [hasScroll, setHasScroll] = useState(false);

	// Check if content is scrollable
	const checkScroll = React.useCallback(() => {
		if (formContentRef.current) {
			const element = formContentRef.current;
			setHasScroll(element.scrollHeight > element.clientHeight);
		}
	}, []);

	// Check scroll on content changes
	React.useEffect(() => {
		checkScroll();
		// Add resize observer to check scroll when container size changes
		const resizeObserver = new ResizeObserver(checkScroll);
		if (formContentRef.current) {
			resizeObserver.observe(formContentRef.current);
		}
		return () => resizeObserver.disconnect();
	}, [checkScroll, changes, sources]);

	const handleRemoveCampaign = (campaign: Campaign): void => {
		setSources({
			...sources,
			campaigns: sources.campaigns.filter(c => c.id !== campaign.id),
		});
	}

	const handleRemoveQuestion = (question: Question): void => {
		setSources({
			...sources,
			questions: sources.questions.filter(q => q.id !== question.id),
		});
	}

	const handleRemoveTrainingSet = (trainingSet: TrainingSet): void => {
		setSources({
			...sources,
			trainingSets: sources.trainingSets.filter(ts => ts.id !== trainingSet.id),
		});
	}

	const handleRemoveVideo = (video: TrainingSetVideo): void => {
		setSources({
			...sources,
			videos: sources.videos.filter(v => v.id !== video.id),
		});
	}

	const handleRemoveTrainingSetFile = (file: TrainingSetFile): void => {
		setSources({
			...sources,
			files: sources.files.filter(f => f.id !== file.id),
		});
	}

	return (
		<>
			<Modal
				size="medium"
				isOpen={isOpen}
				onClose={handleClose}
				//title="Create New Workflow"
				className={styles.modal}
				backgroundColor="transparent"
			>
				<div style={{color: isDarkMode ? "#BFBFBF" : "inherit"}} className={`${styles.cardContainer} ${creationProgress ? styles.flipped : ''}`}>
					<div className={`${styles.cardSide} ${styles.cardFront}`}>
						<div className={styles.form}>
							<div className={`${styles.formWrapper} ${hasScroll ? styles.hasScroll : ''}`}>
								<div
									ref={formContentRef}
									className={styles.formContent}
								>
									<Input
										placeholder="My workflow"
										label="Workflow Name"
										value={changes.name}
										onChange={(value: string) => handleChangeField(value, "name")}
										validation={validation.name}
									/>

									<div className={styles.objectiveWrapper}>
										<div className={styles.textContainer}>
											<Body color="text-secondary" size="s" type="medium">
												Objective
											</Body>
											<Body color="text-tertiary" size="s" type="regular">
												Define the overall objective for this workflow. This doesn&apos;t need to be highly
												specific, as you&apos;ll be able to assign detailed tasks to each agent later on.
											</Body>
										</div>
										<TextArea
											placeholder="e.g., Enhance marketing insights or Analyze customer feedback"
											value={changes.objective}
											onChange={(value: string) => handleChangeField(value, "objective")}
											validation={validation.objective}
										/>
									</div>

									<div className={styles.sourcesWrapper}>
										<div className={styles.textContainer}>
											<Body color="text-secondary" size="s" type="medium">
												Sources
											</Body>
											<Body color="text-tertiary" size="s" type="regular">
												Link appropriate sources for your workflow. The entire workflow will have access to
												these added sources, such as campaigns or datasets, to ensure comprehensive data
												integration.
											</Body>
										</div>

										<SourcesSection
											sources={sources}
											onRemoveCampaign={handleRemoveCampaign}
											onRemoveQuestion={handleRemoveQuestion}
											onRemoveTrainingSet={handleRemoveTrainingSet}
											onRemoveVideo={handleRemoveVideo}
											onRemoveFile={handleRemoveTrainingSetFile}
										/>

										<Button
											className={styles.addSource}
											leftIcon={<PlusSmallIcon />}
											variant="text"
											onClick={openIsSelectCreateWorkflowSourcesModal}
										>
											Add source
										</Button>
									</div>

									{/*<Checkbox
										text="Auto Workflow"
										checked={autoWorkflow}
										onChange={e => setAutoWorkflow(e.target.checked)}
										className={styles.checkboxText}
									/>*/}
								</div>
							</div>

							<div className={styles.footer}>
								<Button className={styles.button} variant="outlined" onClick={handleClose}>
									Cancel
								</Button>

								<Button className={styles.button} onClick={handleSave}>
									Create Workflow
								</Button>
							</div>
						</div>
					</div>
					<div className={`${styles.cardSide} ${styles.cardBack}`}>
						<div className={styles.progressContainer}>
							<div className={styles.spinner} />
							<div className={styles.messageContainer}>
								<div className={styles.message} key={creationProgress?.message}>
									{creationProgress?.message.replace(/^Creating agent:\s*/i, '')}
								</div>
							</div>
							<div className={styles.progressBar}>
								<div
									className={styles.progressBarFill}
									style={{
										width: `${creationProgress ? (creationProgress.current / creationProgress.total) * 100 : 0}%`
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			</Modal>

			<SelectAiSourcesModal
				init={sources}
				isOpen={isSelectCreateWorkflowSourcesModalOpen}
				onClose={closeIsSelectCreateWorkflowSourcesModal}
				onSave={async (sources) => {
					setSources(sources);
					closeIsSelectCreateWorkflowSourcesModal();
				}}
			/>
		</>
	);
};
