import React, {ReactElement} from "react";
import classNames from "classnames/bind";

import {DocumentsFolderFileIcon, PhotoImageIcon, DocumentIcon} from "../../../icons";
import {Dropdown} from "../../../shared/v2";
import {DropdownProps} from "@/shared/v2/dropdown";
import {SelectChatSourcesModal} from "@/canvas/select-chat-sources-modal";
import {useChatConversationContext} from "../../../context/chat-contexts";
import {useLocalFileUpload, useModalActions, useUploadChatDocument} from "../../../hooks";

import styles from "./actions-dropdown.module.scss";

const bStyles = classNames.bind(styles);

export interface ActionsDropdownProps {
	children: Pick<DropdownProps, "trigger">["trigger"];
  disabled?: boolean;
  className?: string;
  sources?: boolean;
  image?: boolean;
  document?: boolean;
  onClose?: () => void;
  agent?: boolean;
}

export const ActionsDropdown = ({
  children,
  className,
  disabled = false,
  sources = true,
  image = true,
  document = true,
}: ActionsDropdownProps): ReactElement => {
  const {
    isUpdatingDisabled,
    update: updateConversation,
  } = useChatConversationContext();
  const uploadImage = useLocalFileUpload({
    onUpload: ([image]) => {
      updateConversation({nextQuestion: {image}});
    },
    options: {
      accept: "image/*",
    },
  });
  const {upload: uploadDocument} = useUploadChatDocument();

  const {
    value: isSelectChatSourcesModalOpen,
    open: openSelectChatSourcesModal,
    close: closeSelectChatSourcesModal,
  } = useModalActions({search: "chat-sources"});

  return (
    <>
      <Dropdown
        className={bStyles("actionsDropdown", className)}
        position="bottom-start"
        disabled={disabled}
        space={12}
        trigger={children}
        ariaLabel="plus-button"
        items={[
          sources
            ? {
                label: "Include Sources",
                disabled: isUpdatingDisabled,
                icon: <DocumentsFolderFileIcon />,
                onClick: openSelectChatSourcesModal,
              }
            : undefined,
          image
            ? {
                label: "Upload Image",
                icon: <PhotoImageIcon />,
                onClick: uploadImage,
              }
            : undefined,
          document
            ? {
                label: "Upload Document",
                icon: <DocumentIcon />,
                onClick: uploadDocument,
              }
            : undefined,
        ]}
      />

      {sources && (
        <SelectChatSourcesModal
          isOpen={isSelectChatSourcesModalOpen}
          onClose={closeSelectChatSourcesModal}
        />
      )}
    </>
  );
};
