import React, { ReactElement } from "react";

import styles from "./SubmitForApprovalModal.module.scss";
import { BaseModalProps, Button, Modal } from "@/shared/v2";

export interface SubmitForApprovalModalProps extends BaseModalProps {
  onConfirm: () => void;

  className?: string;
}

export const SubmitForApprovalModal = ({
  isOpen,
  onClose,
  onConfirm,

  className,
}: SubmitForApprovalModalProps): ReactElement => {
  return (
    <Modal
      size='small'
      isOpen={isOpen}
      onClose={onClose}
      title='Submit for approval?'
      description='Once you submit your recruitment survey for approval, you won’t be able to make any further edits. The review process typically takes 1-2 business days. Are you sure you want to proceed?'
      className={className}>
      <div className={styles.footer}>
        <Button variant='outlined' className={styles.button} onClick={onClose}>
          Cancel
        </Button>
        <Button className={styles.button} onClick={onConfirm}>
          Submit for approval
        </Button>
      </div>
    </Modal>
  );
};
