import {LineConfig} from "konva/lib/shapes/Line";
import Konva from "konva";
import React, {createContext, Dispatch, MutableRefObject, ReactElement, ReactNode, SetStateAction, useRef, useState} from "react";

import {useImageElementContext} from "./image-element";

export interface CanvasContextValue {
  stageRef: MutableRefObject<Konva.Stage | null>;
  lines: LineConfig[];
  setLines: Dispatch<SetStateAction<LineConfig[]>>;
  getMask: () => {hasMask: boolean, maskData: string};
}

export const CanvasContext =
  createContext<CanvasContextValue | undefined>(undefined);

const drawLineOnCanvas = (
  context: CanvasRenderingContext2D,
  line: LineConfig,
  stage: Konva.Stage,
  naturalWidth: number,
  naturalHeight: number
): void => {
  if (!line.points || line.points.length < 2) {
    console.warn("Invalid line points:", line);
    return;
  }

  const stageSize = stage.size();
  const stageWidth = stageSize.width;
  const stageHeight = stageSize.height;

  const scaleX = naturalWidth / stageWidth;
  const scaleY = naturalHeight / stageHeight;

  context.beginPath();
  context.moveTo(line.points[0] * scaleX, line.points[1] * scaleY);

  for (let i = 2; i < line.points.length; i += 2) {
    if (i + 1 < line.points.length) {
      context.lineTo(line.points[i] * scaleX, line.points[i + 1] * scaleY);
    }
  }

  context.strokeStyle = "rgb(254, 254, 254)";
  context.lineWidth = (line.strokeWidth as number) * ((scaleX + scaleY) / 2);
  context.lineCap = "round";
  context.lineJoin = "round";
  context.globalCompositeOperation = line.globalCompositeOperation || "source-over";
  context.stroke();
};

export const CanvasContextProvider = (
  {children}: {children: ReactNode},
): ReactElement => {
  const [lines, setLines] = useState<LineConfig[]>([]);
  const {element: imageEl} = useImageElementContext();
  const stageRef = useRef<Konva.Stage | null>(null);

  const getMask = (): {
    hasMask: boolean;
    maskData: string;
  } => {
    if (!stageRef.current || !lines.length) {
      return {
        hasMask: false,
        maskData: ""
      };
    }

    try {
      const stage = stageRef.current;
      
      // Check if canvas has valid dimensions to prevent errors
      if (!stage.width() || !stage.height()) {
        console.warn("Canvas has invalid dimensions (width or height is 0)");
        return {
          hasMask: false,
          maskData: ""
        };
      }
      
      // Create a temporary canvas to get mask data
      const canvas = document.createElement('canvas');
      canvas.width = stage.width();
      canvas.height = stage.height();
      
      const context = canvas.getContext('2d');
      if (!context) {
        console.error("Failed to get canvas context");
        return {
          hasMask: false,
          maskData: ""
        };
      }
      
      // Start with a black background (fully transparent mask)
      context.fillStyle = 'black';
      context.fillRect(0, 0, canvas.width, canvas.height);
      
      // Set white lines with full opacity for the mask
      context.globalCompositeOperation = 'source-over';
      
      // Draw lines directly on canvas 
      let hasValidLines = false;
      
      for (const line of lines) {
        if (!line.points || line.points.length < 4) continue; // Need at least 2 points to draw
        
        context.beginPath();
        context.strokeStyle = 'white'; // Use white for the mask
        context.lineWidth = line.strokeWidth || 40;
        context.lineCap = 'round';
        context.lineJoin = 'round';
        
        // Move to first point
        context.moveTo(line.points[0], line.points[1]);
        
        // Draw lines through all remaining points
        for (let i = 2; i < line.points.length; i += 2) {
          context.lineTo(line.points[i], line.points[i + 1]);
        }
        
        context.stroke();
        hasValidLines = true;
      }
      
      if (!hasValidLines) {
        return {
          hasMask: false,
          maskData: ""
        };
      }
      
      // Check if there are any white pixels in the mask
      const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;
      let hasWhitePixel = false;
      
      // Check for white pixels (255, 255, 255)
      for (let i = 0; i < data.length; i += 4) {
        if (data[i] === 255 && data[i + 1] === 255 && data[i + 2] === 255) {
          hasWhitePixel = true;
          break;
        }
      }
      
      if (!hasWhitePixel) {
        console.log("No white pixels found in mask");
        return {
          hasMask: false,
          maskData: ""
        };
      }
      
      // Add logging to help debug
      console.log("Generated valid mask with dimensions:", canvas.width, "x", canvas.height);
      
      // Convert to webp format for better compatibility
      const maskDataUrl = canvas.toDataURL('image/webp');

      return {
        hasMask: true,
        maskData: maskDataUrl
      };
    } catch (error) {
      console.error("Error generating mask:", error);
      return {
        hasMask: false,
        maskData: ""
      };
    }
  };

  return (
    <CanvasContext.Provider value={{
      stageRef,
      lines,
      setLines,
      getMask,
    }}>
      {children}
    </CanvasContext.Provider>
  );
};

export const useCanvasContext = (): CanvasContextValue => {
  const context = React.useContext(CanvasContext);

  if (context === undefined) {
    throw new Error(
      "useCanvasContext must be used within a CanvasContextProvider",
    );
  }

  return context;
};
