import React, { ReactElement, ReactNode } from "react";

import { useSignUpModal } from "../../context/sign-up-modal.context";
import {
  SignUpModalActionTypes,
  SignUpModalStep,
} from "../../reducer/sign-up-modal-reducer";
import { ChevronLeftIcon, VurveyLabsLockupBlackIcon } from "../../icons";
import { Body, Header } from "../../shared/v2";

import styles from "./step-template.module.scss";

export interface StepTemplateProps {
  header?: string;
  id?: string;
  children?: ReactNode;
  onRevert?: () => void;
  description?: string;
}

export const StepTemplate = ({
  id,
  onRevert,
  header = "Unlock Vurvey AI!",
  description = "Sign in to generate what's next for your brand.",
  children,
}: StepTemplateProps): ReactElement => {
  const {
    dispatch,
    state: { currentStep, history },
    setIsOpen,
  } = useSignUpModal();

  const handleArrowBackClick = (): void => {
    if (onRevert) {
      return onRevert();
    }

    if (history.length === 1) {
      setIsOpen(false);
      return;
    }

    dispatch({ type: SignUpModalActionTypes.REVERT_STEP });
  };

  return (
    <>
      <div className={styles.content} id={id}>
        <div className={styles.contentWrapper}>
          <VurveyLabsLockupBlackIcon className={styles.fullLogo} />
          {currentStep !== SignUpModalStep.Initial && (
            <ChevronLeftIcon
              onClick={handleArrowBackClick}
              className={styles.closeIcon}
            />
          )}

          <Header type='semibold' className={styles.header}>
            {header}
          </Header>
          <Body size='l' color='text-secondary' className={styles.description}>
            {description}
          </Body>
          {children}
        </div>
      </div>
      <div className={styles.imageWrapper}>
        <img
          className={styles.image}
          src='/images/login-photo.png'
          alt='Login'
        />
        <div className={styles.imageTextWrapper}>
          <div className={styles.imageText}>“It&apos;s like</div>
          <div className={styles.imageText}>private TikTok</div>
          <div className={styles.imageText}>directly with</div>
          <div className={styles.imageText}>my customers”</div>
        </div>
      </div>
    </>
  );
};
