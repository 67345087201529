import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {Body, Checkbox, Separator, Subheader} from "@/shared/v2";
import {Campaign} from "@/models/ai-model";
import {ChatBubbleCameraIcon, ChatBubbleCheckmarkIcon, ChatBubbleCompletesIcon, ChatBubbleQuestionIcon} from "@/icons";
import {convertTimeFromMiliseconds} from "@/shared/utility/utility";
import {CounterContainer} from "@/shared/v2/campaign-card";
import {OverflowTooltip} from "@/shared/v2/tooltip/overflow-tooltip";
import {SelectSourcesPage, useNavigationContext} from "../../../contexts/navigation-context";
import {useSelectedCampaignsContext} from "../../../contexts/selected-campaigns-context";
import {useThemeMode} from "@/context/theme-mode-context";

import styles from "./select-campaign-button.module.scss";

const cx = classNames.bind(styles);

export interface SelectCampaignButtonProps {
  campaign: Campaign;
	isSelected: boolean;
  totalSelectedQuestions: number;
}

export const SelectCampaignButton = ({campaign, isSelected, totalSelectedQuestions}: SelectCampaignButtonProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {setCurrentPage} = useNavigationContext();
	const {add, remove} = useSelectedCampaignsContext();

	const selectedQuestionsCount = isSelected ? campaign.questionCount : totalSelectedQuestions;

	const handleToggleCheck = () => {
		if (isSelected) {
			remove([campaign]);
		} else {
			add([campaign]);
		}
	};

	return (
		<div
			className={cx(
				"selectCampaignButton",
				{
					selected: isSelected || totalSelectedQuestions > 0,
					isDarkMode,
				}
			)}
			onClick={() => setCurrentPage(SelectSourcesPage.CAMPAIGN_QUESTIONS, campaign)}
		>
			<div className={styles.contentWrapper}>
				<div className={styles.nameWrapper}>
					<Checkbox
						checked={isSelected}
						onChange={handleToggleCheck}
						onClick={(e) => e.stopPropagation()}
					/>

					<OverflowTooltip>
						<Subheader
							color="text-body"
							type="semibold"
							className={styles.campaignName}
						>
							{campaign.name}
						</Subheader>
					</OverflowTooltip>
				</div>

				<div className={styles.countersContainer}>
					<CounterContainer
						icon={<ChatBubbleCheckmarkIcon className={styles.icon} />}
						label="Responses:"
						value={campaign.responseCount}
					/>

					<CounterContainer
						icon={<ChatBubbleCompletesIcon className={styles.icon} />}
						label="Completes:"
						value={campaign.completedResponseCount}
					/>

					<CounterContainer
						icon={<ChatBubbleCameraIcon className={styles.icon} />}
						label="Video Minutes:"

						value={`${Math.floor(convertTimeFromMiliseconds(campaign.totalVideoDuration, "minutes"))} min`}
					/>
				</div>
			</div>

			<Separator className={styles.separator} />

			<div className={styles.filesSection}>
				<div className={styles.totalFiles}>
					<ChatBubbleQuestionIcon className={styles.totalFilesIcon} />

					<Body
						size="s"
						type="medium"
						className={styles.totalFilesText}
					>
						{campaign.questionCount} Questions
					</Body>
				</div>

				<Body
					size="s"
					type="medium"
					className={styles.selectedFiles}
				>
					Selected: <span className={styles.selectedFilesCount}>{selectedQuestionsCount}</span>
				</Body>
			</div>
		</div>
	);
}
