import React, {ReactElement} from "react"

import {Persona, PersonaStatus, PersonaTransformedStatus} from '../../../../models/persona';
import {Avatar, Body, Subheader} from "../../../../shared/v2";
import {VurveyLabsIconVolt} from "../../../../icons";
import {Badge} from "../../../../workspace-settings/components/model-card/badge";

import styles from "./persona-card-content.module.scss";

export interface PersonaCardContentProps {
  persona: Persona;
}

export const transformPersonaStatus = (status: string): PersonaTransformedStatus => {
	if (status === PersonaStatus.PUBLISHED) {
		return PersonaTransformedStatus.ACTIVE;
	}
	return PersonaTransformedStatus.INACTIVE;
}

export const PersonaCardContent = ({persona}: PersonaCardContentProps):ReactElement => {
	return <>
		<Avatar firstName={persona.name} size="xl" url={persona.picture?.url || persona.avatarUri} additionalIcon={persona.isVurvey ? <VurveyLabsIconVolt /> : undefined} />
		<div className={styles.contentWrapper}>
			<Subheader type="medium" className={styles.name}>{persona.name}</Subheader>
			<Body
				size="s"
				color="text-secondary"
				className={styles.description}
			>
				{persona.description}
			</Body>
			<div className={styles.bottomTags}>
				<Badge text={transformPersonaStatus(persona?.personaStatus)}/>
			</div>
		</div>
	</>
}
