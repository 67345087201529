import React, {ReactElement, ReactNode, createContext, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {useNavigate} from "../../../route";

export interface ImageHistoryContextValue {
  history: string[];
  setImageSrc: (src: string) => void;
  clearImage: () => void;
  imageSrc: string | null;
  addImagesToHistory: (images: string[]) => void;
  originalSrc: string | null;
}

export const ImageHistoryContext =
  createContext<ImageHistoryContextValue | undefined>(undefined);

export const ImageHistoryContextProvider = (
  {children}: {children: ReactNode},
): ReactElement => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const [current, setCurrent] = useState<string | null>(null);
  const [history, setHistory] = useState<string[]>([]);
  const [originalSrc, setOriginalSrc] = useState<string | null>(null);

  const isValidImageUrl = (url: string): boolean => {
    if (!url || typeof url !== 'string') return false;
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.protocol.startsWith('http');
    } catch {
      return false;
    }
  };

  useEffect(() => {
    const image = params.get("image");
    const originalImage = params.get("originalImage");

    if (image === null) {
      setHistory([]);
      setCurrent(null);
      setOriginalSrc(null);
      return;
    }
    
    try {
      // Ensure the image URL is properly decoded
      const decodedImage = decodeURIComponent(image);
      
      // Validate the URL
      if (!isValidImageUrl(decodedImage)) {
        console.warn("Invalid image URL format:", decodedImage);
        setCurrent(null);
        return;
      }
      
      setCurrent(decodedImage);
      
      // Handle original image tracking
      if (originalImage) {
        const decodedOriginal = decodeURIComponent(originalImage);
        if (isValidImageUrl(decodedOriginal)) {
          setOriginalSrc(decodedOriginal);
        } else {
          setOriginalSrc(decodedImage);
        }
      } else {
        setOriginalSrc(decodedImage);
      }
      
      // Process history if present
      const historyParam = params.get("history");
      if (historyParam) {
        try {
          // Handle multiple images in history parameter
          const historyArray = historyParam.split(",")
            .map(url => {
              try {
                return decodeURIComponent(url);
              } catch (e) {
                console.warn("Error decoding history URL:", url, e);
                return url;
              }
            })
            .filter(isValidImageUrl);
            
          console.log(`Loaded ${historyArray.length} valid images from history:`, historyArray);
          setHistory(historyArray);
        } catch (error) {
          console.warn("Error processing history parameter:", error);
          setHistory([decodedImage]);
        }
      } else {
        setHistory([decodedImage]);
      }
    } catch (error) {
      console.warn("Error processing image URL:", error);
      if (isValidImageUrl(image)) {
        setCurrent(image);
        setOriginalSrc(originalImage && isValidImageUrl(originalImage) ? originalImage : image);
        const historyParam = params.get("history");
        const historyArray = historyParam ? 
          historyParam.split(",").filter(isValidImageUrl) : 
          [image];
        setHistory(historyArray);
      } else {
        setCurrent(null);
        setOriginalSrc(null);
        setHistory([]);
      }
    }
  }, [params]);

  // Helper to add multiple images to history
  const addImagesToHistory = (images: string[]): void => {
    if (!images || images.length === 0) {
      console.log("No images provided to addImagesToHistory");
      return;
    }
    
    // Filter out invalid URLs and duplicates
    const validImages = images
      .filter(img => isValidImageUrl(img))
      .filter(img => {
        const isDuplicate = history.some(existingUrl => 
          existingUrl === img || isSameFile(existingUrl, img)
        );
        if (isDuplicate) console.log("Filtering out duplicate image:", img);
        return !isDuplicate;
      });
      
    if (validImages.length === 0) {
      console.log("No valid images to add to history");
      return;
    }
    
    // Create new history array with the new images
    const newHistory = [...history, ...validImages];
    
    // Update URL with all images in history and preserve the original image
    try {
      navigate(
        {search: {
          image: current, 
          history: deduplicateArray(newHistory).join(","),
          originalImage: originalSrc
        }},
        {search: true, replace: true}
      );
      
      // Update local state
      setHistory(deduplicateArray(newHistory));
    } catch (error) {
      console.warn("Error updating history:", error);
    }
  };
  
  // Check if two URLs point to the same file (ignoring URL parameters)
  const isSameFile = (url1: string, url2: string): boolean => {
    try {
      // Strip query parameters if they exist
      const stripParams = (url: string) => url.split('?')[0].split('#')[0];
      return stripParams(url1) === stripParams(url2);
    } catch (error) {
      return false;
    }
  };

  // Utility function to deduplicate history entries
  const deduplicateArray = (array: string[]): string[] => {
    return [...new Set(array)];
  };

  const setImage = (src: string): void => {
    navigate(
      {search: {
        image: src, 
        history: deduplicateArray(history).join(","),
        originalImage: originalSrc
      }},
      {search: true, replace: true}
    );
  };

  // Helper function to check if two images are from the same generation
  const isSameImageGeneration = (url1: string, url2: string): boolean => {
    try {
      // Extract base path without query parameters
      const getBasePath = (url: string) => {
        const urlObj = new URL(url);
        return urlObj.pathname;
      };
      return getBasePath(url1) === getBasePath(url2);
    } catch (error) {
      console.error("Error comparing image URLs:", error);
      return false;
    }
  };

  const setImageSrc = (src: string): void => {
    // For debugging
    console.log("setImageSrc called with:", src);
    console.log("Current originalSrc:", originalSrc);
    console.log("Current history:", history);

    // Check if this is a completely new image from chat vs. an edited image
    const isNewSourceImage = !originalSrc || 
      // If we have an original but this is an entirely new image not in history 
      // AND not from the same image generation
      (originalSrc && 
       !history.includes(src) && 
       !isSameImageGeneration(originalSrc, src));
       
    console.log("Is considered a new source image:", isNewSourceImage);

    // If this is a completely new image (e.g., from chat), reset history
    if (isNewSourceImage) {
      console.log("New starting image detected, resetting history");
      
      // Update URL with the new image as both current and original
      navigate(
        {search: {
          image: src,
          history: [src].join(","),
          originalImage: src // Set this image as the new original
        }},
        {search: true, replace: true}
      );
      
      // Update local state
      setCurrent(src);
      setOriginalSrc(src);
      setHistory([src]);
      return;
    }

    // If the image is already in history, just navigate to it
    if (history.includes(src)) {
      navigate(
        {search: {
          image: src,
          history: history.join(","),
          originalImage: originalSrc // Preserve the original image
        }},
        {search: true, replace: true}
      );
      setCurrent(src);
      return;
    }

    // Otherwise, add the new edited image to history
    const newHistory = [...history, src];
    navigate(
      {search: {
        image: src,
        history: newHistory.join(","),
        originalImage: originalSrc // Preserve the original image
      }},
      {search: true, replace: true}
    );
    
    setCurrent(src);
    setHistory(newHistory);
  };

  const clearImage = (): void => {
    navigate(
      {search: {image: null, history: null, originalImage: null}},
      {search: true}
    );
  };

  return (
    <ImageHistoryContext.Provider value={{
      imageSrc: current,
      history,
      setImageSrc,
      clearImage,
      addImagesToHistory,
      originalSrc
    }}>
      {children}
    </ImageHistoryContext.Provider>
  );
};

export const useImageHistoryContext = (): ImageHistoryContextValue => {
  const context = React.useContext(ImageHistoryContext);

  if (context === undefined) {
    throw new Error(
      "useImageHistoryContext must be used within a ImageHistoryContextProvider",
    );
  }

  return context;
};
