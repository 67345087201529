// @ts-nocheck
import classNames from "classnames/bind";
import React, {ReactElement, useMemo, useState} from "react";

import {
	ChevronRightIcon,
	ManageUsersSquareMouseIcon,
	MentionsChatsIcon,
	RewardTrophyCupIcon,
	SettingsGearIcon,
	SwitchRotateArrowsIcon,
	TagLabelIcon,
	UserProfileIcon,
	VurveyLabsIconVolt,
} from "../../../../icons";
import {Body} from "../../../../shared/v2/typography";
import {Dropdown, DropdownItem} from "../../../../shared/v2/dropdown";
import {useNavigate} from "../../../../route";
import {useThemeMode} from "../../../../context/theme-mode-context";
import {useUserContext} from "../../../../context/user-context";
import {useWorkspaceContext} from "../../../../context/workspace-context";
import {Workspace} from "../../../../models/workspace";
import {WorkspacePickerModal} from "./workspace-picker-modal";
import {Avatar} from "../../../../shared/v2";

import styles from "./header-dropdowns.module.scss";

const cx = classNames.bind(styles);

export interface HeaderDropdownsProps {
  className?: string;
}

export const HeaderDropdowns = ({className}: HeaderDropdownsProps): ReactElement => {
	const {workspaces, isEnterpriseManagerOrSupport} = useUserContext();;
	const {isDarkMode} = useThemeMode();
	const [isWorkspacePickerModalOpen, setIsWorkspacePickerModalOpen] = useState(false);
	const navigate = useNavigate();
	const {workspace, isWokspaceAdmin} = useWorkspaceContext();


	const workspacePickerDropdownItem: DropdownItem = useMemo(() => {
		const isModalMode = workspaces.length > 7;

		return {
			label: "Switch workspace",
			icon: <SwitchRotateArrowsIcon />,
			...(
				isModalMode ? {
					onClick: () => setIsWorkspacePickerModalOpen(true),
				} : {
					dropdown: {
						space: 16,
						items: workspaces.map(option => (
							{
								label: option.name,
								avatar: (
									<img
										className={styles.workspaceOptionLogo}
										src={option.logo?.badge}
									/>
								),
								onClick: () => handleSwitchWorkspace(option),
							}
						))
					}
				}
			)
		};
	}, [workspaces]);


	const handleSwitchWorkspace = ({id: workspaceId}: Workspace) => {
		if (workspaceId === workspace?.id) {
			return;
		}
		localStorage.setItem("workspaceId", workspaceId);
		navigate("/");
		window.location.reload();
	}

	const dropdownItems = useMemo(() => {
		const items = [
			{
				label: "Manage Users",
				icon: <ManageUsersSquareMouseIcon />,
				link: "/workspace/members"
			},
			{
				label: "Brand Profile",
				icon: <UserProfileIcon />,
				link: "/branding"
			},
			{
				label: "Rewards",
				icon: <RewardTrophyCupIcon />,
				link: "/rewards"
			},
			{
				label: "Settings",
				icon: <SettingsGearIcon />,
				link: "/workspace/settings"
			},
			workspaces.length > 1 ? workspacePickerDropdownItem : undefined,
			isEnterpriseManagerOrSupport ?
				{
					label: "Mentions",
					icon: <MentionsChatsIcon />,
					link: "/mentions"
				}
				: undefined,

			isEnterpriseManagerOrSupport ? {
				label: "Super Admin",
				icon: <VurveyLabsIconVolt className={styles.vurveyLogoIcon} />,
				color: "primary",
				link: "/admin"
			} : undefined,
		]

		if (isWokspaceAdmin) {
			items.unshift({
				label: "Manage Keys for Labels",
				icon: <TagLabelIcon />,
				link: "/workspace/keys"
			})
		}

		return items;
	})

	return (
		<>
			<div className={cx("headerDropdowns", className, {isDarkMode})}>
				<Dropdown
					items={dropdownItems}
					space={8}
					position="right-start"
					className={cx("tile", "first")}
					triggerClassName={cx("tileContent", "workspaceDropdownTrigger")}
					popupClassName={styles.workspaceDropdown}
					trigger={
						<>
							<Avatar url={workspace?.logo?.thumbnail} firstName={workspace?.name} size="xs" />
							<Body
								title={workspace?.name}
								size="s"
								type="semibold"
								className={styles.name}
							>
								{workspace?.name}
							</Body>
							<ChevronRightIcon className={styles.workspaceDropdownTrigger} />
						</>
					}
				/>
			</div>
			<WorkspacePickerModal
				workspaces={workspaces}
				isOpen={isWorkspacePickerModalOpen}
				onClose={() => setIsWorkspacePickerModalOpen(false)}
				onSubmit={(workspace) => handleSwitchWorkspace(workspace)}
			/>
		</>
	);
}
