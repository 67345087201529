/* eslint-disable react/prop-types */
import classNames from "classnames/bind";
import React, {ReactElement, useMemo} from "react";

import {AllSurveyQuestionsReturn, determineQuestionType, Question} from "@/models/questions";
import {ArrowDownIcon, ArrowUpIcon} from "@/icons";
import {Checkbox, Caption, Body, Spinner} from "@/shared/v2";
import {Column, IdType, useTable, useFlexLayout, useSortBy, usePagination, useRowSelect, useMountedLayoutEffect} from "react-table";
import {GET_ALL_SURVEY_QUESTIONS} from "@/graphql/queries/survey-queries";
import {Icon} from "@/shared";
import {QUESTION_TYPES} from "@/shared/constants/constants";
import {QuestionTooltip} from "../question-tooltip";
import {useNavigationContext} from "../../contexts/navigation-context";
import {useQuery} from "@apollo/client";
import {useSearchContext} from "../../contexts/search-context";
import {useSelectedCampaignsContext} from "../../contexts/selected-campaigns-context";
import {useSelectedQuestionsContext} from "../../contexts/selected-questions-context";
import {useThemeMode} from "@/context/theme-mode-context";

import styles from "./questions-table.module.scss";

const bStyles = classNames.bind(styles);

const isQuestionSelected = (question: Question, selectedQuestionsArray: Question[]): boolean => {
  return Boolean(selectedQuestionsArray.find(q => q.id === question.id));
};

export const QuestionsTable = (): ReactElement => {
	const {search} = useSearchContext();
	const {
    selected: selectedQuestionsArray,
    add: addSelectedQuestions,
    remove: removeSelectedQuestions,
  } = useSelectedQuestionsContext();
	const {campaign} = useNavigationContext();
	const {
		selected: selectedCampaigns,
		remove: removeSelectedCampaigns,
	} = useSelectedCampaignsContext();

	const {isDarkMode} = useThemeMode();

	const {
		data,
		loading: isLoadingQuestions,
	} = useQuery<AllSurveyQuestionsReturn>(GET_ALL_SURVEY_QUESTIONS, {
		variables: {
			surveyId: campaign?.id,
		},
		skip: !campaign?.id,
		fetchPolicy: "cache-first",
		notifyOnNetworkStatusChange: true,
	})

	const allQuestions = useMemo(() => data?.questions.items || [], [data]);
	const filteredQuestions = useMemo(() => allQuestions.filter(q => q.text.toLowerCase().includes(search.toLowerCase())), [allQuestions, search]);
	const isCampaignSelected = Boolean(selectedCampaigns.find(c => c.id === campaign?.id));
  const isAllSelected = useMemo(() => {
    return isCampaignSelected || filteredQuestions.every(media => isQuestionSelected(media, selectedQuestionsArray));
  }, [selectedQuestionsArray, filteredQuestions, isCampaignSelected]);

  const handleSelectOne = (question: Question, isSelected: boolean) => {
		if (isCampaignSelected) {
			campaign && removeSelectedCampaigns([campaign]);
			addSelectedQuestions(filteredQuestions.filter(q => q.id !== question.id));
			return;
		}

    if (isSelected) {
      removeSelectedQuestions([question]);
    } else {
      addSelectedQuestions([question]);
    }
  };

  const handleSelectAll = () => {
		if (isCampaignSelected) {
			campaign && removeSelectedCampaigns([campaign]);
		}

    if (isAllSelected) {
      removeSelectedQuestions(filteredQuestions);
    } else {
      addSelectedQuestions(filteredQuestions);
    }
  };

  const columns = useMemo(
		(): Column<Question>[] => [
			{
				Header: "Order",
				accessor: "index",
				width: 28,
				Cell: data => {
					const index = data.row.original.index + 1;
					return <Body size="s" type="medium" >{index}</Body>;
				}
			},
			{
				Header: "Name",
				accessor: "text",
				width: 160,
				Cell: data => {
					const question = data.row.original;
					return (
						<Body size="s" type="medium" className={styles.overflow}>
							{question.text}
						</Body>
					);
				},
			},
			{
				Header: "Type",
				accessor: "type",
				width: 25,
				Cell: data => {
					const questionType = determineQuestionType(data.row.original);

					return <Icon name={QUESTION_TYPES[questionType].icon} size="extrasmall"/>
				}
			},
			{
				Header: "Responses",
				accessor: "answerCount",
				width: 45,
				Cell: data => {
					const {answerCount} = data.row.original;
					return (
						<Body size="s">
							{answerCount}
						</Body>
					)
				}
			},
			{
				Header: "Video Follow Up",
				accessor: "videoResponse",
				width: 60,
				Cell: data => {
					const {videoResponse} = data.row.original;
					return (
						<Body size="s">
							{videoResponse ? "Yes" : "No"}
						</Body>
					)
				}
			}
		],
		[selectedQuestionsArray, isAllSelected]
	);

	const {
		getTableProps,
		headerGroups,
		page,
		prepareRow,
		selectedFlatRows,
		toggleAllRowsSelected,
	} = useTable(
		{
			columns,
			data: filteredQuestions,
			manualPagination: true,
			initialState: {
				pageSize: allQuestions.length,
				sortBy: [
					{
						id: "index",
						desc: false,
					},
				],
        selectedRowIds: selectedQuestionsArray.reduce((acc, row) => {
          const index = filteredQuestions.findIndex(q => q.id === row.id);
          if (index !== -1) {
            acc[index] = true;
          };
          return acc;
        }, {} as Record<IdType<Question>, boolean>),
			},
		},
		useFlexLayout,
		useSortBy,
		usePagination,
		useRowSelect,
		hooks => {
      hooks.visibleColumns.push(allColumns => [
        {
          id: "selection",
          width: 25,
          Header: ({getToggleAllPageRowsSelectedProps}) => {

            const {
              ...props
            } = getToggleAllPageRowsSelectedProps();

            return (
              <Checkbox
                {...props}
                checked={isAllSelected}
                className={styles.checkbox}
                onChange={handleSelectAll}
              />
            )
          },
          Cell: ({row}) => {
            const isSelected = isQuestionSelected(row.original, selectedQuestionsArray) || isCampaignSelected;

            return (
              <Checkbox
                checked={isSelected}
                className={styles.checkbox}
                onChange={() => handleSelectOne(row.original, isSelected)}
              />
            )
          },
        },
        ...allColumns,
      ]);
		},
	);

	useMountedLayoutEffect(() => {
		if (selectedQuestionsArray?.length !== 0 && selectedFlatRows.length === 0) toggleAllRowsSelected(false);
	}, [selectedQuestionsArray]);

	return (
		<div className={styles.mainContainer}>
			{isLoadingQuestions ? (
				<Spinner className={styles.tableLoader} />
			) : (
				<>
					<div className={styles.tableContainer}>
						<table {...getTableProps()} className={bStyles("table", {isDarkMode})}>
							<thead className={styles.header}>
								{headerGroups.map((headerGroup, i) => (
									<tr {...headerGroup.getHeaderGroupProps()} key={i}>
										{headerGroup.headers.map((column, j) => (
											<th {...column.getHeaderProps(column.getSortByToggleProps())} key={j}>
												<Caption type="medium" color="gray-modern-600" className={styles.tableThText}>
													{column.render("Header")}
													{column.isSorted ?

														column.isSortedDesc ?
														<ArrowDownIcon className={styles.arrowIcon} /> :
														<ArrowUpIcon className={styles.arrowIcon}/>
													: null}
													</Caption>
											</th>
										))}
									</tr>
								))}
							</thead>
							<tbody>
								{page.map((row, i) => {
									prepareRow(row);
									return (
										<QuestionTooltip
											key={i}
											question={row.original}
										>
											<tr {...row.getRowProps()} key={i}>
												{row.cells.map((cell, j) => (
													<td {...cell.getCellProps()} key={j}>
														{cell.render("Cell", {})}
													</td>
												))}
											</tr>
										</QuestionTooltip>
									);
								})}
							</tbody>
						</table>
					</div>
				</>
			)}
		</div>
	);
};
