import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {Body} from "../../../../shared/v2";
import {ChatFakeResponseMessage} from "@/reducer/chat-reducer";
import {Persona} from "../../../../models";
import {useThemeMode} from "../../../../context/theme-mode-context";
import {useUnsafeAgentCreationContext} from "../../../../context/agent-creation-context";

import styles from "./loading-content.module.scss";

const cx = classNames.bind(styles);

export interface LoadingContentProps {
	message: ChatFakeResponseMessage;
}

export const LoadingContent = ({message}: LoadingContentProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {stateHistory, persona} = message;
	const {
		state: {
			name: agentCreationName = undefined,
		} = {}
	} = useUnsafeAgentCreationContext() ?? {};

	return (
		<Body className={cx("loadingWrapper", {isDarkMode})}>
			<div className={styles.stateHistory}>
				{stateHistory.length === 0 ? (
					<span>
						{`${persona?.name || agentCreationName || "V"} is generating`}
						<span className={styles.loadingIcon}></span>
					</span>
				) : (
					stateHistory.map((state, index) => (
						<div key={index} className={cx("stateItem", { latest: index === stateHistory.length - 1 })}>
							{state}
							{index === stateHistory.length - 1 && <span className={styles.loadingIcon}></span>}
						</div>
					))
				)}
			</div>
		</Body>
	);
}
