import {AiOrchestration, AiOrchestrationOutputTypes, AiPersonaTask, AiPersonaTaskOperation} from "@/models/ai-orchestration";
import {Campaign, TrainingSet, TrainingSetFile, TrainingSetVideo} from "@/models/ai-model";
import {extractWorkflowChanges, isWorkflowDirty, normalizeWorkflow, updateTask} from "@/shared";
import {Question} from "@/models/questions";

export interface WorkflowState {
  original: AiOrchestration | undefined;
  workflow: AiOrchestration | undefined;
  changes: WorkflowChanges;
  isDirty: boolean;
}

export const workflowReducerInit: WorkflowState = {
  original: undefined,
  workflow: undefined,
  changes: {
    settings: undefined,
    tasks: [],
  },
  isDirty: false,
};

export enum WorkflowActionTypes {
  SET_ORIGINAL = "SET_ORIGINAL",
  UPDATE_ORIGINAL = "UPDATE_ORIGINAL",
  UPDATE_WORKFLOW = "UPDATE_WORKFLOW",
  SET_TASKS = "SET_TASKS",
  ADD_TASK = "ADD_TASK",
  DELETE_TASK = "DELETE_TASK",
  UPDATE_TASK = "UPDATE_TASK",
}

export interface AiTaskInput {
  name: string;
  instructions: string;
  temperature: string;
  maxTokens: string;
  model: string;
  systemPrompt: string;
  taskPrompt: string;
}

export type WorkflowSources = Pick<AiOrchestration, "trainingSets" | "files" | "videos">;

export interface UpdateWorkflowActionPayload {
  name?: string;
  description?: string;
  inputParameters?: string;
  instructions?: string;
  outputType?: AiOrchestrationOutputTypes;
  files?: TrainingSetFile[];
  questions?: Question[];
  campaigns?: Campaign[];
  trainingSets?: TrainingSet[];
  videos?: TrainingSetVideo[];
}

export type AiPersonaDeleteTaskInput = Pick<AiPersonaTask, "id">;
export interface AiPersonaCreateTaskInput {
  index: AiPersonaTask["index"];
  orchestrationId: string;
  personaId: string;
  smartPromptEnabled: AiPersonaTask["smartPromptEnabled"];
  task: AiPersonaTask["task"];
}

export interface UpdateAiPersonaTaskMutationInput {
  id: AiPersonaTask["id"];
  index?: AiPersonaTask["index"];
  smartPromptEnabled?: AiPersonaTask["smartPromptEnabled"];
  task?: {
    taskPrompt: string;
  }
}

export interface UpdateAiPersonaTaskInput {
  index?: AiPersonaTask["index"];
  smartPromptEnabled?: AiPersonaTask["smartPromptEnabled"];
  taskPrompt?: string;
}

export type AiPersonaTaskChanges = {
  operation: AiPersonaTaskOperation.DELETE;
  input: AiPersonaDeleteTaskInput;
} | {
  operation: AiPersonaTaskOperation.CREATE;
  input: AiPersonaCreateTaskInput;
} | {
  operation: AiPersonaTaskOperation.UPDATE;
  input: UpdateAiPersonaTaskMutationInput;
}

export interface WorkflowChanges {
  settings?: (
    Omit<UpdateWorkflowActionPayload, "outputType" | "id"> &
    {outputTypeId?: string, id: string}
  );
  tasks: AiPersonaTaskChanges[];
}

export type ModifyTasksFunction = (tasks: AiOrchestration["aiPersonaTasks"]) => AiOrchestration["aiPersonaTasks"];

export type WorkflowAction =
  {
    type: WorkflowActionTypes.SET_ORIGINAL,
    payload: AiOrchestration
  } |
  {
    type: WorkflowActionTypes.UPDATE_ORIGINAL,
    payload: AiOrchestration
  } |
  {
    type: WorkflowActionTypes.UPDATE_WORKFLOW,
    payload: UpdateWorkflowActionPayload
  } |
  {
    type: WorkflowActionTypes.SET_TASKS,
    payload: {
      modifier: ModifyTasksFunction;
    }
  } | {
    type: WorkflowActionTypes.ADD_TASK,
    payload: {
      task: AiPersonaTask;
    }
  } | {
    type: WorkflowActionTypes.DELETE_TASK,
    payload: {
      id: string;
    }
  } | {
    type: WorkflowActionTypes.UPDATE_TASK,
    payload: {
      id: string;
      input: UpdateAiPersonaTaskInput;
    }
  };

export type WorkflowReducerType = (state: WorkflowState, action: WorkflowAction) => WorkflowState;

export const workflowReducer: WorkflowReducerType = (state, action) => {
  switch (action.type) {
    case WorkflowActionTypes.SET_ORIGINAL: {
      return {
        ...state,
        original: action.payload,
        workflow: normalizeWorkflow(action.payload),
        changes: {
          settings: undefined,
          tasks: [],
        },
        isDirty: false,
      };
    }
    case WorkflowActionTypes.UPDATE_ORIGINAL: {
      if (!state.original || !state.workflow) {
        return state;
      }

      const newOriginal = action.payload;
      const newChanges = extractWorkflowChanges(newOriginal, state.workflow as AiOrchestration);
      const newIsDirty = isWorkflowDirty(newChanges);

      return {
        ...state,
        original: newOriginal,
        changes: newChanges,
        isDirty: newIsDirty,
      }
    }
    case WorkflowActionTypes.UPDATE_WORKFLOW: {
      const update = action.payload;
      const original = state.original as AiOrchestration;
      const current = state.workflow as AiOrchestration;

      if (Object.keys(update).length === 0 || !current || !original) {
        return state;
      }

      const newCurrent = normalizeWorkflow({...current, ...update}) as AiOrchestration;
      const newChanges = extractWorkflowChanges(original, newCurrent);
      const newIsDirty = isWorkflowDirty(newChanges);

      return {
        ...state,
        workflow: newCurrent,
        changes: newChanges,
        isDirty: newIsDirty,
      };
    }
    case WorkflowActionTypes.SET_TASKS: {
      const {
        original,
        workflow,
      } = state;
      const {modifier} = action.payload;

      if (!workflow || !original) {
        return state;
      }

      const newWorkflow = normalizeWorkflow({
        ...workflow,
        aiPersonaTasks: modifier(workflow.aiPersonaTasks),
      }) as AiOrchestration;
      const newChanges = extractWorkflowChanges(original, newWorkflow);
      const newIsDirty = isWorkflowDirty(newChanges);

      return {
        ...state,
        workflow: newWorkflow,
        changes: newChanges,
        isDirty: newIsDirty,
      };
    }
    case WorkflowActionTypes.ADD_TASK: {
      const task = action.payload.task;
      const {original, workflow} = state;

      if (!workflow || !original) {
        return state;
      }

      const newWorkflow = normalizeWorkflow({
        ...workflow,
        aiPersonaTasks: [...workflow.aiPersonaTasks, task],
      }) as AiOrchestration;
      const newChanges = extractWorkflowChanges(original, newWorkflow);
      const newIsDirty = isWorkflowDirty(newChanges);

      return {
        ...state,
        workflow: newWorkflow,
        changes: newChanges,
        isDirty: newIsDirty,
      };
    }
    case WorkflowActionTypes.DELETE_TASK: {
      const {id} = action.payload;
      const {original, workflow} = state;

      if (!workflow || !original) {
        return state;
      }

      const newWorkflow = normalizeWorkflow({
        ...workflow,
        aiPersonaTasks: workflow.aiPersonaTasks.filter((task) => task.id !== id),
      }) as AiOrchestration;
      const newChanges = extractWorkflowChanges(original, newWorkflow);
      const newIsDirty = isWorkflowDirty(newChanges);

      return {
        ...state,
        workflow: newWorkflow,
        changes: newChanges,
        isDirty: newIsDirty,
      }
    }
    case WorkflowActionTypes.UPDATE_TASK: {
      const {id: taskId, input} = action.payload;
      const {original, workflow} = state;

      if (!workflow || !original) {
        return state;
      }

      const newTasks = updateTask(workflow.aiPersonaTasks, taskId, input);
      const newWorkflow = normalizeWorkflow({
        ...workflow,
        aiPersonaTasks: newTasks,
      }) as AiOrchestration;
      const newChanges = extractWorkflowChanges(original, newWorkflow);
      const newIsDirty = isWorkflowDirty(newChanges);

      return {
        ...state,
        workflow: newWorkflow,
        changes: newChanges,
        isDirty: newIsDirty,
      };
    }
  }
};
