import React, { ReactElement } from 'react';
import {Option, SelectInput} from '@/shared/v2/inputs/select-input';

interface DatasetsSortProps {
    value: string;
    onChange: (value: string) => void;
    className?: string;
}

export const DatasetsSort = ({
    value,
    onChange,
    className
}: DatasetsSortProps): ReactElement => {
  const sortOptions: Option[] = [
    { name: 'Name (A-Z)', id: 'ALIAS_ASC' },
    { name: 'Name (Z-A)', id: 'ALIAS_DESC' },
    { name: "Most Recently Updated", id: "UPDATED_AT_DESC" },
    { name: "Oldest Updated", id: "UPDATED_AT_ASC" },
    { name: "Most Recently Created", id: "CREATED_AT_DESC" },
    { name: "Oldest Created", id: "CREATED_AT_ASC" },
    { name: "Most Files", id: "MOST_FILES" },
    { name: "Least Files", id: "LEAST_FILES" },
  ];

  return (
      <SelectInput
        className={className}
        options={sortOptions}
        placeholder="Sort"
        onChange={onChange}
        value={value}
      />
  );
};
