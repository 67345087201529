import React, { ReactElement } from "react";
import classNames from "classnames/bind";
import { useThemeMode } from "../../../context/theme-mode-context";

import styles from "./separator.module.scss";

const bStyles = classNames.bind(styles);

export interface SeparatorProps {
  className?: string;
  size?: "24" | "auto";
  orientation?: "vertical" | "horizontal";
  color?:
    | "border-container"
    | "charcoal-elevation-400"
    | "border-soft"
    | "navigation"
    | "gray-modern-300"
    | "gray-modern-200"
    | "charcoal-elevation-800"
    | "workflow"
    | "recruit";
}

export const Separator = ({
  className,
  color = "border-container",
  size = "auto",
  orientation = "horizontal",
}: SeparatorProps): ReactElement => {
  const { isDarkMode } = useThemeMode();

  return (
    <div
      className={bStyles(
        "separator",
        orientation,
        `size-${size}`,
        `color-${color}`,
        className,
        { isDarkMode }
      )}
    />
  );
};
