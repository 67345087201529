import React, { ReactElement } from "react";

import styles from "./Steps.module.scss";
import { Body, Separator } from "@/shared/v2";
import {
  ProgressCircleDoneIcon,
  ProgressCircleIntermediateCurrentIcon,
  ProgressCircleUpcomingNextPendingIcon,
} from "@/icons";
import { Step, useRecruitContext } from "@/campaign/contexts/RecruitContext";
import { STEPS } from "../../../contexts/RecruitContext";
import classNames from "classnames/bind";
import { useThemeMode } from "@/context/theme-mode-context";

const cx = classNames.bind(styles);

export const StepItem = ({
  label,
  status,
  onClick,
}: {
  label: string;
  status: "completed" | "current" | "upcoming";
  onClick: () => void;
}): ReactElement => {
  const { isDarkMode } = useThemeMode();

  return (
    <div className={styles.step} onClick={onClick} tabIndex={0}>
      <div className={cx("iconWrapper", { isDarkMode })}>
        {status === "completed" && (
          <ProgressCircleDoneIcon className={styles.icon} />
        )}
        {status === "current" && (
          <ProgressCircleIntermediateCurrentIcon className={styles.icon} />
        )}
        {status === "upcoming" && (
          <ProgressCircleUpcomingNextPendingIcon
            className={cx("icon", "disabled")}
          />
        )}
      </div>
      <Body
        size='xs'
        type='medium'
        className={cx("label", {
          disabled: status === "upcoming",
          isDarkMode,
        })}>
        {label}
      </Body>
    </div>
  );
};

export const Steps = (): ReactElement => {
  const { currentStep, setCurrentStep } = useRecruitContext();

  const handleStepClick = (step: Step) => {
    setCurrentStep(step);
  };

  return (
    <div className={styles.stepsContainer}>
      <StepItem
        label={STEPS.SURVEY_SETTINGS}
        status={currentStep === STEPS.SURVEY_SETTINGS ? "current" : "completed"}
        onClick={() => handleStepClick(STEPS.SURVEY_SETTINGS)}
      />
      <StepItem
        label={STEPS.QUALIFYING_QUESTIONS}
        status={
          currentStep === STEPS.QUALIFYING_QUESTIONS ? "current" : "upcoming"
        }
        onClick={() => handleStepClick(STEPS.QUALIFYING_QUESTIONS)}
      />
      <StepItem
        label={STEPS.SUMMARY}
        status={currentStep === STEPS.SUMMARY ? "current" : "upcoming"}
        onClick={() => handleStepClick(STEPS.SUMMARY)}
      />
      <Separator color='workflow' className={styles.separator} />
    </div>
  );
};
