import {DeleteAllAnswersReturn, DeleteAllAnswersVars, DeleteAnswersReturn, DeleteAnswersVars} from "../../models/answer";
import React, {ReactElement, useContext} from "react";
import {DELETE_ALL_ANSWERS, DELETE_ANSWERS} from "../../graphql/mutations/results-mutations";
import {SelectContext} from "../../context/select-context";
import {ToastContext} from "../../context/toast-context";
import styles from "./delete-multiple-answer.module.scss";
import {updateCacheDeletePageItemByFieldId} from "../../shared/utility/update-cache";
import {useMutation} from "@apollo/client";
import {useLocation} from "react-router";
import {Body, Button, Subheader, Modal, BaseModalProps} from "../../shared/v2";
import {useFilter, useParams, useSearchParams} from "@/route";
import {SurveyContext} from "@/context/survey-context";
import {SurveyParams} from "@/survey";
import {GET_ALL_ANSWERS} from "@/graphql/queries/survey-queries";

export interface DeleteMultipleAnswerModalProps extends BaseModalProps {
	allSelected: boolean;
	totalItems: number;
	clearAllSelected: () => void;
	refetchData: () => void;
}

const DeleteMultipleAnswerModal = (props: DeleteMultipleAnswerModalProps): ReactElement => {
	const {isOpen, allSelected, totalItems, refetchData, clearAllSelected, onClose} = props;
	const {questions} = useContext(SurveyContext);
	const {questionId = questions[0]?.id} = useParams<SurveyParams>();
	const {membersFilter} = useFilter();
	const {selected, emptySelectValues} = useContext(SelectContext);
	const {updateToast} = useContext(ToastContext);
	const {pathname} = useLocation();
	const resultsFilter = useSearchParams();


	const [deleteAnswers] = useMutation<DeleteAnswersReturn, DeleteAnswersVars>(DELETE_ANSWERS, {
		onCompleted: data => {
			updateToast({
				description: `Deleted ${data.deleteAnswers.length} answers`,
				type: "informational",
			});
			emptySelectValues();
			onClose();
		},
	});

	const [deleteAllAnswers] = useMutation<DeleteAllAnswersReturn, DeleteAllAnswersVars>(DELETE_ALL_ANSWERS, {
		onCompleted: async data => {
			if (data.deleteAllAnswers) {
				updateToast({
					description: `Deleted ${totalItems} answers`,
					type: "informational",
				});
				await refetchData();
				clearAllSelected();
				onClose();
			};
		},
	});

	const handleConfirm = (): void => {
		// We know the strings are ANSWERID_USERID, we want the first id
		const ansIds = selected.map(value => {
			const [x] = value.split("_");
			return x;
		});
		if (allSelected) {
			deleteAllAnswers({
				variables: {
					questionId,
					filter: {
						...membersFilter,
						highlighted: resultsFilter.highlighted,
						search: resultsFilter.search ? String(resultsFilter.search): undefined,
						textAndTranscriptsSearch: resultsFilter.textAndTranscriptsSearch ? String(resultsFilter.textAndTranscriptsSearch): undefined,
						choiceId: resultsFilter.choiceId?.length ? resultsFilter.choiceId : undefined,
						numberAnswer: resultsFilter.numberAnswer?.length ? resultsFilter.numberAnswer : undefined,
					},
				},
				refetchQueries: [GET_ALL_ANSWERS],
			});
		} else {
			deleteAnswers({
				variables: {ids: ansIds},
				update(cache, {data}) {
					if (data) {
						if (pathname.includes("video-search")) {
							// If this is being used on video search page we need to cache differently
							data.deleteAnswers.forEach(answer => {
								cache.modify({
									id: `Answer:${answer.id}`,
									fields(fieldValue, details) {
										return details.DELETE;
									},
								});
							});
							return;
						}
						data.deleteAnswers.forEach(answer => {
							updateCacheDeletePageItemByFieldId(
								cache,
								"answers",
								"answer",
								answer.question.id,
								answer.id,
							);
						});
					}
				},
			});
		}
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size="x-small"
			title="Delete Answers"
		>
			<div className={styles.container}>
				<Subheader size="l" type="semibold" className={styles.header}>
					{`Are you sure you want to delete ${allSelected ? totalItems : selected.length} answers?`}
				</Subheader>
				<Body size="xs" className={styles.text}>
					Once removed, all of the answers selected will be permanently deleted
				</Body>
				<Button onClick={handleConfirm} style="danger">Delete</Button>
			</div>
		</Modal>
	);
};

export {DeleteMultipleAnswerModal};
