import {useChatConversationContext} from "@/context/chat-contexts";
import {useLocalFileUpload} from "./useLocalFileUpload";
import {useState} from "react";
import {gql, useMutation} from "@apollo/client";
import {useWorkspaceContext} from "@/context/workspace-context";
import {FileEmbeddingsGenerationStatus, TrainingSetFile} from "@/models/ai-model";
import {v4 as uuid} from "uuid";
import {useToastContext} from "@/context/toast-context";

type UploadVariables = {
  file: File;
}

type Upload = {
  id: string;
  filename: string;
  mimetype: string;
}

type UploadResponse = {
  upload: Upload;
}

const UPLOAD = gql`
  mutation Upload($file: Upload!) {
    upload(file: $file) {
      id
      filename
      mimetype
    }
  }
`;

type CreateChatDocFileVariables = {
  uploadItemId: string;
  workspaceId: string;
}

type CreateChatDocFileResponse = {
  createChatDocFile: TrainingSetFile;
}

const CREATE_CHAT_DOC_FILE = gql`
  mutation CreateChatDocFile($uploadItemId: GUID!, $workspaceId: GUID!) {
    createChatDocFile(uploadItemId: $uploadItemId, workspaceId: $workspaceId) {
      id
      originalFilename
      originalMimetype
      uploadStatus
    }
  }
`;

export const useUploadChatDocument = () => {
  const {workspace: {id: workspaceId} = {}} = useWorkspaceContext();
  const [file, setFile] = useState<File | null>(null);
	const {update: updateConversation} = useChatConversationContext();
  const {updateToast} = useToastContext();

  const [uploadMutation, {loading: isUploading}] = useMutation<UploadResponse, UploadVariables>(UPLOAD);
	const [createChatDocFile, {loading: isCreatingChatDocFile}] = useMutation<CreateChatDocFileResponse, CreateChatDocFileVariables>(CREATE_CHAT_DOC_FILE);

	const uploadDocument = useLocalFileUpload({
		onUpload: async ([file]) => {
      setFile(file);
      updateConversation({nextQuestion: {document: {
        originalFilename: file.name,
        originalMimetype: file.type,
        id: uuid(),
        url: "",
        embeddingsGenerationStatus: FileEmbeddingsGenerationStatus.UPLOADED,
        trainingSetId: "",
        tags: [],
        __typename: "File",
      }}});
      const {data: uploadData} = await uploadMutation({
        variables: {
          file,
        },
        onError: () => {
          updateConversation({nextQuestion: {document: null}});
          updateToast({
            description: "Error uploading document",
            type: "failure",
          });
        }
      });

      if (uploadData?.upload?.id) {
        const {data: fileData} = await createChatDocFile({
          variables: {
            uploadItemId: uploadData.upload.id,
            workspaceId: workspaceId || "",
          },
        });

        if (fileData?.createChatDocFile?.id) {
          updateConversation({nextQuestion: {document: fileData.createChatDocFile}});
        }
      }
		},
		options: {
			accept: ".pdf,.txt,.csv",
		},
	});

	return {
    file,
    isUploading: isUploading || isCreatingChatDocFile,
    upload: uploadDocument,
  };
};
