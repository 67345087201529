import {CHAT_CONVERSATION_UPDATES} from "@/graphql/subscriptions";
import {ChatAction, ChatActionTypes} from "@/reducer/chat-reducer";
import {Dispatch, useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import {useSubscription} from "@apollo/client";
import {ConversationNameChanged, VurveyConversationEvent} from "@/models/subscriptions";
import { useToastContext } from "@/context/toast-context";

export interface ChatConversationUpdatesResponse {
	chatConversationUpdates: VurveyConversationEvent;
}

export interface ConversationNameChangedResponse {
  workspaceUpdates: ConversationNameChanged;
}

export const useEventHandlers = (dispatch: Dispatch<ChatAction>) => {
  const [searchParams] = useSearchParams();
  const canvasId = searchParams.get("canvasId");
  const { updateToast } = useToastContext();

  // Log WebSocket subscription status on mount
  useEffect(() => {
    if (canvasId) {
      console.log('[ChatEvents] Setting up subscription for conversation:', {
        canvasId,
        timestamp: new Date().toISOString()
      });
    }
  }, [canvasId]);

	const { error } = useSubscription<ChatConversationUpdatesResponse>(
    CHAT_CONVERSATION_UPDATES,
    {
      variables: {conversationId: canvasId},
      skip: !canvasId,
      onError: (err) => {
        console.error('[ChatEvents] WebSocket error:', {
          message: err.message,
          canvasId,
          timestamp: new Date().toISOString()
        });

        // Display user-friendly error
        updateToast({
          description: "Connection issue. Some chat updates may not appear in real-time.",
          type: "failure",
        });

        // Log additional details for debugging
        if (err.networkError) {
          console.error('[ChatEvents] Network error details:', {
            name: err.networkError.name,
            message: err.networkError.message,
            stack: err.networkError.stack,
          });
        }
      },
      onData: (data) => {
        const {
          data: {
            data: {
              chatConversationUpdates: event,
            } = {}
          },
        } = data;

        if (event) {
          console.log(`[ChatEvents] Received event: ${JSON.stringify(event, null, 2)}`);

          dispatch({
            type: ChatActionTypes.HANDLE_CONVERSATION_EVENT,
            payload: event,
          });
        }
      }
    }
  );

  // Log errors for debugging
  useEffect(() => {
    if (error) {
      console.error('[ChatEvents] Subscription error detected:', {
        message: error.message,
        canvasId,
        timestamp: new Date().toISOString()
      });
    }
  }, [error, canvasId]);
}
