import React, {createContext, useContext, useState} from "react";
import {useSubscription} from "@apollo/client";
import {ORCHESTRATION_EVENTS} from "../../graphql/subscriptions";
import {
	VurveyOrchestrationEvent,
	TaskCompleted,
	TaskOutputGenerated,
	TaskStarted,
	TaskUpdated,
} from "../../models/workflow-events";
import {useToastContext} from "../toast-context";
import {useParams} from "react-router";
import {useWorkflowContext} from "./workflow-context";
import {useCarouselScrollContext} from "./carousel-scroll-context";
import {useWorkflowStateContext} from "./workflow-state-context";

interface WorkflowEventsContextProps {
	isGeneratingReport: boolean;
}

const WorkflowEventsContext = createContext<WorkflowEventsContextProps | undefined>(undefined);

export const WorkflowEventsProvider = ({children}: { children: React.ReactNode }) => {
	const {updateToast} = useToastContext();
	const {workflowId} = useParams();

	const {setTasks} = useWorkflowStateContext();
	const {refetchHistory, setReportUrl, setReportWordUrl, setRunInProgress} = useWorkflowContext();
	const {scrollToElement} = useCarouselScrollContext();

	const [isGeneratingReport, setIsGeneratingReport] = useState(false);

	interface OrchestrationSubscriptionData {
		orchestrationUpdates: VurveyOrchestrationEvent;
	}

	// Set up subscription with retry logic
	const {error: subscriptionError} = useSubscription<OrchestrationSubscriptionData>(ORCHESTRATION_EVENTS, {
		variables: {orchestrationId: workflowId},
		shouldResubscribe: true,
		onData: ({data: subscriptionData}) => {
			console.log('Subscription data received:', {
				// activeId: activeOrchestrationId,
				data: subscriptionData?.data?.orchestrationUpdates,
				timestamp: new Date().toISOString()
			});

			const event = subscriptionData?.data?.orchestrationUpdates;
			if (event) {
				// Process event
				console.log('Processing event:', {
					type: event.type,
					orchestrationId: event.data.orchestrationId,
					data: event.data,
					timestamp: new Date().toISOString()
				});
				handleWorkflowEvent(event);


			}
		},
		onError: (error) => {
			// Handle different types of errors
			if (error.message.includes('Failed to fetch') || error.message.includes('Socket closed')) {
				// Network/connection error - let Apollo Client handle retry
				console.log('Network error detected, Apollo Client will handle retry');

				// Show a more user-friendly message
				updateToast({
					description: "Connection interrupted. Attempting to reconnect...",
					type: "informational",
				});
			} else {
				// Other errors - show error and reset state
				updateToast({
					description: `WebSocket error: ${error.message}`,
					type: "failure",
				});
			}
		}
	});

	const clearAllTasks = () => {
		setTasks((prevTasks) =>
			prevTasks.map((task) => ({
				...task,
				processingState: undefined,
				output: undefined,
				processingSteps: [],
				currentStep: undefined
			}))
		);
	};

	const markAllTasksCompleted = () => {
		setTasks((prevTasks) =>
			prevTasks.map((task) => ({
				...task,
				processingState: "completed",
			}))
		);
	};

	const handleWorkflowEvent = (event: VurveyOrchestrationEvent) => {
		switch (event.type) {
		case "OrchestrationStarted": {
			clearAllTasks();
			updateToast({description: "Workflow started", type: "informational"});
			break;
		}
		case "OrchestrationCompleted": {
				setRunInProgress(false);
				setReportUrl(event.data.reportUrl ?? "");
				setReportWordUrl(event.data.reportWordUrl ?? "");
				refetchHistory();
				setIsGeneratingReport(false);
				markAllTasksCompleted();

			updateToast({
				description: (
					<>
						Workflow completed. You can now download the results at{" "}
						<a href={event.data.reportUrl ?? ""} target="_blank" rel="noopener noreferrer">
							Report
						</a>
					</>
				),
				type: "success",
				displayTime: 30000,
			});
			break;
		}
		case "OrchestrationError": {
			updateToast({
				description: `Workflow error: ${event.data.error}`,
				type: "failure",
				displayTime: 10000
			});

			setRunInProgress(false);
			setIsGeneratingReport(false);
			refetchHistory();
			setTasks((prevTasks) =>
				prevTasks.map((task) =>
					task.processingState === "processing"
						? {...task, processingState: "error", output: "Task failed due to workflow error"}
						: task
				)
			);

			break;
		}
		case "TaskError": {
			const taskId = event.data.taskId;
			const error = event.data.error;

			setTasks((prevTasks) =>
				prevTasks.map((task) =>
					task.id === taskId
						? {...task, processingState: "error", output: `Error: ${error}`}
						: task
				)
			);
			updateToast({
				description: `Task error: ${error}`,
				type: "failure",
				displayTime: 10000
			});
			break;
		}
		case "TaskOutputGenerated": {
			updateOutputState(event);
			break;
		}
		case "ReportGeneratingStarted": {
			setIsGeneratingReport(true);
			markAllTasksCompleted();
			break;
		}
		case "ReportGeneratingCompleted": {
			setIsGeneratingReport(false);
			setReportUrl(event.data.reportUrl ?? "");
			setReportWordUrl(event.data.reportWordUrl ?? "");
			break;
		}
		case "TaskStarted":
			updateTaskStarted(event);
			break;
		case "TaskCompleted":
			updateTaskCompleted(event);
			break;
		case "TaskUpdated":
			updateTaskProcessingState(event);
			break;
		}
	};

	const updateOutputState = (event: TaskOutputGenerated) => {
		setTasks((prevTasks) =>
			prevTasks.map((task) =>
				task.id === event.data.taskId ? {...task, output: event.data.output} : task
			)
		);
	};

	const updateTaskStarted = (event: TaskStarted) => {
		setTasks((prevTasks) =>
			prevTasks.map((task) =>
				task.id === event.data.taskId ? {
					...task,
					processingState: "processing",
					processingSteps: [{
						timestamp: Date.now(),
						message: event.data.processingState || "Task started"
					}]
				} : task
			)
		);

		scrollToElement(event.data.taskId);

	};

	const updateTaskCompleted = (event: TaskCompleted) => {
		setTasks((prevTasks) =>
			prevTasks.map((task) =>
				task.id === event.data.taskId ? {
					...task,
					processingState: "[COMPLETED]",
				} : task
			)
		);
	};

	const updateTaskProcessingState = (event: TaskUpdated) => {
		// Skip verifyUrl-related messages
		if (event.data.processingState.includes('verifyUrl') ||
			event.data.processingState.includes('Starting verifyUrl') ||
			event.data.processingState.includes('Completed verifyUrl') ||
			event.data.processingState.includes('Using verifyUrl')) {
			return;
		}

		setTasks((prevTasks) =>
			prevTasks.map((task) => {
				if (task.id === event.data.taskId) {
					const newStep = {
						timestamp: event.metadata?.timestamp || Date.now(),
						message: event.data.processingState,
						sequence: event.metadata?.sequence
					};

					const lastStep = task.processingSteps?.[task.processingSteps.length - 1];
					const isDuplicate = lastStep?.message === newStep.message;

					// Sort steps by sequence if available, then by timestamp
					const updatedSteps = [
						...(task.processingSteps || []),
						...(isDuplicate ? [] : [newStep])
					].sort((a, b) => {
						if (a.sequence && b.sequence) {
							return a.sequence - b.sequence;
						}
						return (a.timestamp || 0) - (b.timestamp || 0);
					});

					const isCompleted = event.data.processingState?.toLowerCase().includes("[completed]");
					const isError = event.data.processingState?.toLowerCase().includes("error");

					return {
						...task,
						processingSteps: updatedSteps,
						processingState: isCompleted ? "[COMPLETED]" :
										isError ? "error" :
										"processing"
					};
				}
				return task;
			})
		);
	};

	return (
		<WorkflowEventsContext.Provider value={{
			isGeneratingReport,
		}}>
			{children}
		</WorkflowEventsContext.Provider>
	);
};

export const useWorkflowEventsContext = () => {
	const context = useContext(WorkflowEventsContext);
	if (!context) {
		throw new Error("useWorkflowEventsContext must be used within an WorkflowEventsProvider");
	}
	return context;
};
