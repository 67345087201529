import {ChatLayoutMode, ReducedChatConversation} from "@/reducer/chat-reducer";
import {ChatState, CreateConversationPayload} from "@/reducer/chat-reducer"
import {extractChatChanges} from "./extract-chat-changes";
import {isChatFakeQueryMessage, isChatFakeResponseMessage} from "../type-guards";

export const handleCreateConversation = (state: ChatState, payload: CreateConversationPayload = {}): ChatState => {
  const {isSendingQuestion} = state.actions;

  const newOriginal = null;
  const newMessages = state.current.messages.filter(m => isChatFakeQueryMessage(m) || isChatFakeResponseMessage(m));
  const newCurrent: ReducedChatConversation = {
    ...state.current,
    ...payload,
    id: null,
    createdAt: null,
    messages: newMessages,
    layout: ChatLayoutMode.CHAT,
  };
  const newChanges = extractChatChanges(null, newCurrent);
  const newActions = {
    ...state.actions,
    isCreating: true,
    isLoading: true,
    isInitialLoading: !isSendingQuestion,
  }

  return {
    ...state,
    original: newOriginal,
    current: newCurrent,
    changes: newChanges,
    actions: newActions,
  }
}
