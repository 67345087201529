import React, {ReactElement} from "react";

import {DropdownItemSpacing} from "../_helpers";

export interface DropdownCustomElementItemProps {
  item: ReactElement;
}

export const DropdownCustomElementItem = ({item}: DropdownCustomElementItemProps): ReactElement => {
  return (
    <DropdownItemSpacing>
      {item}
    </DropdownItemSpacing>
  );
}
