import React, {useEffect, useState} from "react";
import {createContext, useContext} from "react";
import {useToastContext} from "../toast-context";
import {useSubscription} from "@apollo/client";
import {VurveyOrchestrationEvent} from "@/models/workflow-events";
import {ORCHESTRATION_EVENTS} from "@/graphql/subscriptions";

interface OrchestrationSubscriptionData {
  orchestrationUpdates: VurveyOrchestrationEvent;
}

interface WorkflowCreationEventsContextProps {
  workflowCreationStarted?: {
      orchestrationId: string;
      name: string;
  };
  agentCreationProgress?: {
      orchestrationId: string;
      agentName: string;
      progress: number;
      total: number;
  };
  workflowCreationCompleted?: {
      orchestrationId: string;
      agentCount: number;
  };
  workflowCreationError?: {
      orchestrationId: string;
      error: string;
  };
  setActiveOrchestrationId: (id: string | undefined) => void;
}

const WorkflowCreationEventsContext = createContext<WorkflowCreationEventsContextProps | undefined>(undefined);

export const WorkflowCreationEventsProvider = ({children}: { children: React.ReactNode }) => {
    const {updateToast} = useToastContext();
    const [activeOrchestrationId, setActiveOrchestrationId] = useState<string | undefined>(undefined);
    const [workflowCreationStarted, setWorkflowCreationStarted] = useState<WorkflowCreationEventsContextProps['workflowCreationStarted']>();
    const [agentCreationProgress, setAgentCreationProgress] = useState<WorkflowCreationEventsContextProps['agentCreationProgress']>();
    const [workflowCreationCompleted, setWorkflowCreationCompleted] = useState<WorkflowCreationEventsContextProps['workflowCreationCompleted']>();
    const [workflowCreationError, setWorkflowCreationError] = useState<WorkflowCreationEventsContextProps['workflowCreationError']>();

    // Set up subscription with retry logic
    const {error: subscriptionError} = useSubscription<OrchestrationSubscriptionData>(ORCHESTRATION_EVENTS, {
        variables: {orchestrationId: activeOrchestrationId},
        skip: !activeOrchestrationId,
        shouldResubscribe: true,
        onComplete: () => {
            console.log('Subscription completed for:', activeOrchestrationId);
            // Only clear states if we're not in an error state
            if (!workflowCreationError) {
                setWorkflowCreationStarted(undefined);
                setAgentCreationProgress(undefined);
                setWorkflowCreationCompleted(undefined);
            }
        },
        onData: ({data: subscriptionData}) => {
            console.log('Subscription data received:', {
                activeId: activeOrchestrationId,
                data: subscriptionData?.data?.orchestrationUpdates,
                timestamp: new Date().toISOString()
            });

            const event = subscriptionData?.data?.orchestrationUpdates;
            if (event) {
                // Don't process any more events if we've already completed
                if (workflowCreationCompleted) {
                    console.log('Skipping event - workflow already completed:', {
                        type: event.type,
                        timestamp: new Date().toISOString()
                    });
                    return;
                }

                // Process event
                console.log('Processing event:', {
                    type: event.type,
                    orchestrationId: event.data.orchestrationId,
                    data: event.data,
                    timestamp: new Date().toISOString()
                });
                handleWorkflowEvent(event);

                // If this was the completion event, stop subscription
                if (event.type === 'WorkflowCreationCompleted') {
                    console.log('Workflow completed, stopping subscription');
                    setActiveOrchestrationId(undefined);
                }
            }
        },
        onError: (error) => {
            console.error('Subscription error:', {
                error,
                orchestrationId: activeOrchestrationId,
                timestamp: new Date().toISOString()
            });

            // Handle different types of errors
            if (error.message.includes('Failed to fetch') || error.message.includes('Socket closed')) {
                // Network/connection error - let Apollo Client handle retry
                console.log('Network error detected, Apollo Client will handle retry');

                // Show a more user-friendly message
                updateToast({
                    description: "Connection interrupted. Attempting to reconnect...",
                    type: "informational",
                });
            } else {
                // Other errors - show error and reset state
                updateToast({
                    description: `WebSocket error: ${error.message}`,
                    type: "failure",
                });

                // Set error state but keep orchestrationId for retry
                setWorkflowCreationError({
                    orchestrationId: activeOrchestrationId || '',
                    error: error.message
                });
            }
        }
    });

    // Handle subscription setup and cleanup
    useEffect(() => {
        if (activeOrchestrationId) {
            console.log('Setting up subscription for running workflow:', {
                id: activeOrchestrationId,
                timestamp: new Date().toISOString()
            });

            // Return cleanup function
            return () => {
                console.log('Cleaning up subscription for workflow:', {
                    id: activeOrchestrationId,
                    timestamp: new Date().toISOString()
                });
                // Clear all workflow states on cleanup
                setWorkflowCreationStarted(undefined);
                setAgentCreationProgress(undefined);
                setWorkflowCreationCompleted(undefined);
                setWorkflowCreationError(undefined);
            };
        }
    }, [activeOrchestrationId]);

    // Handle subscription errors with retry logic
    useEffect(() => {
        if (subscriptionError) {
            console.error('Subscription error occurred:', subscriptionError);

            // For network errors, keep the orchestrationId active for retry
            if (subscriptionError.message.includes('Failed to fetch') ||
                subscriptionError.message.includes('Socket closed')) {
                // Let Apollo Client handle retry with its built-in mechanisms
                return;
            }

            // For other errors, reset state after a delay to allow for cleanup
            const timeoutId = setTimeout(() => {
                setActiveOrchestrationId(undefined);
                setWorkflowCreationStarted(undefined);
                setAgentCreationProgress(undefined);
                setWorkflowCreationCompleted(undefined);
                setWorkflowCreationError(undefined);
            }, 1000);

            return () => clearTimeout(timeoutId);
        }
    }, [subscriptionError]);

    // Handle workflow creation events
    useEffect(() => {
        if (workflowCreationCompleted || workflowCreationError) {
            console.log('Workflow creation finished:', {
                completed: !!workflowCreationCompleted,
                error: !!workflowCreationError,
                activeOrchestrationId
            });

            // Clear states but keep subscription active until component unmounts
            setWorkflowCreationStarted(undefined);
            setAgentCreationProgress(undefined);

            // If we were using a wildcard subscription, clean it up
            if (activeOrchestrationId === '*') {
                setActiveOrchestrationId(undefined);
            }
        }
    }, [workflowCreationCompleted, workflowCreationError]);

    // Cleanup subscription when component unmounts
    useEffect(() => {
        return () => {
            console.log('Cleaning up workflow events context');
            setWorkflowCreationStarted(undefined);
            setAgentCreationProgress(undefined);
            setWorkflowCreationCompleted(undefined);
            setWorkflowCreationError(undefined);
            setActiveOrchestrationId(undefined);
        };
    }, []);

    const handleWorkflowEvent = (event: VurveyOrchestrationEvent) => {
        // If this is a completion event, handle it immediately and stop
        if (event.type === "WorkflowCreationCompleted") {
            setWorkflowCreationCompleted(event.data);
            setWorkflowCreationStarted(undefined);
            setAgentCreationProgress(undefined);
            // Don't stop subscription here, let it continue for orchestration events
            return;
        }

        // For all other events, only process if we haven't completed yet
        if (workflowCreationCompleted) {
            console.log('Skipping event - workflow already completed:', event.type);
            return;
        }

        switch (event.type) {
        case "WorkflowCreationStarted": {
            setWorkflowCreationStarted(event.data);
            setWorkflowCreationError(undefined);
            setWorkflowCreationCompleted(undefined);
            break;
        }
        case "AgentCreationProgress": {
            setAgentCreationProgress(event.data);
            break;
        }
        case "WorkflowCreationError": {
            setWorkflowCreationError(event.data);
            setWorkflowCreationStarted(undefined);
            setAgentCreationProgress(undefined);
            updateToast({
                description: `Error creating workflow: ${event.data.error}`,
                type: "failure",
            });
            break;
        }
      }
    };

    return (
        <WorkflowCreationEventsContext.Provider value={{
            workflowCreationStarted,
            agentCreationProgress,
            workflowCreationCompleted,
            workflowCreationError,
            setActiveOrchestrationId
        }}>
            {children}
        </WorkflowCreationEventsContext.Provider>
    );
};

export const useWorkflowCreationEventsContext = () => {
    const context = useContext(WorkflowCreationEventsContext);
    if (!context) {
        throw new Error("useWorkflowEventsContext must be used within an WorkflowEventsProvider");
    }
    return context;
};
