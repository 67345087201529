import classNames from "classnames/bind";
import React, {ReactElement, useMemo} from "react";

import {DropdownActionItem, DropdownCustomElementItem, DropdownItem, DropdownLinkItem, DropdownNestedItem, DropdownSection, DropdownSectionItem} from "./items";
import {DropdownOpenActionItem} from "./items/open-action";
import {Popup, PopupProps} from "../popup";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./dropdown.module.scss";

const cx = classNames.bind(styles);

export interface DropdownProps extends Omit<PopupProps, "children"> {
	ariaLabel?: string;
	className?: string;
	popupContentClassName?: string;
	items: (DropdownItem | DropdownSection | ReactElement | undefined)[];
}

export const Dropdown = ({
	items,
	popupContentClassName,
	ariaLabel,
	...popupProps
}: DropdownProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	const filteredItems = useMemo(() => items?.filter(item => Boolean(item)), [items]) || [];

	const renderItem = (item: typeof items[number], index: number) => {
		if (!item) {
			return;
		}

		if ("dropdown" in item) {
			return <DropdownNestedItem key={index} item={item} />;
		}

		if ("items" in item) {
			return (
				<DropdownSectionItem
					key={index}
					section={item}
					isFirst={index === 0}
					isLast={index === filteredItems.length - 1}
				/>
			)
		}

		if ("link" in item) {
			return <DropdownLinkItem key={index} item={item} />;
		}

		if ("props" in item) {
			return <DropdownCustomElementItem key={index} item={item} />;
		}

		if ("type" in item) {
			if (item.type === "checkbox") {
				return <DropdownOpenActionItem key={index} item={item} />;
			}
		}

		return <DropdownActionItem key={index} item={item} />;
	}

	return (
		<Popup {...popupProps} >
			<div
				aria-label={ariaLabel}
				className={cx("dropdown", popupContentClassName, {isDarkMode})}
				onWheel={(e) => {
					const isOverflown = e.currentTarget.scrollHeight > e.currentTarget.clientHeight;
					if (isOverflown) {
						e.stopPropagation();
					}
				}}
			>
				{filteredItems?.map(renderItem)}
			</div>
		</Popup>
	)
};
