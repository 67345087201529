import {gql} from "@apollo/client";
import {AI_ORCHESTRATION_FRAGMENT} from "../fragments/workflow";

export const GET_ORCH_OUTPUT_TYPES = gql`
	query GetAllOrchestrationOutputTypes {
		aiOrchestrationOutputTypes {
      id
			code
			name
			vurveyOnly
			createdAt
			updatedAt
		}
	}
`;

export const AI_ORCHESTRATIONS_PAGE = gql`
  query AiOrchestrationsPage($workspaceId: GUID!, $filter: AiOrchestrationsFilter, $limit: PositiveInt, $sort: AiOrchestrationSort, $cursor: String) {
    aiOrchestrationsPage(workspaceId: $workspaceId, filter: $filter, limit: $limit, sort: $sort, cursor: $cursor) {
      items {
        id
        name
        description
        workspaceId
        aiPersonaTasks {
          persona {
            id
            name
            avatarUri
            picture {
              id
              url(version: THUMBNAIL)
            }
          }
        }
      }
      cursor
      remaining
    }
  }
`;

// Get a single one, and all info (except workspace)
export const GET_AI_ORCHESTRATION = gql`
  query GetAiOrchestration($id: String!) {
    aiOrchestration(id: $id) {
      ...AiOrchestrationFields
    }
  }

  ${AI_ORCHESTRATION_FRAGMENT}
`;

export const GET_AI_ORCHESTRATION_HISTORY = gql`
  query GetAiOrchestrationHistory($orchestrationId: GUID!) {
    aiOrchestrationHistory(orchestrationId: $orchestrationId) {
      id
      aiOrchestrationId
      completedAt
      output
      status
      reportUrl
      reportWordUrl
      sources {
        surveyIds
        trainingSetIds
      }
      populatedSources {
        __typename
        ... on Survey {
          id
          name
        }
        ... on TrainingSet {
          id
          alias
        }
        ... on Question {
          id
          text
        }
        ... on TrainingSetVideo {
          id
          originalFilename
        }
        ... on File {
          id
          originalFilename
        }
      }
      outputHistoryTasks {
        taskId
        persona {
          id
          name
          avatarUri
          picture {
            id
            url(version: THUMBNAIL)
          }
        }
        input
        output
        taskPrompt
      }
    }
  }
`;
