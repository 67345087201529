
import {useMutation, useQuery} from "@apollo/client";
import React, {ReactElement, useContext, useEffect, useMemo, useState} from "react";

import {BaseModalProps, Body, Button, Checkbox, DebounceSearch, Modal} from "../../../shared/v2";
import {CLIP_FRAGMENT} from "../../../graphql/fragments/fragments";
import {CREATE_CLIPS} from "../../../graphql/mutations/clip-mutations";
import {CreateClipsReturn, CreateClipsVars} from "../../../models/clip";
import {GET_ALL_SURVEY_NAMES, GET_VIDEO_ANSWERS} from "../../../graphql/queries/survey-queries";
import {Heading, Select} from "../../../shared";
import {IdName} from "../../../models/generic";
import {Pagination} from "../../../shared/components/pagination";
import {ReelContext} from "../../../context/reel-context";
import {SearchableFilter} from "../../../shared/components/searchable-filter";
import {SelectContext} from "../../../context/select-context";
import {SORT_TYPES} from "../../../shared/constants/constants";
import {SurveyNamePageData} from "../../../models/survey";
import {ToastContext} from "../../../context/toast-context";
import {updateCacheAddItemByFieldId} from "../../../shared/utility/update-cache";
import {useLoadingQuery} from "../../../hooks";
import {useWorkspaceContext} from "../../../context/workspace-context";
import {VideoAnswersData} from "../../../models/answer";
import {VideoResult} from "./video-result";

import styles from "./search-modal.module.scss";

const PAGE_SIZE = 50;

const SearchModal = (props: BaseModalProps): ReactElement => {
	const {isOpen, onClose} = props;
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const {selected: items, emptySelectValues} = useContext(SelectContext);
	const {updateToast} = useContext(ToastContext);
	const {reelId} = useContext(ReelContext);

	const [search, setSearch] = useState("");
	const [sort, setSort] = useState(SORT_TYPES[0].value);
	const [surveyIds, setSurveyIds] = useState<string[]>([]);
	const [highlighted, setHighlighted] = useState(false);
	const [currentPage, setCurrentPage] = useState(0);

	const {data: campaignsData} = useQuery<SurveyNamePageData>(GET_ALL_SURVEY_NAMES, {
		skip: !isOpen,
		variables: {workspaceId},
	});

	/**
	 * As a note, this should probably have a limit and be set up to handle
	 * cursors and such. Should have been done with the search page at the start, too.
	 * (I didn't feel like doing it right now since there's still a lot to do here)
	 */
	const {data: answersData, fragment} = useLoadingQuery<VideoAnswersData>(GET_VIDEO_ANSWERS, {
		skip: !search,
		what: "videos",
		nextFetchPolicy: "cache-only",
		variables: {
			sort: sort || undefined,
			workspaceId,
			filter: {
				search,
				highlighted: highlighted || undefined,
				surveyIds: surveyIds.length ? surveyIds : undefined,
			},
		},
		loadingClassName: styles.loading,
	});

	const [createClips, {loading}] = useMutation<CreateClipsReturn, CreateClipsVars>(CREATE_CLIPS);

	const sortedCampains = useMemo(() =>
		[...campaignsData?.workspaceSurveys.items ?? []].sort((a, b) =>
			a.name.localeCompare(b.name)), [campaignsData]);

	const reset = (): void => {
		setSearch("");
		setCurrentPage(0);
		setSort(SORT_TYPES[0].value);
		setSurveyIds([]);
		setHighlighted(false);
		emptySelectValues();
	};

	const handleAddClips = (): void => {
		if (!reelId) return;
		createClips({
			variables: {reelId, input: items.map(val => ({answerId: val}))},
			onCompleted: () => {
				updateToast({description: "Clips added", type: "informational"});
				emptySelectValues();
				onClose();
			},

			onError: () => updateToast({description: "Failed to add to reel, try again later", type: "failure"}),
			update(cache, {data: createData}) {
				if (!createData) return;
				createData.createClips.forEach(clip => {
					const clipDuration = clip.endTime - clip.startTime;
					const newRef = cache.writeFragment({
						data: clip,
						fragment: CLIP_FRAGMENT,
						fragmentName: "ClipFields",
					});
					updateCacheAddItemByFieldId(
						cache,
						"clips",
						newRef,
						reelId,
						clip.id,
						clip.index,
					);
					cache.modify({
						id: `Reel:${reelId}`,
						fields: {
							duration(currentDuration: number) {
								return currentDuration + clipDuration;
							},
						},
					});
				});
			},
		});
	};

	const handleClearCampaignsFilter = (): void => setSurveyIds([]);
	const handleClearSearch = (): void => setSearch("");

	// We need to empty the select values before setting most of the search related values
	const handleSearch = (s: string): void => {
		emptySelectValues();
		setSearch(s);
	};

	const handleSortSelect = (s: string): void => {
		emptySelectValues();
		setSort(s);
	};

	const handleHighlight = (e: React.ChangeEvent<HTMLInputElement>): void => {
		emptySelectValues();
		setHighlighted(e.target.checked);
	};

	const handleCampaignsFilter = (selected: IdName): void => {
		emptySelectValues();
		if (surveyIds.includes(selected.id)) {
			setSurveyIds(surveyIds.filter((id: string) => id !== selected.id));
		} else {
			setSurveyIds(prev => [...prev, selected.id]);
		}
	};


	// Whenever we close we want to reset the values
	useEffect(() => {
		if (!isOpen) reset();
	}, [isOpen]);

	const currentData = useMemo(() =>
		answersData?.searchAnswers?.items.slice(
			currentPage * PAGE_SIZE,
			(currentPage + 1) * PAGE_SIZE,
		), [currentPage, answersData]);

	const count = answersData ?
		answersData.searchAnswers?.items.length + answersData.searchAnswers?.remaining : undefined;

	const clearButton: ReactElement = <button
		className={styles.fakeButton}
		onClick={handleClearSearch}
	>
		clear search
	</button>;

	return (

		<Modal className={styles.modal} isOpen={isOpen} onClose={onClose}>
			<div className={styles.container}>
				<Heading size="md">Video Search</Heading>
				<div className={styles.filters}>
					<DebounceSearch
						id="search-input"
						value={search}
						onChange={handleSearch}
					/>
					<Select
						id="sort-select"
						options={SORT_TYPES}
						onChange={handleSortSelect}
						selectedValue={sort}
						size="medium"
					/>
					<SearchableFilter
						options={sortedCampains}
						value={surveyIds}
						placeholder="All Campaigns"
						selectedLabel={surveyIds.length === 1 ? "1 campaign" :
							`${surveyIds.length} campaigns`}
						onChange={handleCampaignsFilter}
						onClear={handleClearCampaignsFilter}
					/>
					<Checkbox
						id="highlights-only"
						onChange={handleHighlight}
						size="s"
						className={styles.highlightsCheckbox}
						checked={highlighted}
						text={(
							<Body size="xs">
								Highlights only
							</Body>
						)}
					/>
				</div>
				{count ?
					<div className={styles.searchResults}>
						<span className={styles.countText}>
									Showing {count} {count === 1 ? "video" : "videos"}
						</span>
						{search &&
									<>
										<span>
											&nbsp;for <strong>{`"${search}"`}</strong>
										</span>
										{clearButton}
									</>
						}
					</div>
					:
					<div className={styles.searchResults}>
						{
							search ?
								fragment ? <span>Searching...</span>
									: <span>No videos found</span>
								: <span>Enter a search term</span>
						}
						{search && clearButton}
					</div>
				}
				{fragment || (answersData && currentData &&
				<div className={styles.results}>
					{currentData.map(answer => <VideoResult
						key={answer.id}
						answer={answer}
						search={search}
					/>)}
					<Pagination
						currentPage={currentPage}
						totalCount={answersData?.searchAnswers?.items.length}
						pageSize={PAGE_SIZE}
						onChange={setCurrentPage}
					/>
				</div>)
				}
				{items.length > 0 && <div className={styles.bar}>
					<span>{items.length} selected</span>
					<Button
						onClick={handleAddClips}
						disabled={loading}
					>
						{loading ? "Adding..." : "Add to Reel"}
					</Button>
				</div>}
			</div>
		</Modal>
	);
};

export {SearchModal};
