import React, {ReactElement} from "react";

import {
	CommunityUsersIcon,
	CopyDocumentsIcon,
	HomeHouseIcon,
	HornMegaphoneCampaignIcon,
	PersonaIcon,
	WorkflowSolarCirclesIcon,
	VurveyLabsLockupBlackIcon,
	VurveyLabsLockupVoltWhiteIcon
} from "../../../icons";
import {Copilot} from "./copilot";
import {Footer} from "./footer";
import {HeaderDropdowns} from "./header-dropdowns";
import {Link} from "../../../route";
import {NavItem} from "./nav-item";
import {Separator} from "../../../shared/v2/separator";
import {useThemeMode} from "../../../context/theme-mode-context";
import {useWorkspaceContext} from "../../../context/workspace-context";

import styles from "./navigation.module.scss";

export const Navigation = (): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {workspace: {chatbotEnabled} = {}} = useWorkspaceContext();

	return (
		<div className={styles.wrapper}>
			<Link to="/" className={styles.logoContainer} workspace>
				{
					isDarkMode ?
					<VurveyLabsLockupVoltWhiteIcon className={styles.logo} /> :
					<VurveyLabsLockupBlackIcon className={styles.logo} /> 
				}
			</Link>

			<HeaderDropdowns className={styles.headerDropdowns} />
			<div className={styles.scrollableSection}>
				<div className={styles.linksSection}>
					{chatbotEnabled && <NavItem to="/" text="Home" icon={<HomeHouseIcon />}  />}
					{chatbotEnabled && <NavItem to="agents" text="Agents" icon={<PersonaIcon />} />}
					<NavItem to="audience" text="Audience" icon={<CommunityUsersIcon />} />
					<NavItem to="campaigns" text="Campaigns" icon={<HornMegaphoneCampaignIcon />} />

					{chatbotEnabled && <NavItem to="datasets" text="Datasets" icon={<CopyDocumentsIcon />} />}
					{chatbotEnabled && <NavItem to="workflow/flows" text="Workflow" icon={<WorkflowSolarCirclesIcon />} isBeta />}
				</div>

				{chatbotEnabled && (
					<>
						<Separator className={styles.copilotSeparator} color="navigation" />
						<Copilot />
					</>
				)}
			</div>
			<Footer />
		</div>
	);
};
