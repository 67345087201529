import React, {ReactElement, useRef} from "react";
import {Icon, IconTypes} from "../icon";
import {CssSpinner} from "../css-spinner";
import {MediaState} from "../../../hooks/useMediaUpload";
import classNames, {Argument} from "classnames";
import styles from "./simple-video-input.module.scss";

export interface SimpleVideoInputProps {
	id: string;
	media: MediaState;
	clearMedia: () => void;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	hasError?: boolean;
	emptyIcon?: IconTypes;
	className?: Argument
	helpText?: string;
	isProcessing?: boolean;
	disabled?: boolean;
}

const SimpleVideoInput = (props: SimpleVideoInputProps): ReactElement => {

	const {emptyIcon, id, className, media, onChange, hasError, isProcessing, helpText, disabled, clearMedia} = props;
	const fileType = media?.type ?? "video";
	const ref = useRef<HTMLInputElement>(null);

	const handleClear = (e: React.MouseEvent): void => {
		e.preventDefault();
		clearMedia();
		// React weird.
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore
		if (ref.current) ref.current.value = null;
	};
	return (
		<label
			htmlFor={id}
			className={classNames(
				className,
				styles.label,
				(fileType !== "video" || hasError) && styles.error,
				disabled && styles.disabled,
			)}
		>
			{media?.preview && fileType === "video" ? (
				<>
					<video src={media?.preview} controls className={styles.video} />
					{isProcessing && <CssSpinner className={styles.loading}/> }
					<div className={styles.cancel} onClick={handleClear}>
						<Icon name="cross" size="extrasmall" />
					</div>
				</>

			)	: (fileType !== "video" || hasError) ? <div>.mp4, .mpeg, .mov, .avi only</div>
				: <div className={styles.empty}>
					<Icon name={emptyIcon || "plus"} fill={"var(--batterii-grey-extradark)"}/>
					{helpText && <span>{helpText}</span>}
				</div>}
			<input
				style={{display: "none"}}
				id={id}
				ref={ref}
				className={styles.input}
				type="file"
				onChange={onChange}
				disabled={disabled}
				accept=".mp4, .mpeg, .mov, .avi"
			/>
		</label>
	);
};

export {SimpleVideoInput};
