import classNames from "classnames/bind";
import React, {cloneElement, ReactElement, useEffect} from "react";

import {ButtonIcon, Subheader, Button, Tooltip, Body} from "@/shared/v2";
import {ChevronLeftIcon, DownloadIcon, ResetBackToolIcon, VurveyLabsLockupVoltBlackIcon, VurveyLabsLockupBlackIcon} from "@/icons";
import {useImageHistoryContext, useImageElementContext, useAiActionsContext} from "../../contexts";
import {useThemeMode} from "@/context/theme-mode-context";

import styles from "./navigation.module.scss";

const cx = classNames.bind(styles);

export interface NavigationProps {
  submitButton?: ReactElement;
}

export const Navigation = ({submitButton}: NavigationProps): ReactElement => {
  const {isDarkMode} = useThemeMode();
  const {clearImage, history, imageSrc: currentEntry, originalSrc, setImageSrc} = useImageHistoryContext();
  const {isUpdatingImage, saveImage} = useAiActionsContext();
  const {element: imageEl} = useImageElementContext();
  const isOriginalImage = history[0] === currentEntry;

  // Check if we can reset to original
  const canResetToOriginal = originalSrc &&
                            currentEntry !== originalSrc &&
                            history.includes(originalSrc);

  const handleClearImage = () => {
    if (isUpdatingImage) {
      return;
    }

    clearImage();
  }

  const handleResetToOriginal = () => {
    if (isUpdatingImage || !originalSrc) {
      return;
    }

    setImageSrc(originalSrc);
  }

  // Handler for keyboard shortcut
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      // Alt+R for Reset to Original
      if (e.altKey && e.key === 'r' && canResetToOriginal && !isUpdatingImage) {
        handleResetToOriginal();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [canResetToOriginal, isUpdatingImage, handleResetToOriginal]);

  const downloadImage = () => {
    if (!imageEl) {
      return;
    }

    const a = document.createElement("a");
    a.href = imageEl.src;
    a.target = "_blank";
    a.download = "image.png";
    a.click();
  }

  const renderSubmitButton = () => {
    if (!submitButton) {
      return null;
    }

    return cloneElement(submitButton, {
      ...submitButton.props,
      onClick: saveImage,
      disabled: submitButton.props.disabled || isUpdatingImage || isOriginalImage,
    });
  }

  return (
    <div className={cx("nav", {isDarkMode})}>
      <div className={styles.leftControls}>
        <Tooltip content={<Body size="xs">Exit Image Studio</Body>} placement="bottom" offset={[0, 8]}>
          <ButtonIcon
            icon={<ChevronLeftIcon className={styles.arrowBack} />}
            disabled={isUpdatingImage}
            className={styles.backButton}
            onClick={handleClearImage}
          />
        </Tooltip>

        {isDarkMode ? <VurveyLabsLockupVoltBlackIcon className={styles.logo} /> : <VurveyLabsLockupBlackIcon className={styles.logo} />}
      </div>

      <Subheader type="semibold" className={styles.header}>
        Image Studio
      </Subheader>

      <div className={styles.rightControls}>
        {canResetToOriginal && (
          <Tooltip content={<Body size="xs">Reset to original image (Alt+R)</Body>} placement="bottom" offset={[0, 8]}>
            <Button
              leftIcon={<ResetBackToolIcon />}
              variant="outlined"
              size="small"
              onClick={handleResetToOriginal}
              disabled={isUpdatingImage}
              className={styles.resetButton}
              title="Reset to Original (Alt+R)"
            >
              Reset to Original
            </Button>
          </Tooltip>
        )}

        <Tooltip content={<Body size="xs">Download this image to your device</Body>} placement="bottom" offset={[0, 8]}>
          <Button
            leftIcon={<DownloadIcon />}
            variant="outlined"
            size="small"
            onClick={downloadImage}
          >
            Download
          </Button>
        </Tooltip>

        {submitButton && (
          <Tooltip content={<Body size="xs">Save the edited image to your conversation</Body>} placement="bottom" offset={[0, 8]}>
            {renderSubmitButton()}
          </Tooltip>
        )}
      </div>
    </div>
  );
}
