import React, { ReactElement } from "react";
import styles from "./ThemeSwitcher.module.scss";
import classNames from "classnames/bind";
import { WeatherSunIcon, AstrologyMoonIcon } from "@/icons";
import { useThemeMode } from "@/context/theme-mode-context";

const cx = classNames.bind(styles);

export interface ThemeSwitcherProps {
  className?: string;
}

export const ThemeSwitcher = ({
  className,
}: ThemeSwitcherProps): ReactElement => {
  const { isDarkMode, toggleTheme } = useThemeMode();

  return (
    <div className={cx("wrapper", className, { isDarkMode })}>
      <ButtonIcon
        icon={<WeatherSunIcon className={cx("icon", "sun")} />}
        className={cx("button", {
          active: !isDarkMode,
        })}
        onClick={toggleTheme}
      />
      <ButtonIcon
        icon={<AstrologyMoonIcon className={cx("icon", "moon")} />}
        className={cx("button", {
          active: isDarkMode,
        })}
        onClick={toggleTheme}
      />
    </div>
  );
};

const ButtonIcon = ({
  icon,
  className,
  onClick,
}: {
  icon: ReactElement;
  className?: string;
  onClick: () => void;
}) => {
  return (
    <button className={cx("button", className)} onClick={onClick}>
      {icon}
    </button>
  );
};
