import {ApolloClient, ApolloLink, ApolloProvider, from, split} from "@apollo/client";
import {createClient} from "graphql-ws";
import {createUploadLink} from "apollo-upload-client";
import {getMainDefinition} from "@apollo/client/utilities";
import {GraphQLWsLink} from "@apollo/client/link/subscriptions";
import {onError} from "@apollo/client/link/error";
import {setContext} from "@apollo/client/link/context";
import {v4 as uuid} from "uuid";
import Bugsnag from "@bugsnag/js";
import React, {ReactElement, ReactNode, useMemo} from "react";

import {auth} from "../utils/firebase";
import {cache} from "../cache";
import {typeDefs, resolvers} from "../graphql/resolvers/resolvers";
import config from "../config";

export const ApolloContextProvider = ({children}: {children: ReactNode}): ReactElement => {
	const applyDecorators = (link: ApolloLink, decorators: ApolloLink[]) => {
		return decorators.reduce((acc, decorator) => decorator.concat(acc), link);
	};

	const getToken = async () => {
		const user = auth?.currentUser;
		let token: string | null = null;

		if (user) {
			token = await user.getIdToken();
			localStorage.setItem("token", token);
		}
		return token;
	};

	const reportError = (error): void => {
		if (config.bugsnagReleaseStage === "development") {
			return console.error(error);
		}
		return Bugsnag.notify(error);
	};

	// Links

	const errorHandler = useMemo(
		() =>
			onError(({graphQLErrors, networkError}) => {
				if (graphQLErrors) {
					graphQLErrors.forEach(({message, locations, path}) => {
						if (message.includes("Please re-authenticate") || message.includes("Internal server error : Auth token time")) {
							localStorage.removeItem("token");
							auth.signOut();
						}
						reportError(
							new Error(`[GraphQL error]: Message: ${message},
        Location: ${JSON.stringify(locations)},
        Path: ${path}`),
						);
					});
				}
				if (networkError) reportError(new Error(`[Network error]: ${networkError}`));
			}),
		[],
	);

	const graphqlLink = useMemo(
		() =>
			createUploadLink({
				uri: `${config.apiHost}/graphql`,
				credentials: "include",
				fetchOptions: {
					timeout: 3 * 60 * 1000, // 3 minutes in milliseconds
				},
			}),
		[],
	);

	const wsLink = useMemo(() => {
		return new GraphQLWsLink(
			createClient({
				url: `${config.pubsubHost}/graphql`,
				connectionParams: async () => {
					// Wait for token to be available before attempting connection
					let retries = 0;
					while (retries < 3) {
						const token = await getToken();
						if (token) {
							return { authToken: token };
						}
						await new Promise(resolve => setTimeout(resolve, 1000));
						retries++;
					}
					throw new Error('Unable to get authentication token');
				},
				// More aggressive retry strategy for long-running operations
				retryAttempts: 10, // Increase retry attempts for long-running operations
				retryWait: async (retries) => {
					// Exponential backoff with a minimum of 2 seconds and maximum of 30 seconds
					const backoff = Math.min(2000 * Math.pow(1.5, retries), 30000);
					console.log(`[WebSocket] Retry attempt ${retries + 1}, waiting ${backoff}ms before reconnecting`);
					await new Promise((resolve) => setTimeout(resolve, backoff));
				},
				shouldRetry: (errOrCloseEvent: unknown) => {
					// Type guard to check if the error has a message property
					if (typeof errOrCloseEvent === 'object' && errOrCloseEvent !== null && 'message' in errOrCloseEvent) {
						const error = errOrCloseEvent as { message: string };
						console.debug('[WebSocket] Connection error:', error.message);

						// Don't retry on authentication errors
						if (error.message?.includes("Please re-authenticate")) {
							console.log('[WebSocket] Authentication error - redirecting to login');
							localStorage.removeItem("token");
							auth.signOut();
							return false;
						}

						// Special handling for workflow-related operations
						if (error.message?.includes('Socket closed') ||
							error.message?.includes('Failed to fetch') ||
							error.message?.includes('connection failed')) {
							console.log('[WebSocket] Temporary connection issue - will retry');
							return true;
						}

						// Retry on all other errors
						return true;
					}

					// Handle close events
					if (errOrCloseEvent instanceof CloseEvent) {
						console.debug('[WebSocket] Connection closed:', errOrCloseEvent.code, errOrCloseEvent.reason);

						// Don't retry on specific close codes
						const nonRetryableCodes = [
							1000, // Normal closure
							1001, // Going away
							1015  // TLS handshake failure
						];

						// Special handling for code 1006 (abnormal closure)
						if (errOrCloseEvent.code === 1006) {
							console.log('[WebSocket] Abnormal closure (1006) - will retry');
							return true;
						}

						if (nonRetryableCodes.includes(errOrCloseEvent.code)) {
							console.log(`[WebSocket] Non-retryable close code ${errOrCloseEvent.code}`);
							return false;
						}
					}

					// Retry by default for other types of errors or close events
					return true;
				},
				keepAlive: 15000, // Increase keep-alive interval for better stability
				on: {
					connected: () => {
						console.log('[WebSocket] Connected successfully');
						// Log connection details
						console.log('[WebSocket] Connection URL:', `${config.pubsubHost}/graphql`);
						console.log('[WebSocket] Connection params:', {
							hasToken: !!localStorage.getItem('token'),
							timestamp: new Date().toISOString()
						});

						// Clear any existing reconnection attempts
						if (window.wsReconnectTimeout) {
							clearTimeout(window.wsReconnectTimeout);
							delete window.wsReconnectTimeout;
						}
					},
					connecting: () => {
						const user = auth?.currentUser;
						if (!user) {
							console.log('[WebSocket] Delaying connection - no user');
							return false; // Prevent connection attempt if no user
						}
						console.log('[WebSocket] Attempting to connect...');
						return true;
					},
					closed: (event) => {
						if (event instanceof CloseEvent) {
							const details = {
								code: event.code,
								reason: event.reason,
								wasClean: event.wasClean,
								timestamp: new Date().toISOString()
							};
							console.log('[WebSocket] Connection closed:', details);

							if (!event.wasClean) {
								console.log('[WebSocket] Unclean closure - letting retry mechanism handle reconnection');
							}
						} else {
							console.log('[WebSocket] Connection closed with unknown event:', {
								event,
								timestamp: new Date().toISOString()
							});
						}
					},
					error: (error) => {
						const errorDetails = {
							error,
							stack: error instanceof Error ? error.stack : undefined,
							timestamp: new Date().toISOString()
						};
						console.error('[WebSocket] Connection error:', errorDetails);
						reportError(error);
					},
					message: (message) => {
						console.log('[WebSocket] Message received:', {
							message,
							timestamp: new Date().toISOString()
						});
					},
					ping: () => console.debug('[WebSocket] Server ping received'),
					pong: () => console.debug('[WebSocket] Server pong received'),
				},
				lazy: true, // Prevent premature connection attempts
				isFatalConnectionProblem: (error) => {
					// Only treat authentication errors as fatal
					return error instanceof Error && error.message.includes('Please re-authenticate');
				}
			}),
		);
	}, []);

	// Decorators

	const authDecorator = useMemo(
		() =>
			setContext(async (_, {headers}) => {
				const token = await getToken();
				return {
					headers: {
						...headers,
						authorization: token ? `Bearer ${token}` : "",
					},
				};
			}),
		[],
	);

	const requestIdDecorator = useMemo(
		() =>
			setContext((_, {headers}) => {
				return {
					headers: {
						...headers,
						"X-Request-ID": uuid(),
					},
				};
			}),
		[],
	);

	// Merge links into one

	const link = useMemo(() => {
		console.log('[Apollo] Setting up Apollo link');
		return from([
			errorHandler,
			split(
				({query}) => {
					const definition = getMainDefinition(query);
					const isSubscription =
						definition.kind === "OperationDefinition" &&
						'operation' in definition &&
						definition.operation === "subscription";
					console.log('[Apollo] Operation type:', {
						kind: definition.kind,
						operation: isSubscription ? 'subscription' : 'other',
						isSubscription
					});
					return isSubscription;
				},
				wsLink,
				applyDecorators(graphqlLink, [authDecorator, requestIdDecorator]),
			),
		]);
	}, [errorHandler, graphqlLink, authDecorator, requestIdDecorator, wsLink]);

	const client = useMemo(
		() =>
			new ApolloClient({
				link,
				cache,
				typeDefs,
				resolvers,
				connectToDevTools: true,
				defaultOptions: {
					watchQuery: {
						// Default to cache-first for better performance
						fetchPolicy: "cache-first",
						// Use cache-and-network for subsequent fetches to stay up-to-date
						nextFetchPolicy: "cache-and-network",
					},
					query: {
						// Use cache-first for one-time queries
						fetchPolicy: "cache-first",
						// Add error policy to handle partial errors gracefully
						errorPolicy: "all",
					},
				},
			}),
		[link],
	);

	return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
