import React, {ReactElement, useMemo, useState, useEffect} from "react";
import classNames from "classnames/bind";

import {BrushSizePicker} from "../brush-size-picker";
import {ButtonIcon, Button, Separator, Tooltip, Body} from "../../../../shared/v2";
import {ImageCanvas} from "../image-canvas";
import {KeyboardEventMiddlewareContextProvider} from "../../../../context/keyboard-event-middleware-context";
import {SendArrowChatIcon, ResetBackToolIcon, EnhanceStarPlusToolIcon, RemoveEraserToolIcon, PencilPlusIcon, PencilMinusIcon} from "../../../../icons";
import {useAiActionsContext, useCanvasContext, useEditorSettingsContext} from "../../contexts";
import {useTask} from "@/hooks/useTask";
import {useThemeMode} from "../../../../context/theme-mode-context";
import {useToastContext} from "@/context/toast-context";
import CommandTextArea from "../../../chat-bubble/command-text-area";

import styles from "./editor.module.scss";

const cx = classNames.bind(styles);

// Placeholder text based on active tool
const PLACEHOLDER_TEXT = {
  draw: "Describe what you want to add in the selected area (e.g. 'a red balloon', 'a small dog')",
  erase: "Describe what should replace the selected area (e.g. 'clear blue sky', 'green grass')",
  default: "What would you like to change?",
  enhancing: "Describe how you want to enhance the image (e.g. 'make it more vibrant', 'add dramatic lighting')",
  removing: "No prompt needed - just click Remove to erase the selected area"
};

// Tooltip text for each tool
const TOOLTIP_TEXT = {
  add: "Select 'Select' to paint areas where you want to add something new",
  subtract: "Select 'Un-select' to remove parts of your selection",
  reset: "Reset your selection completely",
  enhance: "Enhance the entire image with AI",
  remove: "Remove the selected area from the image"
};

export const Editor = (): ReactElement => {
  const {brushMode, setBrushMode} = useEditorSettingsContext();
  const {lines, getMask, stageRef: {current: stage}, setLines} = useCanvasContext();
  const {updateToast} = useToastContext();
  const [value, setValue] = React.useState("");
  const {isDarkMode} = useThemeMode();
  const {
    enhanceImage,
    changeImage,
    isEnhancingImage,
    isUpdatingImage,
  } = useAiActionsContext();
  const [activeAction, setActiveAction] = useState("default");

  const hasMask = useMemo(() => {
    try {
      return Boolean(stage && getMask().hasMask);
    } catch (error) {
      console.error("Error checking mask:", error);
      return false;
    }
  }, [stage, lines.length, getMask]);
  
  // Update active action based on user interaction
  useEffect(() => {
    if (isEnhancingImage) {
      setActiveAction("enhancing");
    } else if (brushMode === "draw" && hasMask) {
      setActiveAction("draw");
    } else if (brushMode === "erase" && hasMask) {
      setActiveAction("erase");
    } else {
      setActiveAction("default");
    }
  }, [brushMode, hasMask, isEnhancingImage]);

  const blur = () => {
    const activeElement = document.activeElement as Element;

    if (activeElement instanceof HTMLElement) {
      activeElement.blur();
    }
  }

  const handleChangeImage = async () => {
    if (value.length === 0) {
      updateToast({type: "failure", description: "Please enter a prompt"});
      return;
    }

    // Get the mask and log details about it
    const maskData = getMask();
    console.log("Mask detection result:", { 
      hasMask: maskData.hasMask, 
      maskDataLength: maskData.maskData.length,
      maskDataPreview: maskData.maskData.substring(0, 50) + '...'
    });

    if (!maskData.hasMask) {
      updateToast({type: "failure", description: "Please select an area to change"});
      return;
    }

    if (!stage) {
      updateToast({type: "failure", description: "Canvas is not ready yet"});
      return;
    }

    blur();
    
    try {
      await changeImage(value);
      setValue("");
    } catch (error) {
      console.error("Error changing image:", error);
      updateToast({
        type: "failure", 
        description: "Failed to change image. Please try again."
      });
    }
  };

  const handleEnhanceImage = async () => {
    blur();
    if (isUpdatingImage) {
      return;
    }

    try {
      await enhanceImage(value);
      setValue("");
    } catch (error) {
      console.error("Error enhancing image:", error);
      updateToast({
        type: "failure", 
        description: "Failed to enhance image. Please try again."
      });
    }
  };

  const {run: handleRemoveFromImage, loading: isRemovingFromImage} = useTask(async () => {
    try {
      blur();
      
      if (!hasMask) {
        updateToast({type: "failure", description: "Please select an area to remove"});
        return;
      }
      
      await changeImage("");
    } catch (error) {
      console.error("Error removing from image:", error);
      updateToast({
        type: "failure", 
        description: "Failed to remove selection. Please try again."
      });
    }
  });
  
  // Get the current placeholder text
  const placeholderText = useMemo(() => {
    if (isRemovingFromImage) return PLACEHOLDER_TEXT.removing;
    if (isEnhancingImage) return PLACEHOLDER_TEXT.enhancing;
    if (brushMode === "draw") return PLACEHOLDER_TEXT.draw;
    if (brushMode === "erase") return PLACEHOLDER_TEXT.erase;
    return PLACEHOLDER_TEXT.default;
  }, [brushMode, isEnhancingImage, isRemovingFromImage]);

  return (
    <div className={styles.editorLayout}>
      <div className={cx("imageStudioEditor", {isDarkMode})}>
        <ImageCanvas />

        <div className={styles.controlsWrapper}>
          <form
            className={cx("inputWrapper", {isDarkMode, disabled: isUpdatingImage})}
            onSubmit={(e) => {
              e.preventDefault();
              handleChangeImage();
            }}
          >
            <KeyboardEventMiddlewareContextProvider>
              <CommandTextArea
                value={value}
                disabled={isUpdatingImage}
                textAreaProps={{"aria-label": "chat-message"}}
                placeholder={placeholderText}
                onChange={setValue}
                handleSubmit={handleChangeImage}
                focusOnMount={false}
              />
            </KeyboardEventMiddlewareContextProvider>
            <ButtonIcon
              className={styles.sendIcon}
              icon={<SendArrowChatIcon />}
              onClick={handleChangeImage}
              title="Send prompt to change image"
            />
          </form>

          <div className={styles.toolkit}>
            <Tooltip content={<Body size="xs">{TOOLTIP_TEXT.add}</Body>} placement="top" offset={[0, 8]}>
              <Button
                className={styles.button}
                style="toolkit"
                filledIcon
                leftIcon={<PencilPlusIcon />}
                active={brushMode === "draw"}
                size="small"
                onClick={() => setBrushMode("draw")}
              >
                Select
              </Button>
            </Tooltip>

            <Tooltip content={<Body size="xs">{TOOLTIP_TEXT.remove}</Body>} placement="top" offset={[0, 8]}>
              <Button
                className={styles.button}
                style="toolkit"
                leftIcon={<RemoveEraserToolIcon />}
                size="small"
                disabled={!hasMask}
                active={isRemovingFromImage}
                onClick={handleRemoveFromImage}
              >
                Remove
              </Button>
            </Tooltip>

            <Tooltip content={<Body size="xs">{TOOLTIP_TEXT.subtract}</Body>} placement="top" offset={[0, 8]}>
              <Button
                className={styles.button}
                style="toolkit"
                filledIcon
                leftIcon={<PencilMinusIcon />}
                active={brushMode === "erase"}
                size="small"
                disabled={!lines.some(line => line.globalCompositeOperation === "source-over")}
                onClick={() => setBrushMode("erase")}
              >
                Un-select
              </Button>
            </Tooltip>

            <BrushSizePicker />

            <Tooltip content={<Body size="xs">{TOOLTIP_TEXT.reset}</Body>} placement="top" offset={[0, 8]}>
              <Button
                className={styles.button}
                style="toolkit"
                leftIcon={<ResetBackToolIcon />}
                size="small"
                disabled={isUpdatingImage}
                onClick={() => setLines([])}
              >
                Reset
              </Button>
            </Tooltip>

            <Separator orientation="vertical" className={styles.separator} />

            <Tooltip content={<Body size="xs">{TOOLTIP_TEXT.enhance}</Body>} placement="top" offset={[0, 8]}>
              <Button
                className={styles.button}
                style="toolkit"
                leftIcon={<EnhanceStarPlusToolIcon />}
                size="small"
                onClick={handleEnhanceImage}
                active={isEnhancingImage}
                disabled={isUpdatingImage}
              >
                Enhance
              </Button>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}
