import {ChatAction, ChatActionTypes, ChatState, UpdateConversationPayload} from "@/reducer/chat-reducer";
import {Dispatch, useCallback, useEffect} from "react";
import {useMutation} from "@apollo/client";
import {CHAT_UPDATE_CONVERSATION} from "@/graphql/mutations/ai-mutations";
import {ChatUpdateConversationResponse, ChatUpdateConversationVariables} from "../chat-conversation-context";
import {useToastContext} from "@/context/toast-context";

export const useUpdateConversation = (state: ChatState, dispatch: Dispatch<ChatAction>) => {
  const {
    changes,
    original,
    actions: {
      isUpdating,
      isCreating,
    },
  } = state;
  const {updateToast} = useToastContext();

  const [updateChatConversation] = useMutation<ChatUpdateConversationResponse, ChatUpdateConversationVariables>(CHAT_UPDATE_CONVERSATION);

  useEffect(() => {
    if (!original || isCreating) {
      return;
    }

    if (isUpdating) {
      updateChatConversation({
        variables: {conversationId: original.id, changes},
        onCompleted: ({chatUpdateConversation}) => {
          dispatch({type: ChatActionTypes.SET_CONVERSATION, payload: {conversation: chatUpdateConversation}});
        },
        onError: () => {
          dispatch({type: ChatActionTypes.REVERT_ACTION, payload: {action: ChatActionTypes.UPDATE_CONVERSATION}});
          updateToast({
            type: "failure",
            description: "Failed to update conversation",
          });
        },
      });
    }
  }, [isUpdating]);

  return useCallback((input: UpdateConversationPayload) => {
    if (isUpdating) {
      return;
    }

    dispatch({type: ChatActionTypes.UPDATE_CONVERSATION, payload: input});
  }, [isUpdating]);
}
