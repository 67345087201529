import classNames from "classnames/bind";
import React, {Fragment, ReactElement} from "react";

import {Body} from "../../../../../shared/v2";
import {GroundingLink, GroundingLinkProps} from "./grounding-link";

import styles from "./grounding-entry.module.scss";

const cx = classNames.bind(styles);

export interface GroundingBaseProps {
  links: GroundingLinkProps[];
  position: number;
  label: string;
	tooltipInside?: boolean;
}

export const GroundingBase = ({links, position, label, tooltipInside}: GroundingBaseProps): ReactElement => {
	return (
		<Body size="xs" color="text-tertiary" className={styles.grounding}>
			<span className={styles.type}>
				<sup>{position}</sup>{label}
			</span>
			<span className={styles.links}>
				{links.map(({className, ...linkProps}, index) => (
					<Fragment key={index}>
						{index > 0 && <span className={styles.linkSeparator}>/</span>}
						<GroundingLink
							className={cx("link", className)}
							tooltipInside={tooltipInside}
							{...linkProps}
						/>
					</Fragment>
				))}
			</span>
		</Body>
	);
}
