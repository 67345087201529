import {AGENTS_PUBLISHED} from "@/graphql/queries/ai-models-queries";
import { useQuery } from "@apollo/client";
import { useWorkspaceContext } from "@/context/workspace-context";
import {AgentsPublishedResponse, AgentsPublishedVariables} from "@/models/persona";

export const usePublishedAgents = () => {
  const {workspace: {id: workspaceId}} = useWorkspaceContext();

  const {
		data: {publishedAgents: personas = []} = {},
		loading: isLoading,
		refetch,
	} = useQuery<AgentsPublishedResponse, AgentsPublishedVariables>(AGENTS_PUBLISHED, {
		variables: {workspaceId},
    fetchPolicy: "cache-first",
	});

  return {
    personas,
    isLoading,
    refetch,
  }
}
