import {Label} from "@/datasets/components/manage-labels-modal/labels-form";
import {TranscodingStatus} from "./answer";
import {GenericPage, IdName} from "./generic";
import {Persona} from "./persona";
import {UploadResult} from "./upload-result";
import {User} from "./user";
import {Workspace} from "./workspace";
import {Question} from "./questions";

export interface AIModelCategory {
	id: string;
	name: string;
	description: string;
}

export interface AIModel {
	id: string;
	name: string;
	description: string;
	provider: string;
	useCase: string;
	task: "foundation" | "transcript" | "question" | "campaign" | "creator" | "answer-video" |
	"language" |"speech" | "multimodal" | "video" | "document" | "image" | "generative";
	modality: "foundation" | "transcript" | "question" | "campaign" | "creator" | "answer-video" |
	"language" |"speech" | "multimodal" | "video" | "document" | "image" | "generative";
	status: "deployed" | "beta" | "coming-soon";
	modelCategory: AIModelCategory;
}

export interface CategoryWithAIModels {
	id: string;
	name: string;
	description: string;
	aiModels: AIModel[];
}

export interface Tag {
	id: string;
	key: string;
	value: string;
}

export interface TrainingSet {
	__typename: "TrainingSet";
	id: string;
	name: string;
	alias: string;
	description?: string;
	files: TrainingSetFile[];
	videos: TrainingSetVideo[];
}

export interface TrainingSetPageData {
	trainingSetsPage: GenericPage<TrainingSet>;
}

export interface GetTrainingSetsResponse {
	trainingSets: TrainingSet[];
}

export interface TrainingSetsFilter {
	alias?: string;
}

export enum TrainingSetsSort {
	DEFAULT = "DEFAULT",
	ALIAS_ASC = "ALIAS_ASC",
	ALIAS_DESC = "ALIAS_DESC",
	CREATED_AT_ASC = "CREATED_AT_ASC",
	CREATED_AT_DESC = "CREATED_AT_DESC",
	UPDATED_AT_ASC = "UPDATED_AT_ASC",
	UPDATED_AT_DESC = "UPDATED_AT_DESC",
	MOST_FILES = "MOST_FILES",
	LEAST_FILES = "LEAST_FILES",
}

export interface GetTrainingSetsVariables {
  workspaceId: string;
}

export interface GetTrainingSetsPageVariables {
  workspaceId: string;
  limit: number;
  filter: TrainingSetsFilter;
  cursor: string;
  sort: TrainingSetsSort;
}

export enum FileEmbeddingsGenerationStatus {
	// Uploaded successfully but not embedded
	UPLOADED = "UPLOADED",
	// In the process of embedding
	PROCESSING = "PROCESSING",
	// Embedding failed
	FAILED = "FAILED",
	// Embedding succeeded
	SUCCESS = "SUCCESS"
}

export interface TrainingSetFile {
	__typename: "File";
	id: string;
	originalFilename: string;
	originalMimetype: string;
	url: string;
	embeddingsGenerationStatus: FileEmbeddingsGenerationStatus;
	trainingSetId: string;
	tags: Tag[];
}

export interface TrainingSetVideo {
	__typename: "TrainingSetVideo";
	id: string;
	originalFilename: string;
	originalMimetype: string;
	url: string;
	transcodingStatus: TranscodingStatus;
	embeddingsGenerationStatus: FileEmbeddingsGenerationStatus;
	trainingSetId: string;
	tags: Tag[];
}

export type TrainingSetMedia = TrainingSetFile | TrainingSetVideo;

export interface FileWithUploadResult extends File {
	uploadResult: UploadResult;
	labels?: Label[];
}
export enum ChatConversationMessageRole {
	USER = "user",
	ASSISTANT = "assistant",
	SYSTEM = "system",
	GENERIC = "generic",
	FUNCTION = "function",
	MEMORY = "memory"
}


interface UserId {
	id: string;
}

export interface ChatConversationMessage {
	__typename: "ChatMessage";
	id: string;
	content: string;
	conversation: ChatConversation;
	conversationId: string;
	persona: Persona;
	survey: Campaign;
	surveyId?: string;
	role: ChatConversationMessageRole;
	type: string | null;
	position: number;
	suggestedFollowUps: string[];
	likedByUsers: UserId[];
	dislikedByUsers: UserId[];
	groundingData?: ChatMessageGroundingEntry[];
}

export interface ChatConversationPreview {
	images: string[];
	creator: {
		firstName: string;
		lastName: string;
	};
	content: string;
	createdAt: string;
}

export interface UpdateChatConversationChanges {
	name?: string;
	trainingSetIds?: string[];
	fileIds?: string[];
	videoIds?: string[];
	aiPersonaId?: string;
	surveyIds?: string[];
	isPreview?: boolean;
	questionIds?: string[];
}

export interface ChatCreateConversationVariables {
  workspaceId: string;
  name: string;
  trainingSetIds?: string[];
  fileIds?: string[];
  videoIds?: string[];
  questionIds?: string[];
  aiPersonaId?: string;
  surveyIds?: string[];
  isPreview?: boolean;
}

export interface ChatCreateConversationResponse {
  chatCreateConversation: ChatConversation;
}

export interface ChatConversation {
	id: string;
	createdAt: string;
	messages: ChatConversationMessage[];
	name: string;
	user: User;
	userId: string;
	workspace: Workspace;
	trainingSets: TrainingSet[];
	files: TrainingSetFile[];
	videos: TrainingSetVideo[];
	questions: Question[];
	aiPersona?: Persona;
	workspaceId: string;
	surveys?: Campaign[];
	preview?: ChatConversationPreview;
	isPreview?: boolean;
}

export interface Campaign {
	id: string;
	name: string;
	questionCount: number;
	totalVideoDuration: number;
	completedResponseCount: number
  responseCount: number;
}

export interface CampaignsPageData {
	campaigns: GenericPage<Campaign>;
}

export interface AiPersonaCategoryData {
	aiPersonaCategories: IdName[];
}

export interface AiPersonaByIdData {
	aiPersonaById: Persona;
}

export interface AiTool {
	id: string;
	name: string;
	code: string;
}

export interface AiToolsPageData {
	aiTools: GenericPage<AiTool>;
}

export interface QuestionSummaryGroundingDetails {
	questionId: string;
	surveyId: string;
	questionText: string;
}

export interface AnswerGroundingDetails {
	surveyId: string;
	surveyTitle: string;
	questionId: string;
	questionText: string;
	answerVideoThumbnailUrl: string;
	answerId: string;
	creatorTag: string;
}

export interface TrainingSetFileGroundingDetails {
	trainingSetId: string;
	trainingSetName: string;
	fileUrl: string;
	originalFilename: string;
	trainingSetDescription: string;
}

export interface Citation {
	startPos: number;
	endPos: number;
	claimText: string;
}

export interface ChatMessageGroundingBase {
	score: number;
  page?: number;
	citations: Citation[]
}

export interface QuestionSummaryGrounding extends ChatMessageGroundingBase {
	type: "questionSummary";
	questionSummary: QuestionSummaryGroundingDetails;
}

export interface AnswerGrounding extends ChatMessageGroundingBase {
	type: "answer";
	answer: AnswerGroundingDetails;
}

export interface TrainingSetFileGrounding extends ChatMessageGroundingBase {
	type: "trainingSetFile";
	trainingSetFile: TrainingSetFileGroundingDetails;
}

export interface TrainingSetVideoGrounding extends ChatMessageGroundingBase {
	type: "trainingSetVideo";
	trainingSetVideo: TrainingSetFileGroundingDetails;
}

export type ChatMessageGroundingEntry = QuestionSummaryGrounding | AnswerGrounding | TrainingSetFileGrounding | TrainingSetVideoGrounding;

export interface Prompt {
  id: string;
  name: string;
  description: string;
  template: string;
  type: string;
  workspaceId: string;
}

export interface AiSkill {
  id: string;
  name: string;
  description: string;
}

export interface ChatDeleteMessagePairResponse {
  chatDeleteMessagePair: {
    id: string;
    content: string;
    role: string;
    deletedAt: string;
  }[];
}

export interface ChatDeleteMessagePairVariables {
  messageId: string;
}

export enum SystemAgentCode {
  CREATE_CAMPAIGN = "create_campaign",
  CREATE_AGENT = "create_agent",
}

export interface SystemAgent {
  id: string;
  name: string;
  code: SystemAgentCode;
  description: string;
}

export interface SystemAgentsResponse {
	systemAgents: SystemAgent[];
}
