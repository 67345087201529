import React, {useEffect, useRef, useId} from "react";
import mermaid from "mermaid";
import styles from "./mermaid-renderer.module.scss";

// Added helper function to clean mermaid content by removing newlines within square brackets
function cleanMermaidContent(content: string): string {
  return content.replace(/(\[[^\]]*\n[^\]]*\])/g, match => match.replace(/\n/g, " "));
}

interface MermaidRendererProps {
  content: string;
}

// Initialize mermaid with recommended configuration
mermaid.initialize({
  startOnLoad: false,
  theme: "default",
  securityLevel: "loose",
  fontFamily: "inherit",
});

export const MermaidRenderer: React.FC<MermaidRendererProps> = ({content}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const uniqueId = useId().replace(/:/g, "-");

  useEffect(() => {
    const renderDiagram = async () => {
      if (!containerRef.current) return;

      try {
        // Clear previous content
        containerRef.current.innerHTML = "";
        
        // Clean the content before rendering
        const cleanedContent = cleanMermaidContent(content);
        
        // Render the diagram
        const {svg} = await mermaid.render(`mermaid-${uniqueId}`, cleanedContent);
        
        if (containerRef.current && svg) {
          containerRef.current.innerHTML = svg;
        }
      } catch (error) {
        console.error("Failed to render Mermaid diagram:", error);
        if (containerRef.current) {
          containerRef.current.innerHTML = "";
        }
      }
    };

    renderDiagram();
  }, [content, uniqueId]);

  return (
    <div 
      ref={containerRef} 
      className={styles.mermaidContainer}
    />
  );
}; 
