import React, { ReactElement } from "react";

import styles from "./CheckboxesSection.module.scss";
import { Body, Checkbox } from "@/shared/v2";
import { SelectValue } from "@/campaign/contexts/RecruitContext";

export interface ChecboxesSectionProps {
  collection: SelectValue[];
  selectedItems: SelectValue[];
  onChange: (items: SelectValue[]) => void;
}

export const CheckboxesSection = ({
  collection,
  selectedItems,
  onChange,
}: ChecboxesSectionProps): ReactElement => {
  const handleChange = (id: string) => {
    const allItem = collection.find((item) => item.id === "all");
    if (id === "all") {
      onChange([allItem!]);
      return;
    }

    const selectedItem = collection.find((item) => item.id === id);

    if (selectedItem) {
      if (selectedItems.some((item) => item.id === selectedItem.id)) {
        onChange(selectedItems.filter((item) => item.id !== selectedItem.id));
      } else {
        onChange(
          selectedItems.filter((item) => item.id !== "all").concat(selectedItem)
        );
      }
    }
  };

  return (
    <div className={styles.sectionItemCheckboxes}>
      {collection.map((item) => {
        return (
          <Checkbox
            key={item.id}
            checked={selectedItems.some(
              (companySizeItem) => companySizeItem.id === item.id
            )}
            text={<Body>{item.name}</Body>}
            onChange={() => handleChange(item.id)}
          />
        );
      })}
    </div>
  );
};
