import React from 'react';

import {TOOLTIP_DELAY_SHORT} from '@/shared/constants/constants';
import {Body, Subheader, Tooltip} from '@/shared/v2';
import {InformationCicrcleIcon} from '@/icons';

import styles from './auto-mode-tooltip.module.scss';

export interface AutoModeTooltipProps {
  offDescription?: string;
  className?: string;
}

export const AutoModeTooltip = ({
  offDescription = "Manually select a specific tool for your prompt (default)",
  className
}: AutoModeTooltipProps ) => {
  return <Tooltip
  startDelay={TOOLTIP_DELAY_SHORT}
  placement="top"
  className={styles.tooltipContent}
  maxWidth="none"
  content={
    <div className={styles.tooltipItems}>
      <div className={styles.modeSection}>
        <Subheader type="bold" size="base" color="text-body">
          Auto mode ON
        </Subheader>
        <Body size="s" color="text-body">
          Intelligently selects the best tools for your prompt
        </Body>
        
        <div className={styles.exampleSection}>
          <Body size="s" type="medium" color="text-secondary">
            Examples:
          </Body>
          <ul className={styles.exampleList}>
            <li>
              <Body size="s" color="text-secondary">
                <span>Show the latest social media trends on...</span> → Social media tools
              </Body>
            </li>
            <li>
              <Body size="s" color="text-secondary">
                <span>Create an image of...</span> → Image generation
              </Body>
            </li>
            <li>
              <Body size="s" color="text-secondary">
                <span>What's the latest news on...</span> → Web search
              </Body>
            </li>
          </ul>
        </div>
      </div>
      
      <div className={styles.divider}></div>
      
      <div className={styles.modeSection}>
        <Subheader type="bold" size="base" color="text-body">
          Auto mode OFF
        </Subheader>
        <Body size="s" color="text-secondary">
          {offDescription}
        </Body>
      </div>
    </div>
  }
>
  <InformationCicrcleIcon className={styles.infoIcon} />
</Tooltip>
}
