import {useToastContext} from "@/context/toast-context";
import {useWorkspaceContext} from "@/context/workspace-context";
import {CHAT_CREATE_CONVERSATION} from "@/graphql/mutations/ai-mutations";
import {ChatCreateConversationResponse, ChatCreateConversationVariables} from "@/models/ai-model";
import {ChatState, UpdateConversationPayload} from "@/reducer/chat-reducer";
import {ChatAction, ChatActionTypes} from "@/reducer/chat-reducer";
import {useMutation} from "@apollo/client";
import {Dispatch, useCallback, useEffect} from "react";
import {useSearchParams} from "react-router-dom";

export const useCreateConversation = (state: ChatState, dispatch: Dispatch<ChatAction>) => {
  const {workspace: {id: workspaceId}} = useWorkspaceContext();
  const {
    original,
    actions: {
      isCreating,
    },
    changes,
    current,
  } = state;
  const [searchParams, setSearchParams] = useSearchParams();
  const {updateToast} = useToastContext();

  const [createChatConversation] =
    useMutation<ChatCreateConversationResponse, ChatCreateConversationVariables>(CHAT_CREATE_CONVERSATION);

  useEffect(() => {
    if (isCreating) {
      createChatConversation({
        variables: {
          workspaceId,
          ...changes,
          name: "Untitled chat",
        },
        onCompleted: ({chatCreateConversation}) => {
          searchParams.set("canvasId", chatCreateConversation.id);
          setSearchParams(searchParams);
        },
        onError: () => {
          dispatch({type: ChatActionTypes.REVERT_ACTION, payload: {action: ChatActionTypes.CREATE_CONVERSATION}});
          updateToast({
            type: "failure",
            description: "Failed to create conversation",
          });
        },
      });
    }
  }, [isCreating, changes]);

  return useCallback((input?: UpdateConversationPayload) => {
    if (isCreating) {
      return;
    }

    if (original) {
      throw new Error("Conversation already exists");
    }

    dispatch({type: ChatActionTypes.CREATE_CONVERSATION, payload: input});
  }, [isCreating, current, original]);
}
