import React, {ReactElement, useCallback, useEffect, useMemo, useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import classNames from "classnames/bind";

import {Body, ButtonIcon, Dropdown, Tooltip} from "../../shared/v2";
import {BinDeleteIcon, CopyDocumentsIcon, HornMegaphoneCampaignIcon, LightingBoltStrikeIcon, MusicSoundWaveReadingIcon, PersonaIcon, ThumbsDownDislikeIcon, ThumbsUpLikeIcon} from "../../icons";
import {useToastContext} from "../../context/toast-context";
import {SystemAgentCode, SystemAgentsResponse} from "../../models/ai-model";
import {
	CHAT_DISLIKE_MESSAGE,
	CHAT_LIKE_MESSAGE,
	CHAT_REMOVE_MESSAGE_REACTION
} from "../../graphql/mutations/ai-mutations";
import {useUserContext} from "../../context/user-context";
import Config from "../../config";
import {AudioPlayer} from '../../shared/v2/audio-player/index';
import {useThemeMode} from "../../context/theme-mode-context";
import {useChatConversationContext} from "../../context/chat-contexts";
import {ConfirmActionModal} from "@/shared/v2/modals/confirm-action-modal";
import {useCitationContext} from "../chat/response-bubble/content/contexts/citation-context";
import {SYSTEM_AGENTS} from "@/graphql/queries/ai-models-queries";
import {ChatQueryMessage, ChatResponseMessage} from "@/reducer/chat-reducer";

import styles from "./chat-actions.module.scss";

const bStyles = classNames.bind(styles);

interface ButtonTooltipProps {
	icon: ReactElement;
	text: string;
	onClick?: (event) => void;
	iconClassName?: string;
}

export const ButtonTooltip = ({icon, text, onClick, iconClassName}: ButtonTooltipProps) => {
	return (
		<Tooltip
			content={
				<Body size="xs" color="text-tertiary">
					{text}
				</Body>
			}
			placement={"top"}
		>
			<ButtonIcon
				size="small"
				color="gray-modern"
				className={iconClassName}
				icon={icon}
				onClick={onClick}
			/>
		</Tooltip>
	);
};

export interface ChatActionProps {
	className?: string;
	message: ChatQueryMessage | ChatResponseMessage;
	hideAudio?: boolean;
	hideCopy?: boolean;
	hideLike?: boolean;
	hideDislike?: boolean;
	hideCitations?: boolean;
	voiceId?: string;
}

export const ChatActions = ({
	className,
	message,
	hideCopy,
	hideLike,
	hideDislike,
	hideCitations,
	voiceId,
}: ChatActionProps): ReactElement => {
	const {deleteMessage, send: sendQuestion} = useChatConversationContext();

	const {isDarkMode} = useThemeMode();
	const {updateToast} = useToastContext();
	const {user, isEnterpriseManagerOrSupport} = useUserContext();
	const {isCitationsVisible, toggleCitationsVisibility} = useCitationContext();

	const {data: {
		systemAgents = []
	} = {}} = useQuery<SystemAgentsResponse>(SYSTEM_AGENTS, {
		fetchPolicy: "cache-first",
	});

	const [likeMessage] = useMutation(CHAT_LIKE_MESSAGE);
	const [dislikeMessage] = useMutation(CHAT_DISLIKE_MESSAGE);
	const [removeMessageReaction] = useMutation(CHAT_REMOVE_MESSAGE_REACTION);

	const [isLiked, setIsLiked] = useState(false);
	const [isDisliked, setIsDisliked] = useState(false);
	const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

	const like = () => {
		setIsLiked(true);
		setIsDisliked(false);
	};

	const dislike = () => {
		setIsDisliked(true);
		setIsLiked(false);
	}

	const isInLikedByUser = useMemo(() => {
		return message?.likedByUsers?.map((user) => user.id).includes(user.id);
	}, [message?.likedByUsers?.length]);

	const isInDislikedByUser = useMemo(() => {
		return message?.dislikedByUsers?.map((user) => user.id).includes(user.id);
	}, [message?.dislikedByUsers?.length]);

	useEffect(() => {
		if (isInLikedByUser) {
			like();
		} else {
			setIsLiked(false);
		}
	}, [isInLikedByUser]);

	useEffect(() => {
		if (isInDislikedByUser) {
			dislike();
		} else {
			setIsDisliked(false);
		}
	}, [isInDislikedByUser]);

	const handleCopy = (event) => {
		event.stopPropagation();
		navigator.clipboard.writeText(message.content);
		updateToast({
			description: "Copied to clipboard",
			type: "informational",
		});
	};

	const handleToggleCitations = (event) => {
		event.stopPropagation();
		toggleCitationsVisibility();
		updateToast({
			description: isCitationsVisible ? "Citations hidden" : "Citations shown",
			type: "informational",
		});
	};

	const handleLike = (event) => {
		event.stopPropagation();
		like();
		likeMessage({
			variables: {
				messageId: message.id,
			},
			onCompleted: () => {
				updateToast({
					description: "Message liked",
					type: "success",
				});
			},
			onError: (error) => {
				setIsLiked(false);
				updateToast({
					description: error.message,
					type: "failure",
				});
			},
		});
	};

	const handleDislike = (event) => {
		event.stopPropagation();
		dislike();
		dislikeMessage({
			variables: {
				messageId: message.id,
			},
			onCompleted: () => {
				updateToast({
					description: "Message disliked",
					type: "success",
				});
			},
			onError: (error) => {
				setIsDisliked(false);
				updateToast({
					description: error.message,
					type: "failure",
				});
			},
		});
	};

	const handleRemoveReaction = (reaction: "like" | "dislike") => {
		if (reaction === "like") {
			setIsLiked(false);
		} else {
			setIsDisliked(false);
		}

		removeMessageReaction({
			variables: {
				messageId: message.id,
			},
			onCompleted: () => {
				updateToast({
					description: "Reaction removed",
					type: "success",
				});
			},
			onError: (error) => {
				updateToast({
					description: error.message,
					type: "failure",
				});
			},
		});
	};


	const handleConfirmDelete = () => {
		deleteMessage(message.id, {onSuccess: () => setIsDeleteConfirmOpen(false)});
	}

	const renderLikeIcon = useCallback(() => {
		if (isLiked) {
			return (
				<ButtonTooltip
					iconClassName={bStyles("likeIcon", "filled")}
					icon={<ThumbsUpLikeIcon />}
					text="Message liked. Click to remove reaction"
					onClick={() => handleRemoveReaction("like")}
				/>
			);
		}
		return (
			<ButtonTooltip
				iconClassName={styles.likeIcon}
				icon={<ThumbsUpLikeIcon />}
				text="Good response"
				onClick={handleLike}
			/>
		);
	}, [isLiked, user.id]);

	const renderDislikeIcon = useCallback(() => {
		if (isDisliked) {
			return (
				<ButtonTooltip
					iconClassName={bStyles("likeIcon", "filled")}
					icon={<ThumbsDownDislikeIcon />}
					text="Message disliked. Click to remove reaction"
					onClick={() => handleRemoveReaction("dislike")}
				/>
			);
		}
		return (
			<ButtonTooltip
				iconClassName={styles.likeIcon}
				icon={<ThumbsDownDislikeIcon />}
				text="Bad response"
				onClick={handleDislike}
			/>
		);
	}, [isDisliked, user.id]);

	const handleGenerateCampaign = () => {
		const campaignSystemAgent = systemAgents?.find(agent => agent.code === SystemAgentCode.CREATE_CAMPAIGN);

		if (campaignSystemAgent) {
			sendQuestion({
				query: `Generate a campaign using insights from our last conversation. Previous response: ${message.content}`,
				nextQuestionInput: {message, systemAgent: campaignSystemAgent}
			})
		}
	}

	const handleGenerateAgent = () => {
		const agentSystemAgent = systemAgents?.find(agent => agent.code === SystemAgentCode.CREATE_AGENT);

		if (agentSystemAgent) {
			sendQuestion({
				query: `Generate an agent based on that response. Previous response: ${message.content}`,
				nextQuestionInput: {message, systemAgent: agentSystemAgent}
			})
		}
	}

	return (
		<>
			<div className={bStyles("wrapper", className, {isDarkMode})}>
				{!hideCitations && message.groundingData && message.groundingData.length > 0 && (
					<ButtonTooltip
						icon={<CitationIcon />}
						text={isCitationsVisible ? "Hide citations" : "Show citations"}
						onClick={handleToggleCitations}
						iconClassName={bStyles("citationIcon", { active: isCitationsVisible })}
					/>
				)}
				{hideLike ? undefined : renderLikeIcon()}
				{hideDislike ? undefined : renderDislikeIcon()}
				{hideCopy ? undefined : <ButtonTooltip icon={<CopyDocumentsIcon />} text="Copy" onClick={handleCopy} />}
				{(message?.persona?.isVurvey || isEnterpriseManagerOrSupport) && <ChatAudioPlayer message={message} voiceId={voiceId} />}
				{message.type !== "image" &&
					<Dropdown
						trigger={
							<ButtonTooltip
								icon={<LightingBoltStrikeIcon />}
								text="More actions"
								iconClassName={bStyles("lightingIcon", {isDarkMode})}
							/>
						}
						items={[
							{
								label: "Generate Campaign",
								icon: <HornMegaphoneCampaignIcon />,
								onClick: handleGenerateCampaign,
							},
							{
								label: "Create Agent",
								icon: <PersonaIcon />,
								onClick: handleGenerateAgent
							}
						]}
						position="top-start"
					/>
				}
				<ButtonTooltip
					icon={<BinDeleteIcon />}
					text="Delete message"
					onClick={() => setIsDeleteConfirmOpen(true)}
				/>
			</div>
				<ConfirmActionModal
					portal
					title="Are You Sure?"
					description="This will delete both your message and the assistant's response. Are you sure you want to continue?"
					isOpen={isDeleteConfirmOpen}
					onClose={() => setIsDeleteConfirmOpen(false)}
					onConfirm={handleConfirmDelete}
				/>
		</>
	);
};

export interface ChatAudioPlayerProps {
  message: ChatQueryMessage | ChatResponseMessage;
  voiceId?: string;
}

export const ChatAudioPlayer = ({message, voiceId}: ChatAudioPlayerProps) => {
	const apiUrl = `${Config.apiHost}/rest/tts?text=${encodeURIComponent(message.content)}&voice=${encodeURIComponent(message.persona?.voiceId || voiceId || "")}`;
	const safeUrl = encodeURI(apiUrl.replace(/#/g, "").replace(/\*/g, ""));
	const [isOpen, setIsOpen] = useState(false);

	if (isOpen) {
		return <AudioPlayer apiUrl={safeUrl} onClose={() => setIsOpen(false)} />
	}

	return <ButtonTooltip icon={<MusicSoundWaveReadingIcon />} text="Listen " onClick={() => setIsOpen(true)} />
}

// Simple citation icon component
const CitationIcon = () => (
	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M3 5H13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
		<path d="M3 8H13" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
		<path d="M3 11H9" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"/>
	</svg>
);
