import React, {ReactElement} from "react";

import {AgentCard} from "../../../../agents/components/agent-card";
import {useUnsafeAgentCreationContext} from "../../../../context/agent-creation-context";
import {Avatar, Tooltip} from "../../../../shared/v2";
import {AvatarProps} from "../../../../shared/v2/avatar";
import {ChatFakeResponseMessage, ChatResponseMessage} from "@/reducer/chat-reducer";

import styles from "./response-avatar.module.scss";
import {useThemeMode} from "@/context/theme-mode-context";
import {VurveyLabsIconBlack, VurveyLabsIconVolt} from "@/icons";

export interface ResponseAvatarProps {
  message: ChatResponseMessage | ChatFakeResponseMessage;
}

export const ResponseAvatar = ({message}: ResponseAvatarProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {state: {picture: agentCreationPicture, name: agentCreationName} = {}} =
    useUnsafeAgentCreationContext() ?? {};

	const avatarProps: Pick<AvatarProps, "size" | "type"> = {
		size: "md",
		type: "square"
	};

	if (message.persona) {
		let content = <Avatar firstName={message.persona.name} {...avatarProps} />;

		// Use picture.url if available, fall back to avatarUri
		const imageUrl = message.persona.picture?.url || message.persona.avatarUri;
		if (imageUrl) {
			content = <Avatar key={imageUrl} {...avatarProps} url={imageUrl} />;
		}
		return (
			<Tooltip
				placement="top-start"
				containerClassname={styles.tooltip}
				custom
				content={<AgentCard className={styles.agentCard} persona={message.persona} />}
			>
				{content}
			</Tooltip>
		);
	} else if (agentCreationPicture) {
		return <Avatar key={agentCreationPicture.url} {...avatarProps} url={agentCreationPicture.url} />;
	} else if (agentCreationName) {
		return <Avatar firstName={agentCreationName} {...avatarProps} />;
	}

	return isDarkMode ? <VurveyLabsIconVolt className={styles.avatar} /> : <VurveyLabsIconBlack className={styles.avatar} />
}
