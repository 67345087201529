import React, {ReactElement, ReactNode, createContext, useContext, useState} from "react";

export interface CitationContextProps {
  children?: ReactNode;
  messageId?: string;
}

export interface CitationContextValue {
  isCitationsVisible: boolean;
  messageId?: string; // Add messageId to track which message this context belongs to
  toggleCitationsVisibility: () => void;
}

export const CitationContext =
  createContext<CitationContextValue | undefined>(undefined);

export const CitationContextProvider = (
  {children, messageId}: CitationContextProps,
): ReactElement => {
  const [isCitationsVisible, setIsCitationsVisible] = useState(false);

  const toggleCitationsVisibility = () => {
    setIsCitationsVisible(prev => !prev);
  };

  return (
    <CitationContext.Provider value={{isCitationsVisible, toggleCitationsVisibility, messageId}}>
      {children}
    </CitationContext.Provider>
  );
};

export const useCitationContext = (): CitationContextValue => {
  const context = useContext(CitationContext);

  if (context === undefined) {
    throw new Error(
      "useCitationContext must be used within a CitationContextProvider",
    );
  }

  return context;
};
