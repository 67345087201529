import {Option, SelectInput} from '@/shared/v2/inputs/select-input';
import React, { ReactElement } from 'react';


interface CampaingsSortProps {
    value: string;
    onChange: (value: string) => void;
    className?: string;
}

export const CampaingsSort = ({
    value,
    onChange,
    className
}: CampaingsSortProps): ReactElement => {
  const sortOptions: Option[] = [
    { name: 'Name (A-Z)', id: 'NAME_ASC' },
    { name: 'Name (Z-A)', id: 'NAME_DESC' },
    { name: "Most Recently Updated", id: "UPDATED_AT_DESC" },
    { name: "Oldest Updated", id: "UPDATED_AT_ASC" },
    { name: 'Newest Opened', id: 'OPEN_AT_DESC' },
    { name: 'Oldest Opened', id: 'OPEN_AT_ASC' },
    { name: 'Most Recently Closed', id: 'CLOSED_AT_DESC' },
    { name: 'Oldest Closed', id: 'CLOSED_AT_ASC' },
    { name: 'Lowest Question Count', id: 'QUESTIONS_CNT_ASC' },
    { name: 'Highest Question Count', id: 'QUESTIONS_CNT_DESC' },
    { name: 'Lowest Responses Count', id: 'RESPONSES_CNT_ASC' },
    { name: 'Highest Responses Count', id: 'RESPONSES_CNT_DESC' },
    { name: 'Lowest Highlight Count', id: 'HIGHLIGHT_CNT_ASC' },
    { name: 'Highest Highlight Count', id: 'HIGHLIGHT_CNT_DESC' },
    { name: 'Lowest Video Minutes', id: 'VIDEO_MINUTES_CNT_ASC' },
    { name: 'Highest Video Minutes', id: 'VIDEO_MINUTES_CNT_DESC' },
  ];
  return (
      <SelectInput
        className={className}
        options={sortOptions}
        placeholder="Sort"
        onChange={onChange}
        value={value}
      />
  );
};
