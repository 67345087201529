import React, {FormEvent, HTMLProps, ReactElement, useState} from "react";
import classNames from "classnames/bind";

import {InputElements} from "../input-elements";
import {UseValidationResult} from "../../../../hooks/useValidation";
import {useThemeMode} from "../../../../context/theme-mode-context";
import {FullScreenExpandIcon} from "../../../../icons";

import styles from "../input/input.module.scss";
import {Modal} from "../../modals/modal";
import {Button} from "../../buttons";

const cx = classNames.bind(styles);

export type TextAreaProps = Omit<HTMLProps<HTMLTextAreaElement>, "size" | "value" | "onChange" | "onBlur"> & {
	variant?: "default" | "error",
	size?: "small" | "medium"
	label?: string;
	hintText?: string;
	onBlur?: (value: string) => void;
	onChange: (value: string) => void;
	validation?: UseValidationResult;
	value: string;
  isExpandable?: boolean;
	refProp?: any;
}

export const TextArea = ({
	value,
	onChange,
	className,
	size = "small",
	variant = "default",
	label,
	hintText,
	validation,
	isExpandable,
	refProp,
	onBlur,
	...props
}: TextAreaProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const [isExpanded, setIsExpanded] = useState(false);

	const toggleExpand = () => {
		setIsExpanded(!isExpanded);
	}

	const renderTextArea = (fullScreen?) => {
		return <textarea
			className={cx(
				"input",
				"textarea",
				size,
				validation?.isError ? "error" : variant,
				{isExpandable, "expanded": fullScreen}
			)}
			onWheel={(e) => {
				const isOverflown = e.currentTarget.scrollHeight > e.currentTarget.clientHeight;
				if (isOverflown) {
					e.stopPropagation();
				}
			}}
			value={value}
			onChange={(e: FormEvent<HTMLTextAreaElement>) => onChange(e.currentTarget.value)}
			onBlur={() => onBlur?.(value)}
			ref={refProp}
			{...props}
		/>
	}

	return <>
		<InputElements
			variant={variant}
			className={className}
			label={label}
			hintText={hintText}
			validation={validation}
		>
			<>
			{renderTextArea()}
				{isExpandable && <FullScreenExpandIcon
					className={cx("expandIcon", {isDarkMode})}
					onClick={toggleExpand}
				/>}
			</>
		</InputElements>

		<Modal
			size="large"
			title={label}
			onClose={toggleExpand}
			isOpen={isExpanded}
			portal
		>
			<InputElements
				variant={variant}
				className={className}
				hintText={hintText}
				validation={validation}
			>
				<>
				{renderTextArea(true)}
				</>
			</InputElements>

			<Button className={styles.closeButton} onClick={toggleExpand}>
				Close
			</Button>
		</Modal>
	</>
}
