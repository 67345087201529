import {ArgumentArray} from "classnames";
import classNames from "classnames/bind";
import React, {cloneElement, ReactElement, useMemo} from "react";

import {Campaign, TrainingSetFile, TrainingSetMedia, TrainingSetVideo} from "../../../models/ai-model";
import {CHAT_TOOLS_ICONS, CHAT_TOOLS_NAMES} from "@/shared/constants/constants";
import {ChatBubbleQuestionIcon, DocumentsFolderFileIcon, FileTextIcon, FileUploadIcon, FileVideoIcon, HornMegaphoneCampaignIcon} from "../../../icons";
import {useChatConversationContext} from "../../../context/chat-contexts";
import {InputChip} from "../../../shared/components/input-chip";
import {isQuestion, isTrainingSet, isTrainingSetFile, isTrainingSetVideo} from "@/shared";
import {Question} from "@/models/questions";
import {TrainingSet} from "../../../models";
import {ChatToolValue, ChatImageTool, ChatSocialTool, ChatWebTool} from "@/reducer/chat-reducer";

import styles from "./sources-section.module.scss";

const cx = classNames.bind(styles);

export type ChatChipType = ChatToolValue | Question | TrainingSet | TrainingSetMedia | Campaign;

export const isTool = (source: ChatChipType): source is ChatToolValue => {
	return typeof source === "string";
}

export interface SourcesSectionProps {
	hideIfEmpty?: boolean;
	className?: string;
}

export const SourcesSection = ({hideIfEmpty, className}: SourcesSectionProps): ReactElement => {
	const {
		conversation: {
			surveys: activeCampaigns,
			questions: activeQuestions,
			trainingSets: activeTrainingSets,
			files: activeFiles,
			videos: activeVideos,
			nextQuestion: {
				document: activeUpload,
			},
			tool: activeTool,
		},
		update: updateConversation,
		isUpdatingDisabled,
	} = useChatConversationContext();

	const handleRemoveTrainingSet = (trainingSet: TrainingSet) => {
		updateConversation({
			trainingSets: activeTrainingSets.filter(ts => ts.id !== trainingSet.id),
		})
	}

	const handleRemoveQuestion = (question: Question) => {
		updateConversation({
			questions: activeQuestions.filter(q => q.id !== question.id),
		})
	}

	const handleRemoveVideo = (video: TrainingSetVideo) => {
		updateConversation({
			videos: activeVideos.filter(v => v.id !== video.id),
		})
	}

	const handleRemoveDocument = () => {
		updateConversation({nextQuestion: {document: null}});
	}

	const handleRemoveTool = () => {
		updateConversation({tool: null});
	}

	const handleRemoveFile = (file: TrainingSetFile) => {
		updateConversation({
			files: activeFiles.filter(f => f.id !== file.id),
		});
	}

	const handleRemoveCampaign = (campaign: Campaign) => {
		updateConversation({
			surveys: activeCampaigns.filter(c => c.id !== campaign.id),
		});
	}

	const sources = useMemo(() => {
		const activeTools = activeTool ? [activeTool] : [];
		const activeUploads = activeUpload ? [activeUpload] : [];

		return [
			...activeTools,
			...activeUploads,
			...activeQuestions,
			...(activeTrainingSets || []),
			...activeCampaigns,
			...activeFiles,
			...activeVideos,
		];
	}, [
		activeCampaigns,
		activeFiles,
		activeQuestions,
		activeTool,
		activeTrainingSets,
		activeUpload,
		activeVideos,
	]);

	const CLASS_NAMES = {
    [ChatImageTool.DALLE]: styles.imageDE,
    [ChatImageTool.IMAGEN]: styles.imageImagen,
    [ChatImageTool.STABLE_DIFFUSION]: styles.imageSD,
    [ChatSocialTool.INSTAGRAM]: styles.socialInstagram,
    [ChatSocialTool.LINKEDIN]: styles.socialLinkedin,
    [ChatSocialTool.REDDIT]: styles.socialReddit,
    [ChatSocialTool.TIKTOK]: styles.socialTiktok,
    [ChatSocialTool.X_TWITTER]: styles.socialXTwitter,
    [ChatSocialTool.YOUTUBE]: styles.socialYoutube,
    [ChatWebTool.SCRAPE_ADVANCED]: styles.web,
    [ChatWebTool.SCRAPE]: styles.web,
    [ChatWebTool.WEB]: styles.web,
  }

	const getInputChipProps = (classNames?: ArgumentArray) => ({
		variant: "squared",
		className: cx("source", ...(classNames || [])),
		size: "small",
	} as const);

	const renderSource = (source: ChatChipType) => {
		if (isTool(source)) {
			return (
				<InputChip
					icon={cloneElement(CHAT_TOOLS_ICONS[source], {className: styles.chipIcon})}
					key="tool"
					onRemove={handleRemoveTool}
					label={CHAT_TOOLS_NAMES[source]}
					{...getInputChipProps(["tool", CLASS_NAMES[source]])}
				/>
			)
		}
		if (isTrainingSetFile(source) && source.id === activeUpload?.id) {
			return (
				<InputChip
					icon={<FileUploadIcon />}
					key="file-uppload"
					onRemove={handleRemoveDocument}
					label={source.originalFilename}
					{...getInputChipProps()}
				/>
			)
		}
		if (isQuestion(source)) {
			return (
				<InputChip
					icon={<ChatBubbleQuestionIcon />}
					key={source.id}
					onRemove={() => handleRemoveQuestion(source)}
					showCloseIcon={!(isUpdatingDisabled || Boolean(activeTool))}
					label={source.text}
					{...getInputChipProps()}
				/>
			)
		}
		if (isTrainingSet(source)) {
			return (
				<InputChip
					icon={<DocumentsFolderFileIcon />}
					key={source.id}
					onRemove={() => handleRemoveTrainingSet(source)}
					showCloseIcon={!(isUpdatingDisabled || Boolean(activeTool))}
					label={source.alias}
					{...getInputChipProps()}
				/>
			);
		}
		if (isTrainingSetVideo(source)) {
			return (
				<InputChip
					icon={<FileVideoIcon />}
					key={source.id}
					showCloseIcon={!(isUpdatingDisabled || Boolean(activeTool))}
					onRemove={() => handleRemoveVideo(source)}
					label={source.originalFilename}
					{...getInputChipProps()}
				/>
			);
		}
		if (isTrainingSetFile(source)) {
			return (
				<InputChip
					icon={<FileTextIcon />}
					key={source.id}
					showCloseIcon={!(isUpdatingDisabled || Boolean(activeTool))}
					onRemove={() => handleRemoveFile(source)}
					label={source.originalFilename}
					{...getInputChipProps()}
				/>
			);
		}

		return (
			<InputChip
				icon={<HornMegaphoneCampaignIcon />}
				key={source.id}
				showCloseIcon={!(isUpdatingDisabled || Boolean(activeTool))}
				onRemove={() => handleRemoveCampaign(source)}
				label={source.name}
				{...getInputChipProps()}
			/>
		);
	};

	if (!sources.length || (hideIfEmpty && !sources.length)) {
		return <></>;
	}

	return (
		<div className={cx("sourcesSection", className)}>
			{sources.map(renderSource)}
		</div>
	);
}
