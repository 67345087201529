import React, {ReactElement, ReactNode, createContext, useEffect, useMemo, useState} from "react";

import {Question} from "@/models/questions";
import {usePropsContext} from "./props-context";

export interface SelectedQuestionsContextValue {
  selected: Question[];
  isDirty: boolean;
  add: (source: Question[]) => void;
  remove: (source: Question[]) => void;
  set: (sources: Question[]) => void;
}

export const SelectedQuestionsContext =
  createContext<SelectedQuestionsContextValue | undefined>(undefined);

export const SelectedQuestionsContextProvider = (
  {children}: {children: ReactNode},
): ReactElement => {
  const {
    init: {
      questions: init,
    }
  } = usePropsContext();
  const [selected, setSelected] = useState<Question[]>([...init]);

  useEffect(() => {
    setSelected([...init]);
  }, [init]);

  const isDirty = useMemo(() => {
    return isChanged(init, selected);
  }, [init, selected]);

  const addCampaigns = (questions: Question[]): void => {
    setSelected([...selected, ...questions]);
  }

  const removeCampaigns = (questions: Question[]): void => {
    setSelected(selected.filter(({id: selectedCampaignId}) => !questions.find(q => q.id === selectedCampaignId)));
  }

  return (
    <SelectedQuestionsContext.Provider value={{
      selected,
      isDirty,
      add: addCampaigns,
      remove: removeCampaigns,
      set: setSelected,
    }}>
      {children}
    </SelectedQuestionsContext.Provider>
  );
};

export const useSelectedQuestionsContext = (): SelectedQuestionsContextValue => {
  const context = React.useContext(SelectedQuestionsContext);

  if (context === undefined) {
    throw new Error(
      "useSelectedQuestionsContext must be used within a SelectedQuestionsContextProvider",
    );
  }

  return context;
};

function isChanged(original: Question[], current: Question[]): boolean {
  const originalIds = original.map((question) => question.id);

  return (
    original.length !== current.length ||
    current.some(({id}) => !originalIds.includes(id))
  );
}
