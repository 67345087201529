import {AiOrchestration, AiPersonaTask, AiPersonaTaskOperation} from "@/models/ai-orchestration";
import {UpdateAiPersonaTaskMutationInput, WorkflowChanges} from "@/reducer/workflow-reducer";

const getSettingsChanges = (original: AiOrchestration, current: AiOrchestration): WorkflowChanges["settings"] | undefined => {
  const SCALAR_FIELDS = [
    "name",
    "description",
    "inputParameters",
    "instructions",
  ];

  const ARRAY_FIELDS = [
    {fieldName: "files", idField: "fileIds"},
    {fieldName: "questions", idField: "questionIds"},
    {fieldName: "campaigns", idField: "surveyIds"},
    {fieldName: "trainingSets", idField: "trainingSetIds"},
    {fieldName: "videos", idField: "videoIds"},
  ];

  const OBJECT_FIELDS = [
    {fieldName: "outputType", idField: "outputTypeId"},
  ];

  let changes = {} as any;

  if (!original || !current) {
    return changes;
  }

  for (const field of SCALAR_FIELDS) {
    if (original[field] !== current[field]) {
      changes[field] = current[field];
    }
  }

  for (const {fieldName, idField} of ARRAY_FIELDS) {
    const originalArray = original[fieldName];
    const currentArray = current[fieldName];

    if (!originalArray && currentArray) {
      changes[idField] = currentArray.map(item => item.id);
      continue;
    }

    if (originalArray && !currentArray || !originalArray && !currentArray) {
      changes[idField] = [];
      continue;
    }

    if (
      original[fieldName].map(item => item.id).sort().join() !==
      current[fieldName].map(item => item.id).sort().join()
    ) {
      changes[idField] = current[fieldName].map(item => item.id);
    }
  }

  for (const {fieldName, idField} of OBJECT_FIELDS) {
    const originalObject = original[fieldName];
    const currentObject = current[fieldName];

    if (!originalObject && !currentObject) {
      continue;
    }

    if (!currentObject) {
      changes[idField] = null;
      continue;
    }

    if (!originalObject) {
      changes[idField] = currentObject.id;
      continue;
    }

    if (originalObject.id !== currentObject.id) {
      changes[idField] = currentObject?.id;
    }
  }

  if (Object.keys(changes).length > 0) {
    return {
      id: current.id,
      ...changes,
    } as WorkflowChanges["settings"];
  }

  return undefined;
}

const getTaskDiff = (original: AiPersonaTask, current: AiPersonaTask): UpdateAiPersonaTaskMutationInput | undefined  => {
  const changes = {} as any;

  if (original.index !== current.index) {
    changes.index = current.index;
  }

  if (original.smartPromptEnabled !== current.smartPromptEnabled) {
    changes.smartPromptEnabled = current.smartPromptEnabled;
  }

  if (current.task && original.task?.taskPrompt !== current.task?.taskPrompt) {
    changes.task = {
      taskPrompt: current.task.taskPrompt
    }
  }

  if (Object.keys(changes).length > 0) {
    return {
      id: current.id,
      ...changes,
    } as UpdateAiPersonaTaskMutationInput;
  }

  return undefined;
}

const getTasksChanges = (original: AiOrchestration, current: AiOrchestration): WorkflowChanges["tasks"] => {
  const originalTasks = original.aiPersonaTasks;
  const currentTasks = current.aiPersonaTasks;

  if (!originalTasks || !currentTasks) {
    return [];
  }

  const changes = [] as WorkflowChanges["tasks"];

  // Check for deleted tasks
  for (const task of originalTasks) {
    const currentTask = currentTasks.find(t => t.id === task.id);

    if (!currentTask) {
      changes.push({
        operation: AiPersonaTaskOperation.DELETE,
        input: {
          id: task.id,
        },
      });

      continue;
    }
  }

  // Check for created or updated tasks
  for (const task of currentTasks) {
    const originalTask = originalTasks.find(t => t.id === task.id);

    if (!originalTask) {
      changes.push({
        operation: AiPersonaTaskOperation.CREATE,
        input: {
          index: task.index,
          orchestrationId: current.id,
          personaId: task.persona?.id || "",
          smartPromptEnabled: task.smartPromptEnabled,
          task: {
            taskPrompt: task.task?.taskPrompt || "",
          },
        },
      });

      continue;
    }

    const diff = getTaskDiff(originalTask, task);

    if (diff) {
      changes.push({
        operation: AiPersonaTaskOperation.UPDATE,
        input: diff,
      });
    }
  }

  return changes;
};

export const extractWorkflowChanges = (original: AiOrchestration, current: AiOrchestration): WorkflowChanges => {
  return {
    settings: getSettingsChanges(original, current),
    tasks: getTasksChanges(original, current),
  }
}
