import React, { ReactElement } from "react";
import { QuestionBlock } from "./QuestionBlock";
import { BottomBar } from "./BottomBar";
import { Button } from "@/shared/v2";
import { PlusIcon } from "@/icons";
import styles from "./QualifyingQuestions.module.scss";
import { useRecruitQuestionsContext } from "@/campaign/contexts/RecruitQuestionsContext";
import { v4 as uuidv4 } from "uuid";
export const QualifyingQuestions = (): ReactElement => {
  const { questions, addQuestion } = useRecruitQuestionsContext();

  const handleAddQuestion = () => {
    addQuestion({
      question: "",
      answers: [
        {
          id: uuidv4(),
          text: "",
          type: "qualify",
          order: 0,
        },
        {
          id: uuidv4(),
          text: "",
          type: "disqualify",
          order: 1,
        },
      ],
      allowCustomAnswer: false,
      order: questions.length,
      type: "single",
    });
  };

  return (
    <>
      {questions.map((question, index) => (
        <QuestionBlock key={index} question={question} />
      ))}

      <Button
        className={styles.addQuestionButton}
        variant='outlined'
        onClick={handleAddQuestion}
        leftIcon={<PlusIcon />}>
        Add Question
      </Button>
      <BottomBar />
    </>
  );
};
