import React, {KeyboardEvent, ReactElement, useEffect, useState} from "react";

import styles from "./chat-name-pill.module.scss";
import {ButtonIcon} from "../../../shared/v2";
import {DotsIcon, PenEditIcon} from "../../../icons";
import {Subheader} from "../../../shared/v2/typography";
import {Dropdown} from "../../../shared/v2/dropdown";
import {
	useChatConversationContext
} from "../../../context/chat-contexts";
import {useThemeMode} from "../../../context/theme-mode-context";

import classNames from "classnames/bind";

const bStyles = classNames.bind(styles);

interface ChatNamePillProps {
	className?: string;
}

export const ChatNamePill = ({className}: ChatNamePillProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {
		conversation: chat,
		update: updateConversation,
		isUpdating,
	} = useChatConversationContext();
	const [name, setName] = useState(chat?.name ?? "Untitled chat");
	const [isEditing, setIsEditing] = useState(false);

	useEffect(() => {
		setName(chat?.name ?? "Untitled chat");
	}, [chat?.name]);

	const handleSave = () => {
		setIsEditing(false);
		updateConversation({
			name,
		});
	};

	const handleEnter = (event: KeyboardEvent<HTMLInputElement>) => {
		if (event.key === "Enter") {
			handleSave();
		}
	};

	return (
		<div
			className={bStyles("wrapper", className, {isEditing, isDarkMode})}
			tabIndex={0}
			aria-label= "chat-name"
		>
			{isEditing ?
				<input
					className={styles.input}
					type="text"
					value={name}
					onChange={e => setName(e.target.value)}
					autoFocus
					onBlur={handleSave}
					onKeyDown={e => handleEnter(e)}
					maxLength={150}
				/>
				:
				<Subheader className={styles.name} onClick={() => setIsEditing(true)}>{name}</Subheader>
			}


			<Dropdown
				className={styles.dropdown}
				trigger={<ButtonIcon className={styles.dropdownButton} icon={<DotsIcon />} />}
				items={[
					{
						label: "Rename",
						onClick: () => {
							setIsEditing(true);
						},
						icon: <PenEditIcon />,
						disabled: isUpdating,
					},
				]}
				position="bottom-start"
			/>
		</div>
	)
};
