import React, { ReactElement } from "react";
import { Accordion } from "../../../Accordion";

import styles from "./AudienceAccordion.module.scss";
import { SectionItem } from "../../../SectionItem";
import { Body, NumberInput, Separator, Slider } from "@/shared/v2";
import {
  COMPANY_SIZES,
  COUNTRIES,
  INDUSTRIES,
  JOB_FUNCTIONS,
  JOB_TITLES,
  SENIORITIES,
  SKILLS,
  TARGET_AUDIENCE,
  useRecruitContext,
} from "@/campaign/contexts/RecruitContext";
import { SelectWithAll } from "../../../SelectWithAll";
import { SelectWithChips } from "../../../SelectWithChips";
import { CheckboxesSection } from "../../../CheckboxesSection";

export const AudienceAccordion = (): ReactElement => {
  const {
    targetParticipants,
    setTargetParticipants,
    industries,
    setIndustries,
    jobFunctions,
    setJobFunctions,
    seniorities,
    setSeniorities,
    companySizes,
    setCompanySizes,
    jobTitles,
    setJobTitles,
    skills,
    setSkills,
    locations,
    setLocations,
    targetAudience,
  } = useRecruitContext();

  return (
    <Accordion title='Audience'>
      <div className={styles.detailsContent}>
        <SectionItem
          title='Target Number of Participants'
          titleTooltip='This refers to the recruitment survey only. The target number of participants for the Vurvey survey will be set in the campaign builder.'>
          <div className={styles.sectionContent}>
            <NumberInput
              className={styles.participantsInput}
              value={targetParticipants}
              onChange={setTargetParticipants}
              min={1}
              max={150}
            />
            <div className={styles.sliderContainer}>
              <Body size='s' type='medium' color='text-tertiary'>
                1
              </Body>
              <Slider
                min={1}
                max={150}
                step={1}
                value={[targetParticipants]}
                onValueChange={(value) => setTargetParticipants(value[0])}
                tooltipTransform={(value) => value.toString()}
              />
              <Body size='s' type='medium' color='text-tertiary'>
                150
              </Body>
            </div>
          </div>
        </SectionItem>

        <Separator color='recruit' className={styles.separator} />

        {targetAudience === TARGET_AUDIENCE.B2B.value && (
          <>
            <SectionItem
              title='Industries'
              description='Choose up to 5 industries, or select “All industries” to include everything.'>
              <SelectWithAll
                id='industries'
                options={INDUSTRIES}
                onChange={setIndustries}
                placeholder='Search Industries'
                selectedItems={industries}
                selectionLimit={5}
              />
            </SectionItem>

            <Separator color='recruit' className={styles.separator} />

            <SectionItem
              title='Job Functions'
              description='Select up to 3 job functions or choose "All job functions." Participants must match at least one selected function.'>
              <SelectWithAll
                id='job-functions'
                options={JOB_FUNCTIONS}
                onChange={setJobFunctions}
                placeholder='Search Job Functions'
                selectedItems={jobFunctions}
                selectionLimit={3}
              />
            </SectionItem>

            <Separator color='recruit' className={styles.separator} />

            <SectionItem
              title='Seniority'
              description='Allow participants to apply only if they meet the following criteria'>
              <CheckboxesSection
                collection={SENIORITIES}
                selectedItems={seniorities}
                onChange={setSeniorities}
              />
            </SectionItem>

            <Separator color='recruit' className={styles.separator} />

            <SectionItem
              title='Company Size'
              description='Allow participants to apply only if they meet the following criteria'>
              <CheckboxesSection
                collection={COMPANY_SIZES}
                selectedItems={companySizes}
                onChange={setCompanySizes}
              />
            </SectionItem>

            <Separator color='recruit' className={styles.separator} />

            <SectionItem
              title='Job Titles'
              description='Select up to 10 job titles that are close to your target.'
              isOptional>
              <SelectWithChips
                id='job-titles'
                options={JOB_TITLES}
                onChange={setJobTitles}
                placeholder='Search Job Title'
                selectedItems={jobTitles}
                selectionLimit={10}
              />
            </SectionItem>

            <Separator color='recruit' className={styles.separator} />

            <SectionItem
              title='Skills'
              description='Select up to 25 skills, knowledge areas, tools and certifications you would like to target.'
              isOptional>
              <SelectWithChips
                id='skills'
                options={SKILLS}
                onChange={setSkills}
                placeholder='Search Skills'
                selectedItems={skills}
                selectionLimit={25}
              />
            </SectionItem>

            <Separator color='recruit' className={styles.separator} />
          </>
        )}

        <SectionItem
          title='Location'
          description='Select up to 25 countries.'
          isOptional>
          <SelectWithAll
            id='locations'
            options={COUNTRIES}
            onChange={setLocations}
            placeholder='Search Countries'
            selectedItems={locations}
            selectionLimit={25}
          />
        </SectionItem>
      </div>
    </Accordion>
  );
};
