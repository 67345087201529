import {useToastContext} from "@/context/toast-context";
import {GET_CHAT_CONVERSATION} from "@/graphql/queries/ai-models-queries";
import {ChatConversation} from "@/models";
import {ChatAction, ChatActionTypes} from "@/reducer/chat-reducer";
import {useQuery} from "@apollo/client";
import {Dispatch, useEffect} from "react";
import {useLocation, useNavigate} from "react-router";
import {useSearchParams} from "react-router-dom";

export const usePopulateData = (dispatch: Dispatch<ChatAction>) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const {updateToast} = useToastContext();

  const canvasId = searchParams.get("canvasId");

  const {
		networkStatus,
	} = useQuery<{chatConversation: ChatConversation}>(GET_CHAT_CONVERSATION, {
		variables: {id: canvasId},
		skip: !canvasId,
		fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      dispatch({type: ChatActionTypes.SET_CONVERSATION, payload: {conversation: data.chatConversation}});
    },
		onError: () => {
			navigate("/");
			updateToast({
				type: "failure",
				description: "There was a problem. Unable to fetch conversation.",
			});
		},
	});

  useEffect(() => {
    if (!canvasId) {
      dispatch({type: ChatActionTypes.CLEAR_CONVERSATION});
    }
  }, [canvasId]);

  useEffect(() => {
    dispatch({type: ChatActionTypes.NETWORK_STATUS_CHANGE, payload: {networkStatus}});
  }, [networkStatus]);

  useEffect(() => {
    if (location.state?.chatConversation) {
      dispatch({
        type: ChatActionTypes.SYNC_LOCATION_STATE,
        payload: {
          ...location.state?.chatConversation,
        }
      })

      navigate(
        location.pathname,
        {replace: true, state: {...location.state, chatConversation: undefined}});
    }
  }, [location]);
}
