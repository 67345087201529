import React, { ReactElement } from "react";
import styles from "./StepsContent.module.scss";
import { useRecruitContext, STEPS } from "@/campaign/contexts/RecruitContext";
import { SurveySettings } from "@/campaign/components/recruit/SurveySettings";
import { QualifyingQuestions } from "@/campaign/components/recruit/QualifyingQuestions";
import { Summary } from "@/campaign/components/recruit/Summary";

export const StepsContent = (): ReactElement => {
  const { currentStep } = useRecruitContext();

  return (
    <div className={styles.children}>
      {currentStep === STEPS.SURVEY_SETTINGS && <SurveySettings />}
      {currentStep === STEPS.QUALIFYING_QUESTIONS && <QualifyingQuestions />}
      {currentStep === STEPS.SUMMARY && <Summary />}
    </div>
  );
};
