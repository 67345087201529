import {motion} from "framer-motion";
import classNames from "classnames/bind";
import React, {ReactElement} from "react";

import {ActionsDropdown} from "../actions-dropdown";
import {Body, ButtonIcon, Tooltip} from "../../../shared/v2";
import {FileUploadIcon, PhotoImageIcon} from "../../../icons";
import {TOOLTIP_DELAY_MEDIUM} from "@/shared/constants/constants";
import {useChatConversationContext} from "@/context/chat-contexts";
import {useLocalFileUpload} from "@/hooks";
import {useThemeMode} from "../../../context/theme-mode-context";
import config from "@/config";

import styles from "./upload-button.module.scss";

const cx = classNames.bind(styles);

export const UploadButton = (): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {update: updateConversation} = useChatConversationContext();

	const uploadImage = useLocalFileUpload({
		onUpload: ([image]) => {
			updateConversation({nextQuestion: {image}});
		},
		options: {
			accept: "image/*",
		},
	});

	return (
		<Tooltip
			content={(
				<Body size="xs">
					Upload image
				</Body>
			)}
			startDelay={TOOLTIP_DELAY_MEDIUM}
			placement="top"
		>
			<motion.div className={cx("uploadButton", {isDarkMode})}>
				<motion.div className={styles.chatUpload}>
					{config.featureToggles.chatDocuments ? (
						<ActionsDropdown sources={false}>
							<ButtonIcon
								className={styles.icon}
								icon={<FileUploadIcon />}
							/>
						</ActionsDropdown>
					) : (
						<ButtonIcon
							className={styles.icon}
							icon={<PhotoImageIcon />}
							onClick={uploadImage}
						/>
					)}
				</motion.div>
			</motion.div>
		</Tooltip>
	);
}
