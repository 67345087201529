import React, { ReactElement } from 'react';
import {Option, SelectInput} from '@/shared/v2/inputs/select-input';

interface ConversationsSortProps {
    value: string;
    onChange: (value: string) => void;
    className?: string;
}

export const ConversationsSort = ({
    value,
    onChange,
    className
}: ConversationsSortProps): ReactElement => {
  const sortOptions: Option[] = [
    { name: 'Name (A-Z)', id: 'NAME_ASC' },
    { name: 'Name (Z-A)', id: 'NAME_DESC' },
    { name: "Most Recently Updated", id: "UPDATED_AT_DESC" },
    { name: "Oldest Updated", id: "UPDATED_AT_ASC" },
    { name: "Most Recently Created", id: "CREATED_AT_DESC" },
    { name: "Oldest Created", id: "CREATED_AT_ASC" },
    { name: "Most Recently Messaged", id: "MESSAGED_AT_DESC" },
    { name: "Oldest Messaged", id: "MESSAGED_AT_ASC" },
  ];

  return (
      <SelectInput
        className={className}
        options={sortOptions}
        placeholder="Sort"
        onChange={onChange}
        value={value}
      />
  );
};
