import React, {ReactElement, useMemo} from "react";

import {MediaTable} from "../../media-table";
import {TrainingSetMedia} from "@/models/ai-model";
import {useSearchContext} from "../../../contexts/search-context";
import {useSelectedSourcesContext} from "../../../contexts/selected-sources-context";

import styles from "./select-media-tab.module.scss";

const filterFilesByOriginalFilename = (media: TrainingSetMedia[], search: string) => {
	return media.filter(({originalFilename}) =>
		originalFilename.toLowerCase().includes(search.toLowerCase()),
	);
};

export const SelectMediaTab = (): ReactElement => {
  const {all: trainingSets} = useSelectedSourcesContext();
  const {search} = useSearchContext();

  const filteredMedia = useMemo(() => {
    const allFiles = trainingSets.reduce((acc, trainingSet) => {
      return [
        ...acc,
        ...trainingSet.files,
      ];
    }, [] as TrainingSetMedia[]);
    const allVideos = trainingSets.reduce((acc, trainingSet) => {
      return [
        ...acc,
        ...trainingSet.videos,
      ];
    }, [] as TrainingSetMedia[]);

    return [
      ...filterFilesByOriginalFilename(allFiles, search),
      ...filterFilesByOriginalFilename(allVideos, search),
    ];
  }, [trainingSets, search]);

  return (
    <div className={styles.selectMediaTab}>
			<MediaTable media={filteredMedia} />
    </div>
  );
}
