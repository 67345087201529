import {ChatState} from "@/reducer/chat-reducer";
import {formatReducedChatConversation, UnformatedReducedChatConversation} from "./format-reduced-chat-conversation";

export type UnformatedChatState = Omit<ChatState, "current"> & {
  current: UnformatedReducedChatConversation;
}

export const formatChatState = (state: UnformatedChatState): ChatState => {
  const newCurrent = formatReducedChatConversation(state.current);

  return {
    ...state,
    current: newCurrent,
  };
}
