import React, {ReactElement} from "react";

import {AnimatePresence, motion} from "framer-motion";
import {ChatConversationContextProvider} from "@/context/chat-contexts";
import {Drawer} from "./drawer";
import {Portal} from "../../../shared/v2";
import {useAgentsPageContext} from "../../../context/agents-page-context";
import {VIEWPORT_PORTAL_NAME} from "../../../portals";

import styles from "./agent-drawer.module.scss";

export const AgentDrawer = (): ReactElement => {
	const {currentAgent, setCurrentAgent} = useAgentsPageContext();

	return (
		<Portal name={VIEWPORT_PORTAL_NAME}>
			<ChatConversationContextProvider>
				<AnimatePresence>
					{currentAgent ? (
						<>
							<Drawer agent={currentAgent} />

							<motion.button
								className={styles.overlay}
								initial={{opacity: 0}}
								animate={{opacity: 0.5}}
								exit={{opacity: 0}}
								onClick={() => setCurrentAgent(null)}
							/>
						</>
					) : null}
				</AnimatePresence>
			</ChatConversationContextProvider>
		</Portal>
	);
}
