import React, {ReactElement, useEffect} from "react";

import {Editor, History, Navigation, UnsavedChangesHandler} from "./components";
import {
  useImageHistoryContext, ImageStudioContextsProvider
} from "./contexts";
import {Portal} from "../../shared/v2";
import {VIEWPORT_PORTAL_NAME} from "../../portals";

import styles from "./image-studio.module.scss";

export interface ImageStudioProps {
  submitButton?: ReactElement;
  onSubmit?: (image: string) => void;
}

const ImageStudioContent = ({submitButton}: Pick<ImageStudioProps, "submitButton">): ReactElement => {
  return (
    <Portal name={VIEWPORT_PORTAL_NAME}>
      <div className={styles.imageStudioWrapper}>
        <Navigation submitButton={submitButton} />
        <div className={styles.content}>
          <History />
          <Editor />
        </div>
      </div>

      <UnsavedChangesHandler />
    </Portal>
  );
}

export const ImageStudio = ({submitButton, onSubmit}: ImageStudioProps): ReactElement => {
  const {imageSrc} = useImageHistoryContext();

  if (!imageSrc) {
    return <></>;
  }

  return (
    <ImageStudioContextsProvider onSubmit={onSubmit}>
      <ImageStudioContent submitButton={submitButton} />
    </ImageStudioContextsProvider>
  );
}
