import React, {ReactElement, ReactNode} from "react";

import {ActionsContextProvider} from "./actions-context";
import {NavigationContextProvider} from "./navigation-context";
import {SearchContextProvider} from "./search-context";
import {SelectedSourcesContextProvider} from "./selected-sources-context";
import {SelectedCampaignsContextProvider} from "./selected-campaigns-context";
import {SelectedQuestionsContextProvider} from "./selected-questions-context";
import {AiSources} from "..";
import {PropsContextProvider} from "./props-context";

export interface SelectAiSourcesContextProvidersProps {
  onClose: () => void;
  init: AiSources;
  onSave: (sources: AiSources) => Promise<void>;
  children: ReactNode;
}

export const SelectAiSourcesContextProviders = ({children, ...props}: SelectAiSourcesContextProvidersProps): ReactElement => {
  return (
    <PropsContextProvider {...props}>
      <SearchContextProvider>
        <NavigationContextProvider>
          <SelectedQuestionsContextProvider>
            <SelectedCampaignsContextProvider>
              <SelectedSourcesContextProvider>
                <ActionsContextProvider>
                  {children}
                </ActionsContextProvider>
              </SelectedSourcesContextProvider>
            </SelectedCampaignsContextProvider>
          </SelectedQuestionsContextProvider>
        </NavigationContextProvider>
      </SearchContextProvider>
    </PropsContextProvider>
  );
}
