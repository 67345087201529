import React, {PropsWithChildren, ReactElement} from "react";
import classNames from "classnames/bind";

import styles from "./Caption.module.scss";

const bStyles = classNames.bind(styles);

export type CaptionProps = PropsWithChildren<{
	className?: string;
	size?: "l" | "base" | "s";
	type?: "regular" | "medium" | "semibold" | "bold";
	color?: "text-body" | "text-secondary" | "gray-modern-500" |
	"charcoal-elevation-100" | "blue-dark-400" | "lime-600" | "gray-modern-600";
	title?: string;
}>;

export const Caption = ({
	children,
	size = "base",
	type = "regular",
	color = "text-body",
	title,
	className,
}: CaptionProps): ReactElement => {
	return <span className={bStyles("caption", size, type, color, className)} title={title}>
		{children}
	</span>;
};
