import React, { createContext, ReactNode, useContext, useState } from "react";

export interface SelectValue {
  id: string;
  name: string;
}

export const TARGET_AUDIENCE = {
  B2B: {
    label: "Industry Professionals (B2B)",
    value: "b2b",
  },
  B2C: {
    label: "General Population (B2C)",
    value: "b2c",
  },
};

export const PROJECT_TOPICS: SelectValue[] = [
  {
    id: "art&entertainment",
    name: "Art & Entertainment",
  },
  {
    id: "automotive",
    name: "Automotive",
  },
  {
    id: "beauty",
    name: "Beauty",
  },
  {
    id: "business",
    name: "Business",
  },
  {
    id: "education",
    name: "Education",
  },
];

export const INDUSTRIES: SelectValue[] = [
  {
    id: "all",
    name: "All Industries",
  },
  {
    id: "airlanes",
    name: "Airlines/Aviation",
  },
  {
    id: "animation",
    name: "Animation",
  },
  {
    id: "security",
    name: "Security",
  },
];

export const JOB_FUNCTIONS: SelectValue[] = [
  {
    id: "all",
    name: "All Job Functions",
  },
  {
    id: "accounting",
    name: "Accounting",
  },
  {
    id: "business-development",
    name: "Business Development",
  },
  {
    id: "engineering",
    name: "Engineering",
  },
];

export const SENIORITIES: SelectValue[] = [
  {
    id: "unpaid",
    name: "Unpaid",
  },
  {
    id: "student",
    name: "Student",
  },
  {
    id: "training",
    name: "Training",
  },
  {
    id: "entry-level",
    name: "Entry level",
  },
  {
    id: "all",
    name: "All seniority levels",
  },
];

export const COMPANY_SIZES: SelectValue[] = [
  {
    id: "1-10",
    name: "1-10 employees",
  },
  {
    id: "11-50",
    name: "11-50 employees",
  },
  {
    id: "51-200",
    name: "51-200 employees",
  },
  {
    id: "201-500",
    name: "201-500 employees",
  },
  {
    id: "501-1000",
    name: "501-1000 employees",
  },
  {
    id: "1001-5000",
    name: "1001-5000 employees",
  },
  {
    id: "5001-10000",
    name: "5001-10000 employees",
  },
  {
    id: "10000+",
    name: "10000+ employees",
  },
  {
    id: "all",
    name: "All company sizes",
  },
];

export const JOB_TITLES: SelectValue[] = [
  {
    id: "ceo",
    name: "CEO",
  },
  {
    id: "cto",
    name: "CTO",
  },
  {
    id: "coo",
    name: "COO",
  },
];

export const SKILLS: SelectValue[] = [
  {
    id: "accounting",
    name: "Accounting",
  },
  {
    id: "business-development",
    name: "Business Development",
  },
  {
    id: "engineering",
    name: "Engineering",
  },
];

export const COUNTRIES: SelectValue[] = [
  {
    id: "all",
    name: "Worldwide",
  },
  {
    id: "us",
    name: "United States",
  },
  {
    id: "uk",
    name: "United Kingdom",
  },
  {
    id: "ca",
    name: "Canada",
  },
];

export const HOUSEHOLD_INCOMES: SelectValue[] = [
  {
    id: "<30",
    name: "<$30,000",
  },
  {
    id: "30-60",
    name: "$30,000-$60,000",
  },
  {
    id: "all",
    name: "All Income",
  },
];

export const EDUCATIONS: SelectValue[] = [
  {
    id: "high-school",
    name: "Some High School",
  },
  {
    id: "college",
    name: "College",
  },

  {
    id: "all",
    name: "N/A",
  },
];

export const ETHNICITIES: SelectValue[] = [
  {
    id: "white",
    name: "White",
  },
  {
    id: "black",
    name: "Black or African American",
  },
  {
    id: "all",
    name: "All of the above",
  },
];

export const GENDERS: SelectValue[] = [
  {
    id: "male",
    name: "All male",
  },
  {
    id: "female",
    name: "All female",
  },
  {
    id: "other",
    name: "Other",
  },
  {
    id: "all",
    name: "N/A",
  },
];

export const AGE_RANGES: SelectValue[] = [
  {
    id: "18-20",
    name: "18-20",
  },
  {
    id: "21-29",
    name: "21-29",
  },
  {
    id: "30-39",
    name: "30-39",
  },
  {
    id: "40-54",
    name: "40-53",
  },
  {
    id: "55+",
    name: "50+",
  },
  {
    id: "all",
    name: "N/A",
  },
];

export const PAST_PARTICIPATIONS: SelectValue[] = [
  {
    id: "no",
    name: "Has never participated in a project with Vurvey",
  },
  {
    id: "no-last-year",
    name: "Has not participated in a project with Vurvey in the last ",
  },
  {
    id: "all",
    name: "N/A",
  },
];
interface RecruitContextProps {
  projectTitle: string;
  setProjectTitle: (title: string) => void;
  projectDetails: string;
  setProjectDetails: (details: string) => void;
  targetAudience: string;
  setTargetAudience: (audience: string) => void;
  projectTopics: SelectValue[];
  setProjectTopics: (topics: SelectValue[]) => void;
  industries: SelectValue[];
  setIndustries: (industries: SelectValue[]) => void;
  jobFunctions: SelectValue[];
  setJobFunctions: (functions: SelectValue[]) => void;
  seniorities: SelectValue[];
  setSeniorities: (seniorities: SelectValue[]) => void;
  companySizes: SelectValue[];
  setCompanySizes: (sizes: SelectValue[]) => void;
  jobTitles: SelectValue[];
  setJobTitles: (titles: SelectValue[]) => void;
  skills: SelectValue[];
  setSkills: (skills: SelectValue[]) => void;
  locations: SelectValue[];
  setLocations: (locations: SelectValue[]) => void;
  targetParticipants: number;
  setTargetParticipants: (participants: number) => void;
  householdIncomes: SelectValue[];
  setHouseholdIncomes: (incomes: SelectValue[]) => void;
  educations: SelectValue[];
  setEducations: (educations: SelectValue[]) => void;
  ethnicities: SelectValue[];
  setEthnicities: (ethnicities: SelectValue[]) => void;
  gender: string;
  setGender: (gender: string) => void;
  ageRanges: SelectValue[];
  setAgeRanges: (ranges: SelectValue[]) => void;
  pastParticipation: string;
  setPastParticipation: (participation: string) => void;
  lastParticipationMonths: number;
  setLastParticipationMonths: (months: number) => void;
  currentStep: Step;
  setCurrentStep: (step: Step) => void;
}

const RecruitContext = createContext<RecruitContextProps | undefined>(
  undefined
);

export const STEPS = {
  SURVEY_SETTINGS: "Survey Settings",
  QUALIFYING_QUESTIONS: "Qualifying Questions",
  SUMMARY: "Summary",
} as const;

export type Step = (typeof STEPS)[keyof typeof STEPS];

export const RecruitProvider = ({ children }: { children: ReactNode }) => {
  const [projectTitle, setProjectTitle] = useState("");
  const [projectDetails, setProjectDetails] = useState("");
  const [targetAudience, setTargetAudience] = useState("b2b");
  const [projectTopics, setProjectTopics] = useState<SelectValue[]>([]);
  const [industries, setIndustries] = useState<SelectValue[]>([]);
  const [jobFunctions, setJobFunctions] = useState<SelectValue[]>([]);
  const [seniorities, setSeniorities] = useState<SelectValue[]>([]);
  const [companySizes, setCompanySizes] = useState<SelectValue[]>([]);
  const [jobTitles, setJobTitles] = useState<SelectValue[]>([]);
  const [skills, setSkills] = useState<SelectValue[]>([]);
  const [locations, setLocations] = useState<SelectValue[]>([]);
  const [targetParticipants, setTargetParticipants] = useState(1);
  const [householdIncomes, setHouseholdIncomes] = useState<SelectValue[]>([]);
  const [educations, setEducations] = useState<SelectValue[]>([]);
  const [ethnicities, setEthnicities] = useState<SelectValue[]>([]);
  const [gender, setGender] = useState<string>("");
  const [ageRanges, setAgeRanges] = useState<SelectValue[]>([]);
  const [pastParticipation, setPastParticipation] = useState<string>("");
  const [lastParticipationMonths, setLastParticipationMonths] =
    useState<number>(6);

  const [currentStep, setCurrentStep] = useState<Step>(STEPS.SURVEY_SETTINGS);

  return (
    <RecruitContext.Provider
      value={{
        projectTitle,
        setProjectTitle,
        projectDetails,
        setProjectDetails,
        targetAudience,
        setTargetAudience,
        projectTopics,
        setProjectTopics,
        industries,
        setIndustries,
        jobFunctions,
        setJobFunctions,
        seniorities,
        setSeniorities,
        companySizes,
        setCompanySizes,
        jobTitles,
        setJobTitles,
        skills,
        setSkills,
        locations,
        setLocations,
        targetParticipants,
        setTargetParticipants,
        householdIncomes,
        setHouseholdIncomes,
        educations,
        setEducations,
        ethnicities,
        setEthnicities,
        gender,
        setGender,
        ageRanges,
        setAgeRanges,
        pastParticipation,
        setPastParticipation,
        lastParticipationMonths,
        setLastParticipationMonths,
        currentStep,
        setCurrentStep,
      }}>
      {children}
    </RecruitContext.Provider>
  );
};

export const useRecruitContext = () => {
  const context = useContext(RecruitContext);
  if (!context) {
    throw new Error("useRecruitContext must be used within a RecruitProvider");
  }
  return context;
};
