import classNames from "classnames/bind";
import React, {ReactElement, useEffect} from "react";

import {AgentPreviewLoader} from "./agent-preview-loader";
import {AgentPreviewPlayground} from "./agent-preview-playground";
import {ChatConversationMode} from "@/reducer/chat-reducer";
import {useAgentCreationContext} from "../../../context/agent-creation-context";
import {useChatConversationContext} from "@/context/chat-contexts";
import {useThemeMode} from "../../../context/theme-mode-context";

import styles from "./agent-preview.module.scss";

const cx = classNames.bind(styles);

export const AgentPreview = (): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {
		agent,
		state: currentAgentData,
	} = useAgentCreationContext();
	const {
		conversation: {mode},
		update: updateConversation,
	} = useChatConversationContext();

	useEffect(() => {
		if (mode !== ChatConversationMode.CONVERSATION) {
			updateConversation({mode: ChatConversationMode.CONVERSATION});
		}
	}, [mode])

	// Enabled if agentId is present or all fields are filled out
	const isEnabled =
    agent || (
    	currentAgentData?.name &&
      currentAgentData?.description &&
      currentAgentData?.instructions &&
      currentAgentData?.background &&
      currentAgentData?.personaTypeId &&
      currentAgentData?.model
    );

	return (
		<div className={cx("agentPreviewWrapper", {isDarkMode})}>
			{isEnabled ? (
				<AgentPreviewPlayground />
			) : (
				<AgentPreviewLoader />
			)}
		</div>
	);
}
