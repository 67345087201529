import {AiOrchestration} from "@/models/ai-orchestration";
import {normalizeWorkflowSources} from ".";

const sortAiPersonaTasks = (aiPersonaTasks: AiOrchestration["aiPersonaTasks"]): AiOrchestration["aiPersonaTasks"] => {
  return [...aiPersonaTasks].sort((a, b) => (a.index || 0) - (b.index || 0));
}

const normalizePersonaTasks = (aiPersonaTasks: AiOrchestration["aiPersonaTasks"]): AiOrchestration["aiPersonaTasks"] => {
  const functions = [sortAiPersonaTasks];

  let aiPersonaTasksCopy = [...aiPersonaTasks];

  for (const func of functions) {
    aiPersonaTasksCopy = func(aiPersonaTasksCopy);
  }

  return aiPersonaTasksCopy;
}

export function normalizeWorkflow(workflow: AiOrchestration | undefined): AiOrchestration | undefined {
  if (!workflow) {
    return undefined;
  }

  const {
    trainingSets,
    files,
    videos,
    aiPersonaTasks,
    ...notNormalized
  } = workflow;

  return {
    ...notNormalized,
    ...normalizeWorkflowSources({trainingSets, files, videos}),
    aiPersonaTasks: normalizePersonaTasks(aiPersonaTasks),
  };
}
