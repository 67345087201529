import React, { ReactElement } from "react";
import { Accordion } from "../Accordion";
import styles from "./RecruitsAccordion.module.scss";
import { CommunityUsersIcon } from "@/icons";
import { Body, Button } from "@/shared/v2";
import { useNavigate } from "react-router";
import { useContext } from "react";
import { SurveyContext } from "@/context/survey-context";
import { AssistiveChip } from "@/shared/v2/assistive-chip";

export const RecruitsAccordion = (): ReactElement => {
  const {
    survey: { id: surveyId },
  } = useContext(SurveyContext);
  const navigate = useNavigate();

  const goToRecruit = () => {
    navigate(`/survey/${surveyId}/recruit`);
  };

  return (
    <Accordion
      triggerPosition='right'
      title={
        <div className={styles.accordionTitle}>
          <Body type='medium'>Recruits</Body>
          <AssistiveChip icon={<CommunityUsersIcon />} text={"33"} />
        </div>
      }>
      <div className={styles.addWrapper}>
        <div className={styles.content}>
          <div className={styles.title}>
            <CommunityUsersIcon />
            <Body size='s' type='semibold'>
              Recruit Participants
            </Body>
          </div>

          <Body size='s' color='text-secondary'>
            Need participants outside Vurvey? Create a recruitment survey and
            get started. After a quick 1-2 business day verification,
            recruitment for your survey will begin.
          </Body>
        </div>

        <Button className={styles.addButton} size='small' onClick={goToRecruit}>
          Start a New Recruitment
        </Button>
      </div>
    </Accordion>
  );
};
