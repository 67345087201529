// Utility function to convert byte position to character position in UTF-8 string
const byteToCharPosition = (str: string, bytePos: number): number => {
  const encoder = new TextEncoder();
  let charPos = 0;
  let currentBytePos = 0;

  while (charPos < str.length && currentBytePos < bytePos) {
    currentBytePos += encoder.encode(str[charPos]).length;
    if (currentBytePos <= bytePos) {
      charPos++;
    }
  }

  return charPos;
};

export const addCitationsToContent = (
  content: string,
  citations: Array<{
    startPos: number;
    endPos: number;
    groundingIndex: number;
    supportScore?: number;
  }>,
  isCitationsVisible: boolean
) => {
  // If citations should be hidden, return the original content
  if (!isCitationsVisible) {
    return content;
  }

  let modifiedContent = content;
  const sortedCitations = [...citations].sort((a, b) => b.endPos - a.endPos);

  sortedCitations.forEach((citation) => {
    const charEndPos = byteToCharPosition(content, citation.endPos);
    const citationText = `^${citation.groundingIndex}^`;

    modifiedContent =
      modifiedContent.slice(0, charEndPos) +
      citationText +
      modifiedContent.slice(charEndPos);
  });

  return modifiedContent;
};
