import React, {ReactElement, useMemo} from "react";

import {AiSources, SelectAiSourcesModal, SelectAiSourcesModalProps} from "@/modals/select-ai-sources-modal";
import {useChatConversationContext} from "@/context/chat-contexts";

export type SelectChatSourcesModalProps = Pick<SelectAiSourcesModalProps, "isOpen" | "onClose" | "portal">;

export const SelectChatSourcesModal = ({isOpen, onClose}: SelectChatSourcesModalProps): ReactElement => {
  const {
    conversation: {
      surveys,
      questions,
      trainingSets,
      files,
      videos,
    },
    update: updateConversation,
  } = useChatConversationContext();

  const init = useMemo(() => {
    return {
      campaigns: surveys,
      questions,
      trainingSets,
      files,
      videos,
    };
  }, [surveys, questions, trainingSets, files, videos]);

  const onSave = async (newSources: AiSources) => {
    updateConversation({
      surveys: newSources.campaigns,
      questions: newSources.questions,
      trainingSets: newSources.trainingSets,
      files: newSources.files,
      videos: newSources.videos,
    });

    onClose();
  };

  return (
    <SelectAiSourcesModal
      init={init}
      isOpen={isOpen}
      onClose={onClose}
      onSave={onSave}
      portal
    />
  );
}
