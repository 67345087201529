import React, {ReactElement} from "react";

import {ButtonIcon, Subheader} from "@/shared/v2";
import {ChevronLeftIcon} from "@/icons";
import {OverflowTooltip} from "@/shared/v2/tooltip/overflow-tooltip";
import {QuestionsTable} from "../../questions-table";
import {SelectSourcesPage, useNavigationContext} from "../../../contexts/navigation-context";

import styles from "./select-campaign-questions-tab.module.scss";

export const SelectCampaignQuestionsTab = (): ReactElement => {
  const {campaign, setCurrentPage} = useNavigationContext();

  return (
    <div className={styles.selectCampaignQuestionsTab}>
      <div className={styles.controls}>
        <ButtonIcon
          filledIcon
          icon={<ChevronLeftIcon />}
          color="text-secondary"
          onClick={() => setCurrentPage(SelectSourcesPage.CAMPAIGNS)}
        />

        <OverflowTooltip>
          <Subheader
            type="semibold"
            color="text-body"
            size="l"
            className={styles.campaignName}
          >
            {campaign?.name || "Campaign"}
          </Subheader>
        </OverflowTooltip>
      </div>

      <QuestionsTable />
    </div>
  );
}
