export interface GetImageFromBase64Options {
  name?: string;
  type?: string;
}

export const getImageFromBase64 = (
  base64: string,
  options: GetImageFromBase64Options = {}
): File => {
  const {name = "image.png", type = "image/png"} = options;

  // Remove data URL prefix if it exists
  const base64String = base64.replace(/^data:image\/\w+;base64,/, '');

  const byteCharacters = atob(base64String);
  const byteArrays: number[] = [];
  for (let i = 0; i < byteCharacters.length; i++) {
    byteArrays.push(byteCharacters.charCodeAt(i));
  }
  const byteArray = new Uint8Array(byteArrays);
  const imageFile = new File([byteArray], name, { type });
  return imageFile;
};
