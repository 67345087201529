import {ChatConversation, UpdateChatConversationChanges} from "@/models/ai-model";
import {hasArrayChanges, hasObjectChanges, hasScalarChanges, ObjectFieldCompareOptions} from "..";
import {ReducedChatConversation} from "@/reducer/chat-reducer";

type ChatConversationFieldCompareOptions = ObjectFieldCompareOptions<keyof ChatConversation> & {
  resultField: keyof UpdateChatConversationChanges;
};

export const extractChatChanges = (original: ChatConversation | null, current: ReducedChatConversation): UpdateChatConversationChanges => {
  const changes: UpdateChatConversationChanges = {};

  const SCALAR_FIELDS = ["name"] as (keyof ChatConversation)[];
  const OBJECT_FIELDS = [
    {fieldName: "aiPersona", compare: ["id"], resultField: "aiPersonaId"},
  ] as ChatConversationFieldCompareOptions[];
  const ARRAY_FIELDS = [
    {fieldName: "trainingSets", compare: ["id"], resultField: "trainingSetIds"},
    {fieldName: "files", compare: ["id"], resultField: "fileIds"},
    {fieldName: "videos", compare: ["id"], resultField: "videoIds"},
    {fieldName: "questions", compare: ["id"], resultField: "questionIds"},
    {fieldName: "surveys", compare: ["id"], resultField: "surveyIds"},
  ] as ChatConversationFieldCompareOptions[];

  if (!original) {
    const {trainingSets, files, videos, questions, surveys} = current;
    const trainingSetIds = trainingSets.map((set) => set.id);
    const fileIds = files.map((file) => file.id);
    const videoIds = videos.map((video) => video.id);
    const questionIds = questions.map((question) => question.id);
    const surveyIds = surveys?.map((survey) => survey.id);

    return {
      name: current.name || undefined,
      aiPersonaId: current.aiPersona?.id,
      trainingSetIds: trainingSetIds.length ? trainingSetIds : undefined,
      fileIds: fileIds.length ? fileIds : undefined,
      videoIds: videoIds.length ? videoIds : undefined,
      questionIds: questionIds.length ? questionIds : undefined,
      surveyIds: surveyIds?.length ? surveyIds : undefined,
      isPreview: current.isPreview,
    };
  }

  for (const field of SCALAR_FIELDS) {
    if (hasScalarChanges(field, original, current as unknown as ChatConversation)) {
      changes[field] = current[field];
    }
  }

  for (const field of OBJECT_FIELDS) {
    if (hasObjectChanges(field, original, current as unknown as ChatConversation)) {
      changes[field.resultField] = current[field.fieldName]?.id ?? null;
    }
  }

  for (const field of ARRAY_FIELDS) {
    if (hasArrayChanges(field, original, current as unknown as ChatConversation)) {
      changes[field.resultField] = current[field.fieldName].map((item) => item.id);
    }
  }

  return changes;
};
