import React, { ReactElement } from "react";

import { VurveyLabsIconBlack, VurveyLabsIconVolt } from "@/icons";
import { motion } from "framer-motion";

import styles from "./spinner.module.scss";
import classNames from "classnames/bind";
import { useThemeMode } from "@/context/theme-mode-context";

const cx = classNames.bind(styles);

export const Spinner = ({
  className,
}: {
  className?: string;
}): ReactElement => {
  const { isDarkMode } = useThemeMode();

  return (
    <motion.div
      className={cx("wrapper", className)}
      animate={{ scale: [1, 0.95, 1], opacity: [1, 0.5, 1] }}
      transition={{ duration: 1.5, repeat: Infinity, ease: "easeInOut" }}>
      {isDarkMode ? (
        <VurveyLabsIconVolt className={styles.icon} />
      ) : (
        <VurveyLabsIconBlack className={styles.icon} />
      )}
    </motion.div>
  );
};
