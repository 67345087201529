import {isArray, isObject} from "lodash-es";

type Model = Record<string, any> & {id: string};

export interface ObjectFieldCompareOptions<T extends string | number | symbol> {
  fieldName: string;
  compare: T[];
}

export const hasScalarChanges = <T extends Model, K extends keyof T>(
  fieldName: K,
  original: T,
  current: T
): boolean => {
  return original[fieldName] !== current[fieldName];
};

export const hasObjectChanges = <T extends Model>(
  {fieldName, compare}: ObjectFieldCompareOptions<keyof T>,
  original: T,
  current: T
): boolean => {
  const originalObject = original[fieldName];
  const currentObject = current[fieldName];

  if (!originalObject && !currentObject) {
    return false;
  }

  if (!originalObject || (originalObject && !currentObject)) {
    return true;
  }

  let hasChanges = false;

  for (const field of compare) {
    const originalValue = originalObject[field];

    if (
      isArray(originalValue) &&
      hasArrayChanges({fieldName: field as string, compare: ["id"]}, originalObject, currentObject)
    ) {
      hasChanges = true;
      break;
    }

    if (
      isObject(originalValue) &&
      hasObjectChanges({fieldName: field as string, compare: ["id"]}, originalObject, currentObject)
    ) {
      hasChanges = true;
      break;
    }

    if (hasScalarChanges(field as string, originalObject, currentObject)) {
      hasChanges = true;
      break;
    }
  }

  return hasChanges;
};

export const hasArrayChanges = <T extends Model>(
  {fieldName, compare}: ObjectFieldCompareOptions<keyof T>,
  original: T,
  current: T
): boolean => {
  const originalArray = original[fieldName];
  const currentArray = current[fieldName];

  if (!originalArray && !currentArray) {
    return false;
  }

  if (!originalArray && currentArray || !currentArray && originalArray) {
    return true;
  }

  if (!isArray(originalArray) || !isArray(currentArray)) {
    throw new Error("hasArrayChanges: Invalid array");
  }

  if (originalArray.length !== currentArray.length) {
    return true;
  }

  let result = false;

  originalArray.forEach((item, index) => {
    const currentItem = currentArray.find(i => i.id === item.id);

    if (!currentItem) {
      result = true;
      return;
    }

    for (const field of compare) {
      if (
        isArray(item[field]) &&
        hasArrayChanges({fieldName: field as string, compare: ["id"]}, item, currentItem)
      ) {
        result = true;
        return;
      }

      if (
        isObject(item[field]) &&
        hasObjectChanges({fieldName: field as string, compare: ["id"]}, item, currentItem)
      ) {
        result = true;
        return;
      }

      if (hasScalarChanges(field as string, item, currentItem)) {
        result = true;
        return;
      }
    }
  })

  return result;
};

