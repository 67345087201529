import React, { ReactElement } from "react";
import {
  DetailsAccordion,
  AudienceAccordion,
  AdditionalFilteringAccordion,
  BottomBar,
} from "@/campaign/components/recruit";

export const SurveySettings = (): ReactElement => {
  return (
    <>
      <DetailsAccordion />
      <AudienceAccordion />
      <AdditionalFilteringAccordion />

      <BottomBar />
    </>
  );
};
