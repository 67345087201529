/* eslint-disable react/prop-types */

import React, {ReactElement, useMemo, useState} from "react";
import {useMutation, useQuery} from "@apollo/client";
import {Column, Row} from "react-table";

import {Avatar, Body, Button, Checkbox, SelectInput, Spinner} from "../../../shared/v2";
import {Badge} from "../../../workspace-settings/components/model-card/badge";
import {GET_ADMIN_FEATURE_WORKSPACES} from "../../../graphql/queries/queries";
import {GET_PERSONAS} from "../../../graphql/queries/ai-models-queries";
import {ManageIsVurveyModal} from "../../components/manage-is-vurvey-modal";
import {Option} from "@/shared/v2/inputs/select-input";
import {PERSONA_CHANGE_VURVEY_FLAG} from "../../../graphql/mutations/persona-mutations";
import {Persona} from "../../../models";
import {Table} from "../../../shared/components/table";
import {VurveyLabsIconVolt} from "../../../icons";

import styles from "./agents-admin-page.module.scss";
import {transformPersonaStatus} from "@/agents/components/agent-card/persona-card-content";

export const AgentsAdminPage = (): ReactElement => {
	const [selectedWorkspace, setSelectedWorkspace] = useState<string | null>(null);
	const [selectedAgents, setSelectedAgents] = useState<Row<Persona>[]>([]);
	const [isModalOpen, setIsModalOpen] = useState(false);

	const {data: workspaceData} = useQuery(GET_ADMIN_FEATURE_WORKSPACES, {
		fetchPolicy: "cache-first",
	});

	const {data: agentsData, loading: isLoadingAgents} = useQuery(GET_PERSONAS, {
		variables: {
			workspaceId: selectedWorkspace,
			version: "THUMBNAIL",
		},
		skip: !selectedWorkspace
	})

	const [updateAgents, {loading: isChangingFlag}] = useMutation(PERSONA_CHANGE_VURVEY_FLAG);

	const options: Option[] = useMemo(() => {
		if (!workspaceData) return [];
		return workspaceData.allWorkspaces.items.map(item => {
			return {
				id: item.id,
				name: item.name,
			} as Option;
		});
	}, [workspaceData]);

	const columns: Column<Persona>[] = [
		{
			Header: "Name",
			accessor: "name",
			Cell: ({row: {original}}) => (
				<div className={styles.nameColumn}>
					<Avatar
						firstName={original.name}
						size="xs"
						url={original.picture?.url}
						additionalIcon={original.isVurvey ? <VurveyLabsIconVolt /> : undefined}
					/>
					<Body size="s">{original.name}</Body>
				</div>
			),
		},

		{
			Header: "description",
			accessor: "description",
			Cell: ({value}) => <Body className={styles.description} size="s">{value}</Body>,
		},
		{
			Header: "Status",
			accessor: "personaStatus",
			Cell: ({value}) => <Badge text={transformPersonaStatus(value)} />,
		},
		{
			Header: "Is Vurvey?",
			accessor: "isVurvey",
			Cell: ({value}) => <Checkbox checked={value ?? false} disabled />,
			sortType: (rowA, rowB) => {
				return (rowA.original.isVurvey ? 1 : 0) - (rowB.original.isVurvey ? 1 : 0);
			},
		},
	];

	const renderTable = (): ReactElement | null => {
		if (isLoadingAgents) return <Spinner />;

		if (!selectedWorkspace) return null;

		return <Table
			columns={columns}
			data={agentsData?.aiPersonasForWorkspace || []}
			selectedValues={selectedAgents}
			onSelectChange={setSelectedAgents}
		/>
	}

	const handleChangeIsVurvey = async(isVurvey: boolean): Promise<void> => {
		await updateAgents({
			variables: {
				personaIds: selectedAgents.map(agent => agent.original.id),
				isVurvey
			}
		})
		setIsModalOpen(false);
	};

	return <>
		<div className={styles.container}>
			<div className={styles.fitlersContainer}>
				<SelectInput
					label="Select worskpace to display agents"
					options={options}
					onChange={setSelectedWorkspace}
					onSearch={(search, option) => option.name.toLowerCase().includes(search.toLowerCase())}
					value={selectedWorkspace || undefined}
					className={styles.select}
				/>

				{selectedAgents.length > 0 &&
          <Button onClick={() => setIsModalOpen(true)}>
            Manage Is Vurvey
          </Button>
				}
			</div>

			{renderTable()}
		</div>

		<ManageIsVurveyModal
			selectedAgents={selectedAgents}
			isOpen={isModalOpen}
			onClose={() => setIsModalOpen(false)}
			onSave={handleChangeIsVurvey}
			isDisabled={isChangingFlag}
		/>
	</>
}
