import classNames from "classnames/bind";
import React, {FormEvent, ReactElement, useState} from "react";

import {Button} from "../../../shared/v2";
import {useAgentCreationContext} from "../../../context/agent-creation-context";
import {useChatConversationContext} from "../../../context/chat-contexts";
import {useThemeMode} from "../../../context/theme-mode-context";
import CommandTextArea from "../../../canvas/chat-bubble/command-text-area";
import {KeyboardEventMiddlewareContextProvider} from "../../../context/keyboard-event-middleware-context";

import styles from "./chat-bubble.module.scss";

const cx = classNames.bind(styles);

export interface AgentsPreviewChatBubbleProps {
  className?: string;
  isChatUpdated?: boolean;
}

export const AgentsPreviewChatBubble = ({
	className,
	isChatUpdated,
}: AgentsPreviewChatBubbleProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const [value, setValue] = useState("");
	const {stringifiedInstructions} = useAgentCreationContext();
	const {
		conversation: {nextQuestion: {mention}},
		send: sendQuestion,
		isSendingQuestion
	} = useChatConversationContext();

	const isDisabled = !value || !value?.trim()?.length || isSendingQuestion;

	const handleResponse = async (e?: FormEvent<HTMLFormElement>): Promise<void> => {
		e?.preventDefault();

		setValue("");

		await sendQuestion({
			query: value,
			createNewConversation: isChatUpdated,
			conversationInput: {isPreview: true},
			nextQuestionInput: {instructions: stringifiedInstructions},
		});
	};

	return (
		<form className={cx("inputWrapper", className, {isDarkMode})} onSubmit={handleResponse}>
			<KeyboardEventMiddlewareContextProvider>
				<CommandTextArea
					value={value}
					textAreaProps={{"aria-label": "chat-message"}}
					placeholder="Test your Agents..."
					onChange={setValue}
					handleSubmit={handleResponse}
					focusOnMount={false}
					mention={mention}
					commands={[
						{command: "/table", description: " (returns response formatted as a table)", icon: "📊"},
						{
							command: "/image",
							description:
								" (Uses a mix of Imagen, Stable Diffusion, and DALL-E to generate images based on your description)",
							icon: "📸",
						},
						{
							command: "/imagesd",
							description: " (Uses Stable Diffusion to generate images based on your description)",
							icon: "📸",
						},
						{
							command: "/imagede",
							description: " (Uses OpenAI DALL-E to generate images based on your description)",
							icon: "📸",
						},
						{
							command: "/imagen",
							description: " (Uses Google Imagen to generate images based on your description)",
							icon: "📸",
						},
						{
							command: "/tiktok",
							description:
								"Allows you to pass in a hashtag to search TikTok to gather and understand the insights, sentiment, top vidoes, etc in order to understand what people are saying or posting about a particular topic. Example: /tiktok #slugging",
							icon: "📈",
						},
						{
							command: "/scrape",
							description:
								"Will scrape the URL (/scrape [url]) that you provide and return a summary of the content while adding the full content to the Scraped-Data Dataset folder.",
							icon: "🌐",
						},
						{
							command: "/scrape_advanced",
							description:
								"Similar to /scrape [url] but will sometimes provide more detail on complicated websites where the default /scrape under performs. This can take much longer though and should only be used when /scrape fails.",
							icon: "🌐",
						},
						{
							command: "/web",
							description:
								"Will use the web to find the answer to your question with supporting images and links if appropriate. Example: /web Who is current president of the USA?",
							icon: "🌐",
						},
						{command: "/help", description: " (shows help)", icon: "❓"},
					]}
				/>
			</KeyboardEventMiddlewareContextProvider>
			<div className={styles.controlPanel}>
				<Button disabled={isDisabled} type="submit" size="small">
					Generate
				</Button>
			</div>
		</form>
	);
}
