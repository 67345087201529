import React from "react";
import { Accordion } from "../../../Accordion";

import styles from "./DetailsAccordion.module.scss";
import { SectionItem } from "../../../SectionItem";
import { Input, RadioGroup, Separator, TextArea } from "@/shared/v2";
import {
  PROJECT_TOPICS,
  TARGET_AUDIENCE,
  useRecruitContext,
} from "@/campaign/contexts/RecruitContext";
import { SelectWithChips } from "../../../SelectWithChips";

export const DetailsAccordion = () => {
  const {
    projectTitle,
    setProjectTitle,
    projectDetails,
    setProjectDetails,
    targetAudience,
    setTargetAudience,
    projectTopics,
    setProjectTopics,
  } = useRecruitContext();

  return (
    <Accordion title='Details'>
      <div className={styles.detailsContent}>
        <SectionItem
          title='Project Title'
          titleTooltip='Ensure your title is concise and clearly describes your project.'>
          <Input
            value={projectTitle}
            onChange={setProjectTitle}
            placeholder='e.g. Exploring Trends in Remote Work'
            hintText='This is visible to Recruits'
          />
        </SectionItem>

        <SectionItem title='Project Details'>
          <TextArea
            value={projectDetails}
            onChange={setProjectDetails}
            placeholder='Provide respondents with a reason to participate. E.g. We’re seeking opinions on sustainable packaging from eco-conscious consumers.'
            hintText='This is visible to Recruits'
          />
        </SectionItem>

        <Separator color='recruit' className={styles.separator} />

        <SectionItem title='Target Audience'>
          <RadioGroup
            value={targetAudience}
            onChange={setTargetAudience}
            size='s'
            className={styles.radioGroup}>
            <RadioGroup.Option
              value={TARGET_AUDIENCE.B2B.value}
              label={TARGET_AUDIENCE.B2B.label}
            />
            <RadioGroup.Option
              value={TARGET_AUDIENCE.B2C.value}
              label={TARGET_AUDIENCE.B2C.label}
            />
          </RadioGroup>
        </SectionItem>

        {targetAudience === TARGET_AUDIENCE.B2C.value && (
          <>
            <Separator color='recruit' className={styles.separator} />
            <SectionItem
              title='Project Topics'
              description='Tagging your projects with topics will better help match your general population project with participants that have indicated interest in these topics.'>
              <SelectWithChips
                id='project-topics'
                options={PROJECT_TOPICS}
                onChange={setProjectTopics}
                placeholder='Select up to 2 topics'
                selectedItems={projectTopics}
                selectionLimit={2}
              />
            </SectionItem>
          </>
        )}
      </div>
    </Accordion>
  );
};
