import {WorkflowChanges} from "@/reducer/workflow-reducer";

export const isWorkflowDirty = (changes: WorkflowChanges): boolean => {
  const {
    settings: settingsChanges,
    tasks: tasksChanges,
  } = changes;

  if (settingsChanges) {
    return true;
  }

  if (tasksChanges.length > 0) {
    return true;
  }

  return false;
}
