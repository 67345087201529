import {ChatConversation} from "@/models";
import {ChatLayoutMode} from "@/reducer/chat-reducer";
import {ChatState, initialState} from "@/reducer/chat-reducer";
import {UnformatedChatState} from "./format-chat-state";

const THREE_MINUTES_AGO = new Date().getTime() - 1000 * 60 * 3;

export const handleSetConversation = (payload: ChatConversation, state: ChatState): UnformatedChatState => {
  const {
    original,
    current,
    actions: {
      isCreating,
      isSendingQuestion,
    }
  } = state;

  const isCreatedRecently = new Date(payload.createdAt).getTime() > Date.now() - THREE_MINUTES_AGO;

  // Case 1: Switch from no conversation to a conversation
  if (!original && (!isCreating || !isCreatedRecently)) {
    const newOriginal = {...payload};
    const newCurrent = {
      ...initialState.current,
      layout: ChatLayoutMode.CHAT,
      ...newOriginal,
    }
    const newActions = {
      ...initialState.actions,
    }

    return {
      ...state,
      original: newOriginal,
      current: newCurrent,
      actions: newActions,
    }
  }

  // Case 2: Create conversation
  if (!original && isCreating) {
    const newOriginal = {...payload};
    const newMessages = isSendingQuestion ? current.messages : newOriginal.messages;
    const newNextQuestion = isSendingQuestion ? current.nextQuestion : initialState.current.nextQuestion;
    const newCurrent = {
      ...current,
      ...newOriginal,
      messages: newMessages,
      nextQuestion: newNextQuestion,
    }

    const newActions = {
      ...state.actions,
      isCreating: false,
      isUpdating: false,
      isInitialLoading: false,
      isLoading: false,
    }

    return {
      ...initialState,
      original: newOriginal,
      current: newCurrent,
      actions: newActions,
    }
  }

  // Case 3: Switch conversations
  if (original && original.id !== payload.id) {
    const newOriginal = {...payload};
    const newCurrent = {
      ...current,
      mode: initialState.current.mode,
      tool: initialState.current.tool,
      ...newOriginal,
    }
    const newActions = {
      ...initialState.actions,
    }

    return {
      ...initialState,
      original: newOriginal,
      current: newCurrent,
      actions: newActions,
    }
  }

  // Case 4: Update conversation
  if (original && original.id === payload.id) {
    const newOriginal = {...payload};
    const newCurrent = {
      ...state.current,
      ...newOriginal,
    }
    const newChanges = {};
    const newActions = {
      ...state.actions,
      isUpdating: false,
      isCreating: false,
      isLoading: false,
    }

    return {
      ...state,
      original: newOriginal,
      current: newCurrent,
      actions: newActions,
      changes: newChanges,
    }
  }

  return state;
}

