import React, {ReactElement, ReactNode, RefObject, createContext} from "react";
import {useTask} from "../../../hooks/useTask";
import {client} from "../../../shared/utility/client";
import {useCanvasContext} from "./canvas-area-selection";
import {useImageHistoryContext} from "./image-history";
import config from "../../../config";
import {useToastContext} from "@/context/toast-context";
import {useImageElementContext} from "./image-element";
import {imageToDataUrl} from "@/shared/utility/image-to-data-url";
import {useNavigate} from "../../../route";

export interface AiActionsContextValue {
  enhanceImage: (prompt: string) => Promise<void>;
  changeImage: (prompt: string) => Promise<void>;
  saveImage: () => Promise<void>;
  isEnhancingImage: boolean;
  isChangingImage: boolean;
  isUpdatingImage: boolean;
  isSavingImageRef: RefObject<boolean>;
}

export const AiActionsContext =
  createContext<AiActionsContextValue | undefined>(undefined);

export interface AiActionsContextProviderProps {
  children: ReactNode;
  onSubmit?: (image: string) => void;
}

export const AiActionsContextProvider = (
  {children, onSubmit}: AiActionsContextProviderProps,
): ReactElement => {
  const {setLines, getMask} = useCanvasContext();
  const {imageSrc, setImageSrc, clearImage, addImagesToHistory, history} = useImageHistoryContext();
  const {updateToast} = useToastContext();
  const {element: imageEl} = useImageElementContext();
  const navigate = useNavigate();

  const {run: handleEnhance, loading: isEnhancing} = useTask(async (prompt: string) => {
		try {
			const {urls} = await client.post(`${config.apiHost}/rest/upscale`, {
				body: JSON.stringify({
					url: imageSrc,
					prompt,
				}),
			}).json<{urls: string[]}>();
      
      if (!urls || urls.length === 0) {
        updateToast({type: "failure", description: "Failed to generate enhanced images"});
        throw new Error("Failed to generate enhanced images");
      }
      
      console.log(`Received ${urls.length} enhanced images from API:`, urls);
      
      // First add all enhanced images to history
      addImagesToHistory(urls);
      console.log(`Added ${urls.length} enhanced images to history`);
      
      // Create a deduplicated combined history array
      const combinedHistory = [...new Set([...history, ...urls])];
      
      // Then select the first image as the current one, but don't reset history
      // Use navigate directly to preserve the originalImage parameter
      navigate(
        {search: {
          image: urls[0], 
          history: combinedHistory.join(","),
          originalImage: history.length > 0 ? history[0] : imageSrc // Keep the first image as original
        }},
        {search: true, replace: true}
      );
      
      console.log(`Set current image to ${urls[0]} while preserving history`);
      
      setLines([]);
		} catch (error) {
			console.error("Failed to generate enhanced images:", error);
			throw error;
		}
	})

  const {
    run: handleGenerateImage,
    loading: isGeneratingImage,
  } = useTask(async (prompt: string) => {
    const {hasMask, maskData} = getMask();

    if (!hasMask) {
      handleEnhance(prompt);
      return;
    }
    
    // Log mask information
    console.log("Sending mask to API", {
      hasMask,
      maskDataLength: maskData.length,
      maskDataPreview: maskData.substring(0, 50) + '...'
    });

    const data = {
			maskData: maskData,
			baseImage: imageSrc,
      prompt,
		};

		try {
			console.log("Sending request to imgeditor API endpoint");
			const {editedImageUrls} = await client.post(`${config.apiHost}/rest/imgeditor`, {
				body: JSON.stringify(data),
			}).json<{editedImageUrls: string[]}>();
      
      console.log("API response received");
      
      if (!editedImageUrls || editedImageUrls.length === 0) {
        updateToast({type: "failure", description: "Failed to generate edited images"});
        throw new Error("Failed to generate edited images");
      }
      
      console.log(`Received ${editedImageUrls.length} edited images from API:`, editedImageUrls);
      
      // First add all images to history
      addImagesToHistory(editedImageUrls);
      console.log(`Added ${editedImageUrls.length} images to history`);
      
      // Create a deduplicated combined history array
      const combinedHistory = [...new Set([...history, ...editedImageUrls])];
      
      // Then select the first image as the current one, but don't reset history
      // We need to use the history's setImage method to preserve history
      // Use navigate directly to preserve the originalImage parameter
      navigate(
        {search: {
          image: editedImageUrls[0], 
          history: combinedHistory.join(","),
          originalImage: history.length > 0 ? history[0] : imageSrc // Keep the first image as original
        }},
        {search: true, replace: true}
      );
      
      console.log(`Set current image to ${editedImageUrls[0]} while preserving history`);
      
		} catch (error) {
			console.error("Error generating edited image:", error);
			throw error;
		} finally {
			setLines([]);
		}
	});
  
  const {run: handleSaveImage, loadingRef: isSavingImageRef} = useTask(async () => {
    // to data url
    if (!imageEl) {
      throw new Error("Image element is not available");
    }
    
    if (!onSubmit) {
      throw new Error("onSubmit is not provided");
    }
    
    const image = imageToDataUrl(imageEl);

    onSubmit(image);

    clearImage();
  });

  return (
    <AiActionsContext.Provider value={{
      enhanceImage: handleEnhance,
      changeImage: handleGenerateImage,
      saveImage: handleSaveImage,
      isUpdatingImage: isEnhancing || isGeneratingImage,
      isEnhancingImage: isEnhancing,
      isChangingImage: isGeneratingImage,
      isSavingImageRef,
    }}>
      {children}
    </AiActionsContext.Provider>
  );
};

export const useAiActionsContext = (): AiActionsContextValue => {
  const context = React.useContext(AiActionsContext);

  if (context === undefined) {
    throw new Error(
      "useAiActionsContext must be used within a AiActionsContextProvider",
    );
  }

  return context;
};
