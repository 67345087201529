import React, {ReactElement, ReactNode, createContext} from "react";

import {usePropsContext} from "./props-context";
import {useSelectedCampaignsContext} from "./selected-campaigns-context";
import {useSelectedQuestionsContext} from "./selected-questions-context";
import {useSelectedSourcesContext} from "./selected-sources-context";
import {useTask} from "@/hooks/useTask";

export interface ActionsContextValue {
  submit: () => void;
  isSubmittingRef: React.MutableRefObject<boolean>;
}

export const ActionsContext =
  createContext<ActionsContextValue | undefined>(undefined);

export const ActionsContextProvider = (
  {children}: {children: ReactNode},
): ReactElement => {
  const {onSave} = usePropsContext();
  const {selected: selectedSources} = useSelectedSourcesContext();
  const {selected: selectedCampaigns} = useSelectedCampaignsContext();
  const {selected: selectedQuestions} = useSelectedQuestionsContext();

  const {run: submit, loadingRef: isSubmittingRef} = useTask(async () => {
    await onSave({
      trainingSets: selectedSources.trainingSets,
      files: selectedSources.files,
      videos: selectedSources.videos,
      campaigns: selectedCampaigns,
      questions: selectedQuestions,
    });
  });

  return (
    <ActionsContext.Provider value={{submit, isSubmittingRef}}>
      {children}
    </ActionsContext.Provider>
  );
};

export const useActionsContext = (): ActionsContextValue => {
  const context = React.useContext(ActionsContext);

  if (context === undefined) {
    throw new Error(
      "useActionsContext must be used within a ActionsContextProvider",
    );
  }

  return context;
};
