import {Image as KonvaImage} from "react-konva";
import {ImageConfig} from "konva/lib/shapes/Image";
import React, {ReactElement, useEffect, useMemo, useState} from "react";

// Default scale to use if container dimensions are invalid
const DEFAULT_SCALE = 0.8;

export interface CanvasImageProps extends Omit<ImageConfig, "image"> {
  src: string | null;
  width: number;
  height: number;
}

export const CanvasImage = ({src, width, height, ...props}: CanvasImageProps): ReactElement => {
  const [imageEl, setImageEl] = useState<HTMLImageElement | null>(null);
  
  // Create and load the image
  useEffect(() => {
    if (!src) {
      console.warn("No source provided to CanvasImage");
      return;
    }
    
    console.log(`Loading image from: ${src}`);
    const img = new Image();
    img.crossOrigin = "anonymous";
    
    const handleLoad = () => {
      console.log(`Image loaded successfully: ${src}`);
      console.log(`Image natural dimensions: ${img.naturalWidth}x${img.naturalHeight}`);
      setImageEl(img);
    };
    
    const handleError = (error: ErrorEvent) => {
      console.error("Failed to load image:", src, error);
    };
    
    img.onload = handleLoad;
    img.onerror = handleError as any;
    img.src = src;
    
    return () => {
      img.onload = null;
      img.onerror = null;
    };
  }, [src]);
  
  // Calculate dimensions that maintain aspect ratio
  const { calculatedWidth, calculatedHeight, x, y } = useMemo(() => {
    if (!imageEl) {
      console.log("Image element not available yet");
      return { 
        calculatedWidth: width || 400, 
        calculatedHeight: height || 300,
        x: 0,
        y: 0
      };
    }
    
    // Handle the case where container dimensions are invalid
    if (width <= 0 || height <= 0) {
      console.log("Container has invalid dimensions, using default scale");
      const calculatedWidth = imageEl.naturalWidth * DEFAULT_SCALE;
      const calculatedHeight = imageEl.naturalHeight * DEFAULT_SCALE;
      return {
        calculatedWidth,
        calculatedHeight,
        x: 0,
        y: 0
      };
    }
    
    const containerRatio = width / height;
    const imageRatio = imageEl.naturalWidth / imageEl.naturalHeight;
    
    let calculatedWidth, calculatedHeight;
    
    // Determine whether to constrain by width or height
    if (imageRatio > containerRatio) {
      // Image is wider than container proportionally, constrain by width
      calculatedWidth = width;
      calculatedHeight = width / imageRatio;
    } else {
      // Image is taller than container proportionally, constrain by height
      calculatedWidth = height * imageRatio;
      calculatedHeight = height;
    }
    
    // Center the image in the container
    const x = (width - calculatedWidth) / 2;
    const y = (height - calculatedHeight) / 2;
    
    console.log(`Calculated image dimensions: ${calculatedWidth}x${calculatedHeight}, position: (${x},${y})`);
    return { calculatedWidth, calculatedHeight, x, y };
  }, [width, height, imageEl]);
  
  // For debugging purposes
  useEffect(() => {
    if (src) {
      console.log("Image source:", src);
      console.log("Container dimensions:", width, height);
    }
  }, [src, width, height]);
  
  if (!imageEl || !src) {
    return <></>;
  }
  
  return (
    <KonvaImage 
      image={imageEl}
      width={calculatedWidth}
      height={calculatedHeight}
      x={x}
      y={y}
      {...props} 
    />
  );
}
