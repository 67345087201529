import React, { ReactElement } from "react";
import styles from "./BottomBar.module.scss";
import { Button } from "@/shared/v2";
import { ChevronLeftIcon } from "@/icons";
import { useRecruitContext, STEPS } from "@/campaign/contexts/RecruitContext";

export const BottomBar = (): ReactElement => {
  const { setCurrentStep } = useRecruitContext();

  const goToNextStep = () => {
    setCurrentStep(STEPS.SUMMARY);
  };

  const goToPreviousStep = () => {
    setCurrentStep(STEPS.SURVEY_SETTINGS);
  };

  return (
    <div className={styles.bottomBar}>
      <Button
        variant='text'
        size='small'
        leftIcon={<ChevronLeftIcon />}
        onClick={goToPreviousStep}>
        Back
      </Button>

      <Button onClick={goToNextStep}>Next: Recruitment Summary</Button>
    </div>
  );
};
