import React, {ReactElement, useEffect, useMemo, useState} from "react";

import {InputBubble} from "./input-bubble";
import {ResponseBubble} from "./response-bubble";
import {ScrollableList} from "./scrollable-list";
import {Button, Spinner} from "../../shared/v2";
import {useChatConversationContext} from "../../context/chat-contexts";
import {ImageStudio} from "../image-studio";
import {useSearchParams} from "@/route";
import {getImageFromBase64, isChatFakeQueryMessage, isChatQueryMessage} from "@/shared";

export interface ChatProps {
	className?: string;
  wrapperClassName?: string;
}

export const Chat = (props: ChatProps): ReactElement => {
	const {canvasId} = useSearchParams();
	const {
		conversation: {messages},
		isCreating,
		isInitialLoading: isLoadingChat,
		isSendingQuestion,
		send: sendQuestion,
	} = useChatConversationContext();
	const isSendingFirstQuestion = useMemo(() => isCreating && isSendingQuestion, [isSendingQuestion])
	const [key, setKey] = useState(`${canvasId}-${messages[0]?.id}`);
	const id = canvasId;

	useEffect(() => {
		setKey(`${id}-${messages[0]?.id}`);
	}, [id, messages]);

	const handleSubmit = (base64: string) => {
		const image = getImageFromBase64(base64);

		sendQuestion({
			query: "Describe the image",
			nextQuestionInput: {image},
		});
	}

	if ((isLoadingChat || key !== `${id}-${messages[0]?.id}`) && !isSendingFirstQuestion) {
		return <Spinner />;
	}

	return (
		<>
			<ScrollableList key={key} {...props}>
				{messages
					.sort((a, b) => a.position - b.position)
					.map((message, index) =>
						isChatQueryMessage(message) || isChatFakeQueryMessage(message) ? (
							<InputBubble key={index} content={message.content} message={message} />
						) : (
							<ResponseBubble
								key={index}
								message={message}
							/>
						)
					)}
			</ScrollableList>

				<ImageStudio
					submitButton={
						<Button size="small">
							Save to conversation
						</Button>
					}
					onSubmit={handleSubmit}
				/>
		</>
	);
};
