import React, { ReactElement } from "react";
import { Accordion } from "../../../Accordion";
import { SectionItem } from "../../../SectionItem";
import { Body, Input, NumberInput, RadioGroup, Separator } from "@/shared/v2";
import {
  AGE_RANGES,
  EDUCATIONS,
  ETHNICITIES,
  GENDERS,
  HOUSEHOLD_INCOMES,
  PAST_PARTICIPATIONS,
  useRecruitContext,
} from "@/campaign/contexts/RecruitContext";
import { CheckboxesSection } from "../../../CheckboxesSection";

import styles from "./AdditionalFilteringAccordion.module.scss";

export const AdditionalFilteringAccordion = (): ReactElement => {
  const {
    householdIncomes,
    setHouseholdIncomes,
    educations,
    setEducations,
    ethnicities,
    setEthnicities,
    gender,
    setGender,
    ageRanges,
    setAgeRanges,
    pastParticipation,
    setPastParticipation,
    lastParticipationMonths,
    setLastParticipationMonths,
  } = useRecruitContext();

  return (
    <Accordion title='Additional Filtering' isOptional>
      <div className={styles.detailsContent}>
        <SectionItem
          title='Past Participation'
          isOptional
          description='Allow participants to apply only if they meet the following criteria'>
          <RadioGroup
            value={pastParticipation}
            onChange={setPastParticipation}
            size='s'
            className={styles.radioGroup}>
            <RadioGroup.Option
              value={PAST_PARTICIPATIONS[0].id}
              label={PAST_PARTICIPATIONS[0].name}
            />
            <RadioGroup.Option
              value={PAST_PARTICIPATIONS[1].id}
              label={
                <Body color='text-secondary' className={styles.label}>
                  {PAST_PARTICIPATIONS[1].name}
                  <NumberInput
                    className={styles.input}
                    size='small'
                    value={lastParticipationMonths}
                    onChange={setLastParticipationMonths}
                  />
                  Months
                </Body>
              }
            />
            <RadioGroup.Option
              value={PAST_PARTICIPATIONS[2].id}
              label={PAST_PARTICIPATIONS[2].name}
            />
          </RadioGroup>
        </SectionItem>

        <Separator color='recruit' className={styles.separator} />

        <SectionItem
          title='Household Income'
          isOptional
          description='Participants that do not match this criteria can still apply, but will appear as “Not Qualified” in your participant list'>
          <CheckboxesSection
            collection={HOUSEHOLD_INCOMES}
            selectedItems={householdIncomes}
            onChange={setHouseholdIncomes}
          />
        </SectionItem>

        <Separator color='recruit' className={styles.separator} />

        <SectionItem
          title='Gender'
          isOptional
          description='Allow participants to apply only if they meet the following criteria'>
          <RadioGroup
            value={gender}
            onChange={setGender}
            size='s'
            className={styles.radioGroup}>
            {GENDERS.map((gender, index) => {
              return (
                <RadioGroup.Option
                  value={gender.id}
                  label={gender.name}
                  key={index}
                />
              );
            })}
          </RadioGroup>
        </SectionItem>

        <Separator color='recruit' className={styles.separator} />

        <SectionItem
          title='Education'
          isOptional
          description='Allow participants to apply only if they meet the following criteria'>
          <CheckboxesSection
            collection={EDUCATIONS}
            selectedItems={educations}
            onChange={setEducations}
          />
        </SectionItem>

        <Separator color='recruit' className={styles.separator} />

        <SectionItem
          title='Ethnicity'
          isOptional
          description='Allow participants to apply only if they meet the following criteria'>
          <CheckboxesSection
            collection={ETHNICITIES}
            selectedItems={ethnicities}
            onChange={setEthnicities}
          />
        </SectionItem>

        <Separator color='recruit' className={styles.separator} />

        <SectionItem
          title='Age Range'
          isOptional
          description='Allow participants to apply only if they meet the following criteria'>
          <CheckboxesSection
            collection={AGE_RANGES}
            selectedItems={ageRanges}
            onChange={setAgeRanges}
          />
        </SectionItem>
      </div>
    </Accordion>
  );
};
