import React, {ReactElement} from "react";
import classNames from "classnames/bind";

import {Body, Subheader} from "../typography";
import {
	ChatBubbleCameraIcon,
	ChatBubbleCheckmarkIcon,
	ChatBubbleCompletesIcon,
	ChatBubbleQuestionIcon,
} from "../../../icons";
import {useThemeMode} from "../../../context/theme-mode-context";
import {Campaign} from "../../../models/ai-model";
import {Checkbox} from "@/shared/v2";
import {convertTimeFromMiliseconds} from "../../utility/utility";

import styles from "./campaign-card.module.scss";

const bStyles = classNames.bind(styles);

export interface CampaignCardProps {
	campaign: Campaign
	isOutlined: boolean;
	isChecked: boolean;
	onSelect: (campaign: Campaign) => void;
	onClick?: (campaign: Campaign) => void;
}

export interface CounterItemProps {
	icon: ReactElement;
	label: string;
	value: number | string;
}

export const CounterContainer = ({icon, label, value}: CounterItemProps): ReactElement => {
	const {isDarkMode} = useThemeMode();


	return <div className={styles.counterContainer}>
		{icon}
		<Body color="text-secondary" className={styles.inline} type="medium" size="s">{label}</Body>
		<Body className={bStyles("value", {isDarkMode})} type="bold" size="s">{value}</Body>
	</div>;
};

export const CampaignCard = ({campaign, isOutlined, isChecked, onSelect, onClick}: CampaignCardProps): ReactElement => {
	const {isDarkMode} = useThemeMode();

	return <div
		className={bStyles("cardWrapper", {isSelected: isOutlined, isDarkMode})}
		onClick={() => onClick ? onClick(campaign) : onSelect(campaign)}
		tabIndex={0}
	>
		<div className={styles.content}>
			<div className={styles.headerContainer}>
				<div className={styles.titleContainer}>
					<Checkbox
						checked={isChecked}
						onClick={(evt) => {
							if (onClick) {
								evt.stopPropagation();
							}
						}}
						onChange={() => onClick && onSelect(campaign)}
					/>
					<Subheader type="semibold">
						{campaign.name}
					</Subheader>
				</div>
			</div>

			<div className={styles.countersContainer}>
				<CounterContainer
					icon={<ChatBubbleQuestionIcon className={styles.icon} />}
					label="Questions:"
					value={campaign.questionCount}
				/>

				<CounterContainer
					icon={<ChatBubbleCheckmarkIcon className={styles.icon} />}
					label="Responses:"
					value={campaign.responseCount}
				/>

				<CounterContainer
					icon={<ChatBubbleCompletesIcon className={styles.icon} />}
					label="Completes:"
					value={campaign.completedResponseCount}
				/>

				<CounterContainer
					icon={<ChatBubbleCameraIcon className={styles.icon} />}
					label="Video Minutes:"

					value={`${Math.floor(convertTimeFromMiliseconds(campaign.totalVideoDuration, "minutes"))} min`}
				/>
			</div>
		</div>
	</div>;
};
