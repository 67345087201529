import React, {ReactElement} from "react";

import {Button} from "@/shared/v2";
import {useSelectedSourcesContext} from "../../contexts/selected-sources-context";
import {useSelectedCampaignsContext} from "../../contexts/selected-campaigns-context";
import {useSelectedQuestionsContext} from "../../contexts/selected-questions-context";

export const ClearAllButton = (): ReactElement => {
  const {set: setSelectedSources} = useSelectedSourcesContext();
  const {set: setSelectedCampaigns} = useSelectedCampaignsContext();
  const {set: setSelectedQuestions} = useSelectedQuestionsContext();

  const handleClearAll = () => {
    setSelectedSources({
      trainingSets: [],
      files: [],
      videos: [],
    });
    setSelectedCampaigns([]);
    setSelectedQuestions([]);
  };

  return (
    <Button variant="text" onClick={handleClearAll}>
      Clear All
    </Button>
  );
}
