import React, {useMemo} from "react"
import classNames from "classnames/bind";
import {useState} from "react";

import {useThemeMode} from "@/context/theme-mode-context";
import {ButtonIcon, Input, SelectInput} from "@/shared/v2";
import {Label} from "../labels-form";
import {BinDeleteIcon} from "@/icons";

import styles from './label-form.module.scss';

const bStyles = classNames.bind(styles);

export interface Key {
  id: string;
  value: string;
}

interface LabelFormProps {
  number: number;
  onRemove?: () => void;
  label: Label;
  onUpdate: (updatedLabel: Label) => void;
  allKeys?: Key[];
}


export const LabelForm =  ({
  number,
  onRemove,
  label,
  onUpdate,
  allKeys,
}: LabelFormProps) => {
  const {isDarkMode} = useThemeMode();
  const [key, setKey] = useState(label.key);
  const [value, setValue] = useState(label.value);

  const handleKeyChange = (newKey: string) => {
    setKey(newKey);
    onUpdate({ ...label, key: newKey });
  };

  const handleValueChange = (newValue: string) => {
    setValue(newValue);
    onUpdate({ ...label, value: newValue });
  };

  const allAvailableKeys = useMemo(() => {
    if (!allKeys) {
      return [];
    }

    return allKeys.map(key => ({ id: key.value, name: key.value }));
  }, [allKeys])

  return <div className={styles.labelForm}>
    <SelectInput
      label={`Key ${number}`}
      value={key}
      onChange={handleKeyChange}
      options={allAvailableKeys}
      onSearch={(search, option) => option.name.toLowerCase().includes(search.toLowerCase())}
    />
    <Input className={styles.input} label={`Value ${number}`} value={value} onChange={handleValueChange} />

    <ButtonIcon
      className={styles.buttonIcon}
      onClick={onRemove}
      icon={<BinDeleteIcon className={bStyles("deleteIcon", {isDarkMode})} />}
    />
  </div>
}
