import {ChatConversation, ChatConversationMessage, ChatDeleteMessagePairResponse} from "@/models/ai-model";
import {handleConversationEvent, handleCreateConversation, handleUpdateConversation, isChatFakeResponseMessage} from "@/shared";
import {handleSetConversation} from "@/shared/utility/chat/handle-set-conversation";
import {ChatConversationMode, ChatLayoutMode, ChatState, CreateConversationPayload, HandleConversationEventPayload, RevertActionPayload, SendQuestionPayload, SyncLocationStatePayload, UpdateConversationPayload} from "./types";
import {handleRevertAction} from "@/shared/utility/chat/handle-revert-action";
import {NetworkStatus} from "@apollo/client";
import {handleSendQuestion} from "@/shared/utility/chat/handle-send-question";
import {formatChatState} from "@/shared/utility/chat/format-chat-state";
import {formatReducedChatConversation} from "@/shared/utility/chat/format-reduced-chat-conversation";
import config from "@/config";

export enum ChatActionTypes {
  CLEAR_CONVERSATION = "CLEAR_CONVERSATION",
  CREATE_CONVERSATION = "CREATE_CONVERSATION",
  DELETE_MESSAGE_PAIR = "DELETE_MESSAGE_PAIR",
  HANDLE_CONVERSATION_EVENT = "HANDLE_CONVERSATION_EVENT",
  NETWORK_STATUS_CHANGE = "NETWORK_STATUS_CHANGE",
  REVERT_ACTION = "REVERT_ACTION",
  SEND_QUESTION = "SEND_QUESTION",
  SEND_QUESTION_SUCCESS = "SEND_QUESTION_SUCCESS",
  SET_CONVERSATION = "SET_CONVERSATION",
  SYNC_LOCATION_STATE = "SYNC_LOCATION_STATE",
  UPDATE_CONVERSATION = "UPDATE_CONVERSATION",
}

export type ChatAction =
  | { type: ChatActionTypes.SET_CONVERSATION, payload: { conversation: ChatConversation } }
  | { type: ChatActionTypes.CLEAR_CONVERSATION }
  | { type: ChatActionTypes.UPDATE_CONVERSATION, payload: UpdateConversationPayload }
  | { type: ChatActionTypes.REVERT_ACTION, payload: RevertActionPayload }
  | { type: ChatActionTypes.SEND_QUESTION, payload: SendQuestionPayload }
  | { type: ChatActionTypes.SEND_QUESTION_SUCCESS, payload: { response: ChatConversationMessage} }
  | { type: ChatActionTypes.CREATE_CONVERSATION, payload?: CreateConversationPayload }
  | { type: ChatActionTypes.DELETE_MESSAGE_PAIR, payload: ChatDeleteMessagePairResponse }
  | { type: ChatActionTypes.NETWORK_STATUS_CHANGE, payload: { networkStatus: NetworkStatus } }
  | { type: ChatActionTypes.SYNC_LOCATION_STATE, payload: SyncLocationStatePayload }
  | { type: ChatActionTypes.HANDLE_CONVERSATION_EVENT, payload: HandleConversationEventPayload }
export type ChatReducerType = (state: ChatState, action: ChatAction) => ChatState;

export const initialState: ChatState = {
  original: null,
  changes: {},
  current: {
    aiPersona: null,
    surveys: [],
    createdAt: null,
    files: [],
    id: null,
    isPreview: false,
    layout: ChatLayoutMode.HOME,
    messages: [],
    mode: ChatConversationMode.CONVERSATION,
    name: null,
    persona: null,
    question: null,
    questions: [],
    tool: null,
    trainingSets: [],
    videos: [],
    nextQuestion: {
      query: null,
      persona: null,
      mention: null,
      instructions: null,
      image: null,
      document: null,
      systemAgent: null,
      message: null,
    },
  },
  actions: {
    isSendingQuestion: false,
    isCreating: false,
    isUpdating: false,
    isInitialLoading: false,
    isLoading: false,
  },
};

const assertNever = (state: never): never => {
  throw new Error("Unexpected action: " + JSON.stringify(state));
}

export const chatReducer: ChatReducerType = (state, action): ChatState => {
  switch (action.type) {
    case ChatActionTypes.CLEAR_CONVERSATION: {
      return initialState;
    }
    case ChatActionTypes.SET_CONVERSATION: {
      const newState = handleSetConversation(action.payload.conversation, state);

      return formatChatState(newState);
    }
    case ChatActionTypes.UPDATE_CONVERSATION: {
      const newState = handleUpdateConversation(state, action.payload);

      return newState;
    }
    case ChatActionTypes.REVERT_ACTION: {
      const newState = handleRevertAction(state, action.payload);

      return newState;
    }
    case ChatActionTypes.CREATE_CONVERSATION: {
      const newState = handleCreateConversation(state, action.payload);

      return newState;
    }
    case ChatActionTypes.SEND_QUESTION: {
      const newState = handleSendQuestion(state, action.payload);

      return newState;
    }
    case ChatActionTypes.SEND_QUESTION_SUCCESS: {
      const newMessages = state.current.messages.map((message) => {
        if (isChatFakeResponseMessage(message)) {
          return action.payload.response;
        }

        return message;
      })
      const newNextQuestion = {...initialState.current.nextQuestion}
      const newCurrent = formatReducedChatConversation({
        ...state.current,
        messages: newMessages,
        nextQuestion: newNextQuestion,
      });
      const newActions = {
        ...state.actions,
        isSendingQuestion: false,
      }

      return {
        ...state,
        actions: newActions,
        current: newCurrent,
      };
    }
    case ChatActionTypes.NETWORK_STATUS_CHANGE: {
      const {networkStatus} = action.payload;
      const {isCreating, isUpdating} = state.actions;

      if (
        (networkStatus === NetworkStatus.loading ||
        networkStatus === NetworkStatus.setVariables) && !isCreating
      ) {
        const newActions = {
          ...state.actions,
          isInitialLoading: true,
          isLoading: true,
        }
        const newCurrent = {
          ...initialState.current,
          name: "Loading...",
          layout: ChatLayoutMode.CHAT,
        }

        return {
          ...state,
          actions: newActions,
          current: newCurrent,
        }
      }

      if (
        networkStatus === NetworkStatus.refetch ||
        isCreating ||
        isUpdating
      ) {
        const newActions = {
          ...state.actions,
          isInitialLoading: false,
          isLoading: true,
        }

        return {
          ...state,
          actions: newActions,
        }
      }

      return state;
    }
    case ChatActionTypes.SYNC_LOCATION_STATE: {
      const {chat: chatPayload, invokeSendQuestion} = action.payload
      let newState = {...state};

      newState = handleUpdateConversation(newState, chatPayload);

      if (invokeSendQuestion) {
        newState = handleSendQuestion(newState, {query: invokeSendQuestion});
      }

      return newState;
    }
    case ChatActionTypes.DELETE_MESSAGE_PAIR: {
      const {chatDeleteMessagePair} = action.payload;
      const newMessages = state.current.messages.filter((message) => !chatDeleteMessagePair.some(m => m.id === message.id));

      const newCurrent = {
        ...state.current,
        messages: newMessages,
      }

      return {
        ...state,
        current: newCurrent,
      }
    }
    case ChatActionTypes.HANDLE_CONVERSATION_EVENT: {
      const newState = handleConversationEvent(state, action.payload);

      return newState;
    }
    default:
      return assertNever(action);
  }
};
