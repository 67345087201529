import {GET_TRAINING_SETS} from "@/graphql/queries/ai-models-queries";
import {useQuery} from "@apollo/client";
import {useWorkspaceContext} from "@/context/workspace-context";
import {GetTrainingSetsResponse, GetTrainingSetsVariables} from "@/models/ai-model";

export const useGetTrainingSets = () => {
  const {workspace: {id: workspaceId}} = useWorkspaceContext();

  const {
		data: { trainingSets = [] } = {},
		loading: isLoading,
		refetch,
	} = useQuery<GetTrainingSetsResponse, GetTrainingSetsVariables>(GET_TRAINING_SETS, {
		variables: {workspaceId},
		fetchPolicy: "cache-and-network",
	});

  return {trainingSets, isLoading, refetch};
};
