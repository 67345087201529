import {InMemoryCache, makeVar} from "@apollo/client";
import {updateObject} from "./shared";
import {isParseable} from "./shared/utility/helpers";
import {ParsedInstructions, ParsedTaskInstructions} from "./models/persona";

export const toggleCreateWorkspaceModal = makeVar<{
	isShowing: boolean;
	type: "WORKSPACE" | "ENTERPRISE";
}>({
	isShowing: false,
	type: "WORKSPACE",
});
export const toggleDeleteResponseModal = makeVar<boolean>(false);
export const toggleInviteToSurveyModal = makeVar<boolean>(false);
export const toggleIntroImageUpload = makeVar<boolean>(false);
export const toggleRecruitModal = makeVar<boolean>(false);
export const toggleShowFilter = makeVar<boolean>(false);
export const toggleShowUploadModal = makeVar<boolean>(false);
export const toggleShowUserModal = makeVar<boolean>(false);
export const toggleShowRemoveMemberModal = makeVar<boolean>(false);
export const toggleShowInviteModal = makeVar<boolean>(false);
export const toggleDeleteQuestionModal = makeVar<{
	isShowing: boolean;
	type: "question" | "choice";
	id: string;
}>({
	isShowing: false,
	type: "question",
	id: "",
});
export const toggleDeleteConfirmModal = makeVar<{
	isShowing: boolean;
	id: string;
}>({
	isShowing: false,
	id: "",
});
export const selectedUser = makeVar<string | undefined>("");
export const selectedResponse = makeVar<string | undefined>("");
export const selectedAnswer = makeVar<string | undefined>("");
export const selectedSurvey = makeVar<{id: string; name: string}>({
	id: "",
	name: "",
});
export const toggleDeleteSurveyModal = makeVar<boolean>(false);

export const cache: InMemoryCache = new InMemoryCache({
	typePolicies: {
		Answer: {
			fields: {
				video: {
					read(existing) {
						return existing;
					},
					merge(_ignored, incoming) {
						return incoming;
					},
				},
			},
		},
		Response: {
			fields: {
				user: {
					read(existing) {
						return existing;
					},
					merge(_, incoming) {
						return incoming;
					},
				},
			},
		},
		Choice: {
			fields: {
				text: {
					merge(_ignored, incoming) {
						return incoming;
					},
				},
			},
		},
		Question: {
			fields: {
				choices: {
					merge(_ignored, incoming) {
						return incoming;
					},
				},
				choiceCounts: {
					merge(_ignored, incoming) {
						return incoming;
					},
				},
				zipFile: {
					merge(_ignored, incoming) {
						return incoming;
					},
				},
			},
		},
		QuestionInsights: {
			fields: {
				summary: {
					merge(_, incoming: string) {
						if (isParseable(incoming)) return JSON.parse(incoming);
						return incoming;
					},
				},
			},
		},
		Reel: {
			fields: {
				editors: {
					merge(_ignored, incoming) {
						return incoming;
					},
				},
			},
		},
		BrandsPage: {
			fields: {
				items: {
					merge(existing = [], incoming, {variables}) {
						if (!variables?.cursor) {
							return incoming;
						}
						return existing ? [...existing, ...incoming] : incoming;
					},
					read(existing) {
						return existing;
					},
				},
			},
		},
		FollowersPage: {
			fields: {
				items: {
					merge(existing = [], incoming, {variables}) {
						if (!variables?.cursor) {
							return incoming;
						}
						return existing ? [...existing, ...incoming] : incoming;
					},
					read(existing) {
						return existing;
					},
				},
			},
		},
		AnswerPage: {
			fields: {
				items: {
					merge(existing = [], incoming, {variables}) {
						if (!variables?.cursor) {
							return incoming;
						}
						return existing ? [...existing, ...incoming] : incoming;
					},
					read(existing) {
						return existing;
					},
				},
			},
		},
		AttributePage: {
			fields: {
				items: {
					merge(existing = [], incoming, {variables}) {
						if (!variables?.cursor) {
							return incoming;
						}
						return existing ? [...existing, ...incoming] : incoming;
					},
					read(existing) {
						return existing;
					},
				},
			},
		},
		ChatMessage: {
			fields: {
				likedByUsers: {
					merge(_ignored, incoming){
						return incoming;
					},
					read(existing) {
						return existing;
					},
				},
				dislikedByUsers: {
					merge(_ignored, incoming){
						return incoming;
					},
					read(existing) {
						return existing;
					},
				},
			},
		},
		ResponsePage: {
			fields: {
				items: {
					merge(existing = [], incoming, {variables}) {
						if (!variables?.cursor) {
							return incoming;
						}
						return existing ? [...existing, ...incoming] : incoming;
					},
					read(existing) {
						return existing;
					},
				},
			},
		},
		DynamicSegmentPage: {
			fields: {
				items: {
					merge(existing = [], incoming, {variables}) {
						if (!variables?.cursor) {
							return incoming;
						}
						return existing ? [...existing, ...incoming] : incoming;
					},
					read(existing) {
						return existing;
					},
				},
			},
		},
		SegmentPage: {
			fields: {
				items: {
					merge(existing = [], incoming, {variables}) {
						if (!variables?.cursor) {
							return incoming;
						}
						return existing ? [...existing, ...incoming] : incoming;
					},
					read(existing) {
						return existing;
					},
				},
			},
		},
		SurveyPage: {
			fields: {
				items: {
					merge(existing = [], incoming, {variables}) {
						if (!variables?.cursor) {
							return incoming;
						}
						return existing ? [...existing, ...incoming] : incoming;
					},
					read(existing) {
						return existing;
					},
				},
			},
		},
		UserPage: {
			fields: {
				items: {
					merge(existing = [], incoming, {variables}) {
						if (!variables?.cursor) {
							return incoming;
						}
						return existing ? [...existing, ...incoming] : incoming;
					},
					read(existing) {
						return existing;
					},
				},
			},
		},
		SurveyMembership: {
			keyFields: ["userId"],
			fields: {
				emails: {
					merge(_, incoming) {
						return incoming;
					},
				},
			},
		},
		SurveyMembershipPage: {
			fields: {
				items: {
					merge(existing = [], incoming, {variables}) {
						if (!variables?.cursor) {
							return incoming;
						}
						return existing ? [...existing, ...incoming] : incoming;
					},
					read(existing) {
						return existing;
					},
				},
			},
		},
		AiPersonaTask: {
			fields: {
				parsedInstructions(_, {readField}) {
					const temp = readField("instructions") as string;
					if (typeof temp === "string" && isParseable(temp)) {
						const parsed: ParsedTaskInstructions[] = JSON.parse(temp);
						return parsed[0];
					}
					return undefined;
				}
			},
		},
		AiPersona: {
			fields: {
				parsedInstructions(_, {readField}) {
					const temp = readField("instructions") as string;
					if (typeof temp === "string" && isParseable(temp)) {
						const parsed: ParsedInstructions[] = JSON.parse(temp);
						return parsed[0];
					}
					return undefined;
				},
			},
		},
		AnswerVideo: {
			fields: {
				url: {
					keyArgs: ["version"],
					merge(_ignored, incoming) {
						return incoming;
					},
				},
			},
		},
		SurveyInsights: {
			fields: {
				summary: {
					merge(_, incoming) {
						return typeof incoming === "string" && isParseable(incoming) ? JSON.parse(incoming) : incoming;
					},
				},
				headlines: {
					merge(_, incoming) {
						return typeof incoming === "string" && isParseable(incoming) ? JSON.parse(incoming) : incoming;
					},
				},
				objective: {
					merge(_, incoming) {
						return typeof incoming === "string" && isParseable(incoming) ? JSON.parse(incoming) : incoming;
					},
				},
				insights: {
					merge(_, incoming) {
						return typeof incoming === "string" && isParseable(incoming) ? JSON.parse(incoming) : incoming;
					},
				},
				cons: {
					merge(_, incoming) {
						return typeof incoming === "string" && isParseable(incoming) ? JSON.parse(incoming) : incoming;
					},
				},
				pros: {
					merge(_, incoming) {
						return typeof incoming === "string" && isParseable(incoming) ? JSON.parse(incoming) : incoming;
					},
				},
				ideas: {
					merge(_, incoming) {
						return typeof incoming === "string" && isParseable(incoming) ? JSON.parse(incoming) : incoming;
					},
				},
			},
		},
		Workspace: {
			fields: {
				followers: {
					merge(existing = [], incoming, {variables}) {
						if (!variables?.cursor) {
							return incoming;
						}
						return existing ? updateObject(existing, {...incoming}) : incoming;
					},
					read(existing) {
						return existing;
					},
				},
			},
		},
		WorkspaceRewardSetting: {
			keyFields: ["rewardType", ["id"] ],
			merge(_ignored, incoming) {
				return incoming;
			},
		},
		SurveyUsers: {
			fields: {
				users: {
					merge(existing = [], incoming) {
						return [...existing, ...incoming];
					},
				},
			},
		},
		TremendousFundingSources: {
			fields: {
				meta: {
					merge(_, incoming) {
						return incoming;
					},
				},
			},
		},
		WorkspacePage: {
			fields: {
				items: {
					merge(existing = [], incoming, {variables}) {
						if (!variables?.cursor) {
							return incoming;
						}
						return existing ? [...existing, ...incoming] : incoming;
					},
					read(existing) {
						return existing;
					},
				},
			},
		},
		Query: {
			fields: {
				// Optimize caching for main routes
				contacts: {
					keyArgs: ["workspaceId", "filter"],
					merge(existing, incoming, {variables, readField}) {
						// If no cursor, it's a fresh fetch
						if (!variables?.cursor) {
							return incoming;
						}

						// If we have existing data, merge it with incoming
						if (existing) {
							// Ensure we don't duplicate items
							const existingIds = new Set(
								existing.items.map(item => readField('id', item))
							);

							const newItems = incoming.items.filter(
								item => !existingIds.has(readField('id', item))
							);

							return {
								...incoming,
								items: [...existing.items, ...newItems]
							};
						}

						return incoming;
					},
					read(existing) {
						return existing;
					}
				},
				workspaceSurveys: {
					keyArgs: ["workspaceId", "filter"],
					merge(existing, incoming, {variables, readField}) {
						if (!variables?.cursor) {
							return incoming;
						}

						if (existing) {
							const existingIds = new Set(
								existing.items.map(item => readField('id', item))
							);

							const newItems = incoming.items.filter(
								item => !existingIds.has(readField('id', item))
							);

							return {
								...incoming,
								items: [...existing.items, ...newItems]
							};
						}

						return incoming;
					},
					read(existing) {
						return existing;
					}
				},
				chatConversations: {
					keyArgs: ["workspaceId", "filter"],
					merge(_ignored, incoming) {
						return incoming;
					}
				},
				paginatedChatConversations: {
					keyArgs: ["workspaceId", "filter"],
					merge(existing, incoming, {variables, readField}) {
						if (!variables?.cursor) {
							return incoming;
						}

						if (existing) {
							const existingIds = new Set(
								existing.items.map(item => readField('id', item))
							);

							const newItems = incoming.items.filter(
								item => !existingIds.has(readField('id', item))
							);

							return {
								...incoming,
								items: [...existing.items, ...newItems]
							};
						}

						return incoming;
					},
					read(existing) {
						return existing;
					}
				},
				trainingSets: {
					keyArgs: ["workspaceId"],
					merge(_ignored, incoming) {
						return incoming;
					}
				},
				trainingSetsPage: {
					keyArgs: ["workspaceId", "filter"],
					merge(existing, incoming, {variables, readField}) {
						if (!variables?.cursor) {
							return incoming;
						}

						if (existing) {
							const existingIds = new Set(
								existing.items.map(item => readField('id', item))
							);

							const newItems = incoming.items.filter(
								item => !existingIds.has(readField('id', item))
							);

							return {
								...incoming,
								items: [...existing.items, ...newItems]
							};
						}

						return incoming;
					},
					read(existing) {
						return existing;
					}
				},
				aiOrchestrationsPage: {
					keyArgs: ["workspaceId", "filter"],
					merge(existing, incoming, {variables, readField}) {
						if (!variables?.cursor) {
							return incoming;
						}
						if (existing) {
							const existingIds = new Set(
								existing.items.map(item => readField('id', item))
							);

							const newItems = incoming.items.filter(
								item => !existingIds.has(readField('id', item))
							);

							return {
								...incoming,
								items: [...existing.items, ...newItems]
							};
						}

						return incoming;
					},
					read(existing) {
						return existing;
					}
				},
				// Existing field policies
				answer: {
					read(_, {args, toReference}) {
						return toReference({
							__typename: "Answer",
							id: args?.id,
						});
					},
					merge(_ignored, incoming) {
						return incoming;
					},
				},
				segment: {
					read(_, {args, toReference}) {
						return toReference({
							__typename: "Segment",
							id: args?.id,
						});
					},
				},
				attributes: {
					merge(_ignored, incoming) {
						return incoming;
					},
					read(existing) {
						return existing;
					},
				},
				brands: {
					keyArgs: ["filter"],
					merge(_ignored, incoming) {
						return incoming;
					},
				},
				answers: {
					keyArgs: ["filter", "sort", "questionId"],
					merge(_ingored, incoming) {
						return incoming;
					},
				},
				countries: {
					merge(_ignored, incoming) {
						return incoming;
					},
					read(existing) {
						return existing;
					},
				},
				ethnicities: {
					merge(_ignored, incoming) {
						return incoming;
					},
					read(existing) {
						return existing;
					},
				},
				states: {
					merge(_ignored, incoming) {
						return incoming;
					},
					read(existing) {
						return existing;
					},
				},
				followers: {
					merge(_ignored, incoming) {
						return incoming;
					},
				},
				searchAnswers: {
					keyArgs: ["filter", "sort", "workspaceId", "matchText"],
					merge(_ingored, incoming) {
						return incoming;
					},
				},
				response: {
					merge(_ignored, incoming) {
						return incoming;
					},
				},
				responses: {
					keyArgs: ["surveyId", "filter"],
					merge(_ignored, incoming) {
						return incoming;
					},
				},
				contact: {
					read(_, {args, toReference}) {
						return toReference({
							__typename: "User",
							id: args?.userId || args?.id,
						});
					},
				},
				questions: {
					merge(_ignored, incoming) {
						return incoming;
					},
				},
				selectedAnswer: {
					read() {
						return selectedAnswer();
					},
				},
				dynamicSegment: {
					read(_, {args, toReference}) {
						return toReference({
							__typename: "DynamicSegment",
							id: args?.id,
						});
					},
				},
				dynamicSegments: {
					merge(_ignored, incoming) {
						return incoming;
					},
					read(existing) {
						return existing;
					},
				},
				workspaceMembers: {
					merge(_ignored, incoming) {
						return incoming;
					},
					read(existing) {
						return existing;
					},
				},
				segments: {
					merge(_ignored, incoming) {
						return incoming;
					},
					read(existing) {
						return existing;
					},
				},
				surveyMembers: {
					keyArgs: ["surveyId", "filter"],
					merge(_ignored, incoming) {
						return incoming;
					},
					read(existing) {
						return existing;
					},
				},
				selectedSurvey: {
					read() {
						return selectedSurvey();
					},
				},
				selectedUser: {
					read() {
						return selectedUser();
					},
				},
				selectedResponse: {
					read() {
						return selectedResponse();
					},
				},
				toggleCreateWorkspaceModal: {
					read() {
						return toggleCreateWorkspaceModal();
					},
				},
				toggleDeleteResponseModal: {
					read() {
						return toggleDeleteResponseModal();
					},
				},
				toggleDeleteConfirmModal: {
					read() {
						return toggleDeleteConfirmModal();
					},
				},
				toggleDeleteQuestionModal: {
					read() {
						return toggleDeleteQuestionModal();
					},
				},
				toggleInviteToSurveyModal: {
					read() {
						return toggleInviteToSurveyModal();
					},
				},
				toggleIntroImageUpload: {
					read() {
						return toggleIntroImageUpload();
					},
				},
				toggleRecruitModal: {
					read() {
						return toggleRecruitModal();
					},
				},
				toggleShowFilter: {
					read() {
						return toggleShowFilter();
					},
				},
				toggleDeleteSurveyModal: {
					read() {
						return toggleDeleteSurveyModal();
					},
				},
				toggleShowRemoveMemberModal: {
					read() {
						return toggleShowRemoveMemberModal();
					},
				},
				toggleShowUploadModal: {
					read() {
						return toggleShowUploadModal();
					},
				},
				toggleShowUserModal: {
					read() {
						return toggleShowUserModal();
					},
				},
				toggleShowInviteModal: {
					read() {
						return toggleShowInviteModal();
					},
				},
				allWorkspaces: {
					keyArgs: ["filter"],
					merge(_ignored, incoming) {
						return incoming;
					},
				},
			},
		},
	},
});
