import {useQuery} from "@apollo/client";
import React, {ReactElement, useMemo} from "react";

import {Body, Spinner} from "../../../../../shared/v2";
import {ChatConversation, Persona} from "../../../../../models";
import {GET_AGENT_RECENT_CONVERSATIONS} from "../../../../../graphql/queries/ai-models-queries";
import {RelatedConversationEntry} from "./related-conversation-entry";
import {useWorkspaceContext} from "../../../../../context/workspace-context";

import styles from "./related-conversations-section.module.scss";

export interface RelatedConversationsSectionProps {
	agent: Persona;
}

export const RelatedConversationsSection = ({agent}: RelatedConversationsSectionProps): ReactElement => {
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const {id: agentId} = agent;

	const {data, loading: isLoadingRelatedConversations} = useQuery(GET_AGENT_RECENT_CONVERSATIONS, {
		variables: {workspaceId},
		fetchPolicy: "cache-and-network",
	})

	const hasPersona = (conversation: ChatConversation) => {
		if (conversation.aiPersona?.id === agentId) {
			return true;
		}

		if (conversation.messages.some(message => message.persona?.id === agentId)) {
			return true;
		}

		return false;
	}

	const relatedConversations = useMemo(() => {
		if (!data) {
			return [];
		}

		return data.paginatedChatConversations?.items.filter(hasPersona);
	}, [data]);

	const renderContent = () => {
		if (isLoadingRelatedConversations) {
			return <Spinner className={styles.spinner} />;
		}

		if (relatedConversations.length === 0) {
			return <div className={styles.relatedConversations} />
		}

		return (
			<>
				<Body size="s" type="medium">
					Related Conversations
				</Body>

				<div className={styles.list}>
					{relatedConversations.map((conversation: ChatConversation, index) => (
						<RelatedConversationEntry key={conversation.id} conversation={conversation} index={index} />
					))}
				</div>
			</>
		);
	}

	return (
		<div className={styles.relatedConversationsSection}>
			{renderContent()}
		</div>
	);
}
