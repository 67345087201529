import React, {cloneElement, ReactElement, useRef} from "react";

import {CHAT_TOOLS_ICONS, CHAT_TOOLS_NAMES} from "@/shared/constants/constants";
import {useChatConversationContext, useChatDrawerContext} from "@/context/chat-contexts";
import {motion} from "framer-motion";
import {Section} from "./section";
import {useOnClickOutside} from "@/hooks";
import {useThemeMode} from "@/context/theme-mode-context";
import classNames from "classnames/bind";
import {ChatImageTool, ChatSocialTool, ChatWebTool, ChatToolValue, ChatConversationMode, ChatLayoutMode} from "@/reducer/chat-reducer";

import styles from "./chat-bubble-drawer.module.scss";

const cx = classNames.bind(styles);

export const ChatBubbleDrawer = (): ReactElement => {
  const {update: updateConversation, conversation: {layout}} = useChatConversationContext();
  const {setIsDrawerOpen} = useChatDrawerContext();
  const {isDarkMode} = useThemeMode();
  const drawerRef = useRef<HTMLDivElement>(null);
  const isAnimatingRef = useRef(false);

  useOnClickOutside(drawerRef, () => {
    if (isAnimatingRef.current) {
      return;
    };
    setIsDrawerOpen(false);
  }, "mousedown");

  const addClassToIcon = (icon: ReactElement, className: string) => {
    if (!icon) return icon; // Return the icon as-is instead of null
    return cloneElement(icon, {
      className: cx(icon.props?.className, className),
    });
  }

  const CLASS_NAMES = {
    [ChatImageTool.DALLE]: styles.iconImageDE,
    [ChatImageTool.IMAGEN]: styles.iconImageImagen,
    [ChatImageTool.STABLE_DIFFUSION]: styles.iconImageSD,
    [ChatSocialTool.INSTAGRAM]: styles.iconInstagram,
    [ChatSocialTool.LINKEDIN]: styles.iconLinkedin,
    [ChatSocialTool.REDDIT]: styles.iconReddit,
    [ChatSocialTool.TIKTOK]: styles.iconTiktok,
    [ChatSocialTool.X_TWITTER]: styles.iconXTwitter,
    [ChatSocialTool.YOUTUBE]: styles.iconYoutube,
    [ChatWebTool.SCRAPE_ADVANCED]: styles.iconScrapeAdvanced,
    [ChatWebTool.SCRAPE]: styles.iconScrape,
    [ChatWebTool.WEB]: styles.iconWeb,
  }

  const createOption = (value: ChatToolValue) => ({
    value,
    label: CHAT_TOOLS_NAMES[value],
    icon: addClassToIcon(CHAT_TOOLS_ICONS[value], CLASS_NAMES[value]),
  })

  const socialOptions = (Object.values(ChatSocialTool) as ChatSocialTool[]).map(createOption);
  // DO not display ALL option
  const imageOptions = (Object.values(ChatImageTool) as ChatImageTool[]).map(createOption).filter(option => option.value !== ChatImageTool.ALL);
  const webOptions = [
    createOption(ChatWebTool.WEB),
    createOption(ChatWebTool.SCRAPE),
    createOption(ChatWebTool.SCRAPE_ADVANCED),
  ];

  const handleSelectTool = (value: ChatToolValue) => {
    updateConversation({
      mode: ChatConversationMode.CONVERSATION,
      tool: value,
    });
  }

  return (
    <div
      className={styles.popup}
      ref={drawerRef}
    >
      <motion.div
        className={
          cx(
            "chatBubbleDrawer",
            layout === ChatLayoutMode.CHAT ? "chat" : "home",
            {isDarkMode},
          )
        }
        onAnimationStart={() => isAnimatingRef.current = true}
        onAnimationComplete={() => isAnimatingRef.current = false}
        initial={{
          height: 0,
          opacity: 0,
        }}
        animate={{
          height: "314px",
          opacity: 1,
        }}
        exit={{
          height: 0,
          opacity: 0,
        }}
        transition={{
          height: { duration: 0.3, ease: "easeInOut" },
          opacity: { duration: 0.4, ease: "easeInOut" },
        }}
      >
        <Section
          name="Social Channels"
          options={socialOptions}
          onSelect={handleSelectTool}
        />

        <Section
          name="Research"
          options={webOptions}
          onSelect={handleSelectTool}
        />

        <Section
          name="Image Models"
          options={imageOptions}
          onSelect={handleSelectTool}
        />
      </motion.div>
    </div>
  );
}
