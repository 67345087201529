import {ChatConversationMessageRole} from "@/models/ai-model";
import {ChatConversationMessageSubtype, ChatFakeConversationMessageBase, ChatFakeQueryMessage, ChatFakeResponseMessage} from "@/reducer/chat-reducer";
import {v4 as uuid} from "uuid";

export type GenerateChatFakeQueryMessageProps =
  Pick<ChatFakeConversationMessageBase, "content" | "position"> &
  Partial<Omit<ChatFakeConversationMessageBase, "content" | "position">> & {
    sentAt?: string;
  }

export type GenerateChatFakeResponseMessageProps =
  {role?: Omit<ChatConversationMessageRole, ChatConversationMessageRole.USER>} &
  Pick<ChatFakeConversationMessageBase, "position"> &
  Partial<Omit<ChatFakeConversationMessageBase, "content" | "position">>


export const generateChatFakeQueryMessage = (props: GenerateChatFakeQueryMessageProps): ChatFakeQueryMessage => {
  const { sentAt, ...restProps } = props;
  return {
    __typename: "ChatMessage",
    type: null,
    id: uuid(),
    role: ChatConversationMessageRole.USER,
    subtype: ChatConversationMessageSubtype.FAKE_QUERY,
    sentAt: sentAt || new Date().toISOString(),
    ...restProps,
  }
}

export const generateChatFakeResponseMessage = (props: GenerateChatFakeResponseMessageProps): ChatFakeResponseMessage => {
  const {role = ChatConversationMessageRole.ASSISTANT, ...rest} = props;

  return {
    __typename: "ChatMessage",
    id: uuid(),
    role,
    content: "",
    type: "text",
    subtype: ChatConversationMessageSubtype.FAKE_RESPONSE,
    stateHistory: [],
    ...rest,
  }
}
