import {ChatActionTypes, ChatLayoutMode, ChatState, RevertActionPayload} from "@/reducer/chat-reducer";

export const handleRevertAction = (state: ChatState, payload: RevertActionPayload): ChatState => {
  if (payload.action === ChatActionTypes.SEND_QUESTION) {
    const newMessages = [...state.current.messages].slice(0, -2);
    const newCurrent = {
      ...state.current,
      messages: newMessages,
    }
    const newActions = {
      ...state.actions,
      isSendingQuestion: false,
    }

    return {
      ...state,
      actions: newActions,
      current: newCurrent,
    }
  }

  if (payload.action === ChatActionTypes.CREATE_CONVERSATION) {
    const newActions = {
      ...state.actions,
      isCreating: false,
    }
    const newCurrent = {
      ...state.current,
      layout: ChatLayoutMode.HOME,
    }

    return {
      ...state,
      actions: newActions,
      current: newCurrent,
    };
  }

  if (payload.action === ChatActionTypes.UPDATE_CONVERSATION) {
    const newActions = {
      ...state.actions,
      isUpdating: false,
    }

    return {
      ...state,
      actions: newActions,
    };
  }

  return state;
}
