import React, { ReactElement } from "react";
import classNames from "classnames/bind";

import styles from "./SectionItem.module.scss";
import { Body, Tooltip } from "@/shared/v2";
import { TOOLTIP_DELAY_SHORT } from "@/shared/constants/constants";
import { InformationCicrcleIcon } from "@/icons";

const bStyles = classNames.bind(styles);

export interface SectionItemProps {
  title: string;
  description?: string;
  titleTooltip?: string;
  children: ReactElement;
  isOptional?: boolean;
}

export const SectionItem = ({
  title,
  description,
  titleTooltip,
  children,
  isOptional,
}: SectionItemProps): ReactElement => {
  return (
    <div className={bStyles("section", { withDescription: !!description })}>
      <div className={styles.header}>
        <div className={styles.title}>
          <Body type='semibold' color='text-secondary'>
            {title}
          </Body>
          {titleTooltip && (
            <Tooltip
              delay={TOOLTIP_DELAY_SHORT}
              content={<Body size='xs'>{titleTooltip}</Body>}>
              <InformationCicrcleIcon className={styles.infoIcon} />
            </Tooltip>
          )}
          {isOptional && (
            <Body size='s' color='text-tertiary'>
              (optional)
            </Body>
          )}
        </div>
        {description && (
          <Body size='s' color='text-tertiary'>
            {description}
          </Body>
        )}
      </div>
      {children}
    </div>
  );
};
