import {ChatConversationMessage} from "@/models/ai-model";
import {ReducedChatConversation, ReducedChatConversationMessage} from "@/reducer/chat-reducer";
import {formatReducedChatConversationMessages} from "./format-reduced-chat-conversation-messages";

export type UnformatedChatConversationMessage = ChatConversationMessage | ReducedChatConversationMessage;

export type UnformatedReducedChatConversation = Omit<ReducedChatConversation, "messages"> & {
  messages: UnformatedChatConversationMessage[];
}

export const formatReducedChatConversation = (conversation: UnformatedReducedChatConversation): ReducedChatConversation => {
  const newMessages = formatReducedChatConversationMessages(conversation.messages);

  return {
    ...conversation,
    messages: newMessages,
  };
}
