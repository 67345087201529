import React, {ReactElement, useMemo} from "react";

import {AiSources, SelectAiSourcesModal, SelectAiSourcesModalProps} from "@/modals/select-ai-sources-modal";
import {useWorkflowStateContext} from "@/context/workflow-contexts/workflow-state-context";
import {useToastContext} from "@/context/toast-context";

export type SelectWorkflowSourcesModalProps = Pick<SelectAiSourcesModalProps, "isOpen" | "onClose">;

export const SelectWorkflowSourcesModal = ({isOpen, onClose}: SelectWorkflowSourcesModalProps): ReactElement => {
  const {workflow, updateSettings} = useWorkflowStateContext();
  const {updateToast} = useToastContext();

  const init = useMemo(() => {
    return {
      campaigns: workflow?.campaigns || [],
      questions: workflow?.questions || [],
      trainingSets: workflow?.trainingSets || [],
      files: workflow?.files || [],
      videos: workflow?.videos || [],
    };
  }, [workflow]);

  const onSave = async (newSources: AiSources) => {
    try {
      if (!workflow) {
        throw new Error("No workflow found");
      }

      await updateSettings(newSources, {save: true});

      onClose();
    } catch (error) {
      updateToast({
        description: "Error while saving workflow sources",
        type: "failure",
      })
    }
  };

  return (
    <SelectAiSourcesModal
      init={init}
      isOpen={isOpen}
      onClose={onClose}
      onSave={onSave}
    />
  );
}
