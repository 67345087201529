import React, {ReactElement, ReactNode, createContext, useEffect, useMemo, useState} from "react";
import {useWorkflowContext} from "./workflow-context";
import {useValidation} from "../../hooks/useValidation";
import {AiPersonaTask} from "../../models/ai-orchestration";
import {RUN_WORKFLOW} from "../../graphql/mutations/ai-mutations";
import {useMutation} from "@apollo/client";
import {useWorkflowStateContext} from "./workflow-state-context";

export interface RunWorkflowContextValue {
	isWorkflowValid: boolean;
  handleRunWorkflow: () => Promise<void>;
}

export const RunWorkflowContext =
  createContext<RunWorkflowContextValue | undefined>(undefined);

export const RunWorkflowContextProvider = (
  {children}: {children: ReactNode},
): ReactElement => {
  const {
    workflow: {
      id: workflowId,
      aiPersonaTasks: agentTasks = []
    } = {}
  } = useWorkflowStateContext();
  const {setRunInProgress} = useWorkflowContext();
  const [runWorkflow] = useMutation(RUN_WORKFLOW);

  const workflowState = useMemo(() => ({
    workflowId,
    agentTasks,
  }), [workflowId, agentTasks]);

	const {validate: validateWorkflow, isValid: isWorkflowValid} = useValidation(workflowState, {
		agentTasks: {
			ruleset: {
				custom: (value: AiPersonaTask[]) => {
					return value?.length > 0;
				},
			},
			customErrorMessage: {
				custom: () => "Workflow must have at least one agent task",
			},
		},
    workflowId: {
      ruleset: {
        required: true,
      }
    }
	});

	useEffect(() => {
		validateWorkflow();
	}, [workflowState]);

	const handleRunWorkflow = async () => {
		if (!validateWorkflow()) {
			return;
		}
    setRunInProgress(true);
		await runWorkflow({variables: {orchestrationId: workflowId}});
	};

  return (
    <RunWorkflowContext.Provider value={{
      isWorkflowValid,
      handleRunWorkflow,
    }}>
      {children}
    </RunWorkflowContext.Provider>
  );
};

export const useRunWorkflowContext = (): RunWorkflowContextValue => {
  const context = React.useContext(RunWorkflowContext);

  if (context === undefined) {
    throw new Error(
      "useRunWorkflowContext must be used within a RunWorkflowContextProvider",
    );
  }

  return context;
};
