import React, {ReactElement} from "react";
import classNames from "classnames/bind";

import {AiOrchestration} from "../../../models/ai-orchestration";
import {ArgumentArray} from "classnames";
import {Body, Button} from "../../../shared/v2";
import {Campaign, TrainingSet, TrainingSetFile, TrainingSetMedia, TrainingSetVideo} from "../../../models/ai-model";
import {ChatBubbleQuestionIcon, DocumentsFolderFileIcon, FileTextIcon, FileVideoIcon, HornMegaphoneCampaignIcon, PlusSmallIcon, SourcesIcon} from "../../../icons";
import {InputChip} from "../../../shared/components/input-chip";
import {isQuestion, isTrainingSet, isTrainingSetFile, isTrainingSetVideo} from "@/shared";
import {NoDraggableItem} from "../no-draggable-item";
import {Question} from "@/models/questions";
import {useThemeMode} from "../../../context/theme-mode-context";
import {useWorkflowStateContext} from "@/context/workflow-contexts/workflow-state-context";

import styles from "./sources-card.module.scss";

const bStyles = classNames.bind(styles);

export type WorkflowSourceType = Question | TrainingSet | TrainingSetMedia | Campaign;

export interface SourcesCardProps {
  workflow: AiOrchestration;
  onUpdate?: () => void;
  openSourcesModal: () => void;
  currentSources: WorkflowSourceType[];
	disabled?: boolean;
}

export const SourcesCard = ({workflow, openSourcesModal, currentSources, disabled}: SourcesCardProps): ReactElement => {
	const {isDarkMode} = useThemeMode();
	const {updateSettings: updateWorkflowSettings, isUpdatingDisabled} = useWorkflowStateContext();

	const handleRemoveQuestion = (question: Question): void => {
		updateWorkflowSettings({
			questions: workflow.questions.filter(q => q.id !== question.id),
		});
	};

	const handleRemoveTrainingSet = (trainingSet: TrainingSet): void => {
		updateWorkflowSettings({
			trainingSets: workflow.trainingSets.filter(ts => ts.id !== trainingSet.id),
		});
	}

	const handleRemoveVideo = (video: TrainingSetVideo): void => {
		updateWorkflowSettings({
			videos: workflow.videos.filter(v => v.id !== video.id),
		});
	}

	const handleRemoveFile = (file: TrainingSetFile): void => {
		updateWorkflowSettings({
			files: workflow.files.filter(f => f.id !== file.id),
		});
	}

	const handleRemoveCampaign = (campaign: Campaign): void => {
		updateWorkflowSettings({
			campaigns: workflow.campaigns.filter(c => c.id !== campaign.id),
		});
	}

	const getInputChipProps = (classNames?: ArgumentArray) => ({
		variant: "squared",
		className: bStyles("source", ...(classNames || [])),
		size: "small",
		showCloseIcon: !disabled,
	} as const);

	const renderSource = (source: WorkflowSourceType) => {
		if (isQuestion(source)) {
			return (
				<InputChip
					icon={<ChatBubbleQuestionIcon />}
					key={source.id}
					onRemove={() => handleRemoveQuestion(source)}
					disabled={isUpdatingDisabled}
					label={source.text}
					{...getInputChipProps()}
				/>
			)
		}
		if (isTrainingSet(source)) {
			return (
				<InputChip
					icon={<DocumentsFolderFileIcon />}
					key={source.id}
					onRemove={() => handleRemoveTrainingSet(source)}
					disabled={isUpdatingDisabled}
					label={source.alias}
					{...getInputChipProps()}
				/>
			);
		}
		if (isTrainingSetVideo(source)) {
			return (
				<InputChip
					icon={<FileVideoIcon />}
					key={source.id}
					disabled={isUpdatingDisabled}
					onRemove={() => handleRemoveVideo(source)}
					label={source.originalFilename}
					{...getInputChipProps()}
				/>
			);
		}
		if (isTrainingSetFile(source)) {
			return (
				<InputChip
					icon={<FileTextIcon />}
					key={source.id}
					disabled={isUpdatingDisabled}
					onRemove={() => handleRemoveFile(source)}
					label={source.originalFilename}
					{...getInputChipProps()}
				/>
			);
		}

		return (
			<InputChip
				icon={<HornMegaphoneCampaignIcon />}
				key={source.id}
				disabled={isUpdatingDisabled}
				onRemove={() => handleRemoveCampaign(source)}
				label={source.name}
				{...getInputChipProps()}
			/>
		);
	};


	const renderSourcesOrPlaceholder = (): ReactElement => {
		if (currentSources.length === 0) {
			return <Body className={styles.placeholder}>
        Add datasets, campaigns, or other sources to enhance your workflow (optional).
			</Body>
		}

		return <div className={styles.sourcesList}>
			{currentSources.map(renderSource)}
		</div>
	}

	return (
		<NoDraggableItem className={styles.noDraggableContainer}>
			<div className={bStyles("container", {isDarkMode})}>
					<div className={styles.headerWrapper}>
						<SourcesIcon className={styles.icon} />
						<Body type="medium">Sources</Body>
					</div>
					<div className={styles.content}>
						{renderSourcesOrPlaceholder()}
					</div>

					{!disabled &&
						<Button
							size="small"
							variant="text"
							leftIcon={<PlusSmallIcon />}
							onClick={openSourcesModal}

						>
							Add Source
						</Button>
					}
				</div>
		</NoDraggableItem>
	)
}
