import {useLocation, useParams} from "react-router";
import {useQuery} from "@apollo/client";
import React, {createContext, useContext, useState, ReactNode, useEffect, useMemo} from "react";

import {
	AiOrchestrationHistory,
	AiPersonaTask,
	AiOrchestrationHistoryData
} from "../../models/ai-orchestration";
import {GET_AI_ORCHESTRATION_HISTORY} from "../../graphql/queries/ai-orchestration-queries";
import {useWorkspaceContext} from "../workspace-context";

interface WorkflowContextProps {
	// OPERATIONS
	isWorkflowRunning: boolean;
	runInProgress: boolean;
	setRunInProgress: React.Dispatch<React.SetStateAction<boolean>>;

	// HISTORY
	aiHistory: AiOrchestrationHistory[];
	currentHistory: AiOrchestrationHistory | undefined;
	currentHistoryRun: AiOrchestrationHistory | undefined;
	isHistoryLoading: boolean;
	setCurrentHistory: (history: AiOrchestrationHistory | undefined) => void;
	activeHistoryTasks: AiPersonaTask[];
	refetchHistory: () => void;

	// NAVIGATION
	isOnHistoryTab: boolean;

	// REPORTS
	reportUrl: string | undefined;
	reportWordUrl: string | undefined;
	setReportUrl: React.Dispatch<React.SetStateAction<string>>;
	setReportWordUrl: React.Dispatch<React.SetStateAction<string>>;
}

const WorkflowContext = createContext<WorkflowContextProps | undefined>(undefined);

export const WorkflowProvider = ({children}: { children: ReactNode }) => {
	const {workspace: {id: workspaceId}} = useWorkspaceContext();
	const location = useLocation();
	const {workflowId} = useParams();
	const [currentHistory, setCurrentHistory] = useState<AiOrchestrationHistory | undefined>(undefined);
	const [activeHistoryTasks, setActiveHistoryTasks] = useState<AiPersonaTask[]>([]);
	const [reportUrl, setReportUrl] = useState<string>("");
	const [reportWordUrl, setReportWordUrl] = useState<string>("");
	const [runInProgress, setRunInProgress] = useState(false);

	const handleSetCurrentHistory = (history: AiOrchestrationHistory | undefined) => {
		setCurrentHistory(history);
	}

	const {data: workflowHistoryData, loading: isHistoryLoading, refetch: refetchHistory} = useQuery<AiOrchestrationHistoryData>(GET_AI_ORCHESTRATION_HISTORY, {
		variables: {
			orchestrationId: workflowId
		},
		skip: !workflowId,
		fetchPolicy: "network-only",
	});

	const currentHistoryRun = useMemo(() => {
		return workflowHistoryData?.aiOrchestrationHistory?.find(
			(history) => !history.completedAt && history.status !== 'failed'
		);
	}, [workflowHistoryData?.aiOrchestrationHistory]);

	const isWorkflowRunning = useMemo(() => {
		return Boolean(currentHistoryRun) || runInProgress;
	}, [currentHistoryRun, runInProgress]);

	const isOnHistoryTab = useMemo(() => {
		return location.pathname.includes("history");
	}, [location.pathname]);

	useEffect(() => {
		if (workflowHistoryData?.aiOrchestrationHistory?.length) {
			const currentWorkflowHistory = workflowHistoryData.aiOrchestrationHistory[0];
			setCurrentHistory(currentWorkflowHistory);
		}
	}, [workflowHistoryData?.aiOrchestrationHistory]);

	useEffect(() => {
		if (currentHistory) {
			const newAgentsFromHistory = currentHistory?.outputHistoryTasks?.map((historyTask, index) => {
					return {
						id: Math.random().toString(),
						index,
						instructions: null,
						persona: historyTask.persona,
						output: historyTask.output,
						task: {
							id: historyTask.taskId,
							output: historyTask.output,
							taskPrompt: historyTask.taskPrompt,
						},
					} as AiPersonaTask;
				}
			)
			setActiveHistoryTasks(newAgentsFromHistory);
		}
	}, [currentHistory]);

	return (
		<WorkflowContext.Provider
			value={{
				aiHistory: workflowHistoryData?.aiOrchestrationHistory || [],
				currentHistory,
				setCurrentHistory: handleSetCurrentHistory,
				activeHistoryTasks,
				refetchHistory,
				isOnHistoryTab,
				reportUrl,
				reportWordUrl,
				setReportUrl,
				setReportWordUrl,
				isHistoryLoading,
				isWorkflowRunning,
				runInProgress,
				setRunInProgress,
				currentHistoryRun,
			}}
		>
			{children}
		</WorkflowContext.Provider>
	);
};

export const useWorkflowContext = () => {
	const context = useContext(WorkflowContext);
	if (!context) {
		throw new Error("useWorkflowContext must be used within a WorkflowProvider");
	}
	return context;
};
