import {AiOrchestration, AiOrchestrationData, AiPersonaTask, AiPersonaTaskOperation, UpdateOrchestrationData, UpdateOrchestrationVars} from "@/models/ai-orchestration";
import {cache} from "@/cache";
import {CREATE_PERSONA_TASK, DELETE_PERSONA_TASK, UPDATE_AI_ORCHESTRATION, UPDATE_PERSONA_TASK} from "@/graphql/mutations/ai-mutations";
import {GET_AI_ORCHESTRATION} from "@/graphql/queries/ai-orchestration-queries";
import {ModifyTasksFunction, UpdateWorkflowActionPayload, WorkflowActionTypes, workflowReducer, workflowReducerInit, UpdateAiPersonaTaskInput, WorkflowChanges} from "@/reducer/workflow-reducer";
import {useMutation, useQuery} from "@apollo/client";
import {useParams} from "react-router";
import {useTask} from "@/hooks/useTask";
import {useToastContext} from "../toast-context";
import React, {ReactElement, ReactNode, createContext, useEffect, useReducer, useRef} from "react";

export interface UpdateWorkflowOptions {
  save: boolean | SaveWorkflowOptions;
}

export interface SaveWorkflowOptions {
  tasks: boolean;
  settings: boolean;
}

export interface WorkflowStateContextValue {
  workflow: AiOrchestration | undefined;
  isDirty: boolean;
  isLoading: boolean;
  isUpdatingDisabled: boolean;
  save: (opts?: SaveWorkflowOptions) => Promise<void>;
  updateSettings: (input: UpdateWorkflowActionPayload, opts?: UpdateWorkflowOptions) => void;
  setTasks: (modifier: ModifyTasksFunction | AiOrchestration["aiPersonaTasks"]) => void;
  createTask: (task: AiPersonaTask) => void;
  deleteTask: (id: string) => void;
  updateTask: (id: string, input: UpdateAiPersonaTaskInput) => void;
}

export const WorkflowStateContext =
  createContext<WorkflowStateContextValue | undefined>(undefined);

export const WorkflowStateContextProvider = (
  {children}: {children: ReactNode},
): ReactElement => {
  const {workflowId} = useParams();
  const [{
    original: workflowOriginal,
    workflow: workflowState,
    changes: workflowChanges,
    isDirty: isWorkflowDirty,
  }, dispatch] = useReducer(workflowReducer, workflowReducerInit);
  const {updateToast} = useToastContext();
  const shouldSaveOnNextUpdateRef = useRef<boolean | SaveWorkflowOptions>(false);

  const {data: workflowData, loading: isLoadingWorkflow, refetch: refetchWorkflow} = useQuery<AiOrchestrationData>(
    GET_AI_ORCHESTRATION,
    {
      variables: {id: workflowId},
      skip: !workflowId,
      fetchPolicy: "cache-first",
    }
  );

  const [updateWorkflow] = useMutation<UpdateOrchestrationData, UpdateOrchestrationVars>(UPDATE_AI_ORCHESTRATION);
  const [createTaskMutation] = useMutation(CREATE_PERSONA_TASK);
  const [updateTaskMutation] = useMutation(UPDATE_PERSONA_TASK);
  const [deleteTaskMutation] = useMutation(DELETE_PERSONA_TASK);

  const syncWorkflowState = () => {
    const workflow = workflowData?.aiOrchestration;

    if (!workflow) {
      return;
    }

    if (workflowOriginal) {
      dispatch({type: WorkflowActionTypes.UPDATE_ORIGINAL, payload: workflow});
    } else {
      dispatch({type: WorkflowActionTypes.SET_ORIGINAL, payload: workflow});
    }
  };

  useEffect(syncWorkflowState, [workflowData]);

  const _saveSettings = async (changes: WorkflowChanges["settings"]) => {
    try {
      const {data: updateData} = await updateWorkflow({variables: changes});

      if (!updateData) {
        throw new Error("Failed to update workflow");
      }

      const {
        updateAiOrchestration: workflow
      } = updateData;

      cache.writeQuery({
        query: GET_AI_ORCHESTRATION,
        variables: {id: workflowId},
        data: {aiOrchestration: workflow},
      });
    } catch (_) {
      updateToast({
        type: "failure",
        description: "Failed to save workflow",
      })
    }
  };

  const _saveTasks = async (changes: WorkflowChanges["tasks"]) => {
    const promises = [] as Promise<any>[];

    for (const {operation, input} of changes) {
      switch (operation) {
        case AiPersonaTaskOperation.CREATE:
          promises.push(createTaskMutation({variables: {input}}));
          break;
        case AiPersonaTaskOperation.UPDATE:
          promises.push(updateTaskMutation({variables: {input}}));
          break;
        case AiPersonaTaskOperation.DELETE:
          promises.push(deleteTaskMutation({variables: input}));
          break;
      }
    }

    await Promise.all(promises);

    updateToast({
      type: "success",
      description: "Workflow saved successfully",
    })

    await refetchWorkflow();
  }

  const {run: save, loading: isSaving} = useTask(async (opts?: SaveWorkflowOptions) => {
    const {tasks = true, settings = true} = opts || {};

    if (!isWorkflowDirty || !workflowState || !workflowOriginal || (!tasks && !settings)) {
      return;
    }

    const {
      settings: updateWorkflowInput,
      tasks: updateTasksInputs,
    } = workflowChanges;

    const promises = [] as Promise<any>[];

    if (settings && updateWorkflowInput) {
      promises.push(_saveSettings(updateWorkflowInput));
    }

    if (tasks && updateTasksInputs.length) {
      promises.push(_saveTasks(updateTasksInputs));
    }

    await Promise.all(promises);
    dispatch({type: WorkflowActionTypes.UPDATE_ORIGINAL, payload: workflowState});
  }, [workflowChanges]);

  useEffect(() => {
    const shouldSave = shouldSaveOnNextUpdateRef.current;

    if (!shouldSave) {
      return;
    }

    shouldSaveOnNextUpdateRef.current = false;
    shouldSave === true ? save() : save(shouldSave);
  }, [save]);

  const updateSettings = (input: UpdateWorkflowActionPayload, opts?: UpdateWorkflowOptions) => {
    dispatch({type: WorkflowActionTypes.UPDATE_WORKFLOW, payload: input});

    if (opts?.save) {
      shouldSaveOnNextUpdateRef.current = {settings: true, tasks: false};
    }
  };

  const setTasks = (tasks: ModifyTasksFunction | AiOrchestration["aiPersonaTasks"]) => {
    const modifier = Array.isArray(tasks) ? () => tasks : tasks;
    dispatch({type: WorkflowActionTypes.SET_TASKS, payload: {modifier}});
  };

  const createTask = (task: AiPersonaTask) => {
    dispatch({type: WorkflowActionTypes.ADD_TASK, payload: {task}});
  };

  const deleteTask = (id: string) => {
    dispatch({type: WorkflowActionTypes.DELETE_TASK, payload: {id}});
  };

  const updateTask = (id: string, input: UpdateAiPersonaTaskInput) => {
    dispatch({type: WorkflowActionTypes.UPDATE_TASK, payload: {id, input}});
  };

  return (
    <WorkflowStateContext.Provider value={{
      workflow: workflowState,
      isDirty: isWorkflowDirty,
      isLoading: isLoadingWorkflow,
      isUpdatingDisabled: isLoadingWorkflow || isSaving,
      save,
      updateSettings,
      setTasks,
      createTask,
      deleteTask,
      updateTask,
    }}>
      {children}
    </WorkflowStateContext.Provider>
  );
};

export const useWorkflowStateContext = (): WorkflowStateContextValue => {
  const context = React.useContext(WorkflowStateContext);

  if (context === undefined) {
    throw new Error(
      "useWorkflowStateContext must be used within a WorkflowStateContextProvider",
    );
  }

  return context;
};
