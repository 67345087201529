import React, { ReactElement } from "react";
import styles from "./TopBar.module.scss";
import { Button, Subheader } from "@/shared/v2";
import { ChevronLeftIcon, CloudSaveUploadIcon } from "@/icons";
import { useThemeMode } from "@/context/theme-mode-context";
import classNames from "classnames/bind";
import { useNavigate } from "react-router-dom";

const cx = classNames.bind(styles);

export const TopBar = (): ReactElement => {
  const { isDarkMode } = useThemeMode();
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(-1);
  };

  return (
    <div className={cx("topbar", { isDarkMode })}>
      <Button
        variant='text'
        size='small'
        leftIcon={<ChevronLeftIcon />}
        onClick={navigateBack}>
        Back to campaign
      </Button>

      <Subheader type='medium'>Recruitment Survey</Subheader>

      <Button size='small' leftIcon={<CloudSaveUploadIcon />}>
        Save as Draft
      </Button>
    </div>
  );
};
