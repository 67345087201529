import React, {ReactElement, useState} from "react";
import {useMutation} from "@apollo/client";
import classNames from "classnames/bind";

import {TRAINING_SET_UPDATE} from "../../../graphql/mutations/ai-mutations";
import {GET_TRAINING_SETS_PAGE} from "../../../graphql/queries/ai-models-queries";
import {useToastContext} from "../../../context/toast-context";
import {BaseModalProps, Button, Modal} from "../../../shared/v2";
import {useThemeMode} from "../../../context/theme-mode-context";
import {TrainingSetForm} from "../../../datasets/components";
import {TrainingSet} from "../../../models";

import styles from "./add-training-set-modal.module.scss";

const bStyles = classNames.bind(styles);

export interface EditTrainingSetModalProps extends BaseModalProps {
  trainingSet: TrainingSet;
}

export const EditTrainingSetModal = ({trainingSet, isOpen, onClose}: EditTrainingSetModalProps): ReactElement => {
	const {updateToast} = useToastContext();
	const {isDarkMode} = useThemeMode();

	const [updateTrainingSet, {loading: isCreating}] = useMutation(TRAINING_SET_UPDATE, {
		refetchQueries: [GET_TRAINING_SETS_PAGE],
	});

	const [alias, setAlias] = useState(trainingSet.alias);
	const [description, setDescription] = useState(trainingSet.description || "");

	const handleSave = (): void => {
		updateTrainingSet({
			variables: {
				trainingSetId: trainingSet.id,
				changes: {
					alias,
					description,
				},
			},
			onCompleted: () => {
				onClose();
				updateToast({
					type: "success",
					description: "Dataset updated successfully.",
				})
			},
			onError: error => {
				const errorMessage = error.message.includes("trainingsets_workspaceid_name_unique") ?
					"The name needs to be unique across workspace." : error.message;

				updateToast({
					description: errorMessage,
					type: "failure",
				});
			},
		});
	};

	return (
		<Modal
			title={`Edit ${trainingSet.alias}`}
			isOpen={isOpen}
			onClose={onClose}
			className={bStyles("modal", {isDarkMode})}
		>

			<TrainingSetForm
				name={alias}
				setName={setAlias}
				description={description}
				setDescription={setDescription}
			/>
			<Button
				disabled={isCreating || !alias}
				onClick={handleSave}
				className={styles.button}
			>
        Edit dataset
			</Button>
		</Modal>
	);
};
