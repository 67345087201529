import { BaseModalProps, Button, Modal, TextArea } from "@/shared/v2";
import React, { ReactElement, useState } from "react";

import styles from "./AddMultipleModal.module.scss";
import { QualifyingQuestionAnswer } from "..";
export interface AddMultipleModalProps extends BaseModalProps {
  title: string;
  description: string;
  onAddAnswers: (answers: QualifyingQuestionAnswer[]) => void;
}

export const AddMultipleModal = ({
  isOpen,
  title,
  description,
  onClose,
  onAddAnswers,
}: AddMultipleModalProps): ReactElement => {
  const [answers, setAnswers] = useState<string>("");

  const handleSaveAnswers = () => {
    const answersArray = answers.split("\n");
    const newAnswers = answersArray.map((answer) => ({
      text: answer,
      type: "qualify" as const,
      order: answersArray.length,
    }));
    onAddAnswers(newAnswers);
    setAnswers("");
    onClose();
  };

  const handleCancel = () => {
    setAnswers("");
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCancel}
      title={title}
      description={description}>
      <TextArea placeholder='Answer' onChange={setAnswers} value={answers} />

      <div className={styles.buttonContainer}>
        <Button
          onClick={handleCancel}
          className={styles.button}
          variant='outlined'>
          Cancel
        </Button>
        <Button onClick={handleSaveAnswers} className={styles.button}>
          Save Answers
        </Button>
      </div>
    </Modal>
  );
};
