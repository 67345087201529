import React, { ReactElement } from "react";
import styles from "./BottomBar.module.scss";
import { Button } from "@/shared/v2";
import { ChevronLeftIcon } from "@/icons";
import { useRecruitContext, STEPS } from "@/campaign/contexts/RecruitContext";
import { useNavigate } from "react-router-dom";

export const BottomBar = (): ReactElement => {
  const { setCurrentStep } = useRecruitContext();
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(-1);
  };

  const goToNextStep = () => {
    setCurrentStep(STEPS.QUALIFYING_QUESTIONS);
  };

  return (
    <div className={styles.bottomBar}>
      <Button
        variant='text'
        size='small'
        leftIcon={<ChevronLeftIcon />}
        onClick={navigateBack}>
        Back to campaign
      </Button>

      <Button onClick={goToNextStep}>Next: Build Your Questions</Button>
    </div>
  );
};
