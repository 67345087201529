import React, { ReactElement } from "react";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { QualifyingQuestionAnswer } from "..";
import { Answer } from "../Answer";

interface SortableAnswersProps {
  answers: QualifyingQuestionAnswer[];
  questionType: "single" | "multiple";
  onDragEnd: (event: DragEndEvent) => void;
  onRemoveAnswer: (index: number) => void;
}

export const SortableAnswers = ({
  answers,
  questionType,
  onDragEnd,
  onRemoveAnswer,
}: SortableAnswersProps): ReactElement => {
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={onDragEnd}>
      <SortableContext
        key={answers.map((a) => a.id).join(",")}
        items={answers.map((a) => a.order)}
        strategy={verticalListSortingStrategy}>
        {answers.map((answer, index) => (
          <Answer
            key={answer.order}
            answer={answer}
            onRemove={() => onRemoveAnswer(index)}
            questionType={questionType}
          />
        ))}
      </SortableContext>
    </DndContext>
  );
};
